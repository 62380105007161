import { BASE_URL, INSURANCE_INFO } from "../../../../utils/constants";
import { dateFormatForApi } from "../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getInsuranceInfo = createAsyncThunk(
    "getInsuranceInfo",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${INSURANCE_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getInsuranceInfoByID = createAsyncThunk(
    "getInsuranceInfoByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${INSURANCE_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setInsuranceInfo = createAsyncThunk(
    "setInsuranceInfo",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${INSURANCE_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setInsuranceInfoByID = createAsyncThunk(
    "setInsuranceInfoByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${INSURANCE_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveInsuranceInfo = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationInsuranceInfo;

    const data = {
        "dontHaveInsurance": store.dontHaveInsurance,
        "allMembers": store.allMembers,
        "totalIncome": store.totalIncome,
        "medicare": store.medicare,
        "medicareEffective": dateFormatForApi(store.medicareEffDate),
        "medicareExpDate": dateFormatForApi(store.medicareExpDate),
        "medicareNumber": store.medicareNumber,
        "medicaid": store.medicaid,
        "medicaidEffective": dateFormatForApi(store.medicaidEffDate),
        "medicaidExpDate": dateFormatForApi(store.medicaidExpDate),
        "medicaidNumber": store.medicaidNumber,
        "denaliKid": store.denaliKid,
        "denaliKidEffective": dateFormatForApi(store.denaliKidEffDate),
        "denaliKidExpDate": dateFormatForApi(store.denaliKidExpDate),
        "denaliKidNumber": store.denaliKidNumber,
        "privateInsurance": store.privateInsurance,
        "companyInsurance": store.companyInsurance,
        "policyHolder": store.policyHolder,
        "policyHolderNumber": store.policyHolderNumber,
        "policyHolderGroupNumber": store.policyHolderGroupNumber,
        "otherInsurance": store.otherInsurance,
        "otherInsuranceNumber": store.otherInsuranceNumber,
        "otherInsuranceGroupNumber": store.otherInsuranceGroupNumber,
        "veteran": store.veteran,
        "benefitsVA": store.benefitsVA,
        "serviceBranch": store.serviceBranch,
        "serviceEntryDate": dateFormatForApi(store.serviceEntryDate),
        "serviceExitDate": dateFormatForApi(store.serviceExitDate),
        "vietnamService": store.vietnamService,
        "doNotOtherInsurance": store.doNotOtherInsurance,
        "selfPay": store.selfPay,
        "relationship": store.relationship,
        "DOB": dateFormatForApi(store.DOB),
        "phone": store.phone,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "effectiveDate": dateFormatForApi(store.effectiveDate),
    }

    dispatch(setInsuranceInfo(data));
};

export const saveInsuranceInfoByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationInsuranceInfo;

    const data = {
        "dontHaveInsurance": store.dontHaveInsurance,
        "allMembers": store.allMembers,
        "totalIncome": store.totalIncome,
        "medicare": store.medicare,
        "medicareEffective": dateFormatForApi(store.medicareEffDate),
        "medicareExpDate": dateFormatForApi(store.medicareExpDate),
        "medicareNumber": store.medicareNumber,
        "medicaid": store.medicaid,
        "medicaidEffective": dateFormatForApi(store.medicaidEffDate),
        "medicaidExpDate": dateFormatForApi(store.medicaidExpDate),
        "medicaidNumber": store.medicaidNumber,
        "denaliKid": store.denaliKid,
        "denaliKidEffective": dateFormatForApi(store.denaliKidEffDate),
        "denaliKidExpDate": dateFormatForApi(store.denaliKidExpDate),
        "denaliKidNumber": store.denaliKidNumber,
        "privateInsurance": store.privateInsurance,
        "companyInsurance": store.companyInsurance,
        "policyHolder": store.policyHolder,
        "policyHolderNumber": store.policyHolderNumber,
        "policyHolderGroupNumber": store.policyHolderGroupNumber,
        "otherInsurance": store.otherInsurance,
        "otherInsuranceNumber": store.otherInsuranceNumber,
        "otherInsuranceGroupNumber": store.otherInsuranceGroupNumber,
        "veteran": store.veteran,
        "benefitsVA": store.benefitsVA,
        "serviceBranch": store.serviceBranch,
        "serviceEntryDate": dateFormatForApi(store.serviceEntryDate),
        "serviceExitDate": dateFormatForApi(store.serviceExitDate),
        "vietnamService": store.vietnamService,
        "doNotOtherInsurance": store.doNotOtherInsurance,
        "selfPay": store.selfPay,
        "relationship": store.relationship,
        "DOB": dateFormatForApi(store.DOB),
        "phone": store.phone,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "effectiveDate": dateFormatForApi(store.effectiveDate),
    }

    dispatch(setInsuranceInfoByID({ id, data }));
};