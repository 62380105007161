import { useParams } from 'react-router-dom';
import { selectLoading, selectEducation, selectLivingArrangement, selectGradeSchoolEducation, selectLastGradeEducation, selectOtherLivingArrangement, selectOtherEducation, selectLivingCondition } from '../../../../../store/forms/newPatientRegistration/education/selectors';
import { setEducation, setLivingArrangement, setLivingCondition, toggleGradeSchoolEducation, setLastGradeEducation, setOtherLivingArrangement, setOtherEducation } from '../../../../../store/forms/newPatientRegistration/education/slice';
import { getEducation, saveEducation, getEducationByID, saveEducationByID } from '../../../../../store/forms/newPatientRegistration/education/middlewares';
import { completeEducation } from '../../../../../store/forms/newPatientRegistration/slice';

import { getLivingArrangements } from '../../../../../store/lookups/livingArrangement/middlewares';
import { getLivingConditions } from '../../../../../store/lookups/livingCondition/middlewares';
import { getEducations } from '../../../../../store/lookups/education/middlewares';

import { selectLivingArrangements } from '../../../../../store/lookups/livingArrangement/selectors';
import { selectLivingConditions } from '../../../../../store/lookups/livingCondition/selectors';
import { selectEducations } from '../../../../../store/lookups/education/selectors';

import { validateGroup } from '../../../../../utils/helper';
import { useDispatch, useSelector } from "react-redux";
import { Education } from './Education';
import { useEffect } from 'react';

export const EducationContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const educations = useSelector(selectEducations);
    const livingArrangements = useSelector(selectLivingArrangements);
    const livingConditions = useSelector(selectLivingConditions);

    const education = useSelector(selectEducation);
    const otherEducation = useSelector(selectOtherEducation);
    const lastGradeEducation = useSelector(selectLastGradeEducation);
    const gradeSchoolEducation = useSelector(selectGradeSchoolEducation);
    const livingArrangement = useSelector(selectLivingArrangement);
    const otherLivingArrangement = useSelector(selectOtherLivingArrangement);
    const livingCondition = useSelector(selectLivingCondition);

    useEffect(() => {
        dispatch(id ? getEducationByID(id) : getEducation());
        dispatch(getEducations());
        dispatch(getLivingConditions());
        dispatch(getLivingArrangements());
    }, [dispatch, id]);

    const onChangeEducation = (event) => {
        dispatch(setEducation(event.value));
    }

    const onChangeOtherEducation = (event) => {
        dispatch(setOtherEducation(event.value.trim()));
    }

    const onChangeLastGradeEducation = (event) => {
        dispatch(setLastGradeEducation(event.value.trim()));
    }

    const onToggleGradeSchoolEducation = () => {
        dispatch(toggleGradeSchoolEducation());
    }

    const onChangeLivingArrangement = (event) => {
        dispatch(setLivingArrangement(event.value));
    }

    const onChangeOtherLivingArrangement = (event) => {
        dispatch(setOtherLivingArrangement(event.value.trim()));
    }

    const onChangeLivingCondition = (event) => {
        dispatch(setLivingCondition(event.value));
    }

    const onSaveEducation = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveEducationByID(id) : saveEducation());
            dispatch(completeEducation(true));
        }
    }

    const onSaveeAndContinueEducation = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveEducationByID(id) : saveEducation());
            dispatch(completeEducation(true));
            onClickNext();
        }
    }

    return (
        <Education
            loading={loading}

            educations={educations}
            livingArrangements={livingArrangements}
            livingConditions={livingConditions}

            education={education}
            otherEducation={otherEducation}
            lastGradeEducation={lastGradeEducation}
            gradeSchoolEducation={gradeSchoolEducation}
            livingArrangement={livingArrangement}
            otherLivingArrangement={otherLivingArrangement}
            livingCondition={livingCondition}

            onChangeEducation={onChangeEducation}
            onChangeOtherEducation={onChangeOtherEducation}
            onChangeLastGradeEducation={onChangeLastGradeEducation}
            onToggleGradeSchoolEducation={onToggleGradeSchoolEducation}
            onChangeLivingArrangement={onChangeLivingArrangement}
            onChangeOtherLivingArrangement={onChangeOtherLivingArrangement}
            onChangeLivingCondition={onChangeLivingCondition}

            onSaveEducation={onSaveEducation}
            onSaveeAndContinueEducation={onSaveeAndContinueEducation}
        />
    );
};