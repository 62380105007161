import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { selectLoading, selectAddress, selectPregnant, selectAnyCustodyArrangements, selectCanLeaveVoiceMessage, selectCity, selectContactedByMedicalRN, selectContactEmail, selectContactFullName, selectContactPhone, selectContactRelationship, selectdateConsent, selectDateOfBirth, selectDrugUser, selectEmail, selectEmployer, selectEmployerAddress, selectEmployerCity, selectEmployerState, selectEmployerZip, selectFamilyMembers, selectFirstName, selectGender, selectGroupNumber, selectHaveBeenInHospitalLast6Months, selectHaveEverBeenInHospital, selectHaveHadIllnessOrInjury, selectInsuranceProvider, selectLastName, selectMiddleName, selectNumberOfArrests, selectNumberOfChildren, selectNumberOfPeople, selectOtherGender, selectPhone, selectPhysicalExam, selectPolicyNumber, selectSSN, selectState, selectZip, selectMaritalStatus, selectEmployment, selectIncomeEstimate, selectLivingWill } from '../../../store/forms/annualPatientRegistration/selectors';
import { setLoading, setAddress, setCity, setContactEmail, setContactFullName, setContactPhone, setContactRelationship, setDateConsent, setDateOfBirth, toggleDrugUser, setEmail, setEmployer, setEmployerAddress, setEmployerCity, setEmployerState, setEmployerZip, setEmployment, setFamilyMembers, setFirstName, setGender, setGroupNumber, setHaveBeenInHospitalLast6Months, setHaveEverBeenInHospital, setHaveHadIllnessOrInjury, setIncomeEstimate, setInsuranceProvider, setLastName, setLivingWill, setMaritalStatus, setMiddleName, setNumberOfArrests, setNumberOfChildren, setNumberOfPeople, setOtherGender, setPhone, setPhysicalExam, setPolicyNumber, setPregnant, setSSN, setState, setZip, toggleAnyCustodyArrangements, toggleCanLeaveVoiceMessage, toggleContactedByMedicalRN } from '../../../store/forms/annualPatientRegistration/slice';

import { selectAdvHCareDirectiveStatuses } from "../../../store/lookups/advHCareDirectiveStatus/selectors";
import { selectTotalHouseholdIncomes } from "../../../store/lookups/totalHouseholdIncome/selectors";
import { selectHospitalInSixMonths } from "../../../store/lookups/hospitalInSixMonths/selectors";
import { selectHospitalResidentialTreatments } from "../../../store/lookups/hospitalResidentialTreatment/selectors";
import { selectIllnessInjury12months } from "../../../store/lookups/illnessInjury12months/selectors";
import { selectPregnantStatuses } from "../../../store/lookups/pregnantStatus/selectors";
import { selectMaritalStatuses } from "../../../store/lookups/maritalStatus/selectors";
import { selectEmployments } from '../../../store/lookups/employment/selectors';
import { selectGenders } from "../../../store/lookups/gender/selectors";
import { selectStates } from '../../../store/lookups/state/selectors';

import { getAnnualPatientRegistration, saveAnnualPatientRegistration, getAnnualPatientRegistrationByID, saveAnnualPatientRegistrationByID } from '../../../store/forms/annualPatientRegistration/middlewares';
import { getAdvHCareDirectiveStatuses } from "../../../store/lookups/advHCareDirectiveStatus/middlewares";
import { getTotalHouseholdIncomes } from "../../../store/lookups/totalHouseholdIncome/middlewares";
import { getHospitalInSixMonths } from '../../../store/lookups/hospitalInSixMonths/middlewares';
import { getHospitalResidentialTreatments } from '../../../store/lookups/hospitalResidentialTreatment/middlewares';
import { getIllnessInjury12months } from '../../../store/lookups/illnessInjury12months/middlewares';
import { getPregnantStatuses } from "../../../store/lookups/pregnantStatus/middlewares";
import { getMaritalStatuses } from "../../../store/lookups/maritalStatus/middlewares";
import { getEmployments } from '../../../store/lookups/employment/middlewares';
import { getGenders } from "../../../store/lookups/gender/middlewares";
import { getState } from '../../../store/lookups/state/middlewares';

import { AnnualPatientRegistration } from './AnnualPatientRegistration';
import { useDispatch, useSelector } from "react-redux";
import { validateGroup } from '../../../utils/helper';
import { useEffect } from 'react';
import { showToast } from '../../Main/MainContainer';
import { isAdmin } from '../../../core/auth/authProvider';
import { USER_PATH } from '../../../utils/constants';

export const AnnualPatientRegistrationContainer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id }   = useParams();
    const loading = useSelector(selectLoading);

    const states = useSelector(selectStates);
    const genders = useSelector(selectGenders);
    const employments = useSelector(selectEmployments);
    const maritalStatuses = useSelector(selectMaritalStatuses);
    const pregnantStatuses = useSelector(selectPregnantStatuses);
    const totalHouseholdIncomes = useSelector(selectTotalHouseholdIncomes);
    const hospitalInSixMonths = useSelector(selectHospitalInSixMonths);
    const hospitalResidentialTreatment = useSelector(selectHospitalResidentialTreatments);
    const illnessInjury12months = useSelector(selectIllnessInjury12months);
    const advHCareDirectiveStatuses = useSelector(selectAdvHCareDirectiveStatuses);

    const firstName = useSelector(selectFirstName);
    const middleName = useSelector(selectMiddleName);
    const lastName = useSelector(selectLastName);
    const dateOfBirth = useSelector(selectDateOfBirth);
    const SSN = useSelector(selectSSN);
    const gender = useSelector(selectGender);
    const otherGender = useSelector(selectOtherGender);
    const email = useSelector(selectEmail);
    const phone = useSelector(selectPhone);
    const canLeaveVoiceMessage = useSelector(selectCanLeaveVoiceMessage);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const maritalStatus = useSelector(selectMaritalStatus);
    const numberOfPeople = useSelector(selectNumberOfPeople);
    const numberOfChildren = useSelector(selectNumberOfChildren);
    const anyCustodyArrangements = useSelector(selectAnyCustodyArrangements);

    const contactFullName = useSelector(selectContactFullName);
    const contactRelationship = useSelector(selectContactRelationship);
    const contactEmail = useSelector(selectContactEmail);
    const contactPhone = useSelector(selectContactPhone);
    const insuranceProvider = useSelector(selectInsuranceProvider);
    const policyNumber = useSelector(selectPolicyNumber);
    const groupNumber = useSelector(selectGroupNumber);

    const familyMembers = useSelector(selectFamilyMembers);
    const employment = useSelector(selectEmployment);
    const totalHouseholdIncome = useSelector(selectIncomeEstimate);
    const employer = useSelector(selectEmployer);
    const employerAddress = useSelector(selectEmployerAddress);
    const employerCity = useSelector(selectEmployerCity);
    const employerState = useSelector(selectEmployerState);
    const employerZip = useSelector(selectEmployerZip);
    const numberOfArrests = useSelector(selectNumberOfArrests);

    const pregnantStatus = useSelector(selectPregnant);
    const drugUser = useSelector(selectDrugUser);
    const haveEverBeenInHospital = useSelector(selectHaveEverBeenInHospital);
    const haveBeenInHospitalLast6Months = useSelector(selectHaveBeenInHospitalLast6Months);
    const haveHadIllnessOrInjury = useSelector(selectHaveHadIllnessOrInjury);
    const physicalExam = useSelector(selectPhysicalExam);
    const contactedByMedicalRN = useSelector(selectContactedByMedicalRN);
    const advHCareDirectiveStatus = useSelector(selectLivingWill);
    const dateConsent = useSelector(selectdateConsent);

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(id ? getAnnualPatientRegistrationByID(id) : getAnnualPatientRegistration());
        dispatch(getState());
        dispatch(getGenders());
        dispatch(getEmployments());
        dispatch(getMaritalStatuses());
        dispatch(getPregnantStatuses());
        dispatch(getTotalHouseholdIncomes());
        dispatch(getHospitalInSixMonths());
        dispatch(getHospitalResidentialTreatments());
        dispatch(getIllnessInjury12months());
        dispatch(getAdvHCareDirectiveStatuses());
    }, [dispatch, id]);

    const onRowInsertingFamilyMembers = (event) => {
        const newValue = [...familyMembers, {
            id: new Date().toISOString(),
            name: event.data?.name,
            relationship: event.data?.relationship,
            age: event.data?.age
        }];

        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setFamilyMembers(newValue));
    }

    const onRowRemovingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = newValue.findIndex(el => el.id === event.data?.id);
        newValue.splice(newId, 1);

        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setFamilyMembers(newValue));
    }

    const onRowUpdatingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = familyMembers.findIndex(el => el.id === event.oldData?.id);
        newValue[newId] = { ...familyMembers[newId], ...event.newData };

        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setFamilyMembers(newValue));
    }

    const onChangeFirstName = (event) => {
        dispatch(setFirstName(event.value.trim()));
    }

    const onChangeMiddleName = (event) => {
        dispatch(setMiddleName(event.value.trim()));
    }

    const onChangeLastName = (event) => {
        dispatch(setLastName(event.value.trim()));
    }

    const onChangeDateOfBirth = (event) => {
        dispatch(setDateOfBirth(event.value));
    }

    const onChangeSSN = (event) => {
        dispatch(setSSN(event.value));
    }

    const onChangeGender = (event) => {
        dispatch(setGender(event.value));
    }

    const onChangeOtherGender = (event) => {
        dispatch(setOtherGender(event.value));
    }

    const onChangeEmail = (event) => {
        dispatch(setEmail(event.value.trim()));
    }

    const onChangePhone = (event) => {
        dispatch(setPhone(event.value));
    }

    const onChangeAddress = (event) => {
        dispatch(setAddress(event.value.trim()));
    }

    const onChangeCity = (event) => {
        dispatch(setCity(event.value.trim()));
    }

    const onChangeState = (event) => {
        dispatch(setState(event.value));
    }

    const onChangeZip = (event) => {
        dispatch(setZip(event.value));
    }

    const onChangeMaritalStatus = (event) => {
        dispatch(setMaritalStatus(event.value));
    }

    const onChangeNumberOfPeople = (event) => {
        dispatch(setNumberOfPeople(event.value));
    }

    const onChangeNumberOfChildren = (event) => {
        dispatch(setNumberOfChildren(event.value));
    }

    const onToggleCanLeaveVoiceMessage = () => {
        dispatch(toggleCanLeaveVoiceMessage());
    }

    const onToggleAnyCustodyArrangements = () => {
        dispatch(toggleAnyCustodyArrangements());
    }

    const onChangeContactFullName = (event) => {
        dispatch(setContactFullName(event.value.trim()));
    }

    const onChangeContactRelationship = (event) => {
        dispatch(setContactRelationship(event.value));
    }

    const onChangeContactEmail = (event) => {
        dispatch(setContactEmail(event.value.trim()));
    }

    const onChangeContactPhone = (event) => {
        dispatch(setContactPhone(event.value));
    }

    const onChangeInsuranceProvider = (event) => {
        dispatch(setInsuranceProvider(event.value));
    }

    const onChangePolicyNumber = (event) => {
        dispatch(setPolicyNumber(event.value));
    }

    const onChangeGroupNumber = (event) => {
        dispatch(setGroupNumber(event.value));
    }

    const onChangeIncomeEstimate = (event) => {
        dispatch(setIncomeEstimate(event.value));
    }

    const onChangeEmployment = (event) => {
        dispatch(setEmployment(event.value));
    }

    const onChangeEmployer = (event) => {
        dispatch(setEmployer(event.value.trim()));
    }

    const onChangeEmployerAddress = (event) => {
        dispatch(setEmployerAddress(event.value.trim()));
    }

    const onChangeEmployerCity = (event) => {
        dispatch(setEmployerCity(event.value.trim()));
    }

    const onChangeEmployerState = (event) => {
        dispatch(setEmployerState(event.value.trim()));
    }

    const onChangeEmployerZip = (event) => {
        dispatch(setEmployerZip(event.value));
    }

    const onChangeNumberOfArrests = (event) => {
        dispatch(setNumberOfArrests(event.value));
    }

    const onChangePregnant = (event) => {
        dispatch(setPregnant(event.value));
    }

    const onToggleDrugUser = () => {
        dispatch(toggleDrugUser());
    }

    const onChangeHaveEverBeenInHospital = (event) => {
        dispatch(setHaveEverBeenInHospital(event.value));
    }

    const onChangeHaveBeenInHospitalLast6Months = (event) => {
        dispatch(setHaveBeenInHospitalLast6Months(event.value));
    }

    const onChangeHaveHadIllnessOrInjury = (event) => {
        dispatch(setHaveHadIllnessOrInjury(event.value));
    }

    const onChangePhysicalExam = (event) => {
        dispatch(setPhysicalExam(event.value));
    }

    const onChangeLivingWill = (event) => {
        dispatch(setLivingWill(event.value));
    }

    const onChangeDateConsent = (event) => {
        dispatch(setDateConsent(event.value));
    }

    const onToggleContactedByMedicalRN = () => {
        dispatch(toggleContactedByMedicalRN());
    }

    const onSaveAnnualPatientRegistration = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveAnnualPatientRegistrationByID(id) : saveAnnualPatientRegistration()).then(() => {
                !isAdmin() && navigate('/main/forms/all');
            }).catch(() => {
                showToast('error', 'Error to save Annual Patient Registration form');
            });
        }
    }

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

    return (
        <AnnualPatientRegistration
            loading={loading}

            states={states}
            genders={genders}
            employments={employments}
            maritalStatuses={maritalStatuses}
            pregnantStatuses={pregnantStatuses}
            totalHouseholdIncomes={totalHouseholdIncomes}
            hospitalInSixMonths={hospitalInSixMonths}
            hospitalResidentialTreatment={hospitalResidentialTreatment}
            illnessInjury12months={illnessInjury12months}
            advHCareDirectiveStatuses={advHCareDirectiveStatuses}

            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dateOfBirth={dateOfBirth}
            SSN={SSN}
            gender={gender}
            otherGender={otherGender}
            email={email}
            phone={phone}
            canLeaveVoiceMessage={canLeaveVoiceMessage}
            address={address}
            city={city}
            state={state}
            zip={zip}
            maritalStatus={maritalStatus}
            numberOfPeople={numberOfPeople}
            numberOfChildren={numberOfChildren}
            anyCustodyArrangements={anyCustodyArrangements}

            contactFullName={contactFullName}
            contactRelationship={contactRelationship}
            contactEmail={contactEmail}
            contactPhone={contactPhone}
            insuranceProvider={insuranceProvider}
            policyNumber={policyNumber}
            groupNumber={groupNumber}

            familyMembers={familyMembers}
            employment={employment}
            totalHouseholdIncome={totalHouseholdIncome}
            employer={employer}
            employerAddress={employerAddress}
            employerCity={employerCity}
            employerState={employerState}
            employerZip={employerZip}
            numberOfArrests={numberOfArrests}

            pregnantStatus={pregnantStatus}
            drugUser={drugUser}
            haveEverBeenInHospital={haveEverBeenInHospital}
            haveBeenInHospitalLast6Months={haveBeenInHospitalLast6Months}
            haveHadIllnessOrInjury={haveHadIllnessOrInjury}
            physicalExam={physicalExam}
            contactedByMedicalRN={contactedByMedicalRN}
            advHCareDirectiveStatus={advHCareDirectiveStatus}
            dateConsent={dateConsent}

            onChangeFirstName={onChangeFirstName}
            onChangeMiddleName={onChangeMiddleName}
            onChangeLastName={onChangeLastName}
            onChangeDateOfBirth={onChangeDateOfBirth}
            onChangeSSN={onChangeSSN}
            onChangeGender={onChangeGender}
            onChangeOtherGender={onChangeOtherGender}
            onChangeEmail={onChangeEmail}
            onChangePhone={onChangePhone}
            onChangeAddress={onChangeAddress}
            onChangeCity={onChangeCity}
            onChangeState={onChangeState}
            onChangeZip={onChangeZip}
            onChangeMaritalStatus={onChangeMaritalStatus}
            onChangeNumberOfPeople={onChangeNumberOfPeople}
            onChangeNumberOfChildren={onChangeNumberOfChildren}
            onToggleCanLeaveVoiceMessage={onToggleCanLeaveVoiceMessage}
            onToggleAnyCustodyArrangements={onToggleAnyCustodyArrangements}

            onChangeContactFullName={onChangeContactFullName}
            onChangeContactRelationship={onChangeContactRelationship}
            onChangeContactEmail={onChangeContactEmail}
            onChangeContactPhone={onChangeContactPhone}
            onChangeInsuranceProvider={onChangeInsuranceProvider}
            onChangePolicyNumber={onChangePolicyNumber}
            onChangeGroupNumber={onChangeGroupNumber}

            onRowInsertingFamilyMembers={onRowInsertingFamilyMembers}
            onRowRemovingFamilyMembers={onRowRemovingFamilyMembers}
            onRowUpdatingFamilyMembers={onRowUpdatingFamilyMembers}
            onChangeIncomeEstimate={onChangeIncomeEstimate}
            onChangeEmployment={onChangeEmployment}
            onChangeEmployer={onChangeEmployer}
            onChangeEmployerAddress={onChangeEmployerAddress}
            onChangeEmployerCity={onChangeEmployerCity}
            onChangeEmployerState={onChangeEmployerState}
            onChangeEmployerZip={onChangeEmployerZip}
            onChangeNumberOfArrests={onChangeNumberOfArrests}

            onChangePregnant={onChangePregnant}
            onToggleDrugUser={onToggleDrugUser}
            onChangeHaveEverBeenInHospital={onChangeHaveEverBeenInHospital}
            onChangeHaveBeenInHospitalLast6Months={onChangeHaveBeenInHospitalLast6Months}
            onChangeHaveHadIllnessOrInjury={onChangeHaveHadIllnessOrInjury}
            onChangePhysicalExam={onChangePhysicalExam}
            onChangeLivingWill={onChangeLivingWill}
            onChangeDateConsent={onChangeDateConsent}
            onToggleContactedByMedicalRN={onToggleContactedByMedicalRN}

            onSaveAnnualPatientRegistration={onSaveAnnualPatientRegistration}
            onClickBack={onClickBack}
        />
    );
}