import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getReferralForm = createAsyncThunk(
    "getReferralForm",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/ReferralBehavioralHealth");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getReferralFormByID = createAsyncThunk(
    "getReferralFormByID",
    async (patientID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/ReferralBehavioralHealth/${patientID}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setReferralForm = createAsyncThunk(
    "setReferralForm",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + "/ReferralBehavioralHealth", data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setReferralFormByID = createAsyncThunk(
    "setReferralFormByID",
    async ({ patienID, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/ReferralBehavioralHealth/${patienID}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveReferralForm = () => (dispatch, getState) => {
    const store = getState().referralForm;

    const data = {
        "agree": store.agree,
        "person": store.person,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "address": store.address,
        "phone": store.phone,
        "referral": store.referral,
        "contact": store.contact,
        "reason": store.reason,
        "request": store.request,
        "appt": store.appt,
        "apptComment": store.apptComment,
        "declined": store.declined,
        "declinedComment": store.declinedComment,
        "noAnswer": store.noAnswer,
        "firstAttempt": dateFormatForApi(store.firstAttempt),
        "firstAttemptBy": store.firstAttemptBy,
        "secondAttempt": dateFormatForApi(store.secondAttempt),
        "secondAttemptBy": store.secondAttemptBy,
        "thirdAttempt": dateFormatForApi(store.thirdAttempt),
        "thirdAttemptBy": store.thirdAttemptBy,
        "closedReferral": store.closedReferral,
        "referralFollowedUpBy": store.referralFollowedUpBy,
        "referralFollowedUpDate": dateFormatForApi(store.referralFollowedUpDate),
    }

    return dispatch(setReferralForm(data));
};

export const saveReferralFormByID = (patienID) => (dispatch, getState) => {
    const store = getState().referralForm;

    const data = {
        "agree": store.agree,
        "person": store.person,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "address": store.address,
        "phone": store.phone,
        "referral": store.referral,
        "contact": store.contact,
        "reason": store.reason,
        "request": store.request,
        "appt": store.appt,
        "apptComment": store.apptComment,
        "declined": store.declined,
        "declinedComment": store.declinedComment,
        "noAnswer": store.noAnswer,
        "firstAttempt": dateFormatForApi(store.firstAttempt),
        "firstAttemptBy": store.firstAttemptBy,
        "secondAttempt": dateFormatForApi(store.secondAttempt),
        "secondAttemptBy": store.secondAttemptBy,
        "thirdAttempt": dateFormatForApi(store.thirdAttempt),
        "thirdAttemptBy": store.thirdAttemptBy,
        "closedReferral": store.closedReferral,
        "referralFollowedUpBy": store.referralFollowedUpBy,
        "referralFollowedUpDate": dateFormatForApi(store.referralFollowedUpDate),
    }

    return dispatch(setReferralFormByID({ patienID, data }));
};