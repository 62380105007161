import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getInvitedList } from '../../../store/users/InvitedList/middlewares';
import { setLoading, setActiveInviteeId } from '../../../store/users/InvitedList/slice';
import { selectLoading, selectActiveValue } from '../../../store/users/InvitedList/selectors';
import { InvitedProfile } from './InvitedProfile';
import { SELECT_INVITED } from '../../../utils/constants';

export const InvitedProfileContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const dataSource = useSelector(selectActiveValue);
  const { id } = useParams();

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getInvitedList());
    dispatch(setActiveInviteeId(id));
  }, [dispatch, id]);

  const onClickBack = () => {
    navigate('../invited', { state: { value: SELECT_INVITED } });
    dispatch(setActiveInviteeId(id));
  };

  return <InvitedProfile loading={loading} onClickBack={onClickBack} dataSource={dataSource} />;
};
