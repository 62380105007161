import { isAdmin } from '../../../core/auth/authProvider';
import { Wrapper, TitleWrapper, Title, SubTitle, Text, UnorderedList, OrderedList, Item, Link, Buttons } from '../../../components/Main/Main.style';
import { GRID_MD, GRID_SM, GRID_XS, MAX_CURRENT_DATE, MAX_WIDTH, MIN_CURRENT_DATE } from '../../../utils/constants';
import { Signature } from '../../../components/Signature/Signature.style';
import { dateFormatFromApi } from '../../../utils/formats';
import SignatureCanvas from 'react-signature-canvas';
import { NAME_RULE } from '../../../utils/rules';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const NoticeOfPrivacyPractices = ({
	loading,
	error,

	name,
	date,

	onChangeName,
	onChangeDate,

	onInitSignature,
	onChangeSignature,
	onClearSignature,
	onSaveNoticeOfPrivacyPractices,
	onClickBack,
}) => {
	const sectionName = "noticeOfPrivacyPractices";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Notice of Privacy Practices & Patient Rights and Responsibilities">
								<Title>Notice of Privacy Practices & Patient Rights and Responsibilities</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							This form is <span style={{ color: 'var(--red-text-color)' }}>required</span>.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>THIS NOTICE DESCRIBES HOW HEALTH INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED BY ALEUTIAN PRIBILOF ISLANDS ASSOCIATION (APIA), AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>We understand that your health information is personal to you, and we are committed to protecting the information about you. This Notice of Privacy Practices (or “Notice”) describes how we will use and disclose protected information and data that we receive or create related to your health care.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Our Duties</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>We are required by law to maintain the privacy of your health information, and to give you this Notice describing our legal duties and privacy practices. We are also required to follow the terms of the Notice currently in effect. We will also notify you if a breach occurs that may have compromised the privacy or security of your information.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>How We May Use and Disclose Health Information About You</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>We will not use or disclose your health information without your authorization, except in the following situations:</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Treatment:</strong> We may use and disclose your health information while providing, coordinating or managing your health care. For example, information obtained by a nurse, physician, or other member of your healthcare team will be recorded in your record and used to determine the course of treatment that should work best for you. We may also provide other healthcare providers with your information to assist him or her in treating you.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Payment:</strong> We may use and disclose your health information to obtain or provide compensation or reimbursement for providing your health care. For example, we may send a bill to you, your employer (if services were ordered by your employer) or your health plan. The information on or accompanying the bill may include information that identifies you, as well as your diagnosis, procedures, and supplies used. As another example, we may disclose information about you to your health plan so that the health plan may determine your eligibility for payment for certain benefits.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Health Care Operations:</strong> We may use and disclose your health information to deal with certain administrative aspects of your health care, and to manage our business more efficiently. For example, members of our staff may use information in your health record to assess the quality of care and outcomes in your case and others like it. This information will then be used in an effort to improve the quality and effectiveness of the healthcare and services we provide.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Business Associates:</strong> There are some services provided in our organization through contracts with business associates. We may disclose your health information to our business associate so they can perform the job we have asked them to do, such as storing test results or processing billing information. However, we require the business associate to take precautions to protect your health information.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Notification of Family:</strong>  We may use or disclose information to notify or assist in notifying a family member, personal representative, or other person responsible for your care of your location and general condition.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Communication with Family:</strong>   We may disclose to a family member, other relative, close personal friend or any other person you identify, health information relevant to that person’s involvement in your care.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Court Proceeding:</strong> We may disclose your health information in response to requests made during judicial and administrative proceedings, such as court orders or subpoenas.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Workers Compensation:</strong> We may disclose health information when authorized and necessary to comply with laws relating to workers compensation or other similar programs.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Other Uses:</strong> We may also use and disclose your personal health information for the following purposes:</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<UnorderedList>
							<Item>
								<Text>To contact you to remind you of an appointment; </Text>
							</Item>
							<Item>
								<Text>To describe or recommend treatment alternatives to you; or </Text>
							</Item>
							<Item>
								<Text>To furnish information about health-related benefits and services that may be of interest to you. On rare occasions, we may disclose information for the following purposes: Food and Drug Administration (FDA), Public Health, Reporting Abuse, Neglector Domestic Violence, Health Oversight, Law Enforcement, Threats to Public Health or Safety, and Specialized Government Functions (military, national security).</Text>
							</Item>
							<Item>
								<Text>For fundraising, however, you can tell us not to contact you for this purpose.</Text>
							</Item>
						</UnorderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Prohibition on Other Uses or Disclosures</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>We may not make any other use or disclosure of your personal health information without your written authorization. Once given, you may revoke the authorization by writing to the contact person listed below. Understandably, we are unable to take back any disclosure we have already made with your permission.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Special Rules About the Confidentiality of Your Alcohol and Drug Abuse Information</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>If you are receiving alcohol or drug abuse services from a recognized APIA substance abuse program, information that would identify you as a person seeking help for a substance abuse problem is protected under a federal law known as “Confidentiality of Alcohol and Drug Abuse Patient Records.” Under certain circumstances these regulations will provide your health information with additional privacy protections beyond those that have already been described. However, not all records for these programs are necessarily provided extra protection as substance abuse records if they do not identify you as a person seeking help for substance abuse.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>For more information about the specific ways in which these protections apply, see 42 U.S.C. 290dd-3, 42 U.S.C. 290 ee-3 and 42 CFR Part 2.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>In general, any information identifying you as addressing a substance abuse problem cannot be shared outside of the APIA substance abuse treatment programs without your specific written consent. Exceptions to this rule include court orders to release your health information, the provision of your health information to medical personnel in an emergency, and for audits or program evaluations. Before your substance abuse health related information can be released to family, friends, law enforcement, judicial and corrections personnel, public health authorities, other providers of medical services, we are required to obtain your written authorization to do so, unless an exception to the legal protection applies. This authorization can be revoked orally, but we are unable to take back any disclosure we have already made with your permission.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>In those instances, where you did authorize us to release your substance abuse related health information, the authorization will always be accompanied by a notice prohibiting the individual or agency/organization receiving your health information from re-releasing it unless permitted under applicable federal law.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>To facilitate communication with other organizations that provide services such as legal advice, laboratory analyses or other services to APIA’s substance abuse programs and patients, we may establish a confidentiality agreement with those organizations. Under this agreement, we may share, without your consent, information about the substance abuse care that you are receiving with the other organization. However, the confidentiality agreement requires that the other organization abide by the same rules described in this Notice to keep information about your substance abuse problem and the care you are receiving confidential.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Violation of these provisions is a federal crime. Suspected violations may be reported to the United States Attorney in the district where the violation occurs. If you have questions about whether these additional protections apply to the treatment you receive, contact the Compliance Officer (Privacy Officer Delegate) at the APIA address listed.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>To the extent anything in this Notice conflicts with the protections described in this special section regarding substance abuse treatment, the portion of the Notice providing you with greater protection will apply.</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Individual Rights</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>You have many rights concerning the confidentiality of your health information. You have the right:</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>To request restrictions on the health information we may use and disclose for treatment, payment, and health care operations. We are not required to agree to these requests, unless your request is to restrict information sent to your insurance company regarding treatment you have paid for entirely out-of-pocket. To request restrictions, please send a written request to the APIA address listed.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>To receive confidential communications of health information about you in a certain manner or at a certain location. For instance, you may request that we only contact you at work or by mail. To make such a request, you must write to us at the APIA address listed, and tell us how or where you wish to be contacted.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>To inspect or copy your health information. You must submit your request in writing to the APIA address listed. If you request a copy of your health information we may charge you a fee for the cost of copying, mailing or other supplies. If your records are held in electronic form, you have the right to request a copy in electronic form. In certain circumstances we may deny your request to inspect or copy your health information. If you are denied access to your health information, you may request that the denial be reviewed. Another licensed health care professional will then review your request and the denial. The person conducting the review will not be the person who denied your request. We will comply with the outcome of the review.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>To receive a copy of this notice in paper format, please contact APIA at the number or address listed.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>To amend health information. If you feel that health information we have about you is incorrect or incomplete, you may ask us to amend the information. To request an amendment, you must write to us at the address below. You must also give us a reason to support your request. We may deny your request to amend your health information if it is not in writing or does not provide a reason to support your request. We may also deny your request if:</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<UnorderedList>
							<Item>
								<Text>The information was not created by us, unless the person that created the information is no longer available to make theamendment,</Text>
							</Item>
							<Item>
								<Text>The information is not part of the health information kept by or for us,</Text>
							</Item>
							<Item>
								<Text>Is not part of the information you would be permitted to inspect or copy, or</Text>
							</Item>
							<Item>
								<Text>Is accurate and complete.</Text>
							</Item>
						</UnorderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>To receive an accounting of disclosures of your health information. You must submit a request in writing to the address below. Not all health information is subject to this request. Your request must state a time period, no longer than six years and may not include dates before April 14, 2003. Your request must state how you would like to receive the report (paper, electronically). The first accounting you request within a 12-month period is free. For additional accountings, we may charge you the cost of providing the accounting. We will notify you of this cost and you may choose to withdraw or modify your request before charges are incurred.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>All requests to restrict use of your health information for treatment, payment, and health care operations, to inspect and copy health information, to amend your health information, or to receive an accounting of disclosures of health information must be made in writing to the contact person listed below.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Complaints</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>If you believe that your privacy rights have been violated, a complaint may be made to our Compliance Officer or Privacy Officer at 1-800-478-2742 or at the APIA address listed. We request the use of our Consumer Concern form for filing complaints. You may also submit a complaint to the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201 or calling 1-877-696-6775, or visiting <Link rel="noreferrer" target="_blank" href="https://www.hhs.gov/hipaa/filing-a-complaint/index.html">www.hhs.gov/hipaa/filing-a-complaint/</Link>. We will not retaliate against you for filing a complaint.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Contact Person</strong></Text>
						<Text>Our contact person for all questions, requests or for further information related to the privacy of your health information is:</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Compliance Officer, Health Director</strong></Text>
						<Text><strong>1131 East International Airport Road</strong></Text>
						<Text><strong>Anchorage, Alaska, 99518-1408</strong></Text>
						<Text><strong>1-800-478-2742</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Changes to This Notice</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>We reserve the right to change our privacy practices and to apply the revised practices to health information about you that we already have. Any revision to our privacy practices will be described in a revised Notice that will be posted prominently in our facility.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Notice Effective Date: January 10, 2011.</Text>
						<Text>Revised: December 6, 2019.</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Patient Rights and Responsibilities</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>APIA Behavioral Health and Primary Care uphold that all patients have the following</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>RIGHTS:</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<OrderedList>
							<Item>
								<Text>To be treated with dignity and respect, regardless of one’s race, creed, religion, disability or sexual preference;</Text>
							</Item>
							<Item>
								<Text>To help develop a plan of care for services that meet one’s specific mental health, substance use need(s);</Text>
							</Item>
							<Item>
								<Text>To participate in decisions regarding one’s behavioral health care;</Text>
							</Item>
							<Item>
								<Text>To refuse treatment and change one’s mind at any time; <b>**IMPORTANT**</b> Patient may be treated without consent only in cases of an emergency and in the professional opinion of one’s provider that failure to act immediately would jeopardize the client’s health.</Text>
							</Item>
							<Item>
								<Text>To request and receive a discharge plan recommending specific self-help procedures and other steps that benefit one’s mental health and well-being;</Text>
							</Item>
							<Item>
								<Text>To know the name and titles of all personnel directly involved in one’s treatment and to consult with treatment team members;</Text>
							</Item>
							<Item>
								<Text>To have all information related to one’s care protected by confidentiality;</Text>
							</Item>
							<Item>
								<Text>To refuse participation or interviews related to research purposes;</Text>
							</Item>
							<Item>
								<Text>To appeal specific treatment decisions to higher authorities for review;</Text>
							</Item>
							<Item>
								<Text>To participate, as far as is practical and desirable, in treatment within the clinic and community;</Text>
							</Item>
							<Item>
								<Text>To be informed of one’s rights to leave the treatment program; and</Text>
							</Item>
							<Item>
								<Text>To not be subjected to physical abuse, corporal punishment or other forms of abuse and retaliation by staff.</Text>
							</Item>
						</OrderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>The patient also has a number of RESPONSIBILITIES:</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<OrderedList>
							<Item>
								<Text>Patient has the responsibility to participate actively and honestly in treatment. In many cases, particularly when the patient is a child or adolescent, effective treatment requires active involvement and participation of parents or other family members.</Text>
							</Item>
							<Item>
								<Text>Patient has the responsibility to keep scheduled appointments or give 24-hour notice of cancellation if patient will be unable to keep an appointment.</Text>
							</Item>
							<Item>
								<Text>Patient has the responsibility to treat other patients and APIA personnel with dignity and respect, realizing that any act of aggression, breaching another patient’s confidentiality, or any other inappropriate behavior will be grounds for involuntary termination from the program.</Text>
							</Item>
							<Item>
								<Text>Patient is responsible for asking questions about any policy, procedure, or service which they do not understand or with which they do not agree.</Text>
							</Item>
							<Item>
								<Text>Patient has the responsibility to honor their financial contract by paying for the services at the time services are provided, unless other financial arrangements have been made in writing.</Text>
							</Item>
							<Item>
								<Text>Patient is responsible for providing all information necessary for billing health insurance or other third-party insurance, including change in address, income, insurance information, etc.</Text>
							</Item>
							<Item>
								<Text>Patient has the responsibility to inform one’s counselor of expected and emergency absences. The counselor will determine if an absence is excusable. All individual and group services begin on time and lateness may be considered an unexcused absence. Two unexcused absences will be grounds for involuntary termination from current services.</Text>
							</Item>
							<Item>
								<Text>IF PATIENT PARTICIPATES IN GROUP COUNSELING: Patient is responsible for, and agrees to pay for, all group sessions attended. Patient may be required to make up sessions to successfully complete a program.</Text>
							</Item>
							<Item>
								<Text>Patient is responsible for their own progress and understands it is the program’s responsibility to assist patients in realizing their goals.</Text>
							</Item>
						</OrderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Contact Us</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text><strong>Aleutian Pribilof Islands Association, Inc.</strong></Text>
						<Text><strong>1131 E. International Airport Rd</strong></Text>
						<Text><strong>Anchorage, AK 99518</strong></Text>
						<Text><strong>Visit us on the Web: <Link rel="noreferrer" target="_blank" href="https://www.apiai.org/">www.apiai.org</Link></strong></Text>
						<Text><strong>Scheduling: 907-222-9764 Main: 907-276-2700</strong></Text>
						<Text><strong>1-800-478-2742 (toll free) Fax: 907-279-4351</strong></Text>
						<Text><strong>Health Fax (Confidential): 907-222-4279</strong></Text>
						<Text><strong>Behavioral Health Scheduling 1-844-375-2742 (toll free)</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							By signing below, I acknowledge that I have received Aleutian Pribilof Islands Association, Inc. Privacy Practices and Patient Rights and Responsibilities.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={8}>
						<Tooltip title="Printed Name">
							<>
								<TextBox
									label="Printed Name"
									value={name}
									onValueChanged={onChangeName}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-name' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Printed Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Printed Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Date">
							<>
								<DateBox
									label="Date"
									type="date"
									value={dateFormatFromApi(date)}
									useMaskBehavior={true}
									onValueChanged={onChangeDate}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date is required"
										/>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Signature error={error}>
							<legend>Signature *</legend>
							<SignatureCanvas
								ref={onInitSignature}
								onEnd={onChangeSignature}
								clearOnResize={false}
								canvasProps={{
									id: 'responsive-canvas'
								}}
							/>
						</Signature>
					</Grid>
					<Grid item xs={GRID_XS} >
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								validationGroup={sectionName}
								onClick={onSaveNoticeOfPrivacyPractices} />
							<Button
								text="Clear Signature"
								type="default"
								stylingMode="outlined"
								onClick={onClearSignature} />
						</Buttons>
					</Grid>
				</Grid >
			}
		</Wrapper >
	);
};