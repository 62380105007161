import { setDrugUser, setPhysicalExam, setPregnantStatus, setNumberOfArrests, setHowManyMentalHealthHospitals, setHaveBeenInHospitalLast6Months, setHaveEverBeenInHospital } from "../../../../../../store/users/invitedProfile/basicInformation/healthAndTreatment/slice";
import { getHealthAndTreatmentById, saveHealthAndTreatmentById } from "../../../../../../store/users/invitedProfile/basicInformation/healthAndTreatment/middlewares";

import { getPregnantStatuses } from "../../../../../../store/lookups/pregnantStatus/middlewares";
import { getHospitalInSixMonths } from "../../../../../../store/lookups/hospitalInSixMonths/middlewares";
import { getHospitalResidentialTreatments } from "../../../../../../store/lookups/hospitalResidentialTreatment/middlewares";
import { selectPregnantStatuses } from "../../../../../../store/lookups/pregnantStatus/selectors";
import { selectHospitalInSixMonths } from "../../../../../../store/lookups/hospitalInSixMonths/selectors";
import { selectHospitalResidentialTreatments } from "../../../../../../store/lookups/hospitalResidentialTreatment/selectors";

import { validateGroup } from "../../../../../../utils/helper";
import { HealthAndTreatment } from "./HealthAndTreatment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const answers = [
	{
		"Oid": true,
		"Name": "Yes",
	}, {
		"Oid": false,
		"Name": "No",
	},
];

export const HealthAndTreatmentContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const pregnantStatuses = useSelector(selectPregnantStatuses);
    const hospitalInSixMonths = useSelector(selectHospitalInSixMonths);
    const hospitalResidentialTreatment = useSelector(selectHospitalResidentialTreatments);

    const statePatientProfileHealthAndTreatment     = useSelector(state => state.patientProfileHealthAndTreatment);
	const getEntityPatientProfileHealthAndTreatment = (name) => statePatientProfileHealthAndTreatment[name];

    useEffect(() => {
        dispatch(getHealthAndTreatmentById(id));
        dispatch(getPregnantStatuses());
        dispatch(getHospitalInSixMonths());
        dispatch(getHospitalResidentialTreatments());
    }, [dispatch, id]);

    const FN_TO_CALL = {
        PregnantStatus: setPregnantStatus,
        PhysicalExam: setPhysicalExam,
        DrugUser: setDrugUser,
        HaveEverBeenInHospital: setHaveEverBeenInHospital,
        HaveBeenInHospitalLast6Months: setHaveBeenInHospitalLast6Months,
        HowManyMentalHealthHospitals: setHowManyMentalHealthHospitals,
        NumberOfArrests: setNumberOfArrests,
    };

    const onChangeFunc = (name, event) => {
        dispatch(FN_TO_CALL[name](event.value));
        setShowButton(true);
    };

    const onCancelHealthAndTreatment = () => {
        dispatch(getHealthAndTreatmentById(id));
        setShowButton(false);
    }

    const onSaveHealthAndTreatment = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveHealthAndTreatmentById(id));
            setShowButton(false);
        }
    }

    return (
        <HealthAndTreatment
            showButton={showButton}

            answers={answers}
            pregnantStatuses={pregnantStatuses}
            hospitalInSixMonths={hospitalInSixMonths}
            hospitalResidentialTreatment={hospitalResidentialTreatment}

            getEntityPatientProfileHealthAndTreatment={getEntityPatientProfileHealthAndTreatment}
            onChangeFunc={onChangeFunc}

            onCancelHealthAndTreatment={onCancelHealthAndTreatment}
            onSaveHealthAndTreatment={onSaveHealthAndTreatment}
        />
    );
};