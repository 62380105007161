import { getNewPatientRegistrationPacket, getNewPatientRegistrationPacketById } from "./middlewares";
import { FETCH_STATUSES } from "../../../utils/constants";
import { showToast } from "../../../screens/Main/MainContainer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    newPatientRegistration: false,
    noticeOfPrivacyPractices: false,
    consentForRelease: false,
    idAndInsuranceCard: false,
    eCommunicationConsent: false,
    paymentAgreement: false,
    discountSchedule: false,
    submitForms: false,
    isActiveStep: 0,
    isStepsLength: 0,
    getStatus: FETCH_STATUSES.IDLE,
    getError: null,
};

export const newPatientRegistrationPacket = createSlice({
    name: "newPatientRegistrationPacket",
    initialState,
    reducers: {
        completeNewPatientRegistration: (state, action) => {
            state.newPatientRegistration = action.payload;
            return state;
        },
        completeNoticeOfPrivacyPractices: (state, action) => {
            state.noticeOfPrivacyPractices = action.payload;
            return state;
        },
        completeConsentForRelease: (state, action) => {
            state.consentForRelease = action.payload;
            return state;
        },
        completeIdAndInsuranceCard: (state, action) => {
            state.idAndInsuranceCard = action.payload;
            return state;
        },
        completeECommunicationConsent: (state, action) => {
            state.eCommunicationConsent = action.payload;
            return state;
        },
        completePaymentAgreement: (state, action) => {
            state.paymentAgreement = action.payload;
            return state;
        },
        completeDiscountSchedule: (state, action) => {
            state.discountSchedule = action.payload;
            return state;
        },
        completeSubmitForms: (state, action) => {
            state.submitForms = action.payload;
            return state;
        },
        setActiveStep: (state, action) => {
            state.isActiveStep = action.payload;
            return state;
        },
        setStepsLength: (state, action) => {
            state.isStepsLength = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNewPatientRegistrationPacket.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
            })
            .addCase(getNewPatientRegistrationPacket.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.newPatientRegistration = action.payload?.NewPatientRegistration;
                state.noticeOfPrivacyPractices = action.payload?.NoticeOfPrivacyPractices;
                state.consentForRelease = action.payload?.ConsentForRelease;
                state.idAndInsuranceCard = action.payload?.InsuranceCardAndId;
                state.eCommunicationConsent = action.payload?.ECommunicationConsent;
                state.paymentAgreement = action.payload?.PaymentAgreement;
                state.discountSchedule = action.payload?.DiscountScheduleApp;
                state.submitForms = action.payload?.SubmitForm;

                state.loading = false;
            })
            .addCase(getNewPatientRegistrationPacket.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get New Patient Registration Packet: ${action.error.message}`);
            })
            .addCase(getNewPatientRegistrationPacketById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
            })
            .addCase(getNewPatientRegistrationPacketById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.newPatientRegistration = action.payload?.NewPatientRegistration;
                state.noticeOfPrivacyPractices = action.payload?.NoticeOfPrivacyPractices;
                state.consentForRelease = action.payload?.ConsentForRelease;
                state.idAndInsuranceCard = action.payload?.InsuranceCardAndId;
                state.eCommunicationConsent = action.payload?.ECommunicationConsent;
                state.paymentAgreement = action.payload?.PaymentAgreement;
                state.discountSchedule = action.payload?.DiscountScheduleApp;
                state.submitForms = action.payload?.SubmitForm;

                state.loading = false;
            })
            .addCase(getNewPatientRegistrationPacketById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get New Patient Registration Packet By Id: ${action.error.message}`);
            })
    },
});

export const {
    completeNewPatientRegistration,
    completeNoticeOfPrivacyPractices,
    completeConsentForRelease,
    completeIdAndInsuranceCard,
    completeECommunicationConsent,
    completePaymentAgreement,
    completeDiscountSchedule,
    completeSubmitForms,

    setActiveStep,
    setStepsLength,
} = newPatientRegistrationPacket.actions;

export default newPatientRegistrationPacket.reducer;
