/**
 * 
 * @param {*}
 */
export const onKeyDownHandle = ({ event }) => {
  const allowedChars = ['space'];
  if (allowedChars.includes(event?.code?.toLowerCase())) event.preventDefault();
};

/**
 *
 * @param {*} bytes
 * @param {*} decimals
 * @returns {string}
 * @example 4 MB
 */
export const formatBytes = (bytes = 4000000, decimals = 0) => {
  if (bytes === 0) return '0';
  else {
    let k = 1024;
    let dm = decimals < 0 ? 0 : decimals;
    let sizes = ['byte', 'kB', 'MB', 'GB', 'TB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
};

/**
 * Group validation fuction 
 * @param {*} event button submit event
 * @returns {boolean} is valid value
 */
export const validateGroup = (event) => {
  return event.validationGroup ? event.validationGroup?.validate().isValid : true
}

/**
 * 
 * @param {*} blob 
 * @param {*} fileName 
 */
export const downloadFile = (blob, fileName) => {
  let a  = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

/**
 * 
 * @param {*} formName
 * @returns
 */
export const isDisabledFromDownload = (formName = []) => {
  const disabledFormNames = ['InsuranceCardAndId', 'AnnualPatientForm'];
  return disabledFormNames.includes(formName);
};