import {
  Wrapper,
  Main,
  Header,
  Img404,
  Img403,
  Title,
  SubTitle,
  Text,
  Button,
} from './ErrorPage.style';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate } from 'react-router-dom';
import { FooterBlock } from '../FooterBlock/FooterBlock';

export const ErrorPage = ({ code = '404' }) => {
  let httpText, text, description, gradient;
  let navigate = useNavigate();
  const { width, ref } = useResizeDetector();
  const link = document.referrer ? '/' + document.referrer.split('/').slice(3).join('/') : '/main/contacts';
  const label = link === '/main/contacts' ? 'contacts' : 'back';

  const handleClick = () => {
    navigate(link);
  };
  switch (code) {
    case '404':
      text = 'Something went wrong';
      httpText = 'Page Not Found';
      description = 'The page or resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.';
      gradient = 'linear-gradient(90deg, #58019B 0%, #A730C5 100%)';
      break;
    case '403':
      text = 'We are sorry';
      httpText = 'Forbidden Page';
      description = 'The page or resource you are trying to view has restricted access.';
      gradient = 'linear-gradient(90deg, #942E35 0%, #F33260 100%)';
      break;
    default:
      break;
  }
  return (
    <Wrapper maxWidth="xl" ref={ref}>
      <Header style={{ background: gradient }}>
        <Title>
          Error {code}
          <div>{httpText}</div>
        </Title>
        {code === '404' ? <Img404 /> : <Img403 />}
      </Header>
      <Main>
        <SubTitle>{text}</SubTitle>
        <Text>{description}</Text>
        <Button
          label={`go to ${label}`}
          variant="contained"
          startIcon="ic:baseline-output"
          onClick={handleClick}
        />
        <FooterBlock width={width} />
      </Main>
    </Wrapper>
  );
};
