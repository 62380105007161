import { getConsentForRelease, setConsentForRelease, getConsentForReleaseByID, setConsentForReleaseByID } from './middlewares';
import { CURRENT_DATE, FETCH_STATUSES, NEXT_YEAR_DATE } from '../../../utils/constants';
import { showToast } from '../../../screens/Main/MainContainer';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	decline: false,
	firstName: "",
	middleName: "",
	lastName: "",
	dob: null,
	address: "",
	city: "",
	state: "",
	zip: "",
	phone: "",
	legalGuardian: "",
	atkaHealthClinic: false,
	anchorageBehavioralHealth: false,
	nikolskiHealthCenter: false,
	saintGeorgeHealthCenter: false,
	oonalaskaPrimaryCare: false,
	oonalaskaBehavioralHealth: false,
	releaseInformationTo: false,
	obtainInformationFrom: false,
	verbally: false,
	inWritingElectronically: false,
	agency: "",
	agencyName: "",
	agencyAddress: "",
	agencyCity: "",
	agencyState: "",
	agencyZip: "",
	agencyPhone: "",
	assessmentRecommendations: false,
	admissionSummary: false,
	resultsOfUrinalysis: false,
	attendanceCompliance: false,
	treatmentPlan: false,
	psychiatricEvaluations: false,
	treatmentStatus: false,
	dischargeDateAndSummary: false,
	bhMedicationNeeds: false,
	regardingOther: false,
	regardingOtherText: "",
	referralTreatmentPlacement: false,
	provideReferralInformation: false,
	verifyParticipationInTreatment: false,
	coordinationOfServices: false,
	purposesOther: false,
	purposesOtherText: "",
	signedName: "",
	signedDate: CURRENT_DATE,
	expiredDate: NEXT_YEAR_DATE,
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
	signPicture: "",
};

const consentForRelease = createSlice({
	name: 'consentForRelease',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		toggleDecline: (state) => {
			state.decline = !state.decline;
			return state;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
			return state;
		},
		setMiddleName: (state, action) => {
			state.middleName = action.payload;
			return state;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
			return state;
		},
		setDOB: (state, action) => {
			state.dob = action.payload;
			return state;
		},
		setAddress: (state, action) => {
			state.address = action.payload;
			return state;
		},
		setCity: (state, action) => {
			state.city = action.payload;
			return state;
		},
		setState: (state, action) => {
			state.state = action.payload;
			return state;
		},
		setZip: (state, action) => {
			state.zip = action.payload;
			return state;
		},
		setPhone: (state, action) => {
			state.phone = action.payload;
			return state;
		},
		setLegalGuardian: (state, action) => {
			state.legalGuardian = action.payload;
			return state;
		},
		toggleAtkaHealthClinic: (state) => {
			state.atkaHealthClinic = !state.atkaHealthClinic;
			return state;
		},
		toggleAnchorageBehavioralHealth: (state) => {
			state.anchorageBehavioralHealth = !state.anchorageBehavioralHealth;
			return state;
		},
		toggleNikolskiHealthCenter: (state) => {
			state.nikolskiHealthCenter = !state.nikolskiHealthCenter;
			return state;
		},
		toggleSaintGeorgeHealthCenter: (state) => {
			state.saintGeorgeHealthCenter = !state.saintGeorgeHealthCenter;
			return state;
		},
		toggleOonalaskaPrimaryCare: (state) => {
			state.oonalaskaPrimaryCare = !state.oonalaskaPrimaryCare;
			return state;
		},
		toggleOonalaskaBehavioralHealth: (state) => {
			state.oonalaskaBehavioralHealth = !state.oonalaskaBehavioralHealth;
			return state;
		},
		toggleReleaseInformationTo: (state) => {
			state.releaseInformationTo = !state.releaseInformationTo;
			return state;
		},
		toggleObtainInformationFrom: (state) => {
			state.obtainInformationFrom = !state.obtainInformationFrom;
			return state;
		},
		toggleVerbally: (state) => {
			state.verbally = !state.verbally;
			return state;
		},
		toggleInWritingElectronically: (state) => {
			state.inWritingElectronically = !state.inWritingElectronically;
			return state;
		},
		setAgency: (state, action) => {
			state.agency = action.payload;
			return state;
		},
		setAgencyName: (state, action) => {
			state.agencyName = action.payload;
			return state;
		},
		setAgencyAddress: (state, action) => {
			state.agencyAddress = action.payload;
			return state;
		},
		setAgencyCity: (state, action) => {
			state.agencyCity = action.payload;
			return state;
		},
		setAgencyState: (state, action) => {
			state.agencyState = action.payload;
			return state;
		},
		setAgencyZip: (state, action) => {
			state.agencyZip = action.payload;
			return state;
		},
		setAgencyPhone: (state, action) => {
			state.agencyPhone = action.payload;
			return state;
		},
		toggleAssessmentRecommendations: (state) => {
			state.assessmentRecommendations = !state.assessmentRecommendations;
			return state;
		},
		toggleAdmissionSummary: (state) => {
			state.admissionSummary = !state.admissionSummary;
			return state;
		},
		toggleResultsOfUrinalysis: (state) => {
			state.resultsOfUrinalysis = !state.resultsOfUrinalysis;
			return state;
		},
		toggleAttendanceCompliance: (state) => {
			state.attendanceCompliance = !state.attendanceCompliance;
			return state;
		},
		toggleTreatmentPlan: (state) => {
			state.treatmentPlan = !state.treatmentPlan;
			return state;
		},
		togglePsychiatricEvaluations: (state) => {
			state.psychiatricEvaluations = !state.psychiatricEvaluations;
			return state;
		},
		toggleTreatmentStatus: (state) => {
			state.treatmentStatus = !state.treatmentStatus;
			return state;
		},
		toggleDischargeDateAndSummary: (state) => {
			state.dischargeDateAndSummary = !state.dischargeDateAndSummary;
			return state;
		},
		toggleBhMedicationNeeds: (state) => {
			state.bhMedicationNeeds = !state.bhMedicationNeeds;
			return state;
		},
		toggleRegardingOther: (state) => {
			state.regardingOther = !state.regardingOther;
			return state;
		},
		setRegardingOtherText: (state, action) => {
			state.regardingOtherText = action.payload;
			return state;
		},
		toggleReferralTreatmentPlacement: (state) => {
			state.referralTreatmentPlacement = !state.referralTreatmentPlacement;
			return state;
		},
		toggleProvideReferralInformation: (state) => {
			state.provideReferralInformation = !state.provideReferralInformation;
			return state;
		},
		toggleVerifyParticipationInTreatment: (state) => {
			state.verifyParticipationInTreatment = !state.verifyParticipationInTreatment;
			return state;
		},
		toggleCoordinationOfServices: (state) => {
			state.coordinationOfServices = !state.coordinationOfServices;
			return state;
		},
		togglePurposesOther: (state) => {
			state.purposesOther = !state.purposesOther;
			return state;
		},
		setPurposesOtherText: (state, action) => {
			state.purposesOtherText = action.payload;
			return state;
		},
		setPrintedName: (state, action) => {
			state.signedName = action.payload;
			return state;
		},
		setSignedDate: (state, action) => {
			state.signedDate = action.payload;
			return state;
		},
		setExpiredDate: (state, action) => {
			state.expiredDate = action.payload;
			return state;
		},
		setSignPicture: (state, action) => {
			state.signPicture = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getConsentForRelease.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setConsentForRelease.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getConsentForRelease.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;
				state.decline = action.payload?.decline;
				state.firstName = action.payload?.firstName;
				state.middleName = action.payload?.middleName;
				state.lastName = action.payload?.lastName;
				state.dob = action.payload?.dob;
				state.address = action.payload?.address;
				state.city = action.payload?.city;
				state.state = action.payload?.state;
				state.zip = action.payload?.zip;
				state.phone = action.payload?.phone;
				state.legalGuardian = action.payload?.legalGuardian;
				state.atkaHealthClinic = action.payload?.atkaHealthClinic;
				state.anchorageBehavioralHealth = action.payload?.anchorageBehavioralHealth;
				state.nikolskiHealthCenter = action.payload?.nikolskiHealthCenter;
				state.saintGeorgeHealthCenter = action.payload?.saintGeorgeHealthCenter;
				state.oonalaskaPrimaryCare = action.payload?.oonalaskaPrimaryCare;
				state.oonalaskaBehavioralHealth = action.payload?.oonalaskaBehavioralHealth;
				state.releaseInformationTo = action.payload?.releaseInformationTo;
				state.obtainInformationFrom = action.payload?.obtainInformationFrom;
				state.verbally = action.payload?.verbally;
				state.inWritingElectronically = action.payload?.inWritingElectronically;
				state.agency = action.payload?.agency;
				state.agencyName = action.payload?.agencyName;
				state.agencyAddress = action.payload?.agencyAddress;
				state.agencyCity = action.payload?.agencyCity;
				state.agencyState = action.payload?.agencyState;
				state.agencyZip = action.payload?.agencyZip;
				state.agencyPhone = action.payload?.agencyPhone;
				state.assessmentRecommendations = action.payload?.assessmentRecommendations;
				state.admissionSummary = action.payload?.admissionSummary;
				state.resultsOfUrinalysis = action.payload?.resultsOfUrinalysis;
				state.attendanceCompliance = action.payload?.attendanceCompliance;
				state.treatmentPlan = action.payload?.treatmentPlan;
				state.psychiatricEvaluations = action.payload?.psychiatricEvaluations;
				state.treatmentStatus = action.payload?.treatmentStatus;
				state.dischargeDateAndSummary = action.payload?.dischargeDateAndSummary;
				state.bhMedicationNeeds = action.payload?.bhMedicationNeeds;
				state.regardingOther = action.payload?.regardingOther;
				state.regardingOtherText = action.payload?.regardingOtherText;
				state.referralTreatmentPlacement = action.payload?.referralTreatmentPlacement;
				state.provideReferralInformation = action.payload?.provideReferralInformation;
				state.verifyParticipationInTreatment = action.payload?.verifyParticipationInTreatment;
				state.coordinationOfServices = action.payload?.coordinationOfServices;
				state.purposesOther = action.payload?.purposesOther;
				state.purposesOtherText = action.payload?.purposesOtherText;
				state.signedName = action.payload?.signedName;
				state.signPicture = action.payload?.signPicture;
				state.signedDate = action.payload?.signedDate || CURRENT_DATE;
				state.expiredDate = action.payload?.expiredDate || NEXT_YEAR_DATE;

				state.loading = false;
			})
			.addCase(setConsentForRelease.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Consent For Release saved successfully");
			})
			.addCase(getConsentForRelease.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Consent For Release: ${action.error.message}`);
			})
			.addCase(setConsentForRelease.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Consent For Release: ${action.error.message}`);
			})
			.addCase(getConsentForReleaseByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getConsentForReleaseByID.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;
				state.decline = action.payload?.decline;
				state.firstName = action.payload?.firstName;
				state.middleName = action.payload?.middleName;
				state.lastName = action.payload?.lastName;
				state.dob = action.payload?.dob;
				state.address = action.payload?.address;
				state.city = action.payload?.city;
				state.state = action.payload?.state;
				state.zip = action.payload?.zip;
				state.phone = action.payload?.phone;
				state.legalGuardian = action.payload?.legalGuardian;
				state.atkaHealthClinic = action.payload?.atkaHealthClinic;
				state.anchorageBehavioralHealth = action.payload?.anchorageBehavioralHealth;
				state.nikolskiHealthCenter = action.payload?.nikolskiHealthCenter;
				state.saintGeorgeHealthCenter = action.payload?.saintGeorgeHealthCenter;
				state.oonalaskaPrimaryCare = action.payload?.oonalaskaPrimaryCare;
				state.oonalaskaBehavioralHealth = action.payload?.oonalaskaBehavioralHealth;
				state.releaseInformationTo = action.payload?.releaseInformationTo;
				state.obtainInformationFrom = action.payload?.obtainInformationFrom;
				state.verbally = action.payload?.verbally;
				state.inWritingElectronically = action.payload?.inWritingElectronically;
				state.agency = action.payload?.agency;
				state.agencyName = action.payload?.agencyName;
				state.agencyAddress = action.payload?.agencyAddress;
				state.agencyCity = action.payload?.agencyCity;
				state.agencyState = action.payload?.agencyState;
				state.agencyZip = action.payload?.agencyZip;
				state.agencyPhone = action.payload?.agencyPhone;
				state.assessmentRecommendations = action.payload?.assessmentRecommendations;
				state.admissionSummary = action.payload?.admissionSummary;
				state.resultsOfUrinalysis = action.payload?.resultsOfUrinalysis;
				state.attendanceCompliance = action.payload?.attendanceCompliance;
				state.treatmentPlan = action.payload?.treatmentPlan;
				state.psychiatricEvaluations = action.payload?.psychiatricEvaluations;
				state.treatmentStatus = action.payload?.treatmentStatus;
				state.dischargeDateAndSummary = action.payload?.dischargeDateAndSummary;
				state.bhMedicationNeeds = action.payload?.bhMedicationNeeds;
				state.regardingOther = action.payload?.regardingOther;
				state.regardingOtherText = action.payload?.regardingOtherText;
				state.referralTreatmentPlacement = action.payload?.referralTreatmentPlacement;
				state.provideReferralInformation = action.payload?.provideReferralInformation;
				state.verifyParticipationInTreatment = action.payload?.verifyParticipationInTreatment;
				state.coordinationOfServices = action.payload?.coordinationOfServices;
				state.purposesOther = action.payload?.purposesOther;
				state.purposesOtherText = action.payload?.purposesOtherText;
				state.signedName = action.payload?.signedName;
				state.signPicture = action.payload?.signPicture;
				state.signedDate = action.payload?.signedDate;
				state.expiredDate = action.payload?.expiredDate;
				state.loading = false;
			})
			.addCase(getConsentForReleaseByID.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;
				showToast('error', `Get Consent For Release: ${action.error.message}`);
			})
			.addCase(setConsentForReleaseByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError  = null;
			})
			.addCase(setConsentForReleaseByID.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError  = null;

				showToast("success", "Consent For Release saved successfully");
			})
			.addCase(setConsentForReleaseByID.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError  = action.error;

				showToast('error', `Set Consent For Release: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,

	toggleDecline,
	setFirstName,
	setMiddleName,
	setLastName,
	setDOB,
	setAddress,
	setCity,
	setState,
	setZip,
	setPhone,
	setLegalGuardian,

	toggleAtkaHealthClinic,
	toggleAnchorageBehavioralHealth,
	toggleNikolskiHealthCenter,
	toggleSaintGeorgeHealthCenter,
	toggleOonalaskaPrimaryCare,
	toggleOonalaskaBehavioralHealth,

	toggleReleaseInformationTo,
	toggleObtainInformationFrom,
	toggleVerbally,
	toggleInWritingElectronically,

	setAgency,
	setAgencyName,
	setAgencyAddress,
	setAgencyCity,
	setAgencyState,
	setAgencyZip,
	setAgencyPhone,

	toggleAssessmentRecommendations,
	toggleAdmissionSummary,
	toggleResultsOfUrinalysis,
	toggleAttendanceCompliance,
	toggleTreatmentPlan,
	togglePsychiatricEvaluations,
	toggleTreatmentStatus,
	toggleDischargeDateAndSummary,
	toggleBhMedicationNeeds,
	toggleRegardingOther,
	setRegardingOtherText,
	setSignPicture,

	toggleReferralTreatmentPlacement,
	toggleProvideReferralInformation,
	toggleVerifyParticipationInTreatment,
	toggleCoordinationOfServices,
	togglePurposesOther,
	setPurposesOtherText,

	setPrintedName,
	setSignedDate,
	setExpiredDate,
} = consentForRelease.actions;

export default consentForRelease.reducer;