import { getAnnualPatientRegistration, setAnnualPatientRegistration, getAnnualPatientRegistrationByID, setAnnualPatientRegistrationByID } from "./middlewares";
import { CURRENT_DATE, FETCH_STATUSES } from "../../../utils/constants";
import { showToast } from "../../../screens/Main/MainContainer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: null,
    SSN: "",
    gender: "",
    otherGender: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    maritalStatus: "",
    numberOfPeople: "",
    numberOfChildren: "",
    canLeaveVoiceMessage: false,
    anyCustodyArrangements: false,
    contactFullName: "",
    contactRelationship: "",
    contactEmail: "",
    contactPhone: "",
    insuranceProvider: "",
    policyNumber: "",
    groupNumber: "",
    familyMembers: [],
    incomeEstimate: "",
    employment: null,
    employer: "",
    employerAddress: "",
    employerCity: "",
    employerState: "",
    employerZip: "",
    numberOfArrests: "",
    pregnant: "",
    drugUser: false,
    haveEverBeenInHospital: "",
    haveBeenInHospitalLast6Months: "",
    haveHadIllnessOrInjury: "",
    livingWill: "",
    physicalExam: null,
    dateConsent: CURRENT_DATE,
    contactedByMedicalRN: false,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const annualPatientRegistration = createSlice({
    name: "annualPatientRegistration",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
            return state;
        },
        setMiddleName: (state, action) => {
            state.middleName = action.payload;
            return state;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
            return state;
        },
        setDateOfBirth: (state, action) => {
            state.dateOfBirth = action.payload;
            return state;
        },
        setSSN: (state, action) => {
            state.SSN = action.payload;
            return state;
        },
        setGender: (state, action) => {
            state.gender = action.payload;
            return state;
        },
        setOtherGender: (state, action) => {
            state.otherGender = action.payload;
            return state;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setMaritalStatus: (state, action) => {
            state.maritalStatus = action.payload;
            return state;
        },
        setNumberOfPeople: (state, action) => {
            state.numberOfPeople = action.payload;
            return state;
        },
        setNumberOfChildren: (state, action) => {
            state.numberOfChildren = action.payload;
            return state;
        },
        toggleCanLeaveVoiceMessage: (state) => {
            state.canLeaveVoiceMessage = !state.canLeaveVoiceMessage;
            return state;
        },
        toggleAnyCustodyArrangements: (state) => {
            state.anyCustodyArrangements = !state.anyCustodyArrangements;
            return state;
        },
        setContactFullName: (state, action) => {
            state.contactFullName = action.payload;
            return state;
        },
        setContactRelationship: (state, action) => {
            state.contactRelationship = action.payload;
            return state;
        },
        setContactEmail: (state, action) => {
            state.contactEmail = action.payload;
            return state;
        },
        setContactPhone: (state, action) => {
            state.contactPhone = action.payload;
            return state;
        },
        setInsuranceProvider: (state, action) => {
            state.insuranceProvider = action.payload;
            return state;
        },
        setPolicyNumber: (state, action) => {
            state.policyNumber = action.payload;
            return state;
        },
        setGroupNumber: (state, action) => {
            state.groupNumber = action.payload;
            return state;
        },
        setFamilyMembers: (state, action) => {
            state.familyMembers = action.payload;
            return state;
        },
        setIncomeEstimate: (state, action) => {
            state.incomeEstimate = action.payload;
            return state;
        },
        setEmployment: (state, action) => {
            state.employment = action.payload;
            return state;
        },
        setEmployer: (state, action) => {
            state.employer = action.payload;
            return state;
        },
        setEmployerAddress: (state, action) => {
            state.employerAddress = action.payload;
            return state;
        },
        setEmployerCity: (state, action) => {
            state.employerCity = action.payload;
            return state;
        },
        setEmployerState: (state, action) => {
            state.employerState = action.payload;
            return state;
        },
        setEmployerZip: (state, action) => {
            state.employerZip = action.payload;
            return state;
        },
        setNumberOfArrests: (state, action) => {
            state.numberOfArrests = action.payload;
            return state;
        },
        setPregnant: (state, action) => {
            state.pregnant = action.payload;
            return state;
        },
        toggleDrugUser: (state) => {
            state.drugUser = !state.drugUser;
            return state;
        },
        setHaveEverBeenInHospital: (state, action) => {
            state.haveEverBeenInHospital = action.payload;
            return state;
        },
        setHaveBeenInHospitalLast6Months: (state, action) => {
            state.haveBeenInHospitalLast6Months = action.payload;
            return state;
        },
        setHaveHadIllnessOrInjury: (state, action) => {
            state.haveHadIllnessOrInjury = action.payload;
            return state;
        },
        setPhysicalExam: (state, action) => {
            state.physicalExam = action.payload;
            return state;
        },
        setLivingWill: (state, action) => {
            state.livingWill = action.payload;
            return state;
        },
        setDateConsent: (state, action) => {
            state.dateConsent = action.payload;
            return state;
        },
        toggleContactedByMedicalRN: (state) => {
            state.contactedByMedicalRN = !state.contactedByMedicalRN;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAnnualPatientRegistration.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
            })
            .addCase(setAnnualPatientRegistration.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getAnnualPatientRegistration.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.firstName = action.payload?.firstName;
                state.middleName = action.payload?.middleName;
                state.lastName = action.payload?.lastName;
                state.dateOfBirth = action.payload?.dateOfBirth;
                state.SSN = action.payload?.ssn;
                state.gender = action.payload?.gender;
                state.otherGender = action.payload?.otherGender;
                state.email = action.payload?.email;
                state.phone = action.payload?.phone;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.maritalStatus = action.payload?.maritalStatus;
                state.numberOfPeople = action.payload?.numberOfPeople;
                state.numberOfChildren = action.payload?.numberOfChildren;
                state.canLeaveVoiceMessage = action.payload?.canLeaveVoiceMessage;
                state.anyCustodyArrangements = action.payload?.anyCustodyArrangements;
                state.contactFullName = action.payload?.contactFullName;
                state.contactRelationship = action.payload?.contactRelationship;
                state.contactEmail = action.payload?.contactEmail;
                state.contactPhone = action.payload?.contactPhone;
                state.insuranceProvider = action.payload?.insuranceProvider;
                state.policyNumber = action.payload?.policyNumber;
                state.groupNumber = action.payload?.groupNumber;
                state.familyMembers = action.payload?.familyMembers;
                state.incomeEstimate = action.payload?.incomeEstimate;
                state.employment = action.payload?.employment;
                state.employer = action.payload?.employer;
                state.employerAddress = action.payload?.employerAddress;
                state.employerCity = action.payload?.employerCity;
                state.employerState = action.payload?.employerState;
                state.employerZip = action.payload?.employerZip;
                state.numberOfArrests = action.payload?.numberOfArrests;
                state.pregnant = action.payload?.pregnant;
                state.drugUser = action.payload?.drugUser;
                state.haveEverBeenInHospital = action.payload?.haveEverBeenInHospital;
                state.haveBeenInHospitalLast6Months = action.payload?.haveBeenInHospitalLast6Months;
                state.haveHadIllnessOrInjury = action.payload?.haveHadIllnessOrInjury;
                state.livingWill = action.payload?.livingWill;
                state.physicalExam = action.payload?.physicalExam;
                state.dateConsent = action.payload?.dateConsent || CURRENT_DATE;
                state.contactedByMedicalRN = action.payload?.contactedByMedicalRN;

                state.loading = false;
            })
            .addCase(setAnnualPatientRegistration.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Annual Patient Registration saved successfully");
            })
            .addCase(getAnnualPatientRegistration.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Annual Patient Registration: ${action.error.message}`);
            })
            .addCase(setAnnualPatientRegistration.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Annual Patient Registration: ${action.error.message}`);
            })
            .addCase(getAnnualPatientRegistrationByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
            })
            .addCase(getAnnualPatientRegistrationByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.firstName = action.payload?.firstName;
                state.middleName = action.payload?.middleName;
                state.lastName = action.payload?.lastName;
                state.dateOfBirth = action.payload?.dateOfBirth;
                state.SSN = action.payload?.ssn;
                state.gender = action.payload?.gender;
                state.otherGender = action.payload?.otherGender;
                state.email = action.payload?.email;
                state.phone = action.payload?.phone;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.maritalStatus = action.payload?.maritalStatus;
                state.numberOfPeople = action.payload?.numberOfPeople;
                state.numberOfChildren = action.payload?.numberOfChildren;
                state.canLeaveVoiceMessage = action.payload?.canLeaveVoiceMessage;
                state.anyCustodyArrangements = action.payload?.anyCustodyArrangements;
                state.contactFullName = action.payload?.contactFullName;
                state.contactRelationship = action.payload?.contactRelationship;
                state.contactEmail = action.payload?.contactEmail;
                state.contactPhone = action.payload?.contactPhone;
                state.insuranceProvider = action.payload?.insuranceProvider;
                state.policyNumber = action.payload?.policyNumber;
                state.groupNumber = action.payload?.groupNumber;
                state.familyMembers = action.payload?.familyMembers;
                state.incomeEstimate = action.payload?.incomeEstimate;
                state.employment = action.payload?.employment;
                state.employer = action.payload?.employer;
                state.employerAddress = action.payload?.employerAddress;
                state.employerCity = action.payload?.employerCity;
                state.employerState = action.payload?.employerState;
                state.employerZip = action.payload?.employerZip;
                state.numberOfArrests = action.payload?.numberOfArrests;
                state.pregnant = action.payload?.pregnant;
                state.drugUser = action.payload?.drugUser;
                state.haveEverBeenInHospital = action.payload?.haveEverBeenInHospital;
                state.haveBeenInHospitalLast6Months = action.payload?.haveBeenInHospitalLast6Months;
                state.haveHadIllnessOrInjury = action.payload?.haveHadIllnessOrInjury;
                state.livingWill = action.payload?.livingWill;
                state.physicalExam = action.payload?.physicalExam;
                state.dateConsent = action.payload?.dateConsent || CURRENT_DATE;
                state.contactedByMedicalRN = action.payload?.contactedByMedicalRN;

                state.loading = false;
            })
            .addCase(getAnnualPatientRegistrationByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Annual Patient Registration: ${action.error.message}`);
            })
            .addCase(setAnnualPatientRegistrationByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(setAnnualPatientRegistrationByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;
                showToast('success', 'Annual Patient Registration saved successfully');
            })
            .addCase(setAnnualPatientRegistrationByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;
                showToast('error', `Set Annual Patient Registration: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setFirstName,
    setMiddleName,
    setLastName,
    setDateOfBirth,
    setSSN,
    setGender,
    setOtherGender,
    setEmail,
    setPhone,
    setAddress,
    setCity,
    setState,
    setZip,
    setMaritalStatus,
    setNumberOfPeople,
    setNumberOfChildren,
    toggleCanLeaveVoiceMessage,
    toggleAnyCustodyArrangements,

    setContactFullName,
    setContactRelationship,
    setContactEmail,
    setContactPhone,
    setInsuranceProvider,
    setPolicyNumber,
    setGroupNumber,

    setFamilyMembers,
    setIncomeEstimate,
    setEmployment,
    setEmployer,
    setEmployerAddress,
    setEmployerCity,
    setEmployerState,
    setEmployerZip,
    setNumberOfArrests,

    setPregnant,
    toggleDrugUser,
    setHaveEverBeenInHospital,
    setHaveBeenInHospitalLast6Months,
    setHaveHadIllnessOrInjury,
    setPhysicalExam,
    setLivingWill,
    setDateConsent,
    toggleContactedByMedicalRN,
} = annualPatientRegistration.actions;

export default annualPatientRegistration.reducer;