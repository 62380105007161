import { Wrapper, Title, Fieldset, Text, CheckboxGrid } from '../../../components/Main/Main.style';
import { CURRENT_DATE, MAX_CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_CURRENT_DATE, MIN_DATE, MAX_DOB, TEXT_DOB, PHONE_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../utils/constants';
import { dateFormatFromApi } from '../../../utils/formats';
import { NAME_RULE } from '../../../utils/rules';
import { onKeyDownHandle } from '../../../utils/helper';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import NumberBox from 'devextreme-react/number-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const PaymentAgreement = ({
	loading,

	decline,
	firstName,
	middleName,
	lastName,
	dateOfBirth,
	phone,
	address,
	amountDue,
	amountDueDate,
	date,
	cashOrCheck,
	creditCard,
	monthlyAmount,
	dateOfEachMonth,
	duration,
	creditCardNumber,
	creditCardExp,
	creditCardCode,
	creditCardName,
	differentGuarantorName,
	differentGuarantorAddress,
	differentGuarantorPhone,

	onToggleDecline,
	onChangeFirstName,
	onChangeMiddleName,
	onChangeLastName,
	onChangeDateOfBirth,
	onChangePhone,
	onChangeAddress,
	onChangeAmountDue,
	onChangeAmountDueDate,
	onChangeDate,
	onToggleCashOrCheck,
	onToggleCreditCard,
	onChangeMonthlyAmount,
	onChangeDateOfEachMonth,
	onChangeDuration,
	onChangeCreditCardNumber,
	onChangeCreditCardExp,
	onChangeCreditCardCode,
	onChangeCreditCardName,
	onChangeDifferentGuarantorName,
	onChangeDifferentGuarantorAddress,
	onChangeDifferentGuarantorPhone,

	onSavePaymentAgreement,
}) => {
	const sectionName = "paymentAgreement";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Title>Payment Agreement for APIA Health Services</Title>
					</Grid>
					<CheckboxGrid container item spacing={2}>
						<CheckboxGrid item>
							<Text>This form is <span style={{ color: 'var(--green-text-color)' }}>optional</span>. Check this checkbox if you DON’T WANT to set up a monthly payment plan.</Text>
						</CheckboxGrid>
						<CheckboxGrid item>
							<CheckBox
								value={decline}
								onValueChanged={onToggleDecline} />
						</CheckboxGrid>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}>
							I agree to pay for the services rendered by <b>Aleutian Pribilof Islands Association</b>, as indicated
							below. Aleutian Pribilof Islands Association, Inc. is confined to deduct only the monthly amount
							agreed to below, unless otherwise informed by notification from the patient.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="First Name *">
							<>
								<TextBox
									label="First Name *"
									value={firstName}
									onValueChanged={onChangeFirstName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="First Name is required"
										/>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Middle Name">
							<>
								<TextBox
									label="Middle Name"
									value={middleName}
									onValueChanged={onChangeMiddleName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-middleName' }} >
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Last Name *">
							<>
								<TextBox
									label="Last Name *"
									value={lastName}
									onValueChanged={onChangeLastName}
									disabled={decline}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Last Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Date of Birth *">
							<>
								<DateBox
									label="Date of Birth *"
									type="date"
									value={dateFormatFromApi(dateOfBirth)}
									onValueChanged={onChangeDateOfBirth}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date of birth is required"
										/>
										<RangeRule
											message="Date cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_SM}>
						<Tooltip title="Phone">
							<>
								<TextBox
									label="Phone"
									value={phone}
									onValueChanged={onChangePhone}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									disabled={decline}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Amount Due">
							<>
								<NumberBox
									label="Amount Due"
									value={amountDue}
									onValueChanged={onChangeAmountDue}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined" />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Amount Due as of date">
							<>
								<DateBox
									label="Amount Due as of date"
									type="date"
									value={dateFormatFromApi(amountDueDate)}
									onValueChanged={onChangeAmountDueDate}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Date">
							<>
								<DateBox
									label="Date"
									type="date"
									value={dateFormatFromApi(date)}
									onValueChanged={onChangeDate}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset disabled={decline}>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text>
										<b>Payment schedule as follows:</b>
									</Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Payments will be made by cash or check">
										<>
											<CheckBox
												text="Payments will be made by cash or check"
												value={cashOrCheck}
												onValueChanged={onToggleCashOrCheck} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Payments will be made by credit card, which I authorize you to use">
										<>
											<CheckBox
												text="Payments will be made by credit card, which I authorize you to use"
												value={creditCard}
												onValueChanged={onToggleCreditCard} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} md={GRID_MD}>
									<Tooltip title="Monthly Amount">
										<>
											<NumberBox
												label="Monthly Amount"
												value={monthlyAmount}
												onValueChanged={onChangeMonthlyAmount}
												labelMode="floating"
												stylingMode="outlined" />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} md={GRID_MD}>
									<Tooltip title="Date of each month">
										<>
											<TextBox
												label="Date of each month"
												value={dateOfEachMonth}
												onValueChanged={onChangeDateOfEachMonth}
												maxLength={30}
												labelMode="floating"
												stylingMode="outlined"
												inputAttr={{ autocomplete: 'new-dateOfEachMonth' }} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS} md={GRID_MD}>
									<Tooltip title="Duration of months">
										<>
											<NumberBox
												label="Duration of months"
												value={duration ? duration : 0}
												onValueChanged={onChangeDuration}
												min={0}
												max={24}
												labelMode="floating"
												stylingMode="outlined" />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}>
							<b>Credit Card:</b>
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Number">
							<>
								<TextBox
									label="Number"
									value={creditCardNumber}
									onValueChanged={onChangeCreditCardNumber}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-creditCardNumber' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Exp">
							<>
								<DateBox
									label="Exp"
									type="date"
									value={dateFormatFromApi(creditCardExp)}
									onValueChanged={onChangeCreditCardExp}
									disabled={decline}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="3-dig Code">
							<>
								<TextBox
									label="3-dig Code"
									value={creditCardCode}
									onValueChanged={onChangeCreditCardCode}
									disabled={decline}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-creditCardCode' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Name as appears on card">
							<>
								<TextBox
									label="Name as appears on card"
									value={creditCardName}
									onValueChanged={onChangeCreditCardName}
									disabled={decline}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-creditCardName' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}>
							It is understood that if the patient misses payments, without prior notification and agreement,
							APIA reserves the right to transfer collections to a collection agency after 3 months of nonpayment.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={decline}>
							<b>Information of Guarantor if different from Patient:</b>
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Name">
							<>
								<TextBox
									label="Name"
									value={differentGuarantorName}
									onValueChanged={onChangeDifferentGuarantorName}
									disabled={decline}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-differentGuarantorName' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={differentGuarantorAddress}
									onValueChanged={onChangeDifferentGuarantorAddress}
									disabled={decline}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-differentGuarantorAddress' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Phone">
							<>
								<TextBox
									label="Phone"
									value={differentGuarantorPhone}
									onValueChanged={onChangeDifferentGuarantorPhone}
									disabled={decline}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-differentGuarantorPhone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="contained"
							validationGroup={sectionName}
							disabled={decline}
							onClick={onSavePaymentAgreement} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	)
}