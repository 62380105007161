import { styled } from '@mui/material/styles';

export const FixedContainer = styled('div')(({ width, fixed }) => ({
	...(fixed && {
		width: `calc(${width + 10}px)`,
		marginLeft: '-5px',
		position: 'fixed',
		top: '64px',
		backgroundColor: '#fff',
		zIndex: "9999999999999999999999998",
		'@media (max-width: 425px)': {
			top: '56px',
		},
	}),
}));

export const TitleAccordion = styled('h3')(() => ({
	color: 'var(--dark-blue-color)',
	fontFamily: 'Montserrat',
	lineHeight: '22px',
	fontSize: '16px',
	fontStyle: 'normal',
	fontWeight: '600',
	'@media (max-width: 425px)': {
		fontSize: '14px',
	},
}));

