import { setFirstName, setLastName, setMiddleName, setSSN, setDateOfBirth, setGender, setOtherGender, setAddress, setCity, setState, setZip, setPhone, setEmail, setNumberOfPeople, setNumberOfChildren, setMaritalStatus, toggleSendEmail, toggleInternetAccess, toggleCanLeaveVoiceMessage, toggleSendRemindersByText } from "../../../../../../store/users/patientProfile/basicInformation/patientInfo/slice";
import { selectActiveValue  } from "../../../../../../store/users/patientsList/selectors";
import { selectMaritalStatuses } from "../../../../../../store/lookups/maritalStatus/selectors";
import { selectStates } from "../../../../../../store/lookups/state/selectors";
import { selectGenders } from "../../../../../../store/lookups/gender/selectors";

import { getPatientInfoById, savePatientInfoByID, checkAbilityToChangeEmail } from '../../../../../../store/users/patientProfile/basicInformation/patientInfo/middlewares';
import { getMaritalStatuses } from "../../../../../../store/lookups/maritalStatus/middlewares";
import { getState } from "../../../../../../store/lookups/state/middlewares";
import { getGenders } from "../../../../../../store/lookups/gender/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { PatientInformation } from "./PatientInformation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from "react";

import { getPatientChecklist } from "../../../../../../store/users/patientProfile/checklist/middlewares";

export const PatientInformationContainer = () => {
	const [showButton, setShowButton] = useState(false);
	const [customRule, setCustomRule] = useState({ result: null, response: '' });
	const [disabledEmail, setDisabledEmail] = useState(false);
	const dispatch = useDispatch();
	const { id } = useParams();

	const genders = useSelector(selectGenders);
	const states = useSelector(selectStates);
	const maritalStatuses = useSelector(selectMaritalStatuses);
	const activePatientEmail  = useSelector(selectActiveValue)?.email;

	const statePatientProfilePatientInfo     = useSelector(state => state.patientProfilePatientInfo);
	const getEntityPatientProfilePatientInfo = (name) => statePatientProfilePatientInfo[name];

	const FN_TO_CALL = {
		FirstName: setFirstName,
		MiddleName: setMiddleName,
		LastName: setLastName,
		SSN: setSSN,
		DateOfBirth: setDateOfBirth,
		Gender: setGender,
		OtherGender: setOtherGender,
		Address: setAddress,
		City: setCity,
		State: setState,
		Zip: setZip,
		Phone: setPhone,
		Email: setEmail,
		NumberOfPeople: setNumberOfPeople,
		NumberOfChildren: setNumberOfChildren,
		MaritalStatus: setMaritalStatus,
		SendEmail: toggleSendEmail,
		InternetAccess: toggleInternetAccess,
		CanLeaveVoiceMessage: toggleCanLeaveVoiceMessage,
		SendRemindersByText: toggleSendRemindersByText,
	};

	useEffect(() => {
		dispatch(getMaritalStatuses());
		dispatch(getState());
		dispatch(getGenders());
		dispatch(getPatientInfoById(id));
	}, [dispatch, id]);

	const onChangeFunc = (name, event = null, trim = false) => {
		if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
		else dispatch(FN_TO_CALL[name]());
		setShowButton(true);
	};

	const onCancelPatientInfo = () => {
		dispatch(getPatientInfoById(id));
		setShowButton(false);
	}

	const onSavePatientInfo = (event) => {
		const isValid = validateGroup(event);
		if (isValid) {
			dispatch(savePatientInfoByID(id))

			setTimeout(() => {
			 	dispatch(getPatientChecklist(id));
			}, 2000);

			setShowButton(false);
			setDisabledEmail(true);
		}
	}

	const validateEmail = useCallback((e) => {
		if(activePatientEmail === e.value) return true;
		dispatch(checkAbilityToChangeEmail({ oldEmail: activePatientEmail, newEmail: e.value }))
			.then((data) => data.payload.response.json())
			.then((response) => {
				if (response === 'ok') setCustomRule({ result: true, response: '' });
				else setCustomRule({ result: false, response });
			});
		return customRule.result;
	},
	// eslint-disable-next-line
	[customRule.result]
);

	return (
		<PatientInformation
			showButton={showButton}
			genders={genders}
			states={states}
			maritalStatuses={maritalStatuses}

			getEntityPatientProfilePatientInfo={getEntityPatientProfilePatientInfo}
			disabledEmail={disabledEmail}
			onChangeFunc={onChangeFunc}

			onCancelPatientInfo={onCancelPatientInfo}
			onSavePatientInfo={onSavePatientInfo}

			validateEmail={validateEmail}
			validateMessage={customRule.response}
		/>
	);
};
