import { useParams } from 'react-router-dom';
import { setAddress, setCity, setDateOfBirth, setEmail, setFirstName, setGender, setLastName, setMaritalStatus, setMiddleName, setNumberOfChildren, setNumberOfPeople, setOtherGender, setPhone, setSSN, setState, setZip, toggleCanLeaveVoiceMessage, toggleInternetAccess, toggleSendEmail, toggleSendRemindersByText } from "../../../../../store/forms/newPatientRegistration/patientInfo/slice";
import { completePatientInfo } from "../../../../../store/forms/newPatientRegistration/slice";
import { requestMailChange } from '../../../../../store/forms/newPatientRegistration/patientInfo/middlewares';

import { setAddress as setAddressByID, setCity as setCityByID, setDateOfBirth as setDateOfBirthByID, setEmail as setEmailByID, setFirstName as setFirstNameByID, setGender as setGenderByID, setLastName as setLastNameByID, setMaritalStatus as setMaritalStatusByID, setMiddleName as setMiddleNameByID, setNumberOfChildren as setNumberOfChildrenByID, setNumberOfPeople as setNumberOfPeopleByID, setOtherGender as setOtherGenderByID, setPhone as setPhoneByID, setSSN as setSSNByID, setState as setStateByID, setZip as setZipByID, toggleCanLeaveVoiceMessage as toggleCanLeaveVoiceMessageByID, toggleInternetAccess as toggleInternetAccessByID, toggleSendEmail as toggleSendEmailByID, toggleSendRemindersByText as toggleSendRemindersByTextByID } from "../../../../../store/users/patientProfile/basicInformation/patientInfo/slice";

import { selectMaritalStatuses } from "../../../../../store/lookups/maritalStatus/selectors";
import { selectStates } from "../../../../../store/lookups/state/selectors";
import { selectGenders } from "../../../../../store/lookups/gender/selectors";

import { getPatientInfo, savePatientInfo, getPatientInfoById, savePatientInfoById } from "../../../../../store/forms/newPatientRegistration/patientInfo/middlewares";
import { checkAbilityToChangeEmail } from '../../../../../store/users/patientProfile/basicInformation/patientInfo/middlewares';
import { getMaritalStatuses } from "../../../../../store/lookups/maritalStatus/middlewares";
import { getState } from "../../../../../store/lookups/state/middlewares";
import { getGenders } from "../../../../../store/lookups/gender/middlewares";

import { validateGroup } from '../../../../../utils/helper';
import { PatientInformation } from "./PatientInformation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { createTokenProvider } from '../../../../../core/auth/tokenProvider';
import { setEmail as setLoginEmail } from '../../../../../store/auth/login/slice';
import { Dialog, DialogTitle, DialogContent, DialogContentRow, DialogText, DialogActions } from '../../../../../components/Dialog/Dialog.style';
import Button from 'devextreme-react/button';

export const PatientInformationContainer = ({ onClickNext }) => {
    const tokenProvider = createTokenProvider();
    const { getUserData } = tokenProvider;
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(state => id ? state.patientProfilePatientInfo.loading : state.newPatientRegistrationPatientInfo.loading);
    const [customRule, setCustomRule] = useState({ result: null, response: '' });
    const [open, setOpen] = useState(false);
    const [disabledEmail, setDisabledEmail] = useState(false);

    const genders = useSelector(selectGenders);
    const states = useSelector(selectStates);
    const maritalStatuses = useSelector(selectMaritalStatuses);

    const firstName = useSelector(state => id ? state.patientProfilePatientInfo.firstName : state.newPatientRegistrationPatientInfo.firstName);
    const middleName = useSelector(state => id ? state.patientProfilePatientInfo.middleName : state.newPatientRegistrationPatientInfo.middleName);
    const lastName = useSelector(state => id ? state.patientProfilePatientInfo.lastName : state.newPatientRegistrationPatientInfo.lastName);
    const SSN = useSelector(state => id ? state.patientProfilePatientInfo.SSN : state.newPatientRegistrationPatientInfo.SSN);
    const dateOfBirth = useSelector(state => id ? state.patientProfilePatientInfo.dateOfBirth : state.newPatientRegistrationPatientInfo.dateOfBirth);
    const gender = useSelector(state => id ? state.patientProfilePatientInfo.gender : state.newPatientRegistrationPatientInfo.gender);
    const otherGender = useSelector(state => id ? state.patientProfilePatientInfo.otherGender : state.newPatientRegistrationPatientInfo.otherGender);
    const address = useSelector(state => id ? state.patientProfilePatientInfo.address : state.newPatientRegistrationPatientInfo.address);
    const city = useSelector(state => id ? state.patientProfilePatientInfo.city : state.newPatientRegistrationPatientInfo.city);
    const state = useSelector(state => id ? state.patientProfilePatientInfo.state : state.newPatientRegistrationPatientInfo.state);
    const zip = useSelector(state => id ? state.patientProfilePatientInfo.zip : state.newPatientRegistrationPatientInfo.zip);
    const phone = useSelector(state => id ? state.patientProfilePatientInfo.phone : state.newPatientRegistrationPatientInfo.phone);
    const email = useSelector(state => id ? state.patientProfilePatientInfo.email : state.newPatientRegistrationPatientInfo.email);
    const numberOfPeople = useSelector(state => id ? state.patientProfilePatientInfo.numberOfPeople : state.newPatientRegistrationPatientInfo.numberOfPeople);
    const numberOfChildren = useSelector(state => id ? state.patientProfilePatientInfo.numberOfChildren : state.newPatientRegistrationPatientInfo.numberOfChildren);
    const maritalStatus = useSelector(state => id ? state.patientProfilePatientInfo.maritalStatus : state.newPatientRegistrationPatientInfo.maritalStatus);
    const sendEmail = useSelector(state => id ? state.patientProfilePatientInfo.sendEmail : state.newPatientRegistrationPatientInfo.sendEmail);
    const internetAccess = useSelector(state => id ? state.patientProfilePatientInfo.internetAccess : state.newPatientRegistrationPatientInfo.internetAccess);
    const canLeaveVoiceMessage = useSelector(state => id ? state.patientProfilePatientInfo.canLeaveVoiceMessage : state.newPatientRegistrationPatientInfo.canLeaveVoiceMessage);
    const sendRemindersByText = useSelector(state => id ? state.patientProfilePatientInfo.sendRemindersByText : state.newPatientRegistrationPatientInfo.sendRemindersByText);

    useEffect(() => {
        id ? dispatch(getPatientInfoById(id)) : dispatch(getPatientInfo());
        dispatch(getMaritalStatuses());
        dispatch(getState());
        dispatch(getGenders());
    }, [dispatch, id]);

    const sendRequest = (value) => {
        const invalidEmail = 'test@dx-email.com';
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(value !== invalidEmail);
            }, 1000);
        });
    }

    const onChangeFirstName = (event) => {
        id ? dispatch(setFirstNameByID(event.value.trim())) : dispatch(setFirstName(event.value.trim()));
    }

    const onChangeMiddleName = (event) => {
        id ? dispatch(setMiddleNameByID(event.value.trim())) : dispatch(setMiddleName(event.value.trim()));
    }

    const onChangeLastName = (event) => {
        id ? dispatch(setLastNameByID(event.value.trim())) : dispatch(setLastName(event.value.trim()));
    }

    const onChangeSSN = (event) => {
        id ? dispatch(setSSNByID(event.value)) : dispatch(setSSN(event.value));
    }

    const onChangeDateOfBirth = (event) => {
        id ? dispatch(setDateOfBirthByID(event.value)) : dispatch(setDateOfBirth(event.value));
    }

    const onChangeGender = (event) => {
        id ? dispatch(setGenderByID(event.value)) : dispatch(setGender(event.value));
    }

    const onChangeOtherGender = (event) => {
        id ? dispatch(setOtherGenderByID(event.value.trim())) : dispatch(setOtherGender(event.value.trim()));
    }

    const onChangeAddress = (event) => {
        id ? dispatch(setAddressByID(event.value.trim())) : dispatch(setAddress(event.value.trim()));
    }

    const onChangeCity = (event) => {
        id ? dispatch(setCityByID(event.value.trim())) : dispatch(setCity(event.value.trim()));
    }

    const onChangeState = (event) => {
        id ? dispatch(setStateByID(event.value)) : dispatch(setState(event.value));
    }

    const onChangeZip = (event) => {
        id ? dispatch(setZipByID(event.value)) : dispatch(setZip(event.value));
    }

    const onChangePhone = (event) => {
        id ? dispatch(setPhoneByID(event.value)) : dispatch(setPhone(event.value));
    }

    const onChangeEmail = (event) => {
        id ? dispatch(setEmailByID(event.value.trim())) : dispatch(setEmail(event.value.trim()));
    }

    const onChangeNumberOfPeople = (event) => {
        id ? dispatch(setNumberOfPeopleByID(event.value)) : dispatch(setNumberOfPeople(event.value));
    }

    const onChangeNumberOfChildren = (event) => {
        id ? dispatch(setNumberOfChildrenByID(event.value)) : dispatch(setNumberOfChildren(event.value));
    }

    const onChangeMaritalStatus = (event) => {
        id ? dispatch(setMaritalStatusByID(event.value)) : dispatch(setMaritalStatus(event.value));
    }

    const onToggleSendEmail = () => {
        id ? dispatch(toggleSendEmailByID()) : dispatch(toggleSendEmail());
    }

    const onToggleInternetAccess = () => {
        id ? dispatch(toggleInternetAccessByID()) : dispatch(toggleInternetAccess());
    }

    const onToggleCanLeaveVoiceMessage = () => {
        id ? dispatch(toggleCanLeaveVoiceMessageByID()) : dispatch(toggleCanLeaveVoiceMessage());
    }

    const onToggleSendRemindersByText = () => {
        id ? dispatch(toggleSendRemindersByTextByID()) : dispatch(toggleSendRemindersByText());
    }

    const onSavePatientInfo = (event, next = false) => {
        const isValid = validateGroup(event);

        if (isValid) {
            if(email !== getUserData('email') && !disabledEmail) {
                setOpen(true);
            }
            else {
                id ? dispatch(savePatientInfoById(id)) : dispatch(savePatientInfo());
                dispatch(completePatientInfo(true));
                dispatch(setLoginEmail(getUserData('email')));
                next && onClickNext();
            }
        }
    }

    const validateEmail = useCallback(
      (e) => {
        if (getUserData('email') === e.value) return true;
        dispatch(checkAbilityToChangeEmail({ oldEmail: getUserData('email'), newEmail: e.value }))
          .then((data) => data.payload.response.json())
          .then((response) => {
            if (response === 'ok') setCustomRule({ result: true, response: '' });
            else setCustomRule({ result: false, response });
          });
        return customRule.result;
      },
      // eslint-disable-next-line
      [customRule.result],
    );

    const onClickCancel = () => {
      setOpen(false);
      dispatch(setEmail(getUserData('email')));
    };

    const onClickOk = () => {
      dispatch(requestMailChange({ ID: getUserData('Id'), email }));
      dispatch(setEmail(getUserData('email')));
      setDisabledEmail(true);
      setOpen(false);
    };

    return (
      <>
        <Dialog open={open}>
          <DialogTitle>Email was changed</DialogTitle>
          <DialogContent>
            <DialogContentRow>
              <DialogText>
                An email has been sent to the address <strong>{ email }</strong> you provided for confirmation.
                Once you confirm the email, it will be changed for your account.
              </DialogText>
            </DialogContentRow>
          </DialogContent>
          <DialogActions>
            <Button text="Ok" type="default" stylingMode="contained" onClick={onClickOk} />
            <Button text="Cancel" type="default" stylingMode="outlined" onClick={onClickCancel} />
          </DialogActions>
        </Dialog>

        <PatientInformation
          loading={loading}
          sendRequest={sendRequest}
          genders={genders}
          states={states}
          maritalStatuses={maritalStatuses}
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          SSN={SSN}
          dateOfBirth={dateOfBirth}
          gender={gender}
          otherGender={otherGender}
          address={address}
          city={city}
          state={state}
          zip={zip}
          phone={phone}
          email={email}
          numberOfPeople={numberOfPeople}
          numberOfChildren={numberOfChildren}
          maritalStatus={maritalStatus}
          sendEmail={sendEmail}
          internetAccess={internetAccess}
          canLeaveVoiceMessage={canLeaveVoiceMessage}
          sendRemindersByText={sendRemindersByText}
          validateEmail={validateEmail}
          validateMessage={customRule.response}
          disabledEmail={disabledEmail}
          onChangeFirstName={onChangeFirstName}
          onChangeMiddleName={onChangeMiddleName}
          onChangeLastName={onChangeLastName}
          onChangeSSN={onChangeSSN}
          onChangeDateOfBirth={onChangeDateOfBirth}
          onChangeGender={onChangeGender}
          onChangeOtherGender={onChangeOtherGender}
          onChangeAddress={onChangeAddress}
          onChangeCity={onChangeCity}
          onChangeState={onChangeState}
          onChangeZip={onChangeZip}
          onChangePhone={onChangePhone}
          onChangeEmail={onChangeEmail}
          onChangeNumberOfPeople={onChangeNumberOfPeople}
          onChangeNumberOfChildren={onChangeNumberOfChildren}
          onChangeMaritalStatus={onChangeMaritalStatus}
          onToggleSendEmail={onToggleSendEmail}
          onToggleInternetAccess={onToggleInternetAccess}
          onToggleCanLeaveVoiceMessage={onToggleCanLeaveVoiceMessage}
          onToggleSendRemindersByText={onToggleSendRemindersByText}
          onSavePatientInfo={onSavePatientInfo}
        />
      </>
    );
};