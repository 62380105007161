import { Wrapper, Grid, Item, Label, TextBox, SelectBox, CheckBox, Buttons, CheckBoxWrapper } from '../../BasicInformation.style';
import { MAX_WIDTH, TRIBAL_AFFILIATION, GRID_XS, GRID_SM, GRID_LG } from '../../../../../../utils/constants';
import { NAME_RULE } from '../../../../../../utils/rules';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	PatternRule,
} from 'devextreme-react/validator';

export const TribalAffiliation = ({
	showButton,

	ethnicities,
	races,
	languages,

	getEntityPatientProfileTribalAffiliation,
	onChangeFunc,

	onCancelTribalAffiliation,
	onSaveTribalAffiliation,
}) => {
	return (
		<Wrapper id={TRIBAL_AFFILIATION} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileTribalAffiliation('loading')}
				position={{ of: `#${TRIBAL_AFFILIATION}` }} />
			{
				!getEntityPatientProfileTribalAffiliation('loading') &&
				<Grid container spacing={2}>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Tribal/Native Corporation">
							<Label>Tribal/Native Corp:</Label>
						</Tooltip>
						<TextBox
							label="Tribal/Native Corporation"
							value={getEntityPatientProfileTribalAffiliation('corporation')}
							onValueChanged={e => onChangeFunc('Corporation', e, true)}
							maxLength={140}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-corporation' }}
						>
							<Validator validationGroup={TRIBAL_AFFILIATION}>
								<PatternRule
									message="Don't use numbers"
									pattern={NAME_RULE}
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Tribal Enrollment #">
							<Label>Tribal Enrollment #:</Label>
						</Tooltip>
						<TextBox
							label="Tribal Enrollment #"
							value={getEntityPatientProfileTribalAffiliation('enrollment')}
							onValueChanged={e => onChangeFunc('Enrollment', e, true)}
							maxLength={140}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-enrollment' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="CIB">
							<Label>CIB:</Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={getEntityPatientProfileTribalAffiliation('CIB')}
								onClick={() => onChangeFunc('CIB')} >
								{getEntityPatientProfileTribalAffiliation('CIB') ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="CIB Card in Medical Record">
							<Label>CIB Card in Medical Record:</Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={getEntityPatientProfileTribalAffiliation('CIBCard')}
								onClick={() => onChangeFunc('CIBCard')} >
								{getEntityPatientProfileTribalAffiliation('CIBCard') ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Ethnicity">
							<Label>Ethnicity:</Label>
						</Tooltip>
						<SelectBox
							label="Ethnicity"
							defaultValue={getEntityPatientProfileTribalAffiliation('ethnicity')}
							dataSource={ethnicities}
							onValueChanged={e => onChangeFunc('Ethnicity', e)}
							valueExpr="Oid"
							displayExpr="Name"
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-ethnicity' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Race">
							<Label>Race:</Label>
						</Tooltip>
						<SelectBox
							label="Race"
							defaultValue={getEntityPatientProfileTribalAffiliation('race')}
							dataSource={races}
							onValueChanged={e => onChangeFunc('Race', e)}
							valueExpr="Oid"
							displayExpr="Name"
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-race' }}
						/>
					</Item>
					{
						!![getEntityPatientProfileTribalAffiliation('race')] && [getEntityPatientProfileTribalAffiliation('race')].includes(races.find((e) => e?.Name === "Alaska Native")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Alaska Native Group">
								<Label>Alaska Native Group:</Label>
							</Tooltip>
							<TextBox
								label="Alaska Native Group"
								value={getEntityPatientProfileTribalAffiliation('raceAlaska')}
								onValueChanged={e => onChangeFunc('RaceAlaska', e, true)}
								maxLength={30}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-raceAlaska' }}
							>
								<Validator validationGroup={TRIBAL_AFFILIATION}>
									<PatternRule
										message="Don't use numbers"
										pattern={NAME_RULE}
									/>
								</Validator>
							</TextBox>
						</Item>
					}
					{
						!![getEntityPatientProfileTribalAffiliation('race')] && [getEntityPatientProfileTribalAffiliation('race')].includes(races.find((e) => e?.Name === "American Indian")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="American Indian Group">
								<Label>American Indian Group:</Label>
							</Tooltip>
							<TextBox
								label="American Indian Group"
								value={getEntityPatientProfileTribalAffiliation('raceAmerican')}
								onValueChanged={e => onChangeFunc('RaceAmerican', e, true)}
								maxLength={30}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-raceAmerican' }}
							>
								<Validator validationGroup={TRIBAL_AFFILIATION}>
									<PatternRule
										message="Don't use numbers"
										pattern={NAME_RULE}
									/>
								</Validator>
							</TextBox>
						</Item>
					}
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Primary Language if not English">
							<Label>Primary Language:</Label>
						</Tooltip>
						<SelectBox
							label="Primary Language if not English"
							defaultValue={Array.isArray(getEntityPatientProfileTribalAffiliation('language')) ? getEntityPatientProfileTribalAffiliation('language')[0] : getEntityPatientProfileTribalAffiliation('language')}
							dataSource={languages}
							onValueChanged={e => onChangeFunc('Language', e)}
							valueExpr="Oid"
							displayExpr="Name"
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-language' }}
						/>
					</Item>
					{
						!![getEntityPatientProfileTribalAffiliation('language')] && [getEntityPatientProfileTribalAffiliation('language')].includes(languages.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Specify Primary Language">
								<Label>Specified Language:</Label>
							</Tooltip>
							<TextBox
								label="Specify Primary Language"
								value={getEntityPatientProfileTribalAffiliation('otherLanguage')}
								onValueChanged={e => onChangeFunc('OtherLanguage', e, true)}
								maxLength={30}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-otherLanguage' }}
							>
								<Validator validationGroup={TRIBAL_AFFILIATION}>
									<PatternRule
										message="Don't use numbers"
										pattern={NAME_RULE}
									/>
								</Validator>
							</TextBox>
						</Item>
					}
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Translator Required">
							<Label>Translator Required:</Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={getEntityPatientProfileTribalAffiliation('translator')}
								onClick={() => onChangeFunc('Translator')} >
								{getEntityPatientProfileTribalAffiliation('translator') ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item xs={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={TRIBAL_AFFILIATION}
								onClick={onSaveTribalAffiliation} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelTribalAffiliation} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};