import { Wrapper, Item, Label, TextBox, SelectBox, CheckBox, Buttons } from '../../BasicInformation.style';
import { EDUCATION, MAX_WIDTH, GRID_XS } from '../../../../../../utils/constants';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
	RequiredRule,
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 900): return 12;
		case (width > 900 && width <= 1200): return 6;
		case (width > 1200 && width <= 1500): return 4;
		case (width > 1500): return 3;
		default: return 12;
	};
};

export const Education = ({
	showButton,

	educations,
	livingArrangements,
	livingConditions,

	getEntityPatientProfileEducation,
	onChangeFunc,

	onCancelEducation,
	onSaveEducation,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={EDUCATION} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileEducation('loading')}
				position={{ of: `#${EDUCATION}` }} />
			{
				!getEntityPatientProfileEducation('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="Education *">
							<span>
								<Label>Education:</Label>
								<SelectBox
									label="Education *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileEducation('education')}
									dataSource={educations}
									onValueChanged={(e) => onChangeFunc('Education', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-education' }}
								>
									<Validator validationGroup={EDUCATION}>
										<RequiredRule
											message="Education is required"
										/>
										<RangeRule
											message="Education is required"
											min={0} />
									</Validator>
								</SelectBox>
							</span>
						</Tooltip>
					</Item>
					{
						getEntityPatientProfileEducation('education') === (educations.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Specify Education *">
								<span>
									<Label>Specified Education:</Label>
									<TextBox
										label="Specify Education *"
										value={getEntityPatientProfileEducation('otherEducation')}
										onValueChanged={(e) => onChangeFunc('OtherEducation', e)}
										maxLength={64}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-otherEducation' }}>
										<Validator validationGroup={EDUCATION}>
											<RequiredRule
												message="Specify Education is required"
											/>
										</Validator>
									</TextBox>
								</span>
							</Tooltip>
						</Item>
					}
					{
						getEntityPatientProfileEducation('education') === (educations.find((e) => e?.Name === "K-11")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="What is the last grade you completed? *">
								<span>
									<Label>Last grade completed:</Label>
									<TextBox
										label="What is the last grade you completed? *"
										value={getEntityPatientProfileEducation('lastGradeEducation')}
										onValueChanged={(e) => onChangeFunc('LastGradeEducation', e)}
										maxLength={64}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-lastGradeEducation' }}>
										<Validator validationGroup={EDUCATION}>
											<RequiredRule
												message="Last grade is required"
											/>
										</Validator>
									</TextBox>
								</span>
							</Tooltip>
						</Item>
					}
					{
						getEntityPatientProfileEducation('education') === (educations.find((e) => e?.Name === "K-11")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Currently in grade school">
								<span>
									<Label>Currently in grade school:</Label>
									<CheckBox
										value={getEntityPatientProfileEducation('gradeSchoolEducation')}
										onClick={() => onChangeFunc('GradeSchoolEducation')} >
										{getEntityPatientProfileEducation('gradeSchoolEducation') ? 'Yes' : 'No'}
									</CheckBox>
								</span>
							</Tooltip>
						</Item>
					}
					<Item item xs={size}>
						<Tooltip title="Living Arrangement *">
							<span>
								<Label>Living Arrangement:</Label>
								<SelectBox
									label="Living Arrangement *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileEducation('livingArrangement')}
									dataSource={livingArrangements}
									onValueChanged={(e) => onChangeFunc('LivingArrangement', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-livingArrangement' }}
								>
									<Validator validationGroup={EDUCATION}>
										<RequiredRule
											message="Living Arrangement is required"
										/>
										<RangeRule
											message="Living Arrangement is required"
											min={0} />
									</Validator>
								</SelectBox>
							</span>
						</Tooltip>
					</Item>
					{
						getEntityPatientProfileEducation('livingArrangement') === (livingArrangements.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Specify Living Arrangement *">
								<span>
									<Label>Specified Living Arrangement:</Label>
									<TextBox
										label="Specify Living Arrangement *"
										value={getEntityPatientProfileEducation('otherLivingArrangement')}
										onValueChanged={(e) => onChangeFunc('OtherLivingArrangement', e)}
										maxLength={64}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-otherLivingArrangement' }}>
										<Validator validationGroup={EDUCATION}>
											<RequiredRule
												message="Other Living Arrangement is required"
											/>
										</Validator>
									</TextBox>
								</span>
							</Tooltip>
						</Item>
					}
					<Item item xs={size}>
						<Tooltip title="How satisfied are you with your living conditions? *">
							<span>
								<Label>Living Conditions:</Label>
								<SelectBox
									label="How satisfied are you with your living conditions? *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileEducation('livingCondition')}
									dataSource={livingConditions.filter(d => d.IsActive)}
									onValueChanged={(e) => onChangeFunc('LivingCondition', e)}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-livingCondition' }}
								>
									<Validator validationGroup={EDUCATION}>
										<RequiredRule
											message="Living Condition is required"
										/>
										<RangeRule
											message="Living Condition is required"
											min={0} />
									</Validator>
								</SelectBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={EDUCATION}
								onClick={onSaveEducation} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelEducation} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};