import { createSlice } from "@reduxjs/toolkit";
import { FETCH_STATUSES } from '../../utils/constants';
import { getUserProfile } from './middlewares';

const initialState = {
	open: false,
	userName: "",
	userID: null,
	getStatus: FETCH_STATUSES.IDLE,
	getError: null,
};

export const main = createSlice({
	name: "main",
	initialState,
	reducers: {
		setOpen: (state, action) => {
			state.open = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserProfile.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
				state.userName = "";
			})
			.addCase(getUserProfile.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;
				state.userName = action.payload.firstName ? action.payload.firstName : action.payload.fullName;
				state.userID   = action.payload.id;
			})
			.addCase(getUserProfile.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.userName = "";
			});
	},
});

export const {
	setOpen,
	userName,
} = main.actions;

export default main.reducer;