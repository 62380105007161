export const selectLoading = state => state.grievanceFeedbackForm.loading;

export const selectName = state => state.grievanceFeedbackForm.name;
export const selectAddress = state => state.grievanceFeedbackForm.address;
export const selectPhone = state => state.grievanceFeedbackForm.phone;
export const selectEmail = state => state.grievanceFeedbackForm.email;
export const selectTypeConnectionPhone = state => state.grievanceFeedbackForm.typeConnectionPhone;
export const selectTypeConnectionEmail = state => state.grievanceFeedbackForm.typeConnectionEmail;
export const selectTypeConnectionMail = state => state.grievanceFeedbackForm.typeConnectionMail;
export const selectBestConnectionMorning = state => state.grievanceFeedbackForm.bestConnectionMorning;
export const selectBestConnectionAfternoon = state => state.grievanceFeedbackForm.bestConnectionAfternoon;
export const selectBestConnectionEvening = state => state.grievanceFeedbackForm.bestConnectionEvening;
export const selectStatusPatientClient = state => state.grievanceFeedbackForm.statusPatientClient;
export const selectStatusVisitor = state => state.grievanceFeedbackForm.statusVisitor;
export const selectCommentTypeGreatJob = state => state.grievanceFeedbackForm.commentTypeGreatJob;
export const selectCommentTypeInquiry = state => state.grievanceFeedbackForm.commentTypeInquiry;
export const selectCommentTypeSuggestion = state => state.grievanceFeedbackForm.commentTypeSuggestion;
export const selectCommentTypeComplaintGrievance = state => state.grievanceFeedbackForm.commentTypeComplaintGrievance;
export const selectCommentTypeSlidingScale = state => state.grievanceFeedbackForm.commentTypeSlidingScale;
export const selectCommentTypeOther = state => state.grievanceFeedbackForm.commentTypeOther;
export const selectCommentTypeOtherText = state => state.grievanceFeedbackForm.commentTypeOtherText;
export const selectDate = state => state.grievanceFeedbackForm.date;
export const selectClinicDepartment = state => state.grievanceFeedbackForm.clinicDepartment;
export const selectExperience = state => state.grievanceFeedbackForm.experience;
export const selectAllowExperience = state => state.grievanceFeedbackForm.allowExperience;