import { getInsuranceInfoById, setInsuranceInfoById } from "./middlewares";
import { showToast } from "../../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dontHaveInsurance: false,
    allMembers: [],
    totalIncome: "",
    medicare: "",
    medicareEffDate: null,
    medicareExpDate: null,
    medicareNumber: "",
    medicaid: "",
    medicaidEffDate: null,
    medicaidExpDate: null,
    medicaidNumber: "",
    denaliKid: "",
    denaliKidEffDate: null,
    denaliKidExpDate: null,
    denaliKidNumber: "",
    privateInsurance: false,
    companyInsurance: "",
    policyHolder: "",
    policyHolderNumber: "",
    policyHolderGroupNumber: "",
    otherInsurance: "",
    otherInsuranceNumber: "",
    otherInsuranceGroupNumber: "",
    veteran: false,
    benefitsVA: false,
    serviceBranch: "",
    serviceEntryDate: null,
    serviceExitDate: null,
    vietnamService: false,
    doNotOtherInsurance: false,
    selfPay: "",
    relationship: "",
    DOB: null,
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    effectiveDate: null,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const patientProfileInsuranceInfo = createSlice({
    name: "patientProfileInsuranceInfo",
    initialState,
    reducers: {
        toggleDontHaveInsurance: (state) => {
            state.dontHaveInsurance = !state.dontHaveInsurance;
            return state;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setAllMembers: (state, action) => {
            state.allMembers = action.payload;
            return state;
        },
        setTotalIncome: (state, action) => {
            state.totalIncome = action.payload;
            return state;
        },
        setMedicare: (state, action) => {
            state.medicare = action.payload;
            return state;
        },
        setMedicareEffDate: (state, action) => {
            state.medicareEffDate = action.payload;
            return state;
        },
        setMedicareExpDate: (state, action) => {
            state.medicareExpDate = action.payload;
            return state;
        },
        setMedicareNumber: (state, action) => {
            state.medicareNumber = action.payload;
            return state;
        },
        setMedicaid: (state, action) => {
            state.medicaid = action.payload;
            return state;
        },
        setMedicaidEffDate: (state, action) => {
            state.medicaidEffDate = action.payload;
            return state;
        },
        setMedicaidExpDate: (state, action) => {
            state.medicaidExpDate = action.payload;
            return state;
        },
        setMedicaidNumber: (state, action) => {
            state.medicaidNumber = action.payload;
            return state;
        },
        setDenaliKid: (state, action) => {
            state.denaliKid = action.payload;
            return state;
        },
        setDenaliKidEffDate: (state, action) => {
            state.denaliKidEffDate = action.payload;
            return state;
        },
        setDenaliKidExpDate: (state, action) => {
            state.denaliKidExpDate = action.payload;
            return state;
        },
        setDenaliKidNumber: (state, action) => {
            state.denaliKidNumber = action.payload;
            return state;
        },
        togglePrivateInsurance: (state) => {
            state.privateInsurance = !state.privateInsurance;
            return state;
        },
        setCompanyInsurance: (state, action) => {
            state.companyInsurance = action.payload;
            return state;
        },
        setPolicyHolder: (state, action) => {
            state.policyHolder = action.payload;
            return state;
        },
        setPolicyHolderNumber: (state, action) => {
            state.policyHolderNumber = action.payload;
            return state;
        },
        setPolicyHolderGroupNumber: (state, action) => {
            state.policyHolderGroupNumber = action.payload;
            return state;
        },
        setOtherInsurance: (state, action) => {
            state.otherInsurance = action.payload;
            return state;
        },
        setOtherInsuranceNumber: (state, action) => {
            state.otherInsuranceNumber = action.payload;
            return state;
        },
        setOtherInsuranceGroupNumber: (state, action) => {
            state.otherInsuranceGroupNumber = action.payload;
            return state;
        },
        toggleVeteran: (state) => {
            state.veteran = !state.veteran;
            return state;
        },
        toggleBenefitsVA: (state) => {
            state.benefitsVA = !state.benefitsVA;
            return state;
        },
        setServiceBranch: (state, action) => {
            state.serviceBranch = action.payload;
            return state;
        },
        setServiceEntryDate: (state, action) => {
            state.serviceEntryDate = action.payload;
            return state;
        },
        setServiceExitDate: (state, action) => {
            state.serviceExitDate = action.payload;
            return state;
        },
        toggleVietnamService: (state) => {
            state.vietnamService = !state.vietnamService;
            return state;
        },
        toggleDoNotOtherInsurance: (state) => {
            state.doNotOtherInsurance = !state.doNotOtherInsurance;
            return state;
        },
        setSelfPay: (state, action) => {
            state.selfPay = action.payload;
            return state;
        },
        setRelationship: (state, action) => {
            state.relationship = action.payload;
            return state;
        },
        setDOB: (state, action) => {
            state.DOB = action.payload;
            return state;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setEffectiveDate: (state, action) => {
            state.effectiveDate = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInsuranceInfoById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(setInsuranceInfoById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getInsuranceInfoById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.dontHaveInsurance = action.payload?.dontHaveInsurance;
                state.allMembers = action.payload?.allMembers;
                state.totalIncome = action.payload?.totalIncome;
                state.medicare = action.payload?.medicare;
                state.medicareEffDate = action.payload?.medicareEffective;
                state.medicareExpDate = action.payload?.medicareExpDate;
                state.medicareNumber = action.payload?.medicareNumber;
                state.medicaid = action.payload?.medicaid;
                state.medicaidEffDate = action.payload?.medicaidEffective;
                state.medicaidExpDate = action.payload?.medicaidExpDate;
                state.medicaidNumber = action.payload?.medicaidNumber;
                state.denaliKid = action.payload?.denaliKid;
                state.denaliKidEffDate = action.payload?.denaliKidEffective;
                state.denaliKidExpDate = action.payload?.denaliKidExpDate;
                state.denaliKidNumber = action.payload?.denaliKidNumber;
                state.privateInsurance = action.payload?.privateInsurance;
                state.companyInsurance = action.payload?.companyInsurance;
                state.policyHolder = action.payload?.policyHolder;
                state.policyHolderNumber = action.payload?.policyHolderNumber;
                state.policyHolderGroupNumber = action.payload?.policyHolderGroupNumber;
                state.otherInsurance = action.payload?.otherInsurance;
                state.otherInsuranceNumber = action.payload?.otherInsuranceNumber;
                state.otherInsuranceGroupNumber = action.payload?.otherInsuranceGroupNumber;
                state.veteran = action.payload?.veteran;
                state.benefitsVA = action.payload?.benefitsVA;
                state.serviceBranch = action.payload?.serviceBranch;
                state.serviceEntryDate = action.payload?.serviceEntryDate;
                state.serviceExitDate = action.payload?.serviceExitDate;
                state.serviceEntryDate = action.payload?.serviceEntryDate;
                state.serviceExitDate = action.payload?.serviceExitDate;
                state.vietnamService = action.payload?.vietnamService;
                state.doNotOtherInsurance = action.payload?.doNotOtherInsurance;
                state.selfPay = action.payload?.selfPay;
                state.relationship = action.payload?.relationship;
                state.DOB = action.payload?.dob;
                state.phone = action.payload?.phone;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.effectiveDate = action.payload?.effectiveDate;

                state.loading = false;
            })
            .addCase(setInsuranceInfoById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Insurance Information saved successfully");
            })
            .addCase(getInsuranceInfoById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Insurance Information: ${action.error.message}`);
            })
            .addCase(setInsuranceInfoById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Insurance Information: ${action.error.message}`);
            });
    },
});


export const {
    setLoading,

    toggleDontHaveInsurance,
    setAllMembers,
    setTotalIncome,
    setMedicare,
    setMedicareEffDate,
    setMedicareExpDate,
    setMedicareNumber,
    setMedicaid,
    setMedicaidEffDate,
    setMedicaidExpDate,
    setMedicaidNumber,
    setDenaliKid,
    setDenaliKidEffDate,
    setDenaliKidExpDate,
    setDenaliKidNumber,
    togglePrivateInsurance,
    setCompanyInsurance,
    setPolicyHolder,
    setPolicyHolderNumber,
    setPolicyHolderGroupNumber,
    setOtherInsurance,
    setOtherInsuranceNumber,
    setOtherInsuranceGroupNumber,
    toggleVeteran,
    toggleBenefitsVA,
    setServiceBranch,
    setServiceEntryDate,
    setServiceExitDate,
    toggleVietnamService,
    toggleDoNotOtherInsurance,
    setSelfPay,
    setRelationship,
    setDOB,
    setPhone,
    setAddress,
    setCity,
    setState,
    setZip,
    setEffectiveDate,
} = patientProfileInsuranceInfo.actions;

export default patientProfileInsuranceInfo.reducer;
