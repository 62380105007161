import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postSignup = createAsyncThunk(
    "signup",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "post",
                url: `${BASE_URL}/AuthManagement/Register`,
                data: data,
            })
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    }
);