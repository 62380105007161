import { selectEmail, selectBtnStatus } from '../../../store/auth/forgotPassword/selectors';
import { setEmail } from '../../../store/auth/forgotPassword/slice';
import { postForgotPassword } from '../../../store/auth/forgotPassword/middlewares';

//import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateGroup } from '../../../utils/helper';
import { ForgotPassword } from './ForgotPassword';
import { showToast } from '../../Main/MainContainer';

export const ForgotPasswordContainer = () => {
    const dispatch = useDispatch();
    //let navigate = useNavigate();

    const email = useSelector(selectEmail);
    const btnStatus = useSelector(selectBtnStatus);

    const onChangeEmail = (event) => {
        const value = event.value.trim();
        dispatch(setEmail(value));
    }

    const handleClick = (event) => {
        const isValid = validateGroup(event);
        if (isValid) {
            //navigate("/auth/change-password");
            dispatch(postForgotPassword(email)).then(data => {
                if (data.payload === undefined) {
                    showToast('error', 'Invalid email');
                } else {
                    showToast('success', `A password reset email with instructions has been sent successfully to ${data.meta.arg}.`);
                }
            })
        };
    }

    const onKeyPressEnter = (event) => {
        if (event.key === 'Enter') {
            handleClick();
        }
    }

    return (
        <ForgotPassword
            email={email}
            onChangeEmail={onChangeEmail}
            onKeyPressEnter={onKeyPressEnter}
            handleClick={handleClick}
            btnStatus={btnStatus}
        />
    );
}