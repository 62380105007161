import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../../../utils/constants";
import { post } from "../../../../../core/rest";

export const postAdminChangePassword = createAsyncThunk(
    "postAdminChangePassword",
    async (data, { rejectWithValue }) => {
        try {
            return await post(`${BASE_URL}/AuthManagement/AdminChangePassword`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);