import { Wrapper, Item, Label, TextBox, DateBox, SelectBox, CheckBox, Buttons } from '../../BasicInformation.style';
import { ADVANCE_CARE, CURRENT_DATE, MAX_WIDTH, MIN_DATE, GRID_XS } from '../../../../../../utils/constants';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200): return 3;
		default: return 12;
	};
};

export const AdvanceCare = ({
	showButton,

	advHCareDirectiveStatuses,
	advPsychDirectiveStatuses,

	getEntityPatientProfileAdvanceCare,
	onChangeFunc,

	onCancelAdvanceCare,
	onSaveAdvanceCare,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={ADVANCE_CARE} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileAdvanceCare('loading')}
				position={{ of: `#${ADVANCE_CARE}` }} />
			{
				!getEntityPatientProfileAdvanceCare('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="I have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One">
							<span>
								<Label>Advance Health Care Directive:</Label>
								<SelectBox
									label="I have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileAdvanceCare('haveLivingWill')}
									dataSource={advHCareDirectiveStatuses}
									onValueChanged={(e) => onChangeFunc('HaveLivingWill', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-haveLivingWill' }}
								/>
							</span>
						</Tooltip>
					</Item>
					{
						getEntityPatientProfileAdvanceCare('haveLivingWill') === (advHCareDirectiveStatuses.find((e) => e?.Name === "Unable to Access")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Reason">
								<span>
									<Label>Reason:</Label>
									<TextBox
										label="Reason"
										value={getEntityPatientProfileAdvanceCare('haveLivingWillReason')}
										onValueChanged={(e) => onChangeFunc('HaveLivingWillReason', e, true)}
										maxLength={30}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-haveLivingWillReason' }} />
								</span>
							</Tooltip>
						</Item>
					}
					<Item item xs={size}>
						<Tooltip title="I have a Psychiatric Advance Directive">
							<span>
								<Label>Psychiatric Advance Directive:</Label>
								<SelectBox
									label="I have a Psychiatric Advance Directive"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileAdvanceCare('psychiatricAdvanceDirective')}
									dataSource={advPsychDirectiveStatuses}
									onValueChanged={(e) => onChangeFunc('PsychiatricAdvanceDirective', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-psychiatricAdvanceDirective' }}
								/>
							</span>
						</Tooltip>
					</Item>
					{
						getEntityPatientProfileAdvanceCare('psychiatricAdvanceDirective') === (advPsychDirectiveStatuses.find((e) => e?.Name === "Unable to Access")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Reason">
								<span>
									<Label>Reason:</Label>
									<TextBox
										label="Reason"
										value={getEntityPatientProfileAdvanceCare('psychiatricAdvanceDirectiveReason')}
										onValueChanged={(e) => onChangeFunc('PsychiatricAdvanceDirectiveReason', e)}
										maxLength={30}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-psychiatricAdvanceDirectiveReason' }} />
								</span>
							</Tooltip>
						</Item>
					}
					<Item item xs={size}>
						<Tooltip title="Info about Directives needed">
							<span>
								<Label>Info about Directives needed:</Label>
								<CheckBox
									value={getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveInfo')}
									onClick={() => onChangeFunc('AdvanceHealthCareDirectiveInfo')} >
									{getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveInfo') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Directive on file">
							<span>
								<Label>Directive on file:</Label>
								<CheckBox
									value={getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveOnFile')}
									onClick={() => onChangeFunc('AdvanceHealthCareDirectiveOnFile')} >
									{getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveOnFile') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					{
						getEntityPatientProfileAdvanceCare('advanceHealthCareDirectiveOnFile') &&
						<Item item xs={size}>
							<Tooltip title="Last date reviewed">
								<span>
									<Label>Last Date Reviewed:</Label>
									<DateBox
										type="date"
										label="Last date reviewed"
										value={dateFormatFromApi(getEntityPatientProfileAdvanceCare('lastDateReview'))}
										onValueChanged={(e) => onChangeFunc('LastDateReview', e)}
										showDropDownButton={false}
										useMaskBehavior={true}
										displayFormat="MM/dd/yyyy"
										invalidDateMessage="The date must have the following format: MM/dd/yyyy"
										labelMode="hidden"
										stylingMode="underlined"
									>
										<Validator validationGroup={ADVANCE_CARE}>
											<RangeRule
												message="Last date reviewed cannot be later than the current date and earlier than 1900"
												min={MIN_DATE}
												max={CURRENT_DATE} />
										</Validator>
									</DateBox>
								</span>
							</Tooltip>
						</Item>
					}
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={ADVANCE_CARE}
								onClick={onSaveAdvanceCare} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelAdvanceCare} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};