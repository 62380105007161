import { Wrapper, Item, Label, TextBox, TagBox, CheckBox, Buttons } from '../../BasicInformation.style';
import { MAX_WIDTH, REFERRAL_INFO, GRID_XS } from '../../../../../../utils/constants';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900): return 4;
		default: return 12;
	};
};

export const ReferralInfo = ({
	showButton,

	referralStatuses,

	getEntityPatientProfileReferralInfo,
	onChangeFunc,

	onCancelReferralInfo,
	onSaveReferralInfo,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={REFERRAL_INFO} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileReferralInfo('loading')}
				position={{ of: `#${REFERRAL_INFO}` }} />
			{
				!getEntityPatientProfileReferralInfo('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="Referral Information">
							<span>
								<Label>Referral Information:</Label>
								<TagBox
									label="Referral Information"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileReferralInfo('referralInfo')}
									dataSource={referralStatuses}
									onValueChanged={(e) => onChangeFunc('ReferralInfo', e)}
									maxDisplayedTags={1}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true} />
							</span>
						</Tooltip>
					</Item>
					{
						!!getEntityPatientProfileReferralInfo('referralInfo') && getEntityPatientProfileReferralInfo('referralInfo').includes(referralStatuses.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Specify Referral Source">
								<span>
									<Label>Specified Referral Source:</Label>
									<TextBox
										label="Specify Referral Source"
										value={getEntityPatientProfileReferralInfo('otherReferralInfo')}
										onValueChanged={(e) => onChangeFunc('OtherReferralInfo', e, true)}
										maxLength={140}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-otherReferralInfo' }} />
								</span>
							</Tooltip>
						</Item>
					}
					<Item item xs={size}>
						<Tooltip title="Contact by Medical RN needed">
							<span>
								<Label>Contact by Medical RN needed:</Label>
								<CheckBox
									value={getEntityPatientProfileReferralInfo('refferalForMedicalRN')}
									onClick={() => onChangeFunc('RefferalForMedicalRN')} >
									{getEntityPatientProfileReferralInfo('refferalForMedicalRN') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Referral for a physical exam needed">
							<span>
								<Label>Referral for a physical exam needed:</Label>
								<CheckBox
									value={getEntityPatientProfileReferralInfo('contactedByPhysicalExam')}
									onClick={() => onChangeFunc('ContactedByPhysicalExam')} >
									{getEntityPatientProfileReferralInfo('contactedByPhysicalExam') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item>
						<Tooltip title="Reason you are seeking Behavioral Health services today">
							<span>
								<Label>Reason you are seeking Behavioral Health services today:</Label>
								<TextBox
									label="Reason you are seeking Behavioral Health services today:"
									value={getEntityPatientProfileReferralInfo('reason')}
									onValueChanged={(e) => onChangeFunc('Reason', e, true)}
									maxLength={140}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-reason' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={REFERRAL_INFO}
								onClick={onSaveReferralInfo} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelReferralInfo} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};