import { selectActiveStep, selectConsentForRelease, selectDiscountSchedule, selectECommunicationConsent, selectIdAndInsuranceCard, selectNewPatientRegistration, selectNoticeOfPrivacyPractices, selectSubmitForms } from '../../../store/forms/newPatientRegistrationPacket/selectors';
import { getNewPatientRegistrationPacket, getNewPatientRegistrationPacketById } from '../../../store/forms/newPatientRegistrationPacket/middlewares';
import { setActiveStep, setStepsLength } from '../../../store/forms/newPatientRegistrationPacket/slice';

import { NoticeOfPrivacyPracticesContainer } from '../NoticeOfPrivacyPractices/NoticeOfPrivacyPracticesContainer';
import { NewPatientRegistrationContainer } from '../NewPatientRegistration/NewPatientRegistrationContainer';
import { ECommunicationConsentContainer } from '../ECommunicationConsent/ECommunicationConsentContainer';
import { IDAndInsuranceCardContainer } from '../IDAndInsuranceCard/IDAndInsuranceCardContainer';
import { ConsentForReleaseContainer } from '../ConsentForRelease/ConsentForReleaseContainer';
import { DiscountScheduleContainer } from '../DiscountSchedule/DiscountScheduleContainer';
import { SubmitFormsContainer } from './SubmitForms/SubmitFormsContainer';

import { NewPatientRegistrationPacket } from './NewPatientRegistrationPacket';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const NewPatientRegistrationPacketContainer = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const activeStep = useSelector(selectActiveStep);

    const newPatientRegistration = useSelector(selectNewPatientRegistration);
    const noticeOfPrivacyPractices = useSelector(selectNoticeOfPrivacyPractices);
    const eCommunicationConsent = useSelector(selectECommunicationConsent);
    const consentForRelease = useSelector(selectConsentForRelease);
    const idAndInsuranceCard = useSelector(selectIdAndInsuranceCard);
    const discountSchedule = useSelector(selectDiscountSchedule);
    const submitForms = useSelector(selectSubmitForms);

    const steps = [{
        title: 'New Patient Registration',
        body: <NewPatientRegistrationContainer />,
        isCompleted: newPatientRegistration,
        icon: "tabler:kayak",
    }, {
        title: 'Consent for Release of Information',
        body: <ConsentForReleaseContainer />,
        isCompleted: consentForRelease,
        icon: "lucide:wind",
    }, {
        title: 'Notice of Privacy Practices',
        body: <NoticeOfPrivacyPracticesContainer />,
        isCompleted: noticeOfPrivacyPractices,
        icon: "lucide:waves",
    }, {
        title: 'ID, Insurance, Tribal Enrollment',
        body: <IDAndInsuranceCardContainer />,
        isCompleted: idAndInsuranceCard,
        icon: "lucide:cloud-rain-wind",
    }, {
        title: 'Consent for Electronic Communication',
        body: <ECommunicationConsentContainer />,
        isCompleted: eCommunicationConsent,
        icon: "lucide:bird",
    }, {
        title: 'Sliding Fee/Discount Schedule Application',
        body: <DiscountScheduleContainer />,
        isCompleted: discountSchedule,
        icon: "lucide:flower",
    }, {
        title: 'Submit Forms',
        body: <SubmitFormsContainer />,
        isCompleted: submitForms,
        icon: "lucide:fish",
    }];

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getNewPatientRegistrationPacketById(id));
        } else {
            dispatch(getNewPatientRegistrationPacket());
        }
        dispatch(setStepsLength(steps.length));
    }, [dispatch, id, steps.length]);

    const useViewportHeight = () => {
        const [height, setHeight] = useState(window.innerHeight);

        useEffect(() => {
            const updateHeight = () => setHeight(window.innerHeight);
            window.addEventListener('resize', updateHeight);
            return () => window.removeEventListener('resize', updateHeight);
        }, []);

        return height;
    };

    const handleStep = (step) => () => {
        dispatch(setActiveStep(step));
    };

    const handleNext = () => {
        const isLastStep = activeStep === steps.length - 1;
        const newActiveStep = isLastStep ? 0 : activeStep + 1;

        dispatch(setActiveStep(newActiveStep));
    };

    const handleBack = () => {
        const isFirstStep = activeStep === 0;
        const newActiveStep = isFirstStep ? steps.length - 1 : activeStep - 1;

        dispatch(setActiveStep(newActiveStep));
    };

    return (
        <NewPatientRegistrationPacket
            steps={steps}
            activeStep={activeStep}
            handleStep={handleStep}
            handleNext={handleNext}
            handleBack={handleBack}
            useViewportHeight={useViewportHeight}
        />
    );
};
