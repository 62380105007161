export const selectPatientInfo = state => state.newPatientRegistration.patientInfo;
export const selectGuardianInfo = state => state.newPatientRegistration.guardianInfo;
export const selectTribalAffiliation = state => state.newPatientRegistration.tribalAffiliation;
export const selectHealthAndTreatment = state => state.newPatientRegistration.healthAndTreatment;
export const selectEmployment = state => state.newPatientRegistration.employment;
export const selectEducation = state => state.newPatientRegistration.education;
export const selectEmergencyContact = state => state.newPatientRegistration.emergencyContact;
export const selectNextOfKin = state => state.newPatientRegistration.nextOfKin;
export const selectInsuranceInfo = state => state.newPatientRegistration.insuranceInfo;
export const selectReferralInfo = state => state.newPatientRegistration.referralInfo;
export const selectAdvanceCare = state => state.newPatientRegistration.advanceCare;
export const selectConsent = state => state.newPatientRegistration.consent;

export const selectFixed = state => state.newPatientRegistration.fixed;
export const selectExpanded = state => state.newPatientRegistration.expanded;