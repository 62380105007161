import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

import { setRoiValue, setProfileCratedInCernerValue, setInsuranceUploadedCernerValue, setInsuranceVerifiedValue, setInsuranceSubmittedValue, setIdVerifiedValue, setIdUploadedCernerValue, setDateChartCreatedCernerValue, setDateReferralApprovedValue, setDateReferralReceviedValue, setDobValue, setFrontDeskStaffValue, setIdSubmittedValue, setLoading, setNoppValue, setPatientNameValueFirstName, setPatientNameValueLastName, setPatientNameValueMiddleName, setReferralSourceValue, setReferralTypeValue, setRegistrationPacketUploadCernerValue, toggleCommunicationConsentCheck, toggleCommunicationConsentValueEmail, toggleCommunicationConsentValueTelehealth, toggleDateChartCreatedCernerCheck, toggleDateReferralApprovedCheck, toggleDateReferralReceviedCheck, toggleDobCheck, toggleFrontDeskStaffCheck, toggleIdSubmittedCheck, toggleIdUploadedCernerCheck, toggleIdVerifiedCheck, toggleInsuranceSubmittedCheck, toggleInsuranceUploadedCernerCheck, toggleInsuranceVerifiedCheck, toggleNoppCheck, togglePatientNameCheck, toggleProfileCratedInCernerCheck, toggleReferralSourceCheck, toggleReferralTypeCheck, toggleRegistrationPacketUploadCernerCheck, toggleRoiCheck, toggleRegFormCheck, setRegFormValue } from "../../../../store/users/patientProfile/checklist/slice";
import { getPatientChecklistById, savePatientChecklistById } from "../../../../store/users/patientProfile/checklist/middlewares";
import { PatientChecklist } from './PatientChecklist';

export const PatientChecklistContainer = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const statePatientChecklist     = useSelector(state => state.patientChecklist);
    const getEntityPatientChecklist = (name) => statePatientChecklist[name];

    useEffect(() => {
        dispatch(setLoading(false));
        dispatch(getPatientChecklistById(id));
    }, [dispatch, id]);

    const FN_TO_CALL = {
        FrontDeskStaffValue: setFrontDeskStaffValue,
        PatientNameValueFirstName: setPatientNameValueFirstName,
        PatientNameValueMiddleName: setPatientNameValueMiddleName,
        PatientNameValueLastName: setPatientNameValueLastName,
        DobValue: setDobValue,
        DateReferralReceviedValue: setDateReferralReceviedValue,
        DateReferralApprovedValue: setDateReferralApprovedValue,
        ReferralSourceValue: setReferralSourceValue,
        ReferralTypeValue: setReferralTypeValue,
        RegFormValue: setRegFormValue,
        NoppValue: setNoppValue,
        DateChartCreatedCernerValue: setDateChartCreatedCernerValue,
        RegistrationPacketUploadCernerValue: setRegistrationPacketUploadCernerValue,
        IdSubmittedValue: setIdSubmittedValue,
        IdVerifiedValue: setIdVerifiedValue,
        IdUploadedCernerValue: setIdUploadedCernerValue,
        InsuranceSubmittedValue: setInsuranceSubmittedValue,
        InsuranceVerifiedValue: setInsuranceVerifiedValue,
        InsuranceUploadedCernerValue: setInsuranceUploadedCernerValue,
        ProfileCratedInCernerValue: setProfileCratedInCernerValue,
        RoiValue: setRoiValue,
        CommunicationConsentValueTelehealth: toggleCommunicationConsentValueTelehealth,
        CommunicationConsentValueEmail: toggleCommunicationConsentValueEmail,
        FrontDeskStaffCheck: toggleFrontDeskStaffCheck,
        PatientNameCheck: togglePatientNameCheck,
        DobCheck: toggleDobCheck,
        DateReferralReceviedCheck: toggleDateReferralReceviedCheck,
        DateReferralApprovedCheck: toggleDateReferralApprovedCheck,
        ReferralSourceCheck: toggleReferralSourceCheck,
        ReferralTypeCheck: toggleReferralTypeCheck,
        RegFormCheck: toggleRegFormCheck,
        NoppCheck: toggleNoppCheck,
        CommunicationConsentCheck: toggleCommunicationConsentCheck,
        DateChartCreatedCernerCheck: toggleDateChartCreatedCernerCheck,
        RegistrationPacketUploadCernerCheck: toggleRegistrationPacketUploadCernerCheck,
        IdSubmittedCheck: toggleIdSubmittedCheck,
        IdVerifiedCheck: toggleIdVerifiedCheck,
        IdUploadedCernerCheck: toggleIdUploadedCernerCheck,
        IdInsuranceSubmittedCheck: toggleInsuranceSubmittedCheck,
        InsuranceVerifiedCheck: toggleInsuranceVerifiedCheck,
        InsuranceUploadedCernerCheck: toggleInsuranceUploadedCernerCheck,
        ProfileCratedInCernerCheck: toggleProfileCratedInCernerCheck,
        RoiCheck: toggleRoiCheck,
    };

    const onChangeFunc = (name, event = null, trim = false) => {
        if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        else dispatch(FN_TO_CALL[name]());
    };

    const onToggleFunc = (name, e) => {
        if (!!e.event) {
            e.event.stopPropagation();
            dispatch(FN_TO_CALL[name]());
        }
    };

    const onSavePatientChecklist = () => {
        dispatch(savePatientChecklistById(id));
    };

    return (
        <PatientChecklist
            open={open}

            getEntityPatientChecklist={getEntityPatientChecklist}
            onToggleFunc={onToggleFunc}
            onChangeFunc={onChangeFunc}

            setOpen={setOpen}
            onSavePatientChecklist={onSavePatientChecklist}
        />
    );
}