import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { showToast } from "../../../screens/Main/MainContainer";
import { getEthnicities } from "./middlewares";

const initialState = {
    value: [],
    status: FETCH_STATUSES.IDLE,
    error: null,
};

export const ethnicity = createSlice({
    name: "ethnicity",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getEthnicities.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
            })
            .addCase(getEthnicities.fulfilled, (state, action) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.value = action.payload;
                state.error = null;
            })
            .addCase(getEthnicities.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = action.error;

                showToast('error', `Get ethnicity: ${action.error.message}`);
            });
    },
});

export default ethnicity.reducer;
