import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../core/rest";

export const getEthnicities = createAsyncThunk(
    "getEthnicities",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/Ethnicity");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);