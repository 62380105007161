import { Drawer, Wrapper, TitleWrapper, Title, Accordion, AccordionSummary, AccordionDetails, Label, Switcher } from './PatientChecklist.style';
import { dateFormatFromApi } from '../../../../utils/formats';
import { Icon } from '@iconify/react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import Tooltip from '@mui/material/Tooltip';

export const PatientChecklist = ({
    open,

    getEntityPatientChecklist,
    onToggleFunc,
    onChangeFunc,

    setOpen,
    onSavePatientChecklist,
}) => {
    const sectionName = "patientChecklist";

    return (
        <Drawer open={open}>
            <Wrapper>
                <LoadPanel
                    visible={getEntityPatientChecklist('loading')}
                    position={{ of: `#${sectionName}` }} />
                {
                    !getEntityPatientChecklist('loading') && <>
                        <TitleWrapper onClick={() => setOpen(!open)} style={{ marginLeft: open ? '0px' : '-100px' }}>
                            {!open && <Icon icon="jam:chevron-left" height="18px" width="18px" />}
                            <Tooltip title="Patient checklist">
                                <Title>Patient checklist</Title>
                            </Tooltip>
                            {open && <Icon icon="jam:chevron-right" height="18px" width="18px" />}
                        </TitleWrapper>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Referral Assigned to Front Desk Staff Member">
                                    <span>
                                        <CheckBox
                                            text="Referral Assigned to Front Desk Staff Member"
                                            value={getEntityPatientChecklist('frontDeskStaffCheck')}
                                            onValueChanged={(e) => onToggleFunc('FrontDeskStaffCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Front Desk Staff Member">
                                    <span>
                                        <TextBox
                                            label="Front Desk Staff Member"
                                            value={getEntityPatientChecklist('frontDeskStaffValue')}
                                            onValueChanged={(e) => onChangeFunc('FrontDeskStaffValue', e, true)}
                                            labelMode="floating"
                                            stylingMode="outlined"
                                            inputAttr={{ autocomplete: 'new-frontDeskStaffValue' }} />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Patient Name">
                                    <span>
                                        <CheckBox
                                            text="Patient Name"
                                            value={getEntityPatientChecklist('patientNameCheck')}
                                            onValueChanged={(e) => onToggleFunc('PatientNameCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextBox
                                    label="First Name"
                                    value={getEntityPatientChecklist('patientNameValueFirstName')}
                                    onValueChanged={(e) => onChangeFunc('PatientNameValueFirstName', e, true)}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-firstname' }} />
                                <TextBox
                                    label="Middle Name"
                                    value={getEntityPatientChecklist('patientNameValueMiddleName')}
                                    onValueChanged={(e) => onChangeFunc('PatientNameValueMiddleName', e, true)}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-middlename' }} />
                                <TextBox
                                    label="Last Name"
                                    value={getEntityPatientChecklist('patientNameValueLastName')}
                                    onValueChanged={(e) => onChangeFunc('PatientNameValueLastName', e, true)}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-lastname' }} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date of Birth">
                                    <span>
                                        <CheckBox
                                            text="Date of Birth"
                                            value={getEntityPatientChecklist('dobCheck')}
                                            onValueChanged={(e) => onToggleFunc('DobCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Date of Birth">
                                    <span>
                                        <DateBox
                                            type="date"
                                            label="Date of Birth"
                                            value={dateFormatFromApi(getEntityPatientChecklist('dobValue'))}
                                            onValueChanged={(e) => onChangeFunc('DobValue', e)}
                                            useMaskBehavior={true}
                                            displayFormat="MM/dd/yyyy"
                                            invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                            labelMode="floating"
                                            stylingMode="outlined"
                                        />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date Referral Received">
                                    <span>
                                        <CheckBox
                                            text="Date Referral Received"
                                            value={getEntityPatientChecklist('dateReferralReceviedCheck')}
                                            onValueChanged={(e) => onToggleFunc('DateReferralReceviedCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Date Referral Received">
                                    <span>
                                        <DateBox
                                            type="date"
                                            label="Date Referral Received"
                                            value={dateFormatFromApi(getEntityPatientChecklist('dateReferralReceviedValue'))}
                                            onValueChanged={(e) => onChangeFunc('DateReferralReceviedValue', e)}
                                            useMaskBehavior={true}
                                            displayFormat="MM/dd/yyyy"
                                            invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                            labelMode="floating"
                                            stylingMode="outlined"
                                        />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date Referral Approved">
                                    <span>
                                        <CheckBox
                                            text="Date Referral Approved"
                                            value={getEntityPatientChecklist('dateReferralApprovedCheck')}
                                            onValueChanged={(e) => onToggleFunc('DateReferralApprovedCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Date Referral Approved">
                                    <span>
                                        <DateBox
                                            type="date"
                                            label="Date Referral Approved"
                                            value={dateFormatFromApi(getEntityPatientChecklist('dateReferralApprovedValue'))}
                                            onValueChanged={(e) => onChangeFunc('DateReferralApprovedValue', e)}
                                            useMaskBehavior={true}
                                            displayFormat="MM/dd/yyyy"
                                            invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                            labelMode="floating"
                                            stylingMode="outlined"
                                        />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Referral Source">
                                    <span>
                                        <CheckBox
                                            text="Referral Source"
                                            value={getEntityPatientChecklist('referralSourceCheck')}
                                            onValueChanged={(e) => onToggleFunc('ReferralSourceCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Referral Source">
                                    <span>
                                        <TextBox
                                            label="Referral Source"
                                            value={getEntityPatientChecklist('referralSourceValue')}
                                            onValueChanged={(e) => onChangeFunc('ReferralSourceValue', e, true)}
                                            labelMode="floating"
                                            stylingMode="outlined"
                                            inputAttr={{ autocomplete: 'new-referralSourceValue' }} />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Referral Type">
                                    <span>
                                        <CheckBox
                                            text="Referral Type"
                                            value={getEntityPatientChecklist('referralTypeCheck')}
                                            onValueChanged={(e) => onToggleFunc('ReferralTypeCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Referral Type">
                                    <span>
                                        <TextBox
                                            label="Referral Type"
                                            value={getEntityPatientChecklist('referralTypeValue')}
                                            onValueChanged={(e) => onChangeFunc('ReferralTypeValue', e, true)}
                                            labelMode="floating"
                                            stylingMode="outlined"
                                            inputAttr={{ autocomplete: 'new-referralTypeValue' }} />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Reg Form">
                                    <span>
                                        <CheckBox
                                            text="Reg Form"
                                            value={getEntityPatientChecklist('regFormCheck')}
                                            onValueChanged={(e) => onToggleFunc('RegFormCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Reg Form">
                                    <span>
                                        <TextBox
                                            label="Reg Form"
                                            value={getEntityPatientChecklist('regFormValue')}
                                            onValueChanged={(e) => onChangeFunc('RegFormValue', e, true)}
                                            labelMode="floating"
                                            stylingMode="outlined"
                                            inputAttr={{ autocomplete: 'new-regFormValue' }} />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="NOPP">
                                    <span>
                                        <CheckBox
                                            text="NOPP"
                                            value={getEntityPatientChecklist('noppCheck')}
                                            onValueChanged={(e) => onToggleFunc('NoppCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="NOPP">
                                    <span>
                                        <TextBox
                                            label="NOPP"
                                            value={getEntityPatientChecklist('noppValue')}
                                            onValueChanged={(e) => onChangeFunc('NoppValue', e, true)}
                                            labelMode="floating"
                                            stylingMode="outlined"
                                            inputAttr={{ autocomplete: 'new-noppValue' }} />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Electronic Communication Consent">
                                    <span>
                                        <CheckBox
                                            text="Electronic Communication Consent"
                                            value={getEntityPatientChecklist('communicationConsentCheck')}
                                            onValueChanged={(e) => onToggleFunc('CommunicationConsentCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <Label>Telehealth: </Label>
                                    <Switcher
                                        value={getEntityPatientChecklist('communicationConsentValueTelehealth')}
                                        onClick={() => onChangeFunc('CommunicationConsentValueTelehealth')} >
                                        {getEntityPatientChecklist('communicationConsentValueTelehealth') ? 'Yes' : 'No'}
                                    </Switcher>
                                </div>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <Label>Email: </Label>
                                    <Switcher
                                        value={getEntityPatientChecklist('communicationConsentValueEmail')}
                                        onClick={() => onChangeFunc('CommunicationConsentValueEmail')} >
                                        {getEntityPatientChecklist('communicationConsentValueEmail') ? 'Yes' : 'No'}
                                    </Switcher>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date Chart Created in Cerner">
                                    <span>
                                        <CheckBox
                                            text="Date Chart Created in Cerner"
                                            value={getEntityPatientChecklist('dateChartCreatedCernerCheck')}
                                            onValueChanged={(e) => onToggleFunc('DateChartCreatedCernerCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tooltip title="Date Chart Created in Cerner">
                                    <span>
                                        <DateBox
                                            type="date"
                                            label="Date Chart Created in Cerner"
                                            value={dateFormatFromApi(getEntityPatientChecklist('dateChartCreatedCernerValue'))}
                                            onValueChanged={(e) => onChangeFunc('DateChartCreatedCernerValue', e)}
                                            useMaskBehavior={true}
                                            displayFormat="MM/dd/yyyy"
                                            invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                            labelMode="floating"
                                            stylingMode="outlined"
                                        />
                                    </span>
                                </Tooltip>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Registration Packet Uploaded in Cerner">
                                    <span>
                                        <CheckBox
                                            text="Registration Packet Uploaded in Cerner"
                                            value={getEntityPatientChecklist('registrationPacketUploadCernerCheck')}
                                            onValueChanged={(e) => onToggleFunc('RegistrationPacketUploadCernerCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('registrationPacketUploadCernerValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('RegistrationPacketUploadCernerValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ID Submitted">
                                    <span>
                                        <CheckBox
                                            text="ID Submitted"
                                            value={getEntityPatientChecklist('idSubmittedCheck')}
                                            onValueChanged={(e) => onToggleFunc('IdSubmittedCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('idSubmittedValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('IdSubmittedValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ID Verified (Active)">
                                    <span>
                                        <CheckBox
                                            text="ID Verified (Active)"
                                            value={getEntityPatientChecklist('idVerifiedCheck')}
                                            onValueChanged={(e) => onToggleFunc('IdVerifiedCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('idVerifiedValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('IdVerifiedValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ID Uploaded in Cerner">
                                    <span>
                                        <CheckBox
                                            text="ID Uploaded in Cerner"
                                            value={getEntityPatientChecklist('idUploadedCernerCheck')}
                                            onValueChanged={(e) => onToggleFunc('IdUploadedCernerCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('idUploadedCernerValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('IdUploadedCernerValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Insurance Submitted">
                                    <span>
                                        <CheckBox
                                            text="Insurance Submitted"
                                            value={getEntityPatientChecklist('insuranceSubmittedCheck')}
                                            onValueChanged={(e) => onToggleFunc('IdInsuranceSubmittedCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('insuranceSubmittedValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('InsuranceSubmittedValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Insurance Verified (Active)">
                                    <span>
                                        <CheckBox
                                            text="Insurance Verified (Active)"
                                            value={getEntityPatientChecklist('insuranceVerifiedCheck')}
                                            onValueChanged={(e) => onToggleFunc('InsuranceVerifiedCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('insuranceVerifiedValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('InsuranceVerifiedValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Insurance Uploaded in Cerner">
                                    <span>
                                        <CheckBox
                                            text="Insurance Uploaded in Cerner"
                                            value={getEntityPatientChecklist('insuranceUploadedCernerCheck')}
                                            onValueChanged={(e) => onToggleFunc('InsuranceUploadedCernerCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('insuranceUploadedCernerValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('InsuranceUploadedCernerValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="APIA BH Case Management Profile Created in Cerner">
                                    <span>
                                        <CheckBox
                                            text="APIA BH Case Management Profile Created in Cerner"
                                            value={getEntityPatientChecklist('profileCratedInCernerCheck')}
                                            onValueChanged={(e) => onToggleFunc('ProfileCratedInCernerCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('profileCratedInCernerValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('ProfileCratedInCernerValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ROI">
                                    <span>
                                        <CheckBox
                                            text="ROI"
                                            value={getEntityPatientChecklist('roiCheck')}
                                            onValueChanged={(e) => onToggleFunc('RoiCheck', e)} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={getEntityPatientChecklist('roiValue')}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={(e) => onChangeFunc('RoiValue', e)} />
                            </AccordionDetails>
                        </Accordion>
                        <Button
                            text="Save"
                            type="default"
                            stylingMode="outlined"
                            onClick={onSavePatientChecklist} />
                    </>
                }
            </Wrapper>
        </Drawer >
    );
}