
import { AdminContainer } from './Steps/Admin/AdminContainer';

import { BasicInformation } from './BasicInformation';
import { useState } from 'react';

export const BasicInformationContainer = () => {
    const [expanded, setExpanded] = useState([]);

    const steps = [{
        title: 'Admin',
        body: <AdminContainer />,
    }];

    const onClickExpand = (panel) => {
        let newItems = [...expanded];
        if (newItems.includes(panel)) {
            const index = newItems.indexOf(panel);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        } else {
            newItems = [...newItems, panel];
        }
        setExpanded(newItems);
    };

    const onClickExpandAll = () => {
        const keys = Object.keys(steps).map((key) => parseInt(key, 10));
        setExpanded(keys);
    }

    const onClickCollapseAll = () => {
        setExpanded([]);
    }

    return (
        <BasicInformation
            steps={steps}
            expanded={expanded}
            onClickExpand={onClickExpand}
            onClickExpandAll={onClickExpandAll}
            onClickCollapseAll={onClickCollapseAll}
        />
    );
}