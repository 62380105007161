import { BASE_URL, CONSENT } from "../../../../../utils/constants";
import { dateFormatForApi } from "../../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getConsentById = createAsyncThunk(
    "getConsentById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${CONSENT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setConsentById = createAsyncThunk(
    "setConsentById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${CONSENT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveConsentById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileConsent;

    const data = {
        "name": store.name,
        "signed": store.signed,
        "signPicture": store.signPicture,
        "guardian": store.guardian,
        "signedDate": dateFormatForApi(store.signedDate),
        "guardianDate": dateFormatForApi(store.guardianDate),
    }

    dispatch(setConsentById({ id, data }));
};