import { useParams } from 'react-router-dom';
import { selectLoading, selectAddress, selectCity, selectEmployer, selectOtherPrimaryIncomeSource, selectPrimaryIncomeSource, selectState, selectZip, selectEmployment } from '../../../../../store/forms/newPatientRegistration/employment/selectors';
import { setAddress, setCity, setEmployer, setOtherPrimaryIncomeSource, setPrimaryIncomeSource, setState, setZip, setEmployment } from '../../../../../store/forms/newPatientRegistration/employment/slice';
import { getEmployment, saveEmployment, getEmploymentByID, saveEmploymentByID } from '../../../../../store/forms/newPatientRegistration/employment/middlewares';
import { completeEmployment } from '../../../../../store/forms/newPatientRegistration/slice';

import { getPrimaryIncomeSources } from '../../../../../store/lookups/primaryIncomeSource/middlewares';
import { getEmployments } from '../../../../../store/lookups/employment/middlewares';
import { getState } from '../../../../../store/lookups/state/middlewares';

import { selectPrimaryIncomeSources } from '../../../../../store/lookups/primaryIncomeSource/selectors';
import { selectEmployments } from '../../../../../store/lookups/employment/selectors';
import { selectStates } from '../../../../../store/lookups/state/selectors';

import { validateGroup } from '../../../../../utils/helper';
import { useDispatch, useSelector } from "react-redux";
import { Employment } from './Employment';
import { useEffect } from 'react';

export const EmploymentContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const states = useSelector(selectStates);
    const employments = useSelector(selectEmployments);
    const primaryIncomeSources = useSelector(selectPrimaryIncomeSources);

    const employment = useSelector(selectEmployment);
    const employer = useSelector(selectEmployer);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const primaryIncomeSource = useSelector(selectPrimaryIncomeSource);
    const otherPrimaryIncomeSource = useSelector(selectOtherPrimaryIncomeSource);

    useEffect(() => {
        dispatch(id ? getEmploymentByID(id) : getEmployment());
        dispatch(getState());
        dispatch(getEmployments());
        dispatch(getPrimaryIncomeSources());
    }, [dispatch, id]);

    const onChangeEmployment = (event) => {
        dispatch(setEmployment(event.value));
    }

    const onChangeEmployer = (event) => {
        dispatch(setEmployer(event.value.trim()));
    }

    const onChangeAddress = (event) => {
        dispatch(setAddress(event.value.trim()));
    }

    const onChangeCity = (event) => {
        dispatch(setCity(event.value.trim()));
    }

    const onChangeState = (event) => {
        dispatch(setState(event.value));
    }

    const onChangeZip = (event) => {
        dispatch(setZip(event.value));
    }

    const onChangePrimaryIncomeSource = (event) => {
        dispatch(setPrimaryIncomeSource(event.value));
    }
    const onChangeOtherPrimaryIncomeSource = (event) => {
        dispatch(setOtherPrimaryIncomeSource(event.value.trim()));
    }

    const onSaveEmployment = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveEmploymentByID(id) : saveEmployment());
            dispatch(completeEmployment(true));
        }
    }

    const onSaveAndContinueEmployment = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveEmploymentByID(id) : saveEmployment());
            dispatch(completeEmployment(true));
            onClickNext();
        }
    }

    return (
        <Employment
            loading={loading}

            states={states}
            employments={employments}
            primaryIncomeSources={primaryIncomeSources}

            employment={employment}
            employer={employer}
            address={address}
            city={city}
            state={state}
            zip={zip}
            primaryIncomeSource={primaryIncomeSource}
            otherPrimaryIncomeSource={otherPrimaryIncomeSource}

            onChangeEmployment={onChangeEmployment}
            onChangeEmployer={onChangeEmployer}
            onChangeAddress={onChangeAddress}
            onChangeCity={onChangeCity}
            onChangeState={onChangeState}
            onChangeZip={onChangeZip}
            onChangePrimaryIncomeSource={onChangePrimaryIncomeSource}
            onChangeOtherPrimaryIncomeSource={onChangeOtherPrimaryIncomeSource}

            onSaveEmployment={onSaveEmployment}
            onSaveAndContinueEmployment={onSaveAndContinueEmployment}
        />
    );
};