import { Text, Box, BoxTitle, WrapIconPNG, FlexDIV, BlockDIV, Link } from './ContactBox.style';

/**
 *
 * @param {text} data
 * @param {text} width
 * @param {text} color
 *
 * @returns {jsx}
 */
const ContactBox = ({ data: { image, title, text, email }, width, color }) => (
  <Box
    width={width}
    style={{
      backgroundColor: color ? `var(--background-${color}-color)` : `var(--background-color)`,
    }}>
    <FlexDIV>
      <WrapIconPNG style={{ background: `url(${image}) no-repeat` }} />
      <BlockDIV>
        <BoxTitle>{title}</BoxTitle>
        <Text>{text}</Text>
        {email && (
          <Text>
            Email: <Link href={email}>{email}</Link>
          </Text>
        )}
      </BlockDIV>
    </FlexDIV>
  </Box>
);

export default ContactBox;
