import Button from 'devextreme-react/button';
import { styled } from '@mui/material/styles';

const ButtonStyled = styled(Button)(() => ({
    width: '100%',
    height: '60px',
    marginTop: '30px',
    borderRadius: '15px',
    background: 'linear-gradient(90deg, #004F93 0%, var(--light-blue-color) 100%)',
    transition: 'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& .dx-button-text': {
        textTransform: 'uppercase',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '2px',
        color: 'var(--white-color)',
    },
    '&.dx-button.dx-state-focused, &.dx-button.dx-state-hover': {
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    },
}));

export default ButtonStyled;