import { Wrapper, Title, Text } from '../../../../components/Main/Main.style';
import Button from '../../../../components/Button/Button';
import { Grid } from '@mui/material';

export const SubmitForms = ({
	isCompleted,
	handleClick,
}) => {
	return (
		<Wrapper>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Title>Submit Forms to Registration	</Title>
				</Grid>
				<Grid item xs={12}>
					<Text>
						Registration packet located on the left side of the screen is required for each patient. Please review and fill in each form. All forms are marked as being <span style={{ color: 'var(--red-text-color)' }}>required</span> or <span style={{ color: 'var(--green-text-color)' }}>optional</span>. If you need assistance with the registration packet, or have questions, please contact the front desk at 1-907-222-9764.
					</Text>
				</Grid>
				<Grid item xs={12}>
					<Text>
						Once all required forms are filled out, "Submit Packet" button becomes available. Click on it, and the forms and all the documents you attach will be sent to registration.
					</Text>
				</Grid>
				<Grid item xs={12} style={{ marginBottom: '30px' }} >
					<Button
						label="Submit packet"
						variant="contained"
						onClick={handleClick}
						disabled={!isCompleted}
						startIcon="akar-icons:paper-airplane"
						sx={{ marginTop: '10px' }} />
				</Grid>
			</Grid>
		</Wrapper >
	);
}
