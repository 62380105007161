import { getPaymentAgreement, setPaymentAgreement } from './middlewares';
import { CURRENT_DATE, FETCH_STATUSES } from '../../../utils/constants';
import { showToast } from '../../../screens/Main/MainContainer';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	decline: false,
	firstName: "",
	middleName: "",
	lastName: "",
	dateOfBirth: null,
	phone: "",
	address: "",
	amountDue: "",
	amountDueDate: null,
	date: CURRENT_DATE,
	cashOrCheck: false,
	creditCard: false,
	monthlyAmount: "",
	dateOfEachMonth: "",
	duration: "",
	creditCardNumber: "",
	creditCardExp: null,
	creditCardCode: "",
	creditCardName: "",
	differentGuarantorName: "",
	differentGuarantorAddress: "",
	differentGuarantorPhone: "",
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
};

export const paymentAgreement = createSlice({
	name: 'paymentAgreement',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		toggleDecline: (state) => {
			state.decline = !state.decline;
			return state;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
			return state;
		},
		setMiddleName: (state, action) => {
			state.middleName = action.payload;
			return state;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
			return state;
		},
		setDateOfBirth: (state, action) => {
			state.dateOfBirth = action.payload;
			return state;
		},
		setPhone: (state, action) => {
			state.phone = action.payload;
			return state;
		},
		setAddress: (state, action) => {
			state.address = action.payload;
			return state;
		},
		setAmountDue: (state, action) => {
			state.amountDue = action.payload;
			return state;
		},
		setAmountDueDate: (state, action) => {
			state.amountDueDate = action.payload;
			return state;
		},
		setDate: (state, action) => {
			state.date = action.payload;
			return state;
		},
		toggleCashOrCheck: (state) => {
			state.cashOrCheck = !state.cashOrCheck;
			return state;
		},
		toggleCreditCard: (state) => {
			state.creditCard = !state.creditCard;
			return state;
		},
		setMonthlyAmount: (state, action) => {
			state.monthlyAmount = action.payload;
			return state;
		},
		setDateOfEachMonth: (state, action) => {
			state.dateOfEachMonth = action.payload;
			return state;
		},
		setDuration: (state, action) => {
			state.duration = action.payload;
			return state;
		},
		setCreditCardNumber: (state, action) => {
			state.creditCardNumber = action.payload;
			return state;
		},
		setCreditCardExp: (state, action) => {
			state.creditCardExp = action.payload;
			return state;
		},
		setCreditCardCode: (state, action) => {
			state.creditCardCode = action.payload;
			return state;
		},
		setCreditCardName: (state, action) => {
			state.creditCardName = action.payload;
			return state;
		},
		setDifferentGuarantorName: (state, action) => {
			state.differentGuarantorName = action.payload;
			return state;
		},
		setDifferentGuarantorAddress: (state, action) => {
			state.differentGuarantorAddress = action.payload;
			return state;
		},
		setDifferentGuarantorPhone: (state, action) => {
			state.differentGuarantorPhone = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPaymentAgreement.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setPaymentAgreement.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getPaymentAgreement.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.decline = action.payload?.decline;
				state.firstName = action.payload?.firstName;
				state.middleName = action.payload?.middleName;
				state.lastName = action.payload?.lastName;
				state.dateOfBirth = action.payload?.dateOfBirth;
				state.phone = action.payload?.phone;
				state.address = action.payload?.address;
				state.amountDue = action.payload?.amountDue;
				state.amountDueDate = action.payload?.amountDueDate;
				state.date = action.payload?.date || CURRENT_DATE;
				state.cashOrCheck = action.payload?.cashOrCheck;
				state.creditCard = action.payload?.creditCard;
				state.monthlyAmount = action.payload?.monthlyAmount;
				state.dateOfEachMonth = action.payload?.dateOfEachMonth;
				state.duration = action.payload?.duration;
				state.creditCardNumber = action.payload?.creditCardNumber;
				state.creditCardExp = action.payload?.creditCardExp;
				state.creditCardCode = action.payload?.creditCardCode;
				state.creditCardName = action.payload?.creditCardName;
				state.differentGuarantorName = action.payload?.differentGuarantorName;
				state.differentGuarantorAddress = action.payload?.differentGuarantorAddress;
				state.differentGuarantorPhone = action.payload?.differentGuarantorPhone;

				state.loading = false;
			})
			.addCase(setPaymentAgreement.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Payment Agreement saved successfully");
			})
			.addCase(getPaymentAgreement.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Payment Agreement: ${action.error.message}`);
			})
			.addCase(setPaymentAgreement.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Payment Agreement: ${action.error.message}`);
			});
	},
});

export const {
	setLoading,

	toggleDecline,
	setFirstName,
	setMiddleName,
	setLastName,
	setDateOfBirth,
	setPhone,
	setAddress,
	setAmountDue,
	setAmountDueDate,
	setDate,
	toggleCashOrCheck,
	toggleCreditCard,
	setMonthlyAmount,
	setDateOfEachMonth,
	setDuration,
	setCreditCardNumber,
	setCreditCardExp,
	setCreditCardCode,
	setCreditCardName,
	setDifferentGuarantorName,
	setDifferentGuarantorAddress,
	setDifferentGuarantorPhone,
} = paymentAgreement.actions;

export default paymentAgreement.reducer;