import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getConsentForRelease = createAsyncThunk(
	"getConsentForRelease",
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + "/ConsentForRelease");
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const getConsentForReleaseByID = createAsyncThunk(
	"getConsentForReleaseByID",
	async (patienID, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + `/ConsentForRelease/${patienID}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setConsentForRelease = createAsyncThunk(
	"setConsentForRelease",
	async (data, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + "/ConsentForRelease", data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setConsentForReleaseByID = createAsyncThunk(
	"setConsentForReleaseByID",
	async ({ patienID, data }, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + `/ConsentForRelease/${patienID}`, data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const saveConsentForRelease = () => (dispatch, getState) => {
	const store = getState().consentForRelease;

	const data = {
		"decline": store.decline,
		"firstName": store.firstName,
		"middleName": store.middleName,
		"lastName": store.lastName,
		"dob": dateFormatForApi(store.dob),
		"address": store.address,
		"city": store.city,
		"state": store.state,
		"zip": store.zip,
		"phone": store.phone,
		"legalGuardian": store.legalGuardian,
		"atkaHealthClinic": store.atkaHealthClinic,
		"anchorageBehavioralHealth": store.anchorageBehavioralHealth,
		"nikolskiHealthCenter": store.nikolskiHealthCenter,
		"saintGeorgeHealthCenter": store.saintGeorgeHealthCenter,
		"oonalaskaPrimaryCare": store.oonalaskaPrimaryCare,
		"oonalaskaBehavioralHealth": store.oonalaskaBehavioralHealth,
		"releaseInformationTo": store.releaseInformationTo,
		"obtainInformationFrom": store.obtainInformationFrom,
		"verbally": store.verbally,
		"inWritingElectronically": store.inWritingElectronically,
		"agency": store.agency,
		"agencyName": store.agencyName,
		"agencyAddress": store.agencyAddress,
		"agencyCity": store.agencyCity,
		"agencyState": store.agencyState,
		"agencyZip": store.agencyZip,
		"agencyPhone": store.agencyPhone,
		"assessmentRecommendations": store.assessmentRecommendations,
		"admissionSummary": store.admissionSummary,
		"resultsOfUrinalysis": store.resultsOfUrinalysis,
		"attendanceCompliance": store.attendanceCompliance,
		"treatmentPlan": store.treatmentPlan,
		"psychiatricEvaluations": store.psychiatricEvaluations,
		"treatmentStatus": store.treatmentStatus,
		"dischargeDateAndSummary": store.dischargeDateAndSummary,
		"bhMedicationNeeds": store.bhMedicationNeeds,
		"regardingOther": store.regardingOther,
		"regardingOtherText": store.regardingOtherText,
		"referralTreatmentPlacement": store.referralTreatmentPlacement,
		"provideReferralInformation": store.provideReferralInformation,
		"verifyParticipationInTreatment": store.verifyParticipationInTreatment,
		"coordinationOfServices": store.coordinationOfServices,
		"purposesOther": store.purposesOther,
		"purposesOtherText": store.purposesOtherText,
		"signedName": store.signedName,
		"signedDate": dateFormatForApi(store.signedDate),
		"expiredDate": dateFormatForApi(store.expiredDate),
		"signPicture": store.signPicture,
	}
	dispatch(setConsentForRelease(data));
};

export const saveConsentForReleaseByID = (patienID) => (dispatch, getState) => {
	const store = getState().consentForRelease;

	const data = {
		"decline": store.decline,
		"firstName": store.firstName,
		"middleName": store.middleName,
		"lastName": store.lastName,
		"dob": dateFormatForApi(store.dob),
		"address": store.address,
		"city": store.city,
		"state": store.state,
		"zip": store.zip,
		"phone": store.phone,
		"legalGuardian": store.legalGuardian,
		"atkaHealthClinic": store.atkaHealthClinic,
		"anchorageBehavioralHealth": store.anchorageBehavioralHealth,
		"nikolskiHealthCenter": store.nikolskiHealthCenter,
		"saintGeorgeHealthCenter": store.saintGeorgeHealthCenter,
		"oonalaskaPrimaryCare": store.oonalaskaPrimaryCare,
		"oonalaskaBehavioralHealth": store.oonalaskaBehavioralHealth,
		"releaseInformationTo": store.releaseInformationTo,
		"obtainInformationFrom": store.obtainInformationFrom,
		"verbally": store.verbally,
		"inWritingElectronically": store.inWritingElectronically,
		"agency": store.agency,
		"agencyName": store.agencyName,
		"agencyAddress": store.agencyAddress,
		"agencyCity": store.agencyCity,
		"agencyState": store.agencyState,
		"agencyZip": store.agencyZip,
		"agencyPhone": store.agencyPhone,
		"assessmentRecommendations": store.assessmentRecommendations,
		"admissionSummary": store.admissionSummary,
		"resultsOfUrinalysis": store.resultsOfUrinalysis,
		"attendanceCompliance": store.attendanceCompliance,
		"treatmentPlan": store.treatmentPlan,
		"psychiatricEvaluations": store.psychiatricEvaluations,
		"treatmentStatus": store.treatmentStatus,
		"dischargeDateAndSummary": store.dischargeDateAndSummary,
		"bhMedicationNeeds": store.bhMedicationNeeds,
		"regardingOther": store.regardingOther,
		"regardingOtherText": store.regardingOtherText,
		"referralTreatmentPlacement": store.referralTreatmentPlacement,
		"provideReferralInformation": store.provideReferralInformation,
		"verifyParticipationInTreatment": store.verifyParticipationInTreatment,
		"coordinationOfServices": store.coordinationOfServices,
		"purposesOther": store.purposesOther,
		"purposesOtherText": store.purposesOtherText,
		"signedName": store.signedName,
		"signedDate": dateFormatForApi(store.signedDate),
		"expiredDate": dateFormatForApi(store.expiredDate),
		"signPicture": store.signPicture,
	}
	dispatch(setConsentForReleaseByID({ patienID, data }));
};