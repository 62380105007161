import React, { useEffect, useCallback, useRef, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCheckAttemptId, getEmailByAttemptId, postChangePassword } from '../../../store/auth/changePassword/middlewares';
import { setBtnStatus } from '../../../store/auth/changePassword/slice';
import { selectKeyStatus, selectBtnStatus } from '../../../store/auth/changePassword/selectors';
import { ChangePassword } from './ChangePassword';
import { validateGroup } from '../../../utils/helper';
import { showToast } from '../../Main/MainContainer';

export const ChangePasswordContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const keyStatus = useSelector(selectKeyStatus);
    const btnStatus = useSelector(selectBtnStatus);

    const params = new URLSearchParams(document.location.search);
    const attemptId = params.get('attemptId');

    useEffect(() => {
        dispatch(getCheckAttemptId(attemptId));
    }, [dispatch, attemptId]);

    const changeFirstMode = () => setNewPassMode(newPassMode === 'text' ? 'password' : 'text');
    const changeSecondMode = () => setConfirmNewPassMode(confirmNewPassMode === "text" ? "password" : "text");

    const validatorRef = useRef(null);

    const [newPass, setPass] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('');

    const [newPassMode, setNewPassMode] = useState('password');
    const [confirmNewPassMode, setConfirmNewPassMode] = useState('password');

    const passComparison = useCallback(() => newPass, [newPass]);

    const onChangedNewPass = useCallback(
        (e) => {
            setPass(e.value.trim());
            if (confirmNewPass) {
                validatorRef.current.instance.validate();
            }
        },
        [confirmNewPass, setPass],
    );

    const onChangedConfirmNewPass = useCallback((e) => {
        setConfirmNewPass(e.value.trim());
    }, []);

    const handleClick = (event) => {
        if (keyStatus) {

            const isValid = validateGroup(event);
            if (isValid) {
                dispatch(getEmailByAttemptId(attemptId)).then(res => {
                    const email = res.payload.data;

                    const params = new URLSearchParams(document.location.search);
                    const attemptId = params.get('attemptId');

                    dispatch(postChangePassword({
                        email,
                        newPassword: newPass,
                        attemptId,
                    })).then(dataAnswer => {
                        if (dataAnswer.payload.success) {
                            showToast('success', 'Password changed successfully');
                            setTimeout(() => {
                                navigate("/auth/login");
                            }, 4000);
                        } else {
                            showToast('error', 'Something went wrong');
                        }
                    })
                })
            };
        } else {
            dispatch(setBtnStatus(true));
            showToast('error', 'The key has expired');
            setTimeout(() => {
                navigate("/auth/forgot-password");
            }, 4000);
        };
    };

    return (
        <ChangePassword
            newPass={newPass}
            confirmNewPass={confirmNewPass}
            onChangedNewPass={onChangedNewPass}
            onChangedConfirmNewPass={onChangedConfirmNewPass}
            newPassMode={newPassMode}
            confirmNewPassMode={confirmNewPassMode}
            changeFirstMode={changeFirstMode}
            changeSecondMode={changeSecondMode}
            validatorRef={validatorRef}
            passComparison={passComparison}
            handleClick={handleClick}
            btnStatus={btnStatus}
        />
    );
};