export const fileExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
export const maxFileSize = 4000000;

export const DOMAIN_URL = process.env.REACT_APP_BUILD_URL;

export const BASE_URL = '/api/v1';

/* Files */
export const uploadFileAPI = `${BASE_URL}/FileUploader/upload`;

export const onChangeFrontId = `${BASE_URL}/FileUploader/findLast/1`;

export const FETCH_STATUSES = {
    IDLE: 'idle',
    REQUEST: 'request',
    FAILURE: 'failure',
    SUCCESS: 'success',
};

export const DRAWER_WIDTH = 300;
export const USER_PATH = 'userPath';
export const MAX_WIDTH = "false";

export const MIN_DATE = new Date(1900, 0, 1);
export const MAX_DATE = new Date(2100, 0, 1);

export const CURRENT_DATE = new Date();
export const MIN_CURRENT_DATE = new Date(CURRENT_DATE.getFullYear(), 0, 1);
export const MAX_CURRENT_DATE = new Date(CURRENT_DATE.getFullYear() + 1, 0, 1);
export const NEXT_YEAR_DATE = new Date(CURRENT_DATE.getFullYear() + 1, CURRENT_DATE.getMonth(), CURRENT_DATE.getDate());

export const SELECT_TIME = (time) => new Date(time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

export const DOWNLOAD = 'download';
export const SHOW = 'show';

export const MAX_DOB = new Date(CURRENT_DATE.getFullYear() - 18, CURRENT_DATE.getMonth(), CURRENT_DATE.getDate());

/* Masks */
export const ZIP_MASK = "X0000";
export const SSN_MASK = "XXX-XX-XXXX";
export const PHONE_MASK = "(000)000-0000";

/* Document Sections  */
export const PATIENT_INFO = "personalData";
export const GUARDIAN_INFO = "guardian";
export const TRIBAL_AFFILIATION = "tribal";
export const HEALTH_INFO = "health";
export const EDUCATION = "education";
export const EMPLOYMENT = "employment";
export const EMERGENCY_CONTACT = "emergency";
export const NEXT_KIN = "nextKin";
export const INSURANCE_INFO = "insurance";
export const ADVANCE_CARE = "advanceCare";
export const REFERRAL_INFO = "referral";
export const CONSENT = "consent";

/* Roles */
export const ADMIN = 'Admin';
export const PATIENT = 'Patient';
export const INVITEE = 'Invitee';

/* Select switcher */
export const SELECT_ADMINS = 'Admins';
export const SELECT_PATIENTS = 'Patients';
export const SELECT_INVITED = 'Invited';

/* Validator messages  */
export const TEXT_DOB = 'You must be at least 18+ years old';

/* Responsive values */
export const GRID_XS = 12;
export const GRID_SM = 6;
export const GRID_MD = 4;
export const GRID_LG = 3;
