import { getEmergencyContactById, setEmergencyContactById } from "./middlewares";
import { showToast } from "../../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contact: "",
    relationship: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const patientProfileEmergencyContact = createSlice({
    name: "patientProfileEmergencyContact",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setContact: (state, action) => {
            state.contact = action.payload;
            return state;
        },
        setRelationship: (state, action) => {
            state.relationship = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
            return state;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmergencyContactById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(setEmergencyContactById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getEmergencyContactById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.contact = action.payload?.contact;
                state.relationship = action.payload?.relationship;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;

                state.loading = false;
            })
            .addCase(setEmergencyContactById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Emergency Contact Info saved successfully");
            })
            .addCase(getEmergencyContactById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Emergency Contact Info: ${action.error.message}`);
            })
            .addCase(setEmergencyContactById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Emergency Contact Info: ${action.error.message}`);
            });
    },
});

export const {
    setLoading,

    setContact,
    setRelationship,
    setAddress,
    setCity,
    setState,
    setZip,
    setPhone,
    setEmail,
} = patientProfileEmergencyContact.actions;

export default patientProfileEmergencyContact.reducer;