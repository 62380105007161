import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { selectLoading, selectName, selectAddress, selectPhone, selectEmail, selectTypeConnectionPhone, selectTypeConnectionEmail, selectTypeConnectionMail, selectBestConnectionMorning, selectBestConnectionAfternoon, selectBestConnectionEvening, selectStatusPatientClient, selectStatusVisitor, selectCommentTypeGreatJob, selectCommentTypeInquiry, selectCommentTypeSuggestion, selectCommentTypeComplaintGrievance, selectCommentTypeSlidingScale, selectCommentTypeOther, selectCommentTypeOtherText, selectDate, selectClinicDepartment, selectExperience, selectAllowExperience } from '../../../store/forms/grievanceFeedbackForm/selectors';
import { setLoading, setName, setAddress, setPhone, setEmail, toggleTypeConnectionPhone, toggleTypeConnectionEmail, toggleTypeConnectionMail, toggleBestConnectionMorning, toggleBestConnectionAfternoon, toggleBestConnectionEvening, toggleStatusPatientClient, toggleStatusVisitor, toggleCommentTypeGreatJob, toggleCommentTypeInquiry, toggleCommentTypeSuggestion, toggleCommentTypeComplaintGrievance, toggleCommentTypeSlidingScale, toggleCommentTypeOther, setCommentTypeOtherText, setDate, setClinicDepartment, setExperience, setAllowExperience } from '../../../store/forms/grievanceFeedbackForm/slice';
import { getGrievanceFeedback, saveGrievanceFeedback, getGrievanceFeedbackByID, saveGrievanceFeedbackByID } from '../../../store/forms/grievanceFeedbackForm/middlewares';

import { GrievanceFeedbackForm } from './GrievanceFeedbackForm';
import { validateGroup } from '../../../utils/helper';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { showToast } from '../../Main/MainContainer';
import { isAdmin } from '../../../core/auth/authProvider';
import { USER_PATH } from '../../../utils/constants';

export const GrievanceFeedbackFormContainer = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id }   = useParams();

	const loading = useSelector(selectLoading);

	const name = useSelector(selectName);
	const address = useSelector(selectAddress);
	const phone = useSelector(selectPhone);
	const email = useSelector(selectEmail);
	const typeConnectionPhone = useSelector(selectTypeConnectionPhone);
	const typeConnectionEmail = useSelector(selectTypeConnectionEmail);
	const typeConnectionMail = useSelector(selectTypeConnectionMail);
	const bestConnectionMorning = useSelector(selectBestConnectionMorning);
	const bestConnectionAfternoon = useSelector(selectBestConnectionAfternoon);
	const bestConnectionEvening = useSelector(selectBestConnectionEvening);
	const statusPatientClient = useSelector(selectStatusPatientClient);
	const statusVisitor = useSelector(selectStatusVisitor);
	const commentTypeGreatJob = useSelector(selectCommentTypeGreatJob);
	const commentTypeInquiry = useSelector(selectCommentTypeInquiry);
	const commentTypeSuggestion = useSelector(selectCommentTypeSuggestion);
	const commentTypeComplaintGrievance = useSelector(selectCommentTypeComplaintGrievance);
	const commentTypeSlidingScale = useSelector(selectCommentTypeSlidingScale);
	const commentTypeOther = useSelector(selectCommentTypeOther);
	const commentTypeOtherText = useSelector(selectCommentTypeOtherText);
	const date = useSelector(selectDate);
	const clinicDepartment = useSelector(selectClinicDepartment);
	const experience = useSelector(selectExperience);
	const allowExperience = useSelector(selectAllowExperience);

	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(id ? getGrievanceFeedbackByID(id) : getGrievanceFeedback());
	}, [dispatch, id]);

	const onChangeName = (event) => {
		dispatch(setName(event.value));
	}
	const onChangeAddress = (event) => {
		dispatch(setAddress(event.value.trim()));
	}
	const onChangePhone = (event) => {
		dispatch(setPhone(event.value));
	}
	const onChangeEmail = (event) => {
		dispatch(setEmail(event.value.trim()));
	}
	const onToggleTypeConnectionPhone = () => {
		dispatch(toggleTypeConnectionPhone());
	}
	const onToggleTypeConnectionEmail = () => {
		dispatch(toggleTypeConnectionEmail());
	}
	const onToggleTypeConnectionMail = () => {
		dispatch(toggleTypeConnectionMail());
	}
	const onToggleBestConnectionMorning = () => {
		dispatch(toggleBestConnectionMorning());
	}
	const onToggleBestConnectionAfternoon = () => {
		dispatch(toggleBestConnectionAfternoon());
	}
	const onToggleBestConnectionEvening = () => {
		dispatch(toggleBestConnectionEvening());
	}
	const onToggleStatusPatientClient = () => {
		dispatch(toggleStatusPatientClient());
	}
	const onToggleStatusVisitor = () => {
		dispatch(toggleStatusVisitor());
	}
	const onToggleCommentTypeGreatJob = () => {
		dispatch(toggleCommentTypeGreatJob());
	}
	const onToggleCommentTypeInquiry = () => {
		dispatch(toggleCommentTypeInquiry());
	}
	const onToggleCommentTypeSuggestion = () => {
		dispatch(toggleCommentTypeSuggestion());
	}
	const onToggleCommentTypeComplaintGrievance = () => {
		dispatch(toggleCommentTypeComplaintGrievance());
	}
	const onToggleCommentTypeSlidingScale = () => {
		dispatch(toggleCommentTypeSlidingScale());
	}
	const onToggleCommentTypeOther = () => {
		dispatch(toggleCommentTypeOther());
	}
	const onChangeCommentTypeOtherText = (event) => {
		dispatch(setCommentTypeOtherText(event.value));
	}
	const onChangeDate = (event) => {
		dispatch(setDate(event.value));
	}
	const onChangeClinicDepartment = (event) => {
		dispatch(setClinicDepartment(event.value));
	}
	const onChangeExperience = (event) => {
		dispatch(setExperience(event.value));
	}
	const onChangeAllowExperience = (event) => {
		dispatch(setAllowExperience(event.value));
	}

	const onSaveGrievanceFeedbackForm = (event) => {
		const isValid = validateGroup(event);

		if (isValid) {
			dispatch(id ? saveGrievanceFeedbackByID(id) : saveGrievanceFeedback()).then(() => {
				!isAdmin() && navigate('/main/forms/all');
			}).catch(() => {
				showToast('error', 'Error to save Grievance & Feedback Form');
			});
		}
	};

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

	return (
		<GrievanceFeedbackForm
			loading={loading}

			name={name}
			address={address}
			phone={phone}
			email={email}
			typeConnectionPhone={typeConnectionPhone}
			typeConnectionEmail={typeConnectionEmail}
			typeConnectionMail={typeConnectionMail}
			bestConnectionMorning={bestConnectionMorning}
			bestConnectionAfternoon={bestConnectionAfternoon}
			bestConnectionEvening={bestConnectionEvening}
			statusPatientClient={statusPatientClient}
			statusVisitor={statusVisitor}
			commentTypeGreatJob={commentTypeGreatJob}
			commentTypeInquiry={commentTypeInquiry}
			commentTypeSuggestion={commentTypeSuggestion}
			commentTypeComplaintGrievance={commentTypeComplaintGrievance}
			commentTypeSlidingScale={commentTypeSlidingScale}
			commentTypeOther={commentTypeOther}
			commentTypeOtherText={commentTypeOtherText}
			date={date}
			clinicDepartment={clinicDepartment}
			experience={experience}
			allowExperience={allowExperience}

			onChangeName={onChangeName}
			onChangeAddress={onChangeAddress}
			onChangePhone={onChangePhone}
			onChangeEmail={onChangeEmail}
			onToggleTypeConnectionPhone={onToggleTypeConnectionPhone}
			onToggleTypeConnectionEmail={onToggleTypeConnectionEmail}
			onToggleTypeConnectionMail={onToggleTypeConnectionMail}
			onToggleBestConnectionMorning={onToggleBestConnectionMorning}
			onToggleBestConnectionAfternoon={onToggleBestConnectionAfternoon}
			onToggleBestConnectionEvening={onToggleBestConnectionEvening}
			onToggleStatusPatientClient={onToggleStatusPatientClient}
			onToggleStatusVisitor={onToggleStatusVisitor}
			onToggleCommentTypeGreatJob={onToggleCommentTypeGreatJob}
			onToggleCommentTypeInquiry={onToggleCommentTypeInquiry}
			onToggleCommentTypeSuggestion={onToggleCommentTypeSuggestion}
			onToggleCommentTypeComplaintGrievance={onToggleCommentTypeComplaintGrievance}
			onToggleCommentTypeSlidingScale={onToggleCommentTypeSlidingScale}
			onToggleCommentTypeOther={onToggleCommentTypeOther}

			onChangeCommentTypeOtherText={onChangeCommentTypeOtherText}
			onChangeDate={onChangeDate}
			onChangeClinicDepartment={onChangeClinicDepartment}
			onChangeExperience={onChangeExperience}
			onChangeAllowExperience={onChangeAllowExperience}

			onSaveGrievanceFeedbackForm={onSaveGrievanceFeedbackForm}
			onClickBack={onClickBack}
		/>
	);
}