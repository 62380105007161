import { getTribalAffiliation, postTribalAffiliation, getTribalAffiliationByID, postTribalAffiliationByID } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    corporation: "",
    enrollment: "",
    ethnicity: "",
    race: [],
    raceAlaska: "",
    raceAmerican: "",
    language: [],
    otherLanguage: "",
    CIB: false,
    CIBCard: false,
    translator: false,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationTribalAffiliation = createSlice({
    name: "newPatientRegistrationTribalAffiliation",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setCorporation: (state, action) => {
            state.corporation = action.payload;
            return state;
        },
        setEnrollment: (state, action) => {
            state.enrollment = action.payload;
            return state;
        },
        setEthnicity: (state, action) => {
            state.ethnicity = action.payload;
            return state;
        },
        setRace: (state, action) => {
            state.race = action.payload;
            return state;
        },
        setRaceAlaska: (state, action) => {
            state.raceAlaska = action.payload;
            return state;
        },
        setRaceAmerican: (state, action) => {
            state.raceAmerican = action.payload;
            return state;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
            return state;
        },
        setOtherLanguage: (state, action) => {
            state.otherLanguage = action.payload;
            return state;
        },
        toggleCIB: (state) => {
            state.CIB = !state.CIB;
            return state;
        },
        toggleCIBCard: (state) => {
            state.CIBCard = !state.CIBCard;
            return state;
        },
        toggleTranslator: (state) => {
            state.translator = !state.translator;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTribalAffiliation.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postTribalAffiliation.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getTribalAffiliation.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.corporation = action.payload?.corporation;
                state.enrollment = action.payload?.enrollment;
                state.ethnicity = action.payload?.ethnicity;
                state.race = action.payload?.race;
                state.raceAlaska = action.payload?.raceAlaska;
                state.raceAmerican = action.payload?.raceAmerican;
                state.language = action.payload?.language;
                state.otherLanguage = action.payload?.otherLanguage;
                state.CIB = action.payload?.cib;
                state.CIBCard = action.payload?.cibCard;
                state.translator = action.payload?.translator;

                state.loading = false;
            })
            .addCase(postTribalAffiliation.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Tribal Affiliation/Ethnicity/Language saved successfully");
            })
            .addCase(getTribalAffiliation.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Tribal Affiliation/Ethnicity/Language: ${action.error.message}`);
            })
            .addCase(postTribalAffiliation.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Tribal Affiliation/Ethnicity/Language: ${action.error.message}`);
            })
            .addCase(getTribalAffiliationByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getTribalAffiliationByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;
                state.corporation = action.payload?.corporation;
                state.enrollment = action.payload?.enrollment;
                state.ethnicity = action.payload?.ethnicity;
                state.race = action.payload?.race;
                state.raceAlaska = action.payload?.raceAlaska;
                state.raceAmerican = action.payload?.raceAmerican;
                state.language = action.payload?.language;
                state.otherLanguage = action.payload?.otherLanguage;
                state.CIB = action.payload?.cib;
                state.CIBCard = action.payload?.cibCard;
                state.translator = action.payload?.translator;
                state.loading = false;
            })
            .addCase(getTribalAffiliationByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;
                showToast('error', `Get Tribal Affiliation/Ethnicity/Language: ${action.error.message}`);
            })
            .addCase(postTribalAffiliationByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(postTribalAffiliationByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;
                showToast('success', 'Tribal Affiliation/Ethnicity/Language saved successfully');
            })
            .addCase(postTribalAffiliationByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;
                showToast('error', `Set Tribal Affiliation/Ethnicity/Language: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setCorporation,
    setEnrollment,
    setEthnicity,
    setRace,
    setRaceAlaska,
    setRaceAmerican,
    setLanguage,
    setOtherLanguage,
    toggleCIB,
    toggleCIBCard,
    toggleTranslator,
} = newPatientRegistrationTribalAffiliation.actions;

export default newPatientRegistrationTribalAffiliation.reducer;