import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getECommunicationConsent = createAsyncThunk(
	"getECommunicationConsent",
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + "/ECommunicationConsent");
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const getECommunicationConsentByID = createAsyncThunk(
	"getECommunicationConsentByID",
	async (patienID, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + `/ECommunicationConsent/${patienID}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setECommunicationConsent = createAsyncThunk(
	"setECommunicationConsent",
	async (data, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + "/ECommunicationConsent", data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const saveECommunicationConsent = () => (dispatch, getState) => {
	const store = getState().eCommunicationConsent;

	const data = {
		teleDto: {
			"decline": store.telehealth.decline,
			"name": store.telehealth.name,
			"email": store.telehealth.email,
			"date": dateFormatForApi(store.telehealth.date),
			"checkbox": store.telehealth.checkbox,
		},
		emailDto: {
			"decline": store.email.decline,
			"name": store.email.name,
			"email": store.email.email,
			"date": dateFormatForApi(store.email.date),
			"checkbox": store.email.checkbox,
			"signPicture": store.email.signPicture,
		},
	}

	dispatch(setECommunicationConsent(data));
};

export const setECommunicationConsentByID = createAsyncThunk(
	"setECommunicationConsentByID",
	async ({ patienID, data }, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + `/ECommunicationConsent/${patienID}`, data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const saveECommunicationConsentByID = (patienID) => (dispatch, getState) => {
	const store = getState().eCommunicationConsent;

	const data = {
		teleDto: {
			"decline": store.telehealth.decline,
			"name": store.telehealth.name,
			"email": store.telehealth.email,
			"date": dateFormatForApi(store.telehealth.date),
			"checkbox": store.telehealth.checkbox,
		},
		emailDto: {
			"decline": store.email.decline,
			"name": store.email.name,
			"email": store.email.email,
			"date": dateFormatForApi(store.email.date),
			"checkbox": store.email.checkbox,
			"signPicture": store.email.signPicture,
		},
	}

	dispatch(setECommunicationConsentByID({ patienID, data }));
};