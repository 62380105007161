import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper, TitleWrapper, Title } from '../../../components/Main/Main.style';

import { PatientChecklistContainer } from './PatientChecklist/PatientChecklistContainer';
import { BasicInformationContainer } from './BasicInformation/BasicInformationContainer';
import { AttachmentsContainer } from './Attachments/AttachmentsContainer';
import { FormsContainer } from './Forms/FormsContainer';

import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

export const InvitedProfile = ({ onClickBack, dataSource }) => {
	const [index, setIndex] = useState(0);
	const location = useLocation();

	const onSelectionChanged = (args) => {
		if (args.name === 'selectedIndex') {
			setIndex(args.value);
		}
	};

	useEffect(() => {
		setIndex(location.state?.indexTab || 0);
	}, [location.state?.indexTab]);

	return (
		<Wrapper style={{ overflowX: 'hidden', margin: '30px 0px 0px 40px' }}>
			<TitleWrapper >
				<Tooltip title="Go back to Invited list">
					<span>
						<Button icon="back" onClick={onClickBack} />
					</span>
				</Tooltip>
				<Title>{`Invitee: ${dataSource?.firstName} ${dataSource?.middleName} ${dataSource?.lastName}`}</Title>
			</TitleWrapper>
			<div style={{ display: 'flex' }}>
				<TabPanel
					width="100%"
					scrollByContent={true}
					showNavButtons={true}
					selectedIndex={index}
					onOptionChanged={onSelectionChanged}
				>
					<Item title="Basic information">
						<BasicInformationContainer />
					</Item>
					<Item title="Attachments ">
						<AttachmentsContainer />
					</Item>
					<Item title="Forms">
						<FormsContainer />
					</Item>
				</TabPanel>
				<PatientChecklistContainer inviteID={dataSource?.id} />
			</div>
		</Wrapper >
	);
}