import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getDiscountSchedule = createAsyncThunk(
	"getDiscountSchedule",
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + "/DiscountScheduleApp");
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const getDiscountScheduleByID = createAsyncThunk(
	"getDiscountScheduleByID",
	async (patientID, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + `/DiscountScheduleApp/${patientID}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setDiscountSchedule = createAsyncThunk(
	"setDiscountSchedule",
	async (data, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + "/DiscountScheduleApp", data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setDiscountScheduleByID = createAsyncThunk(
	"setDiscountScheduleByID",
	async ({ patientID, data }, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + `/DiscountScheduleApp/${patientID}`, data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const saveDiscountSchedule = () => (dispatch, getState) => {
	const store = getState().discountSchedule;

	const data = {
		"decline": store.decline,
		"firstName": store.firstName,
		"lastName": store.lastName,
		"middleName": store.middleName,
		"ssn": store.ssn,
		"date": dateFormatForApi(store.date),
		"phone": store.phone,
		"dateOfBirth": dateFormatForApi(store.dateOfBirth),
		"address": store.address,
		"city": store.city,
		"state": store.state,
		"zip": store.zip,
		"employment": store.employment,
		"employer": store.employer,
		"workPhone": store.workPhone,
		"months": store.months,
		"workedOnBoat": store.workedOnBoat,
		"workedOnBoatContact": store.workedOnBoatContact,
		"proofOfIncome": store.proofOfIncome,
		"fileProofOfIncome": store.fileProofOfIncome,
		"otherProofOfIncome": store.otherProofOfIncome,
		"totalIncome": store.totalIncome,
		"household": store.household,
		"healthInsurance": store.healthInsurance,
		"privateHealthInsurance": store.privateHealthInsurance,
		"medicare": store.medicare,
		"medicaid": store.medicaid,
		"applied": store.applied,
		"effectiveDate": dateFormatForApi(store.effectiveDate),
		"whoInsurance": store.whoInsurance,
		"dateOfBirthInsured": dateFormatForApi(store.dateOfBirthInsured),
		"frontCard": store.frontCard,
		"backCard": store.backCard,
		"signPicture": store.signPicture,
	}

	dispatch(setDiscountSchedule(data));
};

export const saveDiscountScheduleByID = (patientID) => (dispatch, getState) => {
	const store = getState().discountSchedule;

	const data = {
		"decline": store.decline,
		"firstName": store.firstName,
		"lastName": store.lastName,
		"middleName": store.middleName,
		"ssn": store.ssn,
		"date": dateFormatForApi(store.date),
		"phone": store.phone,
		"dateOfBirth": dateFormatForApi(store.dateOfBirth),
		"address": store.address,
		"city": store.city,
		"state": store.state,
		"zip": store.zip,
		"employment": store.employment,
		"employer": store.employer,
		"workPhone": store.workPhone,
		"months": store.months,
		"workedOnBoat": store.workedOnBoat,
		"workedOnBoatContact": store.workedOnBoatContact,
		"proofOfIncome": store.proofOfIncome,
		"fileProofOfIncome": store.fileProofOfIncome,
		"otherProofOfIncome": store.otherProofOfIncome,
		"totalIncome": store.totalIncome,
		"household": store.household,
		"healthInsurance": store.healthInsurance,
		"privateHealthInsurance": store.privateHealthInsurance,
		"medicare": store.medicare,
		"medicaid": store.medicaid,
		"applied": store.applied,
		"effectiveDate": dateFormatForApi(store.effectiveDate),
		"whoInsurance": store.whoInsurance,
		"dateOfBirthInsured": dateFormatForApi(store.dateOfBirthInsured),
		"frontCard": store.frontCard,
		"backCard": store.backCard,
		"signPicture": store.signPicture,
	}

	dispatch(setDiscountScheduleByID({ patientID, data }));
};