import { setAddress, setCity, setDateOfBirth, setEmail, setEmployer, setFirstName, setLastName, setMiddleName, setPhone, setState, setZip } from '../../../../../../store/users/patientProfile/basicInformation/guardianInfo/slice';
import { getGuardianInfoById, saveGuardianInfoById } from '../../../../../../store/users/patientProfile/basicInformation/guardianInfo/middlewares';
import { selectStates } from '../../../../../../store/lookups/state/selectors';
import { getState } from '../../../../../../store/lookups/state/middlewares';

import { validateGroup } from "../../../../../../utils/helper";
import { GuardianInformation } from './GuardianInformation';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const GuardianInformationContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const states = useSelector(selectStates);

    const statePatientProfileGuardianInfo     = useSelector(state => state.patientProfileGuardianInfo);
	const getEntityPatientProfileGuardianInfo = (name) => statePatientProfileGuardianInfo[name];

    useEffect(() => {
        dispatch(getState());
        dispatch(getGuardianInfoById(id));
    }, [dispatch, id]);

    const FN_TO_CALL = {
		FirstName: setFirstName,
		MiddleName: setMiddleName,
		LastName: setLastName,
		DateOfBirth: setDateOfBirth,
        Address: setAddress,
        City: setCity,
        State: setState,
        Zip: setZip,
        Phone: setPhone,
        Email: setEmail,
        Employer: setEmployer,
	};

    const onChangeFunc = (name, event, trim = false) => {
		dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
		setShowButton(true);
	};

    const onCancelGuardianInfo = () => {
        dispatch(getGuardianInfoById(id));
        setShowButton(false);
    }

    const onSaveGuardianInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveGuardianInfoById(id));
            setShowButton(false);
        }
    }

    return (
        <GuardianInformation
            showButton={showButton}

            states={states}

            getEntityPatientProfileGuardianInfo={getEntityPatientProfileGuardianInfo}
            onChangeFunc={onChangeFunc}

            onCancelGuardianInfo={onCancelGuardianInfo}
            onSaveGuardianInfo={onSaveGuardianInfo}
        />
    );
};