import { Wrapper, Title } from '../../components/Main/Main.style';
import Link from "../../components/Link/Link.style";
import Tooltip from '@mui/material/Tooltip';

export const Nav = () => {
    return (
        <Wrapper style={{ gap: "30px" }}>
            <Title> FORMS: </Title>
            <Tooltip title="Welcome Letter" placement="bottom-start">
                <Link to="/main/welcome">
                    Welcome Letter
                </Link>
            </Tooltip>
            <Tooltip title="New Patient Registration Packet" placement="bottom-start">
                <Link to="/main/forms/new-patient-registration-packet">
                    New Patient Registration Packet
                </Link>
            </Tooltip>
            <Tooltip title="Annual Patient Registration Update" placement="bottom-start">
                <Link to="/main/forms/annual-patient-registration">
                    Annual Patient Registration Update
                </Link>
            </Tooltip>
            <Tooltip title="Grievance & Feedback Form" placement="bottom-start">
                <Link to="/main/forms/grievance-feedback-form">
                    Grievance & Feedback Form
                </Link>
            </Tooltip>
            <Tooltip title="Referral to Behavioral Health" placement="bottom-start">
                <Link to="/main/forms/referral-form">
                    Referral to Behavioral Health
                </Link>
            </Tooltip>
        </Wrapper >
    );
}