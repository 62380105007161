
import MuiSelectBox from 'devextreme-react/select-box';
import MuiNumberBox from 'devextreme-react/number-box';
import MuiTextBox from 'devextreme-react/text-box';
import MuiDateBox from 'devextreme-react/date-box';
import MuiTagBox from 'devextreme-react/tag-box';
import MuiButton from '@mui/material/Button';
import MuiGrid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 20px 20px',
}));

export const Panel = styled('div')(() => ({
  padding: '10px',
  order: '0',
}));

export const Title = styled('h1')(() => ({
  color: 'var(--dark-blue-color)',
  fontFamily: 'Montserrat',
  lineHeight: '20px',
  fontWeight: '600',
  fontSize: '16px',
  margin: '0px',
  padding: '0 24px',
}));

export const Item = styled(MuiGrid)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  '&.MuiGrid-item': {
    paddingTop: '6px',
  },
  '& > div': {
    minHeight: '30px',
    alignItems: 'center',
  }
}));

export const Label = styled('label')(() => ({
  color: 'var(--grey-text-color)',
  fontFamily: 'Montserrat',
  whiteSpace: 'nowrap',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
}));

export const TextBox = styled(MuiTextBox)(() => ({
  width: '100%',
  height: '30px',
  '&.dx-widget input': {
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
  },
  '&.dx-editor-underlined::after': {
    border: 'none',
  },
}));

export const NumberBox = styled(MuiNumberBox)(() => ({
  width: '100%',
  height: '30px',
  '&.dx-widget input': {
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
  },
  '&.dx-editor-underlined::after': {
    border: 'none',
  },
}));

export const DateBox = styled(MuiDateBox)(() => ({
  width: '100%',
  height: '30px',
  '&.dx-widget input': {
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
  },
  '&.dx-editor-underlined::after': {
    border: 'none',
  },
}));

export const SelectBox = styled(MuiSelectBox)(() => ({
  width: '100%',
  height: '30px',
  '&.dx-widget input': {
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
  },
  '&.dx-editor-underlined::after': {
    border: 'none',
  },
}));

export const TagBox = styled(MuiTagBox)(() => ({
  width: '100%',
  height: '30px',
  '& .dx-tag-content': {
    padding: '4px 30px 4px 10px',
  },
  '& .dx-tag-content > span': {
    textOverflow: 'ellipsis',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100px',
  },
  '&.dx-widget input': {
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
  },
  '&.dx-editor-underlined::after': {
    border: 'none',
  },
}));

export const CheckBox = styled('div')(({ value }) => ({
  color: value ? 'var(--green-text-color)' : 'var(--red-text-color)',
  fontStyle: 'normal',
  fontWeight: '500',
  display: 'inline-flex',
  alignContent: 'center',
  gap: '7px',
  fontSize: '14px',
  letterSpacing: '.04em',
  cursor: 'pointer',
}));

export const Buttons = styled('div')(() => ({
  display: 'flex',
  gap: '10px',
  padding: '20px 6px 0px',
  marginLeft: 'auto',
}));

export const Button = styled(MuiButton)(() => ({
  color: 'var(--light-blue-color)',
  fontStyle: 'normal',
  fontWeight: '500',
  display: 'inline-flex',
  alignContent: 'center',
  gap: '7px',
  fontSize: '12px',
  textTransform: 'uppercase',
  letterSpacing: '.04em'
}));