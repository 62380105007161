import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Forms } from './Forms';
import { getPatientForms } from '../../../../store/users/patientProfile/forms/middlewares';
import { selectActiveValue  } from "../../../../store/users/patientsList/selectors";
import { selectPatientForms, selectLoading } from '../../../../store/users/patientProfile/forms/selectors';
import { get } from '../../../../core/rest';
import { BASE_URL } from '../../../../utils/constants';
import { showToast } from '../../../Main/MainContainer';
import { downloadFile } from '../../../../utils/helper';

export const FormsContainer = () => {
    const [loadIndicator, setLoadIndicator] = useState(null);
    const dispatch  = useDispatch();
    const navigate  = useNavigate();
    const patientID = useSelector(selectActiveValue)?.id;
    const loading   = useSelector(selectLoading);
    const data      = useSelector(selectPatientForms);

    const downloadForm = (blob, fileName) => {
        downloadFile(blob, fileName);
        setLoadIndicator(null);
    };

    const onClickDownloadForm = (userID, formName) => {
        setLoadIndicator(formName);
        get(BASE_URL + `/ExportToPDF/GetPdf/userId?userId=${userID}&FormName=${formName}Form`, {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('AUTH_TOKEN_DATA')).accessToken,
          responseType: 'blob',
        }).then(blob => downloadForm(blob, formName)).catch(() => {
            showToast('error', `Unable to download ${formName}`);
            setLoadIndicator(null);
        });
    };

    const onClickGoToForm = (path, link) => {
        navigate(`${link}`, { state: { path } });
    };

    useEffect(() => {
        dispatch(getPatientForms(patientID));
    }, [dispatch, patientID]);

    return (
        <Forms
            loading={loading}
            data={data}
            loadIndicator={loadIndicator}
            onClickDownloadForm={onClickDownloadForm}
            onClickGoToForm={onClickGoToForm}
        />
    );
}