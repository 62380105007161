import { Wrapper } from '../../../../../components/Main/Main.style';
import { CURRENT_DATE, GRID_MD, GRID_XS, HEALTH_INFO, MAX_WIDTH, MIN_DATE } from '../../../../../utils/constants';
import { dateFormatFromApi } from '../../../../../utils/formats';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import NumberBox from 'devextreme-react/number-box';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
	RequiredRule,

} from 'devextreme-react/validator';

export const HealthAndTreatment = ({
	loading,

	answers,
	pregnantStatuses,
	hospitalInSixMonths,
	hospitalResidentialTreatment,

	pregnantStatus,
	physicalExam,
	drugUser,
	haveEverBeenInHospital,
	haveBeenInHospitalLast6Months,
	howManyMentalHealthHospitals,
	numberOfArrests,

	onChangePregnantStatus,
	onChangePhysicalExam,
	onChangeDrugUser,
	onChangeHaveEverBeenInHospital,
	onChangeHaveBeenInHospitalLast6Months,
	onChangeHowManyMentalHealthHospitals,
	onChangeNumberOfArrests,

	onSaveHealthAndTreatment,
	onSaveAndContinueHealthAndTreatment,
}) => {
	return (
		<Wrapper id={HEALTH_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${HEALTH_INFO}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="If Female, are you pregnant? *">
							<>
								<SelectBox
									label="If Female, are you pregnant? *"
									defaultValue={pregnantStatus}
									dataSource={pregnantStatuses}
									onValueChanged={onChangePregnantStatus}
									valueExpr="Oid"
									displayExpr="Name"
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-pregnantStatus' }}
								>
									<Validator validationGroup={HEALTH_INFO}>
										<RequiredRule
											message="If Female, are you pregnant is required"
										/>
										<RangeRule
											message="If Female, are you pregnant is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Are you an IV Drug User? *">
							<>
								<SelectBox
									label="Are you an IV Drug User? *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={(drugUser) !== null ? (drugUser) ? 0 : 1 : null}
									dataSource={answers}
									onValueChanged={onChangeDrugUser}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-drugUser' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Date of last physical exam?">
							<>
								<DateBox
									type="date"
									label="Date of last physical exam?"
									value={dateFormatFromApi(physicalExam)}
									onValueChanged={onChangePhysicalExam}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={HEALTH_INFO}>
										<RangeRule
											message="Date cannot be later than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Have you ever been in the hospital or residential treatment for substance abuse? *">
							<>
								<SelectBox
									label="Have you ever been in the hospital or residential treatment for substance abuse? *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={haveEverBeenInHospital}
									dataSource={hospitalResidentialTreatment}
									onValueChanged={onChangeHaveEverBeenInHospital}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-haveEverBeenInHospital' }}
								>
									<Validator validationGroup={HEALTH_INFO}>
										<RequiredRule
											message="Have you ever been in the hospital or residential treatment for substance abuse is required"
										/>
										<RangeRule
											message="Have you ever been in the hospital or residential treatment for substance abuse is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse? *">
							<>
								<SelectBox
									label="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse? *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={haveBeenInHospitalLast6Months}
									dataSource={hospitalInSixMonths}
									onValueChanged={onChangeHaveBeenInHospitalLast6Months}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-haveBeenInHospitalLast6Months' }}
								>
									<Validator validationGroup={HEALTH_INFO}>
										<RequiredRule
											message="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse is required"
										/>
										<RangeRule
											message="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="How many inpatient mental health hospitalizations have you had? (If none, enter 0) *">
							<>
								<NumberBox
									label="How many inpatient mental health hospitalizations have you had? (If none, enter 0) *"
									value={howManyMentalHealthHospitals ? howManyMentalHealthHospitals : 0}
									onValueChanged={onChangeHowManyMentalHealthHospitals}
									showSpinButtons={true}
									labelMode="floating"
									stylingMode="outlined"
									min={0}>
									<Validator validationGroup={HEALTH_INFO}>
										<RequiredRule
											message="How many inpatient mental health hospitalizations have you had? (If none, enter 0) is required"
										/>
									</Validator>
								</NumberBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Legal History: Number of Arrests in the past 30 days (If none, enter 0) *">
							<>
								<NumberBox
									label="Legal History: Number of Arrests in the past 30 days (If none, enter 0) *"
									value={numberOfArrests ? numberOfArrests : 0}
									onValueChanged={onChangeNumberOfArrests}
									showSpinButtons={true}
									labelMode="floating"
									stylingMode="outlined"
									min={0}>
									<Validator validationGroup={HEALTH_INFO}>
										<RequiredRule
											message="Legal History: Number of Arrests in the past 30 days (If none, enter 0) is required"
										/>
									</Validator>
								</NumberBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={HEALTH_INFO}
							onClick={onSaveHealthAndTreatment} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={HEALTH_INFO}
							onClick={onSaveAndContinueHealthAndTreatment} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	);
};