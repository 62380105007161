import { getNewPatientRegistration, getNewPatientRegistrationById } from "./middlewares";
import { FETCH_STATUSES } from "../../../utils/constants";
import { showToast } from "../../../screens/Main/MainContainer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fixed: false,
    expanded: [],
    patientInfo: false,
    guardianInfo: false,
    tribalAffiliation: false,
    healthAndTreatment: false,
    employment: false,
    education: false,
    emergencyContact: false,
    nextOfKin: false,
    insuranceInfo: false,
    referralInfo: false,
    advanceCare: false,
    consent: false,
    getStatus: FETCH_STATUSES.IDLE,
    getError: null,
};

export const newPatientRegistration = createSlice({
    name: "newPatientRegistration",
    initialState,
    reducers: {
        changeFixed: (state, action) => {
            state.fixed = action.payload;
        },
        changeExpanded: (state, action) => {
            state.expanded = action.payload;
        },
        completePatientInfo: (state, action) => {
            state.patientInfo = action.payload;
        },
        completeGuardianInfo: (state, action) => {
            state.guardianInfo = action.payload;
        },
        completeTribalAffiliation: (state, action) => {
            state.tribalAffiliation = action.payload;
        },
        completeHealthAndTreatment: (state, action) => {
            state.healthAndTreatment = action.payload;
        },
        completeEmployment: (state, action) => {
            state.employment = action.payload;
        },
        completeEducation: (state, action) => {
            state.education = action.payload;
        },
        completeEmergencyContact: (state, action) => {
            state.emergencyContact = action.payload;
        },
        completeNextOfKin: (state, action) => {
            state.nextOfKin = action.payload;
        },
        completeInsuranceInfo: (state, action) => {
            state.insuranceInfo = action.payload;
        },
        completeReferralInfo: (state, action) => {
            state.referralInfo = action.payload;
        },
        completeAdvanceCare: (state, action) => {
            state.advanceCare = action.payload;
        },
        completeConsent: (state, action) => {
            state.consent = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNewPatientRegistration.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getNewPatientRegistration.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.patientLetter = action.payload?.confirmletter;
                state.patientInfo = action.payload?.personalData;
                state.guardianInfo = action.payload?.guardian;
                state.tribalAffiliation = action.payload?.tribal;
                state.healthAndTreatment = action.payload?.health;
                state.employment = action.payload?.employment;
                state.education = action.payload?.education;
                state.emergencyContact = action.payload?.emergency;
                state.nextOfKin = action.payload?.nextKin;
                state.insuranceInfo = action.payload?.insurance;
                state.referralInfo = action.payload?.referral;
                state.advanceCare = action.payload?.advanceCare;
                state.consent = action.payload?.consent;

                state.loading = false;
            })
            .addCase(getNewPatientRegistration.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get New Patient Registration: ${action.error.message}`);
            })
            .addCase(getNewPatientRegistrationById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getNewPatientRegistrationById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.patientLetter = action.payload?.confirmletter;
                state.patientInfo = action.payload?.personalData;
                state.guardianInfo = action.payload?.guardian;
                state.tribalAffiliation = action.payload?.tribal;
                state.healthAndTreatment = action.payload?.health;
                state.employment = action.payload?.employment;
                state.education = action.payload?.education;
                state.emergencyContact = action.payload?.emergency;
                state.nextOfKin = action.payload?.nextKin;
                state.insuranceInfo = action.payload?.insurance;
                state.referralInfo = action.payload?.referral;
                state.advanceCare = action.payload?.advanceCare;
                state.consent = action.payload?.consent;

                state.loading = false;
            })
            .addCase(getNewPatientRegistrationById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get New Patient Registration By Id: ${action.error.message}`);
            })
    },
});

export const {
    changeFixed,
    changeExpanded,

    completePatientInfo,
    completeGuardianInfo,
    completeTribalAffiliation,
    completeHealthAndTreatment,
    completeEmployment,
    completeEducation,
    completeEmergencyContact,
    completeNextOfKin,
    completeInsuranceInfo,
    completeReferralInfo,
    completeAdvanceCare,
    completeConsent,
} = newPatientRegistration.actions;

export default newPatientRegistration.reducer;
