import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../utils/constants";
import { get, post } from "../../core/rest";

export const getUserProfile = createAsyncThunk(
	'getUserProfile',
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + `/UserProfile`)
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const confirmMailChange = createAsyncThunk(
  'confirmMailChange',
  async ({ ID, newEmailAddress }, { rejectWithValue }) => {
    try {
      return await post(
        BASE_URL +
          `/NewPatientRegistration/${ID}/ConfirmMailChange?newMailAddress=${newEmailAddress}`,
      );
    } catch (error) {
      return rejectWithValue(error.responseBody);
    }
  },
);