import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../utils/constants";
import { post } from "../../../core/rest";

export const setGenerationAdmins = createAsyncThunk(
	"setGenerationAdmins",
	async (data, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + `/AuthManagement/Register`, data)
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setChangeRole = createAsyncThunk(
	"setChangeRole",
	async (id, { rejectWithValue }) => {
			try {
					return await post(BASE_URL + `/AuthManagement/${id}/ChangeRole?roleNames=Admin`, []);
			} catch (error) {
					return rejectWithValue(error.response.data);
			};
	},
);