import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

export const Accordion = styled((props) => (
    <MuiAccordion {...props} />
))(() => ({
    border: `1px solid var(--grey-status-color)`,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:not(.Mui-expanded):not(:last-of-type)': {
        borderBottom: 'none',
    },
    '&.MuiAccordion-root:first-of-type': {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        '& .MuiAccordionSummary-root': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        },
    },
    '&.MuiAccordion-root:last-of-type': {
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        '& .MuiAccordionSummary-root': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
    },
}));

export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}{...props} />
))(({ completed }) => ({
    height: '70px',
    backgroundColor: completed === "true" ? "#D5F5E8" : "var(--background-color)",
    '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    borderTop: '1px solid var(--grey-status-color)',
    padding: 'unset',
}));

