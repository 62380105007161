export const selectLoading = state => state.referralForm.loading;

export const selectAgree = state => state.referralForm.agree;
export const selectPerson = state => state.referralForm.person;
export const selectDateOfBirth = state => state.referralForm.dateOfBirth;
export const selectAddress = state => state.referralForm.address;
export const selectPhone = state => state.referralForm.phone;
export const selectReferral = state => state.referralForm.referral;
export const selectContact = state => state.referralForm.contact;
export const selectReason = state => state.referralForm.reason;
export const selectRequest = state => state.referralForm.request;
export const selectAppt = state => state.referralForm.appt;
export const selectApptComment = state => state.referralForm.apptComment;
export const selectDeclined = state => state.referralForm.declined;
export const selectDeclinedComment = state => state.referralForm.declinedComment;
export const selectNoAnswer = state => state.referralForm.noAnswer;
export const selectFirstAttempt = state => state.referralForm.firstAttempt;
export const selectFirstAttemptBy = state => state.referralForm.firstAttemptBy;
export const selectSecondAttempt = state => state.referralForm.secondAttempt;
export const selectSecondAttemptBy = state => state.referralForm.secondAttemptBy;
export const selectThirdAttempt = state => state.referralForm.thirdAttempt;
export const selectThirdAttemptBy = state => state.referralForm.thirdAttemptBy;
export const selectClosedReferral = state => state.referralForm.closedReferral;
export const selectReferralFollowedUpBy = state => state.referralForm.referralFollowedUpBy;
export const selectReferralFollowedUpDate = state => state.referralForm.referralFollowedUpDate;
