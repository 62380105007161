import TextBox from 'devextreme-react/text-box';
import { styled } from '@mui/material/styles';

const TextBoxStyled = styled(TextBox)(() => ({
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    '& .dx-texteditor-input-container': {
        order: '1',
        borderRadius: '10px',
        background: 'var(--background-color)',
    },
    '&.dx-texteditor.dx-editor-outlined': {
        background: 'var(--background-color)',
    },
    '&.dx-editor-outlined .dx-texteditor-label .dx-label-before': {
        borderRadius: '10px 0 0 10px',
    },
    '&.dx-editor-outlined .dx-texteditor-label .dx-label-after': {
        borderRadius: '0 10px 10px 0',
    },
    '&.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty:not(.dx-state-focused) .dx-label span': {
        color: 'var(--grey-text-color)',
        fontWeight: '500',
        fontSize: '16px',
    },
    '&.dx-invalid.dx-texteditor.dx-show-invalid-badge .dx-texteditor-input-container::after': {
        display: 'none',
    },
}));

export default TextBoxStyled;