import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createTokenProvider } from '../../../core/auth/tokenProvider';
import { confirmMailChange } from '../../../store/main/middlewares';
import { Wrapper, Main, Header, Img, Title, SubTitle, Text, Button } from './ConfirmEmail.style';
import { useResizeDetector } from 'react-resize-detector';
import { FooterBlock } from '../FooterBlock/FooterBlock';
import { showToast } from '../MainContainer';

export const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const tokenProvider = createTokenProvider();
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    const { getUserData, refreshToken } = tokenProvider;
    const newEmailAddress = searchParams.get('newEmailAddress');
    newEmailAddress &&
      dispatch(confirmMailChange({ ID: getUserData('Id'), newEmailAddress })).then((data) => {
        if (data.payload.response?.status === 200) {
          showToast('success', 'Email changed!');
          refreshToken();
        } else {
          showToast('error', data.payload);
        }
      });
  };

  return (
    <Wrapper maxWidth="xl" ref={ref}>
      <Header>
        <Title>Change email</Title>
        <Img />
      </Header>
      <Main>
        <SubTitle>Confirm Email</SubTitle>
        <Text>
          When editing your personal profile, you were asked to change your email. It needs to be
          confirmed. By clicking on the confirm button (below) you agree that this email belongs to
          you and will be your contact information.
        </Text>
        <Button
          label="confirm email"
          variant="contained"
          startIcon="ic:baseline-output"
          onClick={handleClick}
        />
        <FooterBlock width={width} />
      </Main>
    </Wrapper>
  );
};
