import { Button as MuiButton } from './Button.style';
import { Icon } from '@iconify/react';

const Button = ({ label, endIcon, startIcon, ...params }) => {
    return (
        <MuiButton
            endIcon={<Icon icon={endIcon} />}
            startIcon={<Icon icon={startIcon} />}
            {...params}
        >
            {label}
        </MuiButton >
    );
};

export default Button;