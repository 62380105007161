import { BASE_URL, GUARDIAN_INFO } from "../../../../../utils/constants";
import { dateFormatForApi } from "../../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getGuardianInfoById = createAsyncThunk(
    "getGuardianInfoById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${GUARDIAN_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setGuardianInfoById = createAsyncThunk(
    "setGuardianInfoById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${GUARDIAN_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveGuardianInfoById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileGuardianInfo;

    const data = {
        "sameAsPatient": store.checkbox,
        "firstName": store.firstName,
        "lastName": store.lastName,
        "middleName": store.middleName,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email,
        "employer": store.employer,
    }

    dispatch(setGuardianInfoById({ id, data }));
};