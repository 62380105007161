import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getPatientList } from '../../../store/users/patientsList/middlewares';
import { setLoading, setActivePatientId } from '../../../store/users/patientsList/slice';
import { selectLoading, selectActiveValue  } from "../../../store/users/patientsList/selectors";
import { PatientProfile } from './PatientProfile';

export const PatientProfileContainer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector(selectLoading);
	const dataSource = useSelector(selectActiveValue);
	const { id } = useParams();

	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(getPatientList());
		dispatch(setActivePatientId(id));
	}, [dispatch, id]);

	const onClickBack = () => {
		navigate("../patients");
		dispatch(setActivePatientId(id));
	}

	return (
		<PatientProfile
			loading={loading}
			onClickBack={onClickBack}
			dataSource={dataSource}
		/>
	);
}