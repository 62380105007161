import { styled } from '@mui/material/styles';

export const TitleWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
}));

export const Title = styled('h1')(() => ({
    color: 'var(--dark-blue-color)',
    fontFamily: 'Montserrat',
    userSelect: 'none',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '27px',
    margin: '0px',
    order: '1'
}));