import { useParams } from 'react-router-dom';
import { selectLoading, selectAddress, selectCheckbox, selectCity, selectDateOfBirth, selectEmail, selectEmployer, selectFirstName, selectLastName, selectMiddleName, selectPhone, selectState, selectZip } from '../../../../../store/forms/newPatientRegistration/guardianInfo/selectors';
import { setAddress, setCity, setDateOfBirth, setEmail, setEmployer, setFirstName, setLastName, setMiddleName, setPhone, setState, setZip, toggleCheckbox } from '../../../../../store/forms/newPatientRegistration/guardianInfo/slice';
import { getGuardianInfo, getPatientInfo, saveGuardianInfo, getGuardianInfoByID, saveGuardianInfoByID, getPatientInfoByID } from '../../../../../store/forms/newPatientRegistration/guardianInfo/middlewares';
import { completeGuardianInfo } from '../../../../../store/forms/newPatientRegistration/slice';

import { selectStates } from '../../../../../store/lookups/state/selectors';
import { getState } from '../../../../../store/lookups/state/middlewares';

import { validateGroup } from '../../../../../utils/helper';
import { GuardianInformation } from './GuardianInformation';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';

export const GuardianInformationContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const states = useSelector(selectStates);

    const checkbox = useSelector(selectCheckbox);
    const firstName = useSelector(selectFirstName);
    const middleName = useSelector(selectMiddleName);
    const lastName = useSelector(selectLastName);
    const dateOfBirth = useSelector(selectDateOfBirth);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const phone = useSelector(selectPhone);
    const email = useSelector(selectEmail);
    const employer = useSelector(selectEmployer);

    useEffect(() => {
        dispatch(getState());
        dispatch(id ? getGuardianInfoByID(id) : getGuardianInfo());
    }, [dispatch, id]);

    const onToggleCheckbox = (event) => {
        dispatch(toggleCheckbox());
        if (event.value) {
            dispatch(id ? getPatientInfoByID(id) : getPatientInfo()).then(() => {
                dispatch(id ? saveGuardianInfoByID(id) : saveGuardianInfo());
                dispatch(completeGuardianInfo(true));
                onClickNext();
            });
        }
    }

    const onChangeFirstName = (event) => {
        dispatch(setFirstName(event.value.trim()));
    }

    const onChangeMiddleName = (event) => {
        dispatch(setMiddleName(event.value.trim()));
    }

    const onChangeLastName = (event) => {
        dispatch(setLastName(event.value.trim()));
    }

    const onChangeDateOfBirth = (event) => {
        dispatch(setDateOfBirth(event.value));
    }

    const onChangeAddress = (event) => {
        if (event.value) {
            dispatch(setAddress(event.value.trim()));
        };
    }

    const onChangeCity = (event) => {
        dispatch(setCity(event.value.trim()));
    }

    const onChangeState = (event) => {
        dispatch(setState(event.value));
    }

    const onChangeZip = (event) => {
        dispatch(setZip(event.value));
    }

    const onChangePhone = (event) => {
        dispatch(setPhone(event.value));
    }

    const onChangeEmail = (event) => {
        dispatch(setEmail(event.value.trim()));
    }

    const onChangeEmployer = (event) => {
        dispatch(setEmployer(event.value.trim()));
    }

    const onSaveGuardianInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveGuardianInfoByID(id) : saveGuardianInfo());
            dispatch(completeGuardianInfo(true));
        }
    }

    const onSaveAndContinueGuardianInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveGuardianInfoByID(id) : saveGuardianInfo());
            dispatch(completeGuardianInfo(true));
            onClickNext();
        }
    }

    return (
        <GuardianInformation
            loading={loading}

            states={states}

            checkbox={checkbox}
            firstName={firstName}
            middleName={middleName}
            lastName={lastName}
            dateOfBirth={dateOfBirth}
            address={address}
            city={city}
            state={state}
            zip={zip}
            phone={phone}
            email={email}
            employer={employer}

            onToggleCheckbox={onToggleCheckbox}
            onChangeFirstName={onChangeFirstName}
            onChangeMiddleName={onChangeMiddleName}
            onChangeLastName={onChangeLastName}
            onChangeDateOfBirth={onChangeDateOfBirth}
            onChangeAddress={onChangeAddress}
            onChangeCity={onChangeCity}
            onChangeState={onChangeState}
            onChangeZip={onChangeZip}
            onChangePhone={onChangePhone}
            onChangeEmail={onChangeEmail}
            onChangeEmployer={onChangeEmployer}

            onSaveGuardianInfo={onSaveGuardianInfo}
            onSaveAndContinueGuardianInfo={onSaveAndContinueGuardianInfo}
        />
    );
};