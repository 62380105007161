import { setGuardian, setGuardianDate, setSigned, setSignedDate } from "../../../../../../store/users/patientProfile/basicInformation/consent/slice";
import { getConsentById, saveConsentById } from "../../../../../../store/users/patientProfile/basicInformation/consent/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { Consent } from "./Consent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ConsentContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const statePatientProfileConsent     = useSelector(state => state.patientProfileConsent);
	const getEntityPatientProfileConsent = (name) => statePatientProfileConsent[name];

    useEffect(() => {
        dispatch(getConsentById(id))
    }, [dispatch, id])

    const FN_TO_CALL = {
        Signed: setSigned,
        Guardian: setGuardian,
        SignedDate: setSignedDate,
        GuardianDate: setGuardianDate,
    };

    const onChangeFunc = (name, event = null, trim = false) => {
        dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        setShowButton(true);
    };

    const onCancelConsent = () => {
        dispatch(getConsentById(id));
        setShowButton(false);
    }

    const onSaveConsent = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveConsentById(id));
            setShowButton(false);
        }
    }

    return (
        <Consent
            showButton={showButton}

            getEntityPatientProfileConsent={getEntityPatientProfileConsent}

            onChangeFunc={onChangeFunc}

            onCancelConsent={onCancelConsent}
            onSaveConsent={onSaveConsent}
        />
    );
}