import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Attachments } from './Attachments';
import { BASE_URL } from '../../../../utils/constants';
import { getPatientAttachments } from '../../../../store/users/patientProfile/attachments/middlewares';
import { selectAttachments, selectLoading } from '../../../../store/users/patientProfile/attachments/selectors';
import { selectActiveValue  } from "../../../../store/users/patientsList/selectors";
import { DOWNLOAD, SHOW } from '../../../../utils/constants';
import { get } from '../../../../core/rest';
import { downloadFile } from '../../../../utils/helper';

export const AttachmentsContainer = () => {
  const [loadIndicator, setLoadIndicator] = useState(null);
  const [showIndicator, setShowIndicator] = useState(null);
  const dispatch  = useDispatch();
  const data      = useSelector(selectAttachments);
  const loading   = useSelector(selectLoading);
  const patientID = useSelector(selectActiveValue)?.id;

  const onClickDownload = (blob, fileName) => {
    downloadFile(blob, fileName);
    setLoadIndicator(null);
  };

  const onClickShow = (blob, fileName) => {
    window.open(window.URL.createObjectURL(blob), fileName);
    setShowIndicator(null);
  };

  const onClickHandler = async (fileID, fileName, type) => {
    type === DOWNLOAD ? setLoadIndicator(fileID) : setShowIndicator(fileID);
    get(BASE_URL + `/FileUploader/${patientID}/download/${fileID}`, {
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('AUTH_TOKEN_DATA')).accessToken,
      responseType: 'blob'
    })
      .then((blob) => {
        switch (type) {
          case SHOW:
            onClickShow(blob, fileName);
            break;
          case DOWNLOAD:
            onClickDownload(blob, fileName);
            break;
          default:
        }
      });
  };

  useEffect(() => {
    dispatch(getPatientAttachments(patientID));
  }, [dispatch, patientID]);

  return (
    <Attachments
      loadIndicator={loadIndicator}
      showIndicator={showIndicator}
      onClickHandler={onClickHandler}
      loading={loading}
      data={data}
    />
  );
};
