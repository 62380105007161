import { selectEmail, selectUserName, selectPassword, selectShowEdit } from "../../../store/users/generationAdmins/selectors";
import { setShowAdmin, setEmail, setUserName, setFirstName, setLastName, setPassword, setClear, } from "../../../store/users/generationAdmins/slice";
import { setGenerationAdmins, setChangeRole } from '../../../store/users/generationAdmins/middlewares';
import { getAdminsList } from '../../../store/users/adminsList/middlewares';
import { useDispatch, useSelector } from "react-redux";
import { validateGroup } from "../../../utils/helper";
import GenerationAdmins from './GenerationAdmins';
import { decodeToken } from "react-jwt";

const GenerationAdminsContainer = () => {
	const dispatch = useDispatch();

	const open = useSelector(selectShowEdit);
	const email = useSelector(selectEmail);
	const userName = useSelector(selectUserName);
	const password = useSelector(selectPassword);

	const onChangeEmail = (event) => {
		dispatch(setEmail(event.value.trim()));
	};

	const onChangeUserName = (event) => {
		dispatch(setUserName(event.value.trim()));
		dispatch(setFirstName(event.value.trim()));
		dispatch(setLastName(event.value.trim()));
	}

	const onChangePassword = (event) => {
		dispatch(setPassword(event.value));
	};

	const onClickClose = () => {
		dispatch(setShowAdmin(false));
		dispatch(setClear());
	};

	const onClickCreateUser = (event) => {
		const isValid = validateGroup(event);

		if (isValid) {
			dispatch(
				setGenerationAdmins({
					email,
					username: userName,
					password
				})
			).then((data) => {
				const token = decodeToken(data.payload?.token);
				dispatch(
					setChangeRole(token?.Id)
				).then((_) => {
					dispatch(getAdminsList());
					dispatch(setClear());
				})
			});
		};
	};

	return (
		<GenerationAdmins
			open={open}

			email={email}
			userName={userName}
			password={password}

			onChangeEmail={onChangeEmail}
			onChangeUserName={onChangeUserName}
			onChangePassword={onChangePassword}

			onClickClose={onClickClose}
			onClickCreateUser={onClickCreateUser}
		/>
	)
};

export default GenerationAdminsContainer;