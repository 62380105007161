import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { getPatientList } from '../../../store/users/patientsList/middlewares';
import { setLoading } from '../../../store/users/patientsList/slice';
import { selectLoading, selectPatientList } from "../../../store/users/patientsList/selectors";
import { PatientsList } from './PatientsList';
import { setShowInvite } from "../../../store/users/generationInvites/slice";

export const PatientsListContainer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loading = useSelector(selectLoading);
	const dataSource = useSelector(selectPatientList);

	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(getPatientList());
	}, [dispatch]);

	const showProfile = (id) => {
		navigate(`profile/${id}`);
	}

	const showInvite = () => {
		dispatch(setShowInvite(true));
	}

	return (
		<PatientsList
			loading={loading}
			dataSource={dataSource}
			showProfile={showProfile}
			showInvite={showInvite}
		/>
	);
}