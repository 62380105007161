import { createSlice } from '@reduxjs/toolkit';
import { setGenerationAdmins, setChangeRole } from "./middlewares";
import { showToast } from '../../../screens/Main/MainContainer';
import { FETCH_STATUSES } from '../../../utils/constants';

const initialState = {
	showEdit: false,
	data: {
		email: "",
		userName: "",
		firstName: "",
		lastName: "",
		password: "",
	},
	sendError: null,
	status: FETCH_STATUSES.IDLE,
};

export const providerGenerationAdmins = createSlice({
	name: 'providerGenerationAdmins',
	initialState,
	reducers: {
		setShowAdmin: (state, action) => {
			state.showEdit = action.payload;
			return state;
		},
		setEmail: (state, action) => {
			state.data.email = action.payload;
			return state;
		},
		setUserName: (state, action) => {
			state.data.userName = action.payload;
			return state;
		},
		setFirstName: (state, action) => {
			state.data.firstName = action.payload;
			return state;
		},
		setLastName: (state, action) => {
			state.data.lastName = action.payload;
			return state;
		},
		setPassword: (state, action) => {
			state.data.password = action.payload;
			return state;
		},
		setClear: (state) => {
			state.data = initialState.data;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(setGenerationAdmins.pending, (state) => {
				state.status = FETCH_STATUSES.REQUEST;
				state.sendError = null;
			})
			.addCase(setGenerationAdmins.fulfilled, (state) => {
				state.status = FETCH_STATUSES.SUCCESS;
				state.sendError = null;
				state.showEdit = false;
				showToast("success", "User created successfully");
			})
			.addCase(setGenerationAdmins.rejected, (state) => {
				state.status = FETCH_STATUSES.FAILURE;
				showToast("error", "A user with the same email already exists");
			})
			.addCase(setChangeRole.pending, (state) => {
				state.status = FETCH_STATUSES.REQUEST;
				state.sendError = null;
			})
			.addCase(setChangeRole.fulfilled, (state) => {
				state.status = FETCH_STATUSES.SUCCESS;
				state.sendError = null;
				showToast("success", "Administrator role successfully assigned");
			})
			.addCase(setChangeRole.rejected, (state) => {
				state.status = FETCH_STATUSES.FAILURE;
				showToast("error", "Unable to create a user");
			})
	},
});

export const {
	setShowAdmin,

	setEmail,
	setUserName,
	setFirstName,
	setLastName,
	setPassword,

	setClear,
} = providerGenerationAdmins.actions;

export default providerGenerationAdmins.reducer;