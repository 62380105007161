import { getGrievanceFeedback, setGrievanceFeedback, getGrievanceFeedbackByID, setGrievanceFeedbackByID } from './middlewares';
import { showToast } from '../../../screens/Main/MainContainer';
import { FETCH_STATUSES } from '../../../utils/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: "",
	address: "",
	phone: "",
	email: "",
	typeConnectionPhone: false,
	typeConnectionEmail: false,
	typeConnectionMail: false,
	bestConnectionMorning: false,
	bestConnectionAfternoon: false,
	bestConnectionEvening: false,
	statusPatientClient: false,
	statusVisitor: false,
	commentTypeGreatJob: false,
	commentTypeInquiry: false,
	commentTypeSuggestion: false,
	commentTypeComplaintGrievance: false,
	commentTypeSlidingScale: false,
	commentTypeOther: false,
	commentTypeOtherText: "",
	date: null,
	clinicDepartment: "",
	experience: "",
	allowExperience: "",
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
};

const grievanceFeedbackForm = createSlice({
	name: 'grievanceFeedbackForm',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		setName: (state, action) => {
			state.name = action.payload;
			return state;
		},
		setAddress: (state, action) => {
			state.address = action.payload;
			return state;
		},
		setPhone: (state, action) => {
			state.phone = action.payload;
			return state;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
			return state;
		},
		toggleTypeConnectionPhone: (state) => {
			state.typeConnectionPhone = !state.typeСonnectionPhone;
			return state;
		},
		toggleTypeConnectionEmail: (state) => {
			state.typeConnectionEmail = !state.typeСonnectionEmail;
			return state;
		},
		toggleTypeConnectionMail: (state) => {
			state.typeConnectionMail = !state.typeСonnectionMail;
			return state;
		},
		toggleBestConnectionMorning: (state) => {
			state.bestConnectionMorning = !state.bestConnectionMorning;
			return state;
		},
		toggleBestConnectionAfternoon: (state) => {
			state.bestConnectionAfternoon = !state.bestConnectionAfternoon;
			return state;
		},
		toggleBestConnectionEvening: (state) => {
			state.bestConnectionEvening = !state.bestConnectionEvening;
			return state;
		},
		toggleStatusPatientClient: (state) => {
			state.statusPatientClient = !state.statusPatientClient;
			return state;
		},
		toggleStatusVisitor: (state) => {
			state.statusVisitor = !state.statusVisitor;
			return state;
		},
		toggleCommentTypeGreatJob: (state) => {
			state.commentTypeGreatJob = !state.commentTypeGreatJob;
			return state;
		},
		toggleCommentTypeInquiry: (state) => {
			state.commentTypeInquiry = !state.commentTypeInquiry;
			return state;
		},
		toggleCommentTypeSuggestion: (state) => {
			state.commentTypeSuggestion = !state.commentTypeSuggestion;
			return state;
		},
		toggleCommentTypeComplaintGrievance: (state) => {
			state.commentTypeComplaintGrievance = !state.commentTypeComplaintGrievance;
			return state;
		},
		toggleCommentTypeSlidingScale: (state) => {
			state.commentTypeSlidingScale = !state.commentTypeSlidingScale;
			return state;
		},
		toggleCommentTypeOther: (state) => {
			state.commentTypeOther = !state.commentTypeOther;
			return state;
		},
		setCommentTypeOtherText: (state, action) => {
			state.commentTypeOtherText = action.payload;
			return state;
		},
		setDate: (state, action) => {
			state.date = action.payload;
			return state;
		},
		setClinicDepartment: (state, action) => {
			state.clinicDepartment = action.payload;
			return state;
		},
		setExperience: (state, action) => {
			state.experience = action.payload;
			return state;
		},
		setAllowExperience: (state, action) => {
			state.allowExperience = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getGrievanceFeedback.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setGrievanceFeedback.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getGrievanceFeedback.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.name = action.payload?.name;
				state.address = action.payload?.address;
				state.phone = action.payload?.phone;
				state.email = action.payload?.email;
				state.typeConnectionPhone = action.payload?.typeСonnectionPhone;
				state.typeConnectionEmail = action.payload?.typeСonnectionEmail;
				state.typeConnectionMail = action.payload?.typeСonnectionMail;
				state.bestConnectionMorning = action.payload?.bestConnectionMorning;
				state.bestConnectionAfternoon = action.payload?.bestConnectionAfternoon;
				state.bestConnectionEvening = action.payload?.bestConnectionEvening;
				state.statusPatientClient = action.payload?.statusPatientClient;
				state.statusVisitor = action.payload?.statusVisitor;
				state.commentTypeGreatJob = action.payload?.commentTypeGreatJob;
				state.commentTypeInquiry = action.payload?.commentTypeInquiry;
				state.commentTypeSuggestion = action.payload?.commentTypeSuggestion;
				state.commentTypeComplaintGrievance = action.payload?.commentTypeComplaintGrievance;
				state.commentTypeSlidingScale = action.payload?.commentTypeSlidingScale;
				state.commentTypeOther = action.payload?.commentTypeOther;
				state.commentTypeOtherText = action.payload?.commentTypeOtherText;
				state.date = action.payload?.date;
				state.clinicDepartment = action.payload?.clinicDepartment;
				state.experience = action.payload?.experience;
				state.allowExperience = action.payload?.allowExperience;

				state.loading = false;
			})
			.addCase(setGrievanceFeedback.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Grievance & Feedback Form saved successfully");
			})
			.addCase(getGrievanceFeedback.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Grievance & Feedback Form: ${action.error.message}`);
			})
			.addCase(setGrievanceFeedback.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Grievance & Feedback Form: ${action.error.message}`);
			})
			.addCase(getGrievanceFeedbackByID.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError  = null;
			})
			.addCase(getGrievanceFeedbackByID.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError  = null;

				state.name = action.payload?.name;
				state.address = action.payload?.address;
				state.phone = action.payload?.phone;
				state.email = action.payload?.email;
				state.typeConnectionPhone = action.payload?.typeСonnectionPhone;
				state.typeConnectionEmail = action.payload?.typeСonnectionEmail;
				state.typeConnectionMail = action.payload?.typeСonnectionMail;
				state.bestConnectionMorning = action.payload?.bestConnectionMorning;
				state.bestConnectionAfternoon = action.payload?.bestConnectionAfternoon;
				state.bestConnectionEvening = action.payload?.bestConnectionEvening;
				state.statusPatientClient = action.payload?.statusPatientClient;
				state.statusVisitor = action.payload?.statusVisitor;
				state.commentTypeGreatJob = action.payload?.commentTypeGreatJob;
				state.commentTypeInquiry = action.payload?.commentTypeInquiry;
				state.commentTypeSuggestion = action.payload?.commentTypeSuggestion;
				state.commentTypeComplaintGrievance = action.payload?.commentTypeComplaintGrievance;
				state.commentTypeSlidingScale = action.payload?.commentTypeSlidingScale;
				state.commentTypeOther = action.payload?.commentTypeOther;
				state.commentTypeOtherText = action.payload?.commentTypeOtherText;
				state.date = action.payload?.date;
				state.clinicDepartment = action.payload?.clinicDepartment;
				state.experience = action.payload?.experience;
				state.allowExperience = action.payload?.allowExperience;

				state.loading = false;
			})
			.addCase(getGrievanceFeedbackByID.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError  = action.error;
				state.loading   = false;

				showToast('error', `Get Grievance & Feedback Form: ${action.error.message}`);
			})

			.addCase(setGrievanceFeedbackByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError  = null;
			})
			.addCase(setGrievanceFeedbackByID.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError  = null;

				showToast('success', 'Grievance & Feedback Form saved successfully');
			})
			.addCase(setGrievanceFeedbackByID.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError  = action.error;

				showToast('error', `Set Grievance & Feedback Form: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,

	setName,
	setAddress,
	setPhone,
	setEmail,
	toggleTypeConnectionPhone,
	toggleTypeConnectionEmail,
	toggleTypeConnectionMail,
	toggleBestConnectionMorning,
	toggleBestConnectionAfternoon,
	toggleBestConnectionEvening,
	toggleStatusPatientClient,
	toggleStatusVisitor,
	toggleCommentTypeGreatJob,
	toggleCommentTypeInquiry,
	toggleCommentTypeSuggestion,
	toggleCommentTypeComplaintGrievance,
	toggleCommentTypeSlidingScale,
	toggleCommentTypeOther,
	setCommentTypeOtherText,
	setDate,
	setClinicDepartment,
	setExperience,
	setAllowExperience,
} = grievanceFeedbackForm.actions;

export default grievanceFeedbackForm.reducer;