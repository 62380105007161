export const selectLoading = state => state.annualPatientRegistration.loading;

export const selectFirstName = state => state.annualPatientRegistration.firstName;
export const selectMiddleName = state => state.annualPatientRegistration.middleName;
export const selectLastName = state => state.annualPatientRegistration.lastName;
export const selectDateOfBirth = state => state.annualPatientRegistration.dateOfBirth;
export const selectSSN = state => state.annualPatientRegistration.SSN;
export const selectGender = state => state.annualPatientRegistration.gender;
export const selectOtherGender = state => state.annualPatientRegistration.otherGender;
export const selectEmail = state => state.annualPatientRegistration.email;
export const selectPhone = state => state.annualPatientRegistration.phone;
export const selectCanLeaveVoiceMessage = state => state.annualPatientRegistration.canLeaveVoiceMessage;
export const selectAddress = state => state.annualPatientRegistration.address;
export const selectCity = state => state.annualPatientRegistration.city;
export const selectState = state => state.annualPatientRegistration.state;
export const selectZip = state => state.annualPatientRegistration.zip;
export const selectMaritalStatus = state => state.annualPatientRegistration.maritalStatus;
export const selectNumberOfPeople = state => state.annualPatientRegistration.numberOfPeople;
export const selectNumberOfChildren = state => state.annualPatientRegistration.numberOfChildren;
export const selectAnyCustodyArrangements = state => state.annualPatientRegistration.anyCustodyArrangements;

export const selectContactFullName = state => state.annualPatientRegistration.contactFullName;
export const selectContactRelationship = state => state.annualPatientRegistration.contactRelationship;
export const selectContactEmail = state => state.annualPatientRegistration.contactEmail;
export const selectContactPhone = state => state.annualPatientRegistration.contactPhone;
export const selectInsuranceProvider = state => state.annualPatientRegistration.insuranceProvider;
export const selectPolicyNumber = state => state.annualPatientRegistration.policyNumber;
export const selectGroupNumber = state => state.annualPatientRegistration.groupNumber;

export const selectFamilyMembers = state => state.annualPatientRegistration.familyMembers;
export const selectIncomeEstimate = state => state.annualPatientRegistration.incomeEstimate;
export const selectEmployment = state => state.annualPatientRegistration.employment;
export const selectEmployer = state => state.annualPatientRegistration.employer;
export const selectEmployerAddress = state => state.annualPatientRegistration.employerAddress;
export const selectEmployerCity = state => state.annualPatientRegistration.employerCity;
export const selectEmployerState = state => state.annualPatientRegistration.employerState;
export const selectEmployerZip = state => state.annualPatientRegistration.employerZip;
export const selectNumberOfArrests = state => state.annualPatientRegistration.numberOfArrests;

export const selectPregnant = state => state.annualPatientRegistration.pregnant;
export const selectDrugUser = state => state.annualPatientRegistration.drugUser;
export const selectHaveEverBeenInHospital = state => state.annualPatientRegistration.haveEverBeenInHospital;
export const selectHaveBeenInHospitalLast6Months = state => state.annualPatientRegistration.haveBeenInHospitalLast6Months;
export const selectHaveHadIllnessOrInjury = state => state.annualPatientRegistration.haveHadIllnessOrInjury;
export const selectPhysicalExam = state => state.annualPatientRegistration.physicalExam;
export const selectContactedByMedicalRN = state => state.annualPatientRegistration.contactedByMedicalRN;
export const selectLivingWill = state => state.annualPatientRegistration.livingWill;
export const selectdateConsent = state => state.annualPatientRegistration.dateConsent;
