import { isAdmin } from '../../../core/auth/authProvider';
import { fileExtensions, GRID_SM, GRID_XS, maxFileSize } from '../../../utils/constants';
import { MAX_CURRENT_DATE, MAX_WIDTH, MIN_CURRENT_DATE, BASE_URL } from '../../../utils/constants';
import { Wrapper, TitleWrapper, Title, SubTitle, Text, Fieldset } from '../../../components/Main/Main.style';
import { FileUploaderValidation } from '../../../components/FileUploaderValidation/FileUploaderValidation';
import { dateFormatFromApi } from '../../../utils/formats';
import Link from '../../../components/Link/Link.style';
import { NAME_RULE } from '../../../utils/rules';
import { Grid } from '@mui/material';
import { useState } from 'react'
import FileUploader from 'devextreme-react/file-uploader';
import LoadPanel from 'devextreme-react/load-panel';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const IDAndInsuranceCard = ({
	loading,

	firstName,
	middleName,
	lastName,
	date,
	email,
	frontId,
	backId,
	frontCard,
	backCard,
	cib,
	tribalCard,
	tribalEnrollment,

	onChangeFirstName,
	onChangeMiddleName,
	onChangeLastName,
	onChangeDate,
	onChangeEmail,
	onSelectFrontId,
	onSelectBackId,
	onSelectFrontCard,
	onSelectBackCard,
	onSelectCib,
	onSelectTribalCard,
	onSelectTribalEnrollment,

	onClickAttachedFile,
	onSaveIDAndInsuranceCard,
	onClickBack,
}) => {
	const [chunks, setChunks] = useState([]);
	const sectionName = "iDAndInsuranceCard";

	const onUploadProgress = (e) => {
		const chunk = {
			segmentSize: e.segmentSize,
			bytesLoaded: e.bytesLoaded,
			bytesTotal: e.bytesTotal,
		};
		setChunks(chunk);
		return chunks;
	}

	const onUploadStarted = () => {
		setChunks([]);
	}

	const _token = JSON.parse(localStorage.getItem('AUTH_TOKEN_DATA'));

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="ID, Insurance Card and Proof of Tribal Enrollment">
								<Title>ID, Insurance Card and Proof of Tribal Enrollment</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							This form is <span style={{ color: 'var(--red-text-color)' }}>required</span>.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Please upload the documents listed below — this will be added to your Medical Records.</Text>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="First Name">
							<>
								<TextBox
									label="First Name"
									value={firstName}
									onValueChanged={onChangeFirstName}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="First Name is required"
										/>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Middle Name">
							<>
								<TextBox
									label="Middle Name"
									value={middleName}
									onValueChanged={onChangeMiddleName}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-middleName' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Last Name">
							<>
								<TextBox
									label="Last Name"
									value={lastName}
									onValueChanged={onChangeLastName}
									maxLength={90}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Last Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Date">
							<>
								<DateBox
									label="Date"
									type="date"
									value={dateFormatFromApi(date)}
									onValueChanged={onChangeDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date is required"
										/>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Email">
							<>
								<TextBox
									label="Email"
									value={email}
									onValueChanged={onChangeEmail}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Email is required"
										/>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>ID Card</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Please take a photo of the front and back of your goverment issued ID card.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Front of ID</b></Text>
									{
										frontId &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(frontId.id, frontId.fileName)}> {frontId.fileName}</Link>
										</Text>
									}
									<FileUploader
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={'*'}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadFrontOfID"}
										onUploaded={onSelectFrontId}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}
									/>
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Back of ID</b></Text>
									{
										backId &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(backId.id, backId.fileName)}> {backId.fileName}</Link>
										</Text>
									}
									<FileUploader
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={'*'}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadBackOfID"}
										onUploaded={onSelectBackId}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}
									/>
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Insurance Card</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Please take a photo of the front and back of your primary insurance card.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Front of Insurance Card</b></Text>
									{
										frontCard &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(frontCard.id, frontCard.fileName)}> {frontCard.fileName}</Link>
										</Text>
									}
									<FileUploader
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={'*'}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadFrontOfInsuranceCard"}
										onUploaded={onSelectFrontCard}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}
									/>
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Back of Insurance Card</b></Text>
									{
										backCard &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(backCard.id, backCard.fileName)}> {backCard.fileName}</Link>
										</Text>
									}
									<FileUploader
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={'*'}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadBackOfInsuranceCard"}
										onUploaded={onSelectBackCard}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}
									/>
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle>Tribal Enrollment Documentation</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Please upload Certificate of Indian Birth, Tribal Card, Tribal Enrollment Document (if applicable).
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Certificate of Indian Birth (CIB)</b></Text>
									{
										cib &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(cib.id, cib.fileName)}> {cib.fileName}</Link>
										</Text>
									}
									<FileUploader
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={'*'}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadCIB"}
										onUploaded={onSelectCib}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}
									/>
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Tribal Card</b></Text>
									{
										tribalCard &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(tribalCard.id, tribalCard.fileName)}> {tribalCard.fileName}</Link>
										</Text>
									}
									<FileUploader
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={'*'}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadTribalCard"}
										onUploaded={onSelectTribalCard}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}
									/>
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><b>Tribal Enrollment Document</b></Text>
									{
										tribalEnrollment &&
										<Text style={{ padding: '10px 10px 0px' }}>
											Attached:
											<Link onClick={() => onClickAttachedFile(tribalEnrollment.id, tribalEnrollment.fileName)}> {tribalEnrollment.fileName}</Link>
										</Text>
									}
									<FileUploader
										maxFileSize={maxFileSize}
										chunkSize={190000}
										multiple={false}
										uploadMode={'instantly'}
										accept={'*'}
										allowedFileExtensions={fileExtensions}
										uploadUrl={BASE_URL + "/FileUploader/uploadTribalEnrollmentDocument"}
										onUploaded={onSelectTribalEnrollment}
										onProgress={onUploadProgress}
										onUploadStarted={onUploadStarted}
										uploadHeaders={
											{ 'Authorization': 'Bearer ' + _token.accessToken }
										}
									/>
									<FileUploaderValidation fileExtensions={fileExtensions} maxFileSize={maxFileSize} />
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="contained"
							validationGroup={sectionName}
							disabled={false}
							onClick={onSaveIDAndInsuranceCard} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	)
}