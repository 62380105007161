import { formatBytes } from '../../utils/helper';
import { Div, Span } from './FileUploaderValidation.style';

/**
 *
 * @param {*}
 * @returns
 */
export const FileUploaderValidation = ({ fileExtensions, maxFileSize }) => {
  return (
    <>
      <Div>
        {'Allowed file extensions: '}
        <Span>
          {fileExtensions.map((el, ind) =>
            fileExtensions.length - 1 === ind ? `${el}.` : `${el}, `,
          )}
        </Span>
      </Div>
      <Div>
        {'Maximum file size: '}
        <Span>{formatBytes(maxFileSize)}.</Span>
      </Div>
    </>
  );
};
