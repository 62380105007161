export const selectLoading = state => state.newPatientRegistrationInsuranceInfo.loading;

export const selectDontHaveInsurance = state => state.newPatientRegistrationInsuranceInfo.dontHaveInsurance;
export const selectAllMembers = state => state.newPatientRegistrationInsuranceInfo.allMembers;
export const selectTotalIncome = state => state.newPatientRegistrationInsuranceInfo.totalIncome;
export const selectMedicare = state => state.newPatientRegistrationInsuranceInfo.medicare;
export const selectMedicareEffDate = state => state.newPatientRegistrationInsuranceInfo.medicareEffDate;
export const selectMedicareExpDate = state => state.newPatientRegistrationInsuranceInfo.medicareExpDate;
export const selectMedicareNumber = state => state.newPatientRegistrationInsuranceInfo.medicareNumber;
export const selectMedicaid = state => state.newPatientRegistrationInsuranceInfo.medicaid;
export const selecMedicaidEffDate = state => state.newPatientRegistrationInsuranceInfo.medicaidEffDate;
export const selectMedicaidExpDate = state => state.newPatientRegistrationInsuranceInfo.medicaidExpDate;
export const selectMedicaidNumber = state => state.newPatientRegistrationInsuranceInfo.medicaidNumber;
export const selectDenaliKid = state => state.newPatientRegistrationInsuranceInfo.denaliKid;
export const selectDenaliKidEffDate = state => state.newPatientRegistrationInsuranceInfo.denaliKidEffDate;
export const selectDenaliKidExpDate = state => state.newPatientRegistrationInsuranceInfo.denaliKidExpDate;
export const selectDenaliKidNumber = state => state.newPatientRegistrationInsuranceInfo.denaliKidNumber;
export const selectPrivateInsurance = state => state.newPatientRegistrationInsuranceInfo.privateInsurance;
export const selectCompanyInsurance = state => state.newPatientRegistrationInsuranceInfo.companyInsurance;
export const selectPolicyHolder = state => state.newPatientRegistrationInsuranceInfo.policyHolder;
export const selectPolicyHolderNumber = state => state.newPatientRegistrationInsuranceInfo.policyHolderNumber;
export const selectPolicyHolderGroupNumber = state => state.newPatientRegistrationInsuranceInfo.policyHolderGroupNumber;
export const selectOtherInsurance = state => state.newPatientRegistrationInsuranceInfo.otherInsurance;
export const selectOtherInsuranceNumber = state => state.newPatientRegistrationInsuranceInfo.otherInsuranceNumber;
export const selectotherInsuranceGroupNumber = state => state.newPatientRegistrationInsuranceInfo.otherInsuranceGroupNumber;
export const selectVeteran = state => state.newPatientRegistrationInsuranceInfo.veteran;
export const selectBenefitsVA = state => state.newPatientRegistrationInsuranceInfo.benefitsVA;
export const selectServiceBranch = state => state.newPatientRegistrationInsuranceInfo.serviceBranch;
export const selectServiceEntryDate = state => state.newPatientRegistrationInsuranceInfo.serviceEntryDate;
export const selectServiceExitDate = state => state.newPatientRegistrationInsuranceInfo.serviceExitDate;
export const selectVietnamService = state => state.newPatientRegistrationInsuranceInfo.vietnamService;
export const selectDoNotOtherInsurance = state => state.newPatientRegistrationInsuranceInfo.doNotOtherInsurance;
export const selectSelfPay = state => state.newPatientRegistrationInsuranceInfo.selfPay;
export const selectRelationship = state => state.newPatientRegistrationInsuranceInfo.relationship;
export const selectDOB = state => state.newPatientRegistrationInsuranceInfo.DOB;
export const selectPhone = state => state.newPatientRegistrationInsuranceInfo.phone;
export const selectAddress = state => state.newPatientRegistrationInsuranceInfo.address;
export const selectCity = state => state.newPatientRegistrationInsuranceInfo.city;
export const selectState = state => state.newPatientRegistrationInsuranceInfo.state;
export const selectZip = state => state.newPatientRegistrationInsuranceInfo.zip;
export const selectEffectiveDate = state => state.newPatientRegistrationInsuranceInfo.effectiveDate;