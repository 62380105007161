import { setCorporation, setEnrollment, setEthnicity, setLanguage, setOtherLanguage, setRace, setRaceAlaska, setRaceAmerican, toggleCIB, toggleCIBCard, toggleTranslator } from "../../../../../../store/users/patientProfile/basicInformation/tribalAffiliation/slice";

import { getTribalAffiliationById, saveTribalAffiliationById } from "../../../../../../store/users/patientProfile/basicInformation/tribalAffiliation/middlewares";
import { getEthnicities } from "../../../../../../store/lookups/ethnicity/middlewares";
import { getRaces } from "../../../../../../store/lookups/race/middlewares";
import { getLanguages } from "../../../../../../store/lookups/language/middlewares";

import { selectEthnicities } from "../../../../../../store/lookups/ethnicity/selectors";
import { selectRaces } from "../../../../../../store/lookups/race/selectors";
import { selectLanguages } from "../../../../../../store/lookups/language/selectors";

import { validateGroup } from "../../../../../../utils/helper";
import { TribalAffiliation } from "./TribalAffiliation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const TribalAffiliationContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const ethnicities = useSelector(selectEthnicities);
    const races = useSelector(selectRaces);
    const languages = useSelector(selectLanguages);

    const statePatientProfileTribalAffiliation     = useSelector(state => state.patientProfileTribalAffiliation);
	const getEntityPatientProfileTribalAffiliation = (name) => statePatientProfileTribalAffiliation[name];

    useEffect(() => {
        dispatch(getTribalAffiliationById(id));
        dispatch(getEthnicities());
        dispatch(getRaces());
        dispatch(getLanguages());
    }, [dispatch, id]);

    const FN_TO_CALL = {
		Corporation: setCorporation,
        Enrollment: setEnrollment,
        Ethnicity: setEthnicity,
        Race: setRace,
        RaceAlaska: setRaceAlaska,
        RaceAmerican: setRaceAmerican,
        Language: setLanguage,
        OtherLanguage: setOtherLanguage,
        CIB: toggleCIB,
        CIBCard: toggleCIBCard,
        Translator: toggleTranslator,
	};

	const onChangeFunc = (name, event = null, trim = false) => {
		if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
		else dispatch(FN_TO_CALL[name]());
		setShowButton(true);
	};

    const onCancelTribalAffiliation = () => {
        dispatch(getTribalAffiliationById(id));
        setShowButton(false);
    }

    const onSaveTribalAffiliation = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveTribalAffiliationById(id));
            setShowButton(false);
        }
    }

    return (
        <TribalAffiliation
            showButton={showButton}

            ethnicities={ethnicities}
            races={races}
            languages={languages}

            getEntityPatientProfileTribalAffiliation={getEntityPatientProfileTribalAffiliation}
            onChangeFunc={onChangeFunc}

            onCancelTribalAffiliation={onCancelTribalAffiliation}
            onSaveTribalAffiliation={onSaveTribalAffiliation}
        />
    );
};