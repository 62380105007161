import { selectLoading, selectNextOfKin, selectRelationship, selectAddress, selectCity, selectState, selectZip, selectPhone, selectEmail } from "../../../../../../store/users/patientProfile/basicInformation/nextOfKin/selectors";
import { setNextOfKin, setAddress, setCity, setEmail, setPhone, setRelationship, setState, setZip } from "../../../../../../store/users/patientProfile/basicInformation/nextOfKin/slice";

import { getNextKinById, saveNextOfKinById } from "../../../../../../store/users/patientProfile/basicInformation/nextOfKin/middlewares";
import { selectStates } from "../../../../../../store/lookups/state/selectors";
import { getState } from "../../../../../../store/lookups/state/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { NextOfKin } from "./NextOfKin";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const NextOfKinContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const states = useSelector(selectStates);

    const nextOfKin = useSelector(selectNextOfKin);
    const relationship = useSelector(selectRelationship);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const phone = useSelector(selectPhone);
    const email = useSelector(selectEmail);

    useEffect(() => {
        dispatch(getState());
        dispatch(getNextKinById(id));
    }, [dispatch, id]);

    const FN_TO_CALL = {
		NextOfKin: setNextOfKin,
        Relationship: setRelationship,
        Address: setAddress,
        City: setCity,
        State: setState,
        Zip: setZip,
        Phone: setPhone,
        Email: setEmail,
	};

    const onChangeFunc = (name, event, trim = false) => {
        dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        setShowButton(true);
    };

    const onCancelNextOfKin = () => {
        dispatch(getNextKinById());
        setShowButton(false);
    }

    const onSaveNextOfKin = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveNextOfKinById(id));
            setShowButton(false);
        }
    }

    return (
        <NextOfKin
            loading={loading}
            showButton={showButton}

            states={states}

            nextOfKin={nextOfKin}
            relationship={relationship}
            address={address}
            city={city}
            state={state}
            zip={zip}
            phone={phone}
            email={email}

            onChangeFunc={onChangeFunc}

            onCancelNextOfKin={onCancelNextOfKin}
            onSaveNextOfKin={onSaveNextOfKin}
        />
    )
};