import { Wrapper, Grid, Item, Label, TextBox, DateBox, SelectBox, CheckBox, Buttons, CheckBoxWrapper } from '../../BasicInformation.style';
import { CURRENT_DATE, MAX_DATE, MAX_WIDTH, MIN_DATE, MAX_DOB, TEXT_DOB, INSURANCE_INFO, PHONE_MASK, ZIP_MASK, GRID_XS, GRID_SM, GRID_LG } from '../../../../../../utils/constants';
import DataGrid, { Column, Editing, Toolbar, Item as ItemToolbar } from 'devextreme-react/data-grid';
import { NAME_RULE } from '../../../../../../utils/rules';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import { onKeyDownHandle } from '../../../../../../utils/helper';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	PatternRule,
	RangeRule,
	RequiredRule
} from 'devextreme-react/validator';

export const InsuranceInfo = ({
	loading,
	showButton,

	totalHouseholdIncomes,
	denaliKidStatuses,
	medicaidStatuses,
	medicareStatuses,
	states,

	familyMembers,
	totalHouseholdIncome,
	medicareStatus,
	medicareEffDate,
	medicareExpDate,
	medicareNumber,
	medicaidStatus,
	medicaidEffDate,
	medicaidExpDate,
	medicaidNumber,
	denaliKidStatus,
	denaliKidEffDate,
	denaliKidExpDate,
	denaliKidNumber,
	privateInsurance,
	companyInsurance,
	policyHolder,
	policyHolderNumber,
	policyHolderGroupNumber,
	otherInsurance,
	otherInsuranceNumber,
	otherInsuranceGroupNumber,
	veteran,
	benefitsVA,
	serviceBranch,
	serviceEntryDate,
	serviceExitDate,
	vietnamService,
	doNotOtherInsurance,
	selfPay,
	relationship,
	DOB,
	phone,
	address,
	city,
	state,
	zip,
	effectiveDate,

	onRowInsertingFamilyMembers,
	onRowRemovingFamilyMembers,
	onRowUpdatingFamilyMembers,

	onChangeFunc,

	onCancelInsuranceInfo,
	onSaveInsuranceInfo,
}) => {
	return (
		< Wrapper id={INSURANCE_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${INSURANCE_INFO}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Item item xs={GRID_XS} style={{ paddingBottom: '10px' }}>
						<DataGrid
							showBorders={true}
							dataSource={familyMembers}
							onRowInserting={onRowInsertingFamilyMembers}
							onRowRemoving={onRowRemovingFamilyMembers}
							onRowUpdating={onRowUpdatingFamilyMembers}>
							<Toolbar>
								<ItemToolbar location="before" name='addRowButton' showText />
							</Toolbar>
							<Editing
								mode="row"
								allowAdding={true}
								allowUpdating={true}
								allowDeleting={true}
								confirmDelete={false} />
							<Column
								dataField="name"
								caption="Full Name"
								alignment="left" >
								<RequiredRule />
							</Column>
							<Column
								dataField="relationship"
								caption="Relationship"
								alignment="left" />
							<Column
								dataField="age"
								caption="Age"
								dataType="number"
								alignment="left"
								editorOptions={{
									min: 0,
									max: 120,
									format: 'fixedPoint'
								}} />
						</DataGrid>
					</Item>
					<Item item xs={GRID_XS}>
						<Tooltip title="Total Annual Household Income Estimate">
							<Label>Total Annual Household Income Estimate:</Label>
						</Tooltip>
						<SelectBox
							label="Total Annual Household Income Estimate"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={totalHouseholdIncome}
							dataSource={totalHouseholdIncomes}
							onValueChanged={e => onChangeFunc('Income', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-totalHouseholdIncome' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Medicare">
							<Label>Medicare:</Label>
						</Tooltip>
						<SelectBox
							label="Medicare"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={medicareStatus}
							dataSource={medicareStatuses}
							onValueChanged={e => onChangeFunc('Medicare', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-medicareStatus' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Effective date">
							<Label>Effective date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Effective date"
							value={dateFormatFromApi(medicareEffDate)}
							onValueChanged={e => onChangeFunc('MedicareEffDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Effective Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Exp Date">
							<Label>Exp Date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Exp Date"
							value={dateFormatFromApi(medicareExpDate)}
							onValueChanged={e => onChangeFunc('MedicareExpDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Exp Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="#">
							<Label>#</Label>
						</Tooltip>
						<TextBox
							label="#"
							value={medicareNumber}
							onValueChanged={e => onChangeFunc('MedicareNumber', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-medicareNumber' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Medicaid">
							<Label>Medicaid:</Label>
						</Tooltip>
						<SelectBox
							label="Medicaid"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={medicaidStatus}
							dataSource={medicaidStatuses}
							onValueChanged={e => onChangeFunc('Medicaid', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-medicaidStatus' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Effective date">
							<Label>Effective date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Effective date"
							defaultValue={dateFormatFromApi(medicaidEffDate)}
							onValueChanged={e => onChangeFunc('MedicaidEffDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Effective Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Exp Date">
							<Label>Exp Date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Exp Date"
							value={dateFormatFromApi(medicaidExpDate)}
							onValueChanged={e => onChangeFunc('MedicaidExpDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Exp Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="#">
							<Label>#</Label>
						</Tooltip>
						<TextBox
							label="#"
							value={medicaidNumber}
							onValueChanged={e => onChangeFunc('MedicaidNumber', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-medicaidNumber' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Denali Kid">
							<Label>Denali Kid:</Label>
						</Tooltip>
						<SelectBox
							label="Denali Kid"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={denaliKidStatus}
							dataSource={denaliKidStatuses}
							onValueChanged={e => onChangeFunc('DenaliKid', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-denaliKidStatus' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Effective date">
							<Label>Effective date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Effective date"
							value={dateFormatFromApi(denaliKidEffDate)}
							onValueChanged={e => onChangeFunc('DenaliKidEffDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Effective Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Exp Date">
							<Label>Exp Date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Exp Date"
							value={dateFormatFromApi(denaliKidExpDate)}
							onValueChanged={e => onChangeFunc('DenaliKidExpDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Exp Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="#">
							<Label>#</Label>
						</Tooltip>
						<TextBox
							label="#"
							value={denaliKidNumber}
							onValueChanged={e => onChangeFunc('DenaliKidNumber', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-denaliKidNumber' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Private Insurance">
							<Label>Private Insurance: </Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={privateInsurance}
								onClick={() => onChangeFunc('PrivateInsurance')} >
								{privateInsurance ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Insurance Company">
							<Label>Insurance Company:</Label>
						</Tooltip>
						<TextBox
							label="Insurance Company"
							value={companyInsurance}
							onValueChanged={e => onChangeFunc('CompanyInsurance', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-companyInsurance' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="I DO NOT have other insurance coverage">
							<Label>I DO NOT have other insurance coverage: </Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={doNotOtherInsurance}
								onClick={() => onChangeFunc('DoNotOtherInsurance')} >
								{doNotOtherInsurance ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Policy Holder">
							<Label>Policy Holder:</Label>
						</Tooltip>
						<TextBox
							label="Policy Holder"
							value={policyHolder}
							onValueChanged={e => onChangeFunc('PolicyHolder', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-policyHolder' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Policy #">
							<Label>Policy #</Label>
						</Tooltip>
						<TextBox
							label="Policy #"
							value={policyHolderNumber}
							onValueChanged={e => onChangeFunc('PolicyHolderNumber', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-policyHolderNumber' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Group #">
							<Label>Group #</Label>
						</Tooltip>
						<TextBox
							label="Group #"
							value={policyHolderGroupNumber}
							onValueChanged={e => onChangeFunc('PolicyHolderGroupNumber', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-policyHolderGroupNumber' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Other Insurance">
							<Label>Other Insurance</Label>
						</Tooltip>
						<TextBox
							label="Other Insurance"
							value={otherInsurance}
							onValueChanged={e => onChangeFunc('OtherInsurance', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-otherInsurance}' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Policy #">
							<Label>Policy #</Label>
						</Tooltip>
						<TextBox
							label="Policy #"
							value={otherInsuranceNumber}
							onValueChanged={e => onChangeFunc('OtherInsuranceNumber', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-otherInsuranceNumber' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Group #">
							<Label>Group #</Label>
						</Tooltip>
						<TextBox
							label="Group #"
							value={otherInsuranceGroupNumber}
							onValueChanged={e => onChangeFunc('OtherInsuranceGroupNumber', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-otherInsuranceGroupNumber' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Service Branch">
							<Label>Service Branch:</Label>
						</Tooltip>
						<TextBox
							label="Service Branch"
							value={serviceBranch}
							onValueChanged={e => onChangeFunc('ServiceBranch', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-serviceBranch' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Service Entry Date">
							<Label>Service Entry Date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Service Entry Date"
							value={dateFormatFromApi(serviceEntryDate)}
							onValueChanged={e => onChangeFunc('ServiceEntryDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Service Entry Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Service Exit Date">
							<Label>Service Exit Date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Service Exit Date"
							value={dateFormatFromApi(serviceExitDate)}
							onValueChanged={e => onChangeFunc('ServiceExitDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Service Exit Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Veteran">
							<Label>Veteran: </Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={veteran}
								onClick={() => onChangeFunc('Veteran')} >
								{veteran ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="VA Benefits">
							<Label>VA Benefits: </Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={benefitsVA}
								onClick={() => onChangeFunc('BenefitsVA')} >
								{benefitsVA ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Vietnam Service Indicated">
							<Label>Vietnam Service Indicated: </Label>
						</Tooltip>
						<CheckBoxWrapper>
							<CheckBox
								value={vietnamService}
								onClick={() => onChangeFunc('VietnamService')} >
								{vietnamService ? 'Yes' : 'No'}
							</CheckBox>
						</CheckBoxWrapper>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Self Pay/Guarantor">
							<Label>Self Pay/Guarantor: </Label>
						</Tooltip>
						<TextBox
							label="Self Pay/Guarantor"
							value={selfPay}
							onValueChanged={e => onChangeFunc('SelfPay', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-selfPay' }}
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<PatternRule
									message="Do not use digits in the Self Pay/Guarantor"
									pattern={NAME_RULE}
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Relationship">
							<Label>Relationship: </Label>
						</Tooltip>
						<TextBox
							label="Relationship"
							value={relationship}
							onValueChanged={e => onChangeFunc('Relationship', e, true)}
							maxLength={30}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-relationship' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Date Of Birth">
							<Label>Date Of Birth: </Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Date Of Birth"
							value={dateFormatFromApi(DOB)}
							onValueChanged={e => onChangeFunc('DOB', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Date of Birth cannot be younger than the current date and earlier than 1900"
									min={MIN_DATE}
									max={CURRENT_DATE} />
								<RangeRule
									message={TEXT_DOB}
									max={MAX_DOB} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Phone">
							<Label>Phone: </Label>
						</Tooltip>
						<TextBox
							label="Phone"
							value={phone}
							onValueChanged={e => onChangeFunc('Phone', e)}
							labelMode="hidden"
							stylingMode="underlined"
							onKeyDown={onKeyDownHandle}
							maskChar="."
							mask={PHONE_MASK}
							showMaskMode="onFocus"
							maskRules={{ X: /[0-9]/ }}
							maskInvalidMessage="The phone must have a correct USA phone format"
							validationMessagePosition="bottom"
							inputAttr={{ autocomplete: 'new-phone' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Effective Date">
							<Label>Effective Date: </Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Effective Date"
							value={dateFormatFromApi(effectiveDate)}
							onValueChanged={e => onChangeFunc('EffectiveDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={INSURANCE_INFO}>
								<RangeRule
									message="Effective Date cannot be earlier than 1900 and later than 2100"
									min={MIN_DATE}
									max={MAX_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Address">
							<Label>Address: </Label>
						</Tooltip>
						<TextBox
							label="Address"
							value={address}
							onValueChanged={e => onChangeFunc('Address', e, true)}
							maxLength={150}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-address' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="State">
							<Label>State: </Label>
						</Tooltip>
						<SelectBox
							label="State"
							defaultValue={state}
							valueExpr="Oid"
							displayExpr="Name"
							dataSource={states}
							onValueChanged={e => onChangeFunc('State', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-state' }}
						/>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="City">
							<Label>City:</Label>
						</Tooltip>
						<TextBox
							label="City"
							value={city}
							onValueChanged={e => onChangeFunc('City', e, true)}
							maxLength={20}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-city' }}
						>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} lg={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Zip">
							<Label>Zip:</Label>
						</Tooltip>
						<TextBox
							label="Zip"
							value={zip}
							onValueChanged={e => onChangeFunc('Zip', e)}
							labelMode="hidden"
							stylingMode="underlined"
							onKeyDown={onKeyDownHandle}
							maskChar="."
							mask={ZIP_MASK}
							showMaskMode="onFocus"
							maskRules={{ X: /[0-9]/ }}
							maskInvalidMessage="Zip must have at least 5 symbols"
							validationMessagePosition="bottom"
							inputAttr={{ autocomplete: 'new-zip' }}
						/>
					</Item>
					<Item item xs={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={INSURANCE_INFO}
								onClick={onSaveInsuranceInfo} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelInsuranceInfo} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};