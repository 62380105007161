import { selectLoading, selectInvitedList, selectStatusBtnInvite, selectCurrentFilterState, selectActivefilterValue } from "../../../store/users/InvitedList/selectors";
import { getInvitedList, deactivateInvited, getInviteLinkforCopy } from '../../../store/users/InvitedList/middlewares';
import { setLoading } from '../../../store/users/InvitedList/slice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { InvitedList } from './InvitedList';
import { setShowInvite } from "../../../store/users/generationInvites/slice";
import { Dialog, DialogTitle, DialogContent, DialogContentRow, DialogText, DialogActions } from '../../../components/Dialog/Dialog.style';
import Button from 'devextreme-react/button';

export const InvitedListContainer = () => {
  const [open, setOpen] = useState(false);
  const [invited, setInvited] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(selectLoading);
  const dataSource = useSelector(selectInvitedList);
  const statusBtnInvite = useSelector(selectStatusBtnInvite);
  const currentFilterState = useSelector(selectCurrentFilterState);
  const dataSourceFiltered = useSelector(selectActivefilterValue);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getInvitedList());
  }, [dispatch]);

  const showProfile = (id) => {
    navigate(`profile/${id}`);
  };

  const showInviteLink = (email) => {
    dispatch(getInviteLinkforCopy(email)).then(({ payload }) => {
      if (payload) {
        const key = payload.link.value;
        navigator.clipboard.writeText(`${document.location.host}/auth/invite?id=${key}`);
      };
    });
  };

  const showInvite = () => {
    dispatch(setShowInvite(true));
  };

  const onClickDeactivateInvited = (emails) => {
    dispatch(deactivateInvited(emails)).then(({ payload }) => {
      if (payload.response.ok) {
        setOpen(false);
        dispatch(getInvitedList());
      }
    });
  };

  const onOpenDialog = (invited) => {
    const emails = invited.map((el) => el.email).join(', ');
    setInvited(emails);
    setOpen(true);
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog}>
        <DialogTitle>Delete invite</DialogTitle>
        <DialogContent>
          <DialogContentRow>
            <DialogText>Delete invited user(s) with the following email(s): <b>{invited}</b></DialogText>
          </DialogContentRow>
        </DialogContent>
        <DialogActions>
          <Button text="Ok" type="default" stylingMode="contained" onClick={() => onClickDeactivateInvited(invited)} />
          <Button text="Cancel" type="default" stylingMode="outlined" onClick={onCloseDialog} />
        </DialogActions>
      </Dialog>

      <InvitedList
        loading={loading}
        dataSource={(!currentFilterState) ? dataSource : dataSourceFiltered}
        statusBtnInvite={statusBtnInvite}
        showInviteLink={showInviteLink}
        showProfile={showProfile}
        showInvite={showInvite}
        onOpenDialog={onOpenDialog}
      />
    </>
  );
}