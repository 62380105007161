export const selectLoading = state => state.idAndInsuranceCard.loading;

export const selectFirstName = state => state.idAndInsuranceCard.firstName;
export const selectMiddleName = state => state.idAndInsuranceCard.middleName;
export const selectLastName = state => state.idAndInsuranceCard.lastName;
export const selectDate = state => state.idAndInsuranceCard.date;
export const selectEmail = state => state.idAndInsuranceCard.email;
export const selectFrontId = state => state.idAndInsuranceCard.frontId;
export const selectBackId = state => state.idAndInsuranceCard.backId;
export const selectFrontCard = state => state.idAndInsuranceCard.frontCard;
export const selectBackCard = state => state.idAndInsuranceCard.backCard;
export const selectCib = state => state.idAndInsuranceCard.cib;
export const selectTribalCard = state => state.idAndInsuranceCard.tribalCard;
export const selectTribalEnrollment = state => state.idAndInsuranceCard.tribalEnrollment;
