import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckBox } from 'devextreme-react';
import { IconButton } from '@mui/material';

export const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '100px 80px 0px',
    '@media (max-width: 1400px)': {
        margin: '50px 20px 0px',
    },
}));

export const Title = styled('div')(() => ({
    marginBottom: '20px',
    color: 'var(--dark-blue-color)',
    fontFamily: 'Montserrat',
    userSelect: 'none',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '44px',
}));

export const Text = styled('div')(() => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    userSelect: 'none',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '22px',
    '@media (max-height: 800px)': {
        maxHeight: '100px',
        overflow: 'auto'
    },
}));

export const LinkWrapper = styled('div')(() => ({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
}));

export const CheckBoxWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 1400px)': {
        alignItems: 'flex-start',
        flexDirection: 'column-reverse',
        flexWrap: 'wrap',
        gap: '16px',
    },
}));

export const CheckBoxStyled = styled(CheckBox)(() => ({
    order: '1',
    '& .dx-checkbox-icon': {
        borderColor: 'var(--border-color)',
    },
    '& .dx-checkbox-text': {
        color: 'var(--black-blue-color)',
        fontSize: '16px',
    },
}));

export const IconButtonStyled = styled(IconButton)(() => ({
    order: '2',
    height: '46px',
    marginRight: '10px',
}));

export const HelperText = styled(FormHelperText)(() => ({
    color: 'var(--red-text-color)',
    fontFamily: 'Montserrat',
    marginLeft: '0px',
    fontWeight: '500',
    lineHeight: '22px',
    fontSize: '16px',
}));