import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCheckAttemptId = createAsyncThunk(
    "getCheckAttemptId",
    async (attemptId, { rejectWithValue }) => {
        try {
            return await axios.get(`${BASE_URL}/AuthManagement/CheckAttemptId?attemptId=${attemptId}`);
        } catch (error) {
            console.log("getCheckAttemptId", error)
            return rejectWithValue(error.response.data);
        };
    }
);

export const getEmailByAttemptId = createAsyncThunk(
    "getEmailByAttemptId",
    async (attemptId, { rejectWithValue }) => {
        try {
            return await axios.get(`${BASE_URL}/AuthManagement/GetEmailByAttemptId?attemptId=${attemptId}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    }
);

export const postChangePassword = createAsyncThunk(
    "postChangePassword",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "post",
                url: `${BASE_URL}/AuthManagement/UserChangePassword`,
                data: data,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    }
);