import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Link = styled(NavLink)(() => ({
    color: 'var(--light-blue-color)',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '2px',
    userSelect: 'none',
    textDecoration: 'none',
    textTransform: 'uppercase',
}));

export default Link;