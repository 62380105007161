import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

export const Drawer = styled('div')(({ open }) => ({
    width: open ? '600px' : '0px',
    transition: 'width 0.5s ease-out',
    marginLeft: open ? '0px' : '80px',
    paddingBottom: '40px',
}));

export const Wrapper = styled('div')(() => ({
    minWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '0 50px',
}));

export const TitleWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    borderRadius: '10px',
    backgroundColor: '#E8FBF3',
    paddingLeft: '20px',
    color: 'var(--black-blue-color)',
    "&:hover": {
        backgroundColor: '#D5F5E8',
    }
}));

export const Title = styled('h3')(() => ({
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    paddingLeft: '20px',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '0px',
}));

export const Accordion = styled(MuiAccordion)(() => ({
    boxShadow: 'none',
    margin: '0',
    '&:before': {
        content: 'none',
    },
    '&.Mui-expanded': {
        margin: '0px',
        borderBottom: '1px solid var(--border-color)',
    },
    '&:last-of-type': {
        borderRadius: '0px',
    },
    '&:first-of-type': {
        borderRadius: '0px',
    },
}));

export const AccordionSummary = styled(MuiAccordionSummary)(() => ({
    padding: '0px',
    minHeight: 'auto',
    '&.Mui-expanded': {
        minHeight: 'auto',
    },
    '& .MuiAccordionSummary-content': {
        margin: '10px 0px',
        transition: 'none',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '10px 0px',
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '16px 0px 30px 0px',
}));

export const Label = styled('label')(() => ({
    color: 'var(--grey-text-color)',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
}));

export const Switcher = styled('div')(({ value }) => ({
    color: value ? 'var(--green-text-color)' : 'var(--red-text-color)',
    fontStyle: 'normal',
    fontWeight: '500',
    display: 'inline-flex',
    alignContent: 'center',
    gap: '7px',
    fontSize: '14px',
    letterSpacing: '.04em',
    cursor: 'pointer',
}));




