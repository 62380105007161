import { MAX_WIDTH, MAX_CURRENT_DATE, MIN_CURRENT_DATE, CONSENT, GRID_XS } from '../../../../../../utils/constants';
import { Wrapper, Item, Label, TextBox, DateBox, Buttons } from '../../BasicInformation.style';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import { useResizeDetector } from 'react-resize-detector';
import { NAME_RULE } from '../../../../../../utils/rules';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200 && width <= 1500): return 3;
		case (width > 1500): return 2;
		default: return 12;
	};
};

export const Consent = ({
	loading,
	showButton,

	getEntityPatientProfileConsent,
	onChangeFunc,

	onCancelConsent,
	onSaveConsent,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={CONSENT} ref={ref} maxWidth={MAX_WIDTH} >
			<LoadPanel
				visible={getEntityPatientProfileConsent('loading')}
				position={{ of: `#${CONSENT}` }} />
			{
				!getEntityPatientProfileConsent('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="Signed *">
							<span>
								<Label>Signed by:</Label>
								<TextBox
									label="Signed *"
									value={getEntityPatientProfileConsent('signed')}
									onValueChanged={(e) => onChangeFunc('Signed', e, true)}
									maxLength={90}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-signed' }}
								>
									<Validator validationGroup={CONSENT}>
										<RequiredRule
											message="Signed is required"
										/>
										<PatternRule
											message="Do not use digits in the Signed"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Date *">
							<span>
								<Label>Date:</Label>
								<DateBox
									type="date"
									label="Date *"
									value={dateFormatFromApi(getEntityPatientProfileConsent('signedDate'))}
									onValueChanged={(e) => onChangeFunc('SignedDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={CONSENT}>
										<RequiredRule
											message="Date is required"
										/>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Guardian">
							<span>
								<Label>Guardian:</Label>
								<TextBox
									label="Guardian"
									value={getEntityPatientProfileConsent('guardian')}
									onValueChanged={(e) => onChangeFunc('Guardian', e, true)}
									maxLength={90}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-guardian' }}
								>
									<Validator validationGroup={CONSENT}>
										<PatternRule
											message="Do not use digits in the Printed Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Date">
							<span>
								<Label>Date:</Label>
								<DateBox
									type="date"
									label="Date"
									value={dateFormatFromApi(getEntityPatientProfileConsent('guardianDate'))}
									onValueChanged={(e) => onChangeFunc('GuardianDate', e)}
									showDropDownButton={false}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="hidden"
									stylingMode="underlined"
								>
									<Validator validationGroup={CONSENT}>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={CONSENT}
								onClick={onSaveConsent} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelConsent} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};