import { Title, ProgressBarContsainer, Step, IconKayak, Caption } from './ProgressBar.style';
import Typography from '@mui/material/Typography';
import { Icon } from '@iconify/react';

const ProgressBar = ({ steps, stepsTaken }) => {
	const copySteps = [...steps];
	const sortIsCompleted = copySteps.sort((a, b) => b.isCompleted - a.isCompleted);
	const lastStepNumber = [...sortIsCompleted.filter(item => item.isCompleted)].length;

	const arrayStepColors = [
		'#002875', '#002875',
		'#1148B2', '#1148B2',
		'#235AD6', '#235AD6',
		'#2862E4', '#2862E4',
		'#306FFF', '#306FFF',
		'#6191FF', '#6191FF',
	];

	const checkingNumberSteps = (index) => (sortIsCompleted.length === arrayStepColors.length) ? arrayStepColors[index] : arrayStepColors[0];

	return (
		<div>
			<Title style={{ paddingTop: '16px', paddingBottom: '20px' }}>Your Progress</Title>
			<ProgressBarContsainer>
				{
					sortIsCompleted.map((item, index) => {
						return (
							<Step
								key={item.title}
								color={
									item.isCompleted ? checkingNumberSteps(index) : 'var(--light-grey-color)'
								}
								steps={sortIsCompleted} >
								{index === lastStepNumber - 1 &&
									<IconKayak style={{ right: '0' }}>
										<Icon icon={'tabler:kayak'} color={'var(--white-color)'} fontSize={20} />
									</IconKayak>
								}
								{lastStepNumber === 0 && index === 0 &&
									<IconKayak style={{ left: '0' }}>
										<Icon icon={'tabler:kayak'} color={'var(--white-color)'} />
									</IconKayak>
								}
							</Step>
						)
					})
				}
			</ProgressBarContsainer>
			<Caption>
				<Typography variant="caption">
					{stepsTaken} steps to complete
				</Typography>
			</Caption>
		</div>
	);
}

export default ProgressBar;