import { Wrapper, Grid, Item, Label, TextBox, SelectBox, Buttons } from '../../BasicInformation.style';
import { EMPLOYMENT, MAX_WIDTH, ZIP_MASK, GRID_XS, GRID_SM, GRID_LG } from '../../../../../../utils/constants';
import { onKeyDownHandle } from '../../../../../../utils/helper';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
} from 'devextreme-react/validator';

export const Employment = ({
	showButton,

	states,
	employments,
	primaryIncomeSources,

	getEntityPatientProfileEmployment,
	onChangeFunc,

	onCancelEmployment,
	onSaveEmployment,
}) => {
	return (
		<Wrapper id={EMPLOYMENT} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileEmployment('loading')}
				position={{ of: `#${EMPLOYMENT}` }} />
			{
				!getEntityPatientProfileEmployment('loading') &&
				<Grid container spacing={2}>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Employment Status *">
							<Label>Employment Status:</Label>
						</Tooltip>
						<SelectBox
							label="Employment Status *"
							defaultValue={getEntityPatientProfileEmployment('employment')}
							valueExpr="Oid"
							displayExpr="Name"
							dataSource={employments}
							onValueChanged={e => onChangeFunc('Employment', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-employment' }}
						>
							<Validator validationGroup={EMPLOYMENT}>
								<RequiredRule
									message="Employment is required"
								/>
							</Validator>
						</SelectBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Employer">
							<Label>Employer:</Label>
						</Tooltip>
						<TextBox
							label="Employer"
							value={getEntityPatientProfileEmployment('employer')}
							onValueChanged={e => onChangeFunc('Employer', e, true)}
							maxLength={140}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-employer' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Address">
							<Label>Address:</Label>
						</Tooltip>
						<TextBox
							label="Address"
							value={getEntityPatientProfileEmployment('address')}
							onValueChanged={e => onChangeFunc('Address', e, true)}
							maxLength={150}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-address' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="State">
							<Label>State:</Label>
						</Tooltip>
						<SelectBox
							label="State"
							defaultValue={getEntityPatientProfileEmployment('state')}
							valueExpr="Oid"
							displayExpr="Name"
							dataSource={states}
							onValueChanged={e => onChangeFunc('State', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-state' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="City">
							<Label>City:</Label>
						</Tooltip>
						<TextBox
							label="City"
							value={getEntityPatientProfileEmployment('city')}
							onValueChanged={e => onChangeFunc('City', e, true)}
							maxLength={20}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-city' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Zip">
							<Label>Zip:</Label>
						</Tooltip>
						<TextBox
							label="Zip"
							value={getEntityPatientProfileEmployment('zip')}
							onValueChanged={e => onChangeFunc('Zip', e)}
							labelMode="hidden"
							stylingMode="underlined"
							onKeyDown={onKeyDownHandle}
							maskChar="."
							mask={ZIP_MASK}
							showMaskMode="onFocus"
							maskRules={{ X: /[0-9]/ }}
							maskInvalidMessage="Zip must have at least 5 symbols"
							validationMessagePosition="bottom"
							inputAttr={{ autocomplete: 'new-zip' }}
						/>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Primary Income Source">
							<Label>Primary Income Source:</Label>
						</Tooltip>
						<SelectBox
							label="Primary Income Source"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileEmployment('primaryIncomeSource')}
							dataSource={primaryIncomeSources}
							onValueChanged={e => onChangeFunc('PrimaryIncomeSource', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-primaryIncomeSource' }}
						/>
					</Item>
					{
						getEntityPatientProfileEmployment('primaryIncomeSource') === (primaryIncomeSources.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Specify Primary Income Source">
								<Label>Specified Income Source:</Label>
							</Tooltip>
							<TextBox
								label="Specify Primary Income Source"
								value={getEntityPatientProfileEmployment('otherPrimaryIncomeSource')}
								onValueChanged={e => onChangeFunc('OtherPrimaryIncomeSource', e, true)}
								maxLength={64}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-otherPrimaryIncomeSource' }}
							/>
						</Item>
					}
					<Item item xs={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={EMPLOYMENT}
								onClick={onSaveEmployment} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelEmployment} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};