import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getInvitedList = createAsyncThunk(
	"getInvitedList",
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + "/UserList/userlist?role=Invitee");
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const deactivateInvited = createAsyncThunk(
	'deactivateInvited',
	async (data, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + `/Invite/Deactivate?userEmails=${data}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const getInviteLinkforCopy = createAsyncThunk(
	"getInviteLinkforCopy",
	async (email, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + `/Invite/invite?emailAddress=${email}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);
