import { Validator, RequiredRule, EmailRule } from 'devextreme-react/validator';
import { Wrapper, Title, Text, LinkWrapper } from './ForgotPassword.style';
import TextBoxStyled from '../../../components/Auth/Textbox/Textbox.style';
import ButtonStyled from "../../../components/Auth/Button/Button.style";
import Link from '../../../components/Link/Link.style';
import { GRID_XS } from '../../../utils/constants';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';

export const ForgotPassword = ({
    email,
    onChangeEmail,
    onKeyPressEnter,
    handleClick,
    btnStatus,
}) => {
    return (
        <Wrapper>
            <Title>Forgot your password?</Title>
            <Grid container spacing={2}>
                <Grid item xs={GRID_XS}>
                    <Text>
                        Enter the email address associated with your account. You will receive a link to reset your password via email.
                    </Text>
                </Grid>
                <Grid item xs={GRID_XS} title="Email">
                    <TextBoxStyled
                        label="Email"
                        labelMode="static"
                        stylingMode="outlined"
                        value={email}
                        onValueChanged={onChangeEmail}
                        onKeyPress={onKeyPressEnter}
                    >
                        <Validator>
                            <RequiredRule
                                message="Email is required"
                            />
                            <EmailRule
                                message="Email is invalid"
                            />
                        </Validator>
                    </TextBoxStyled>
                    <ButtonStyled
                        text="Send"
                        onClick={handleClick}
                        disabled={btnStatus}
                    />
                    <LinkWrapper>
                        <Icon icon="bi:arrow-left" height="20px" color="var(--light-blue-color)" />
                        <Link to="/auth/login">back to log in</Link>
                    </LinkWrapper>
                </Grid>
            </Grid>
        </Wrapper>
    );
}