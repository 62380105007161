import { BASE_URL, GUARDIAN_INFO, PATIENT_INFO } from "../../../../utils/constants";
import { dateFormatForApi } from "../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getGuardianInfo = createAsyncThunk(
    "getGuardianInfo",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${GUARDIAN_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getGuardianInfoByID = createAsyncThunk(
    "getGuardianInfoByID",
    async (patienID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${patienID}/${GUARDIAN_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setGuardianInfo = createAsyncThunk(
    "setGuardianInfo",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${GUARDIAN_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setGuardianInfoByID = createAsyncThunk(
    "setGuardianInfoByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${GUARDIAN_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getPatientInfo = createAsyncThunk(
    "getPatientInfo",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${PATIENT_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getPatientInfoByID = createAsyncThunk(
    "getPatientInfoByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${PATIENT_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveGuardianInfo = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationGuardianInfo;

    const data = {
        "sameAsPatient": store.checkbox,
        "firstName": store.firstName,
        "lastName": store.lastName,
        "middleName": store.middleName,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email,
        "employer": store.employer,
    }

    dispatch(setGuardianInfo(data));
};

export const saveGuardianInfoByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationGuardianInfo;

    const data = {
        "sameAsPatient": store.checkbox,
        "firstName": store.firstName,
        "lastName": store.lastName,
        "middleName": store.middleName,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email,
        "employer": store.employer,
    }

    dispatch(setGuardianInfoByID({ id, data }));
};