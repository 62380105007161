import { Wrapper, Title, CheckBoxWrapper, CheckBoxStyled, HelperText, IconButtonStyled } from './Login.style';
import { Validator, RequiredRule, EmailRule } from 'devextreme-react/validator';

import TextBoxStyled from '../../../components/Auth/Textbox/Textbox.style';
import ButtonStyled from "../../../components/Auth/Button/Button.style";
import Link from '../../../components/Link/Link.style';
import { GRID_XS } from '../../../utils/constants';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';
import { useState } from 'react';

export const Login = ({
    email,
    password,
    checkbox,

    error,

    onChangeEmail,
    onChangePassword,
    onToggleCheckbox,

    handleClick,
    onKeyPressEnter,
    btnStatus,
}) => {
    const [mode, setMode] = useState("password");
    const changeMode = () => {
        setMode(mode === "text" ? "password" : "text");
    };

    return (
        <Wrapper>
            <Title>Login</Title>
            <Grid container spacing={2}>
                <Grid item xs={GRID_XS} title="Email">
                    <TextBoxStyled
                        label="Email"
                        value={email}
                        onValueChanged={onChangeEmail}
                        onKeyPress={onKeyPressEnter}
                        labelMode="static"
                        stylingMode="outlined"
                    >
                        <Validator>
                            <RequiredRule
                                message="Email is required"
                            />
                            <EmailRule
                                message="Email is invalid"
                            />
                        </Validator>
                    </TextBoxStyled>
                </Grid>
                <Grid item xs={GRID_XS} title="Password">
                    <TextBoxStyled
                        label="Password"
                        mode={mode}
                        value={password}
                        onValueChanged={onChangePassword}
                        onKeyPress={onKeyPressEnter}
                        labelMode="static"
                        stylingMode="outlined"
                    >
                        <IconButtonStyled onClick={changeMode} tabIndex={-1}>
                            <Icon
                                icon={mode === "text" ? "bx:hide" : "bx:show"}
                                height="30px"
                                width="30px" />
                        </IconButtonStyled>
                        <Validator>
                            <RequiredRule message="Password is required" />
                        </Validator>
                    </TextBoxStyled>
                </Grid>
                <Grid item xs={GRID_XS} title="Remember me">
                    <CheckBoxWrapper>
                        <CheckBoxStyled
                            text="Remember me"
                            value={checkbox}
                            onValueChanged={onToggleCheckbox}
                            focusStateEnabled={false} />
                        <Link to="/auth/forgot-password" style={{ order: '2' }} tabIndex={-1}>forgot password?</Link>
                    </CheckBoxWrapper>
                </Grid>
                <Grid item xs={GRID_XS}>
                    <HelperText>
                        {!!error && error.join(' ')}
                    </HelperText>
                </Grid>
                <Grid item xs={GRID_XS}>
                    <ButtonStyled
                        text="Login"
                        onClick={handleClick}
                        disabled={btnStatus}
                    />
                </Grid>
            </Grid>
        </Wrapper>
    );
}; 