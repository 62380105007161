import { BASE_URL, EMERGENCY_CONTACT } from "../../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getEmergencyContactById = createAsyncThunk(
    "getEmergencyContactById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${EMERGENCY_CONTACT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setEmergencyContactById = createAsyncThunk(
    "setEmergencyContactById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${EMERGENCY_CONTACT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveEmergencyContactById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileEmergencyContact;

    const data = {
        "contact": store.contact,
        "relationship": store.relationship,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email
    }

    dispatch(setEmergencyContactById({ id, data }));
};