import { getReferralForm, setReferralForm, getReferralFormByID, setReferralFormByID } from './middlewares';
import { showToast } from '../../../screens/Main/MainContainer';
import { CURRENT_DATE, FETCH_STATUSES } from '../../../utils/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	agree: false,
	person: "",
	dateOfBirth: null,
	address: "",
	phone: "",
	referral: "",
	contact: "",
	reason: "",
	request: "",
	appt: false,
	apptComment: "",
	declined: false,
	declinedComment: "",
	noAnswer: false,
	firstAttempt: null,
	firstAttemptBy: "",
	secondAttempt: null,
	secondAttemptBy: "",
	thirdAttempt: null,
	thirdAttemptBy: "",
	closedReferral: false,
	referralFollowedUpBy: "",
	referralFollowedUpDate: CURRENT_DATE,
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
};

export const referralForm = createSlice({
	name: 'referralForm',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		toggleAgree: (state) => {
			state.agree = !state.agree;
			return state;
		},
		setPerson: (state, action) => {
			state.person = action.payload;
			return state;
		},
		setDateOfBirth: (state, action) => {
			state.dateOfBirth = action.payload;
			return state;
		},
		setAddress: (state, action) => {
			state.address = action.payload;
			return state;
		},
		setPhone: (state, action) => {
			state.phone = action.payload;
			return state;
		},
		setReferral: (state, action) => {
			state.referral = action.payload;
			return state;
		},
		setContact: (state, action) => {
			state.contact = action.payload;
			return state;
		},
		setReason: (state, action) => {
			state.reason = action.payload;
			return state;
		},
		setRequest: (state, action) => {
			state.request = action.payload;
			return state;
		},
		toggleAppt: (state) => {
			state.appt = !state.appt;
			return state;
		},
		setApptComment: (state, action) => {
			state.apptComment = action.payload;
			return state;
		},
		toggleDeclined: (state) => {
			state.declined = !state.declined;
			return state;
		},
		setDeclinedComment: (state, action) => {
			state.declinedComment = action.payload;
			return state;
		},
		toggleNoAnswer: (state) => {
			state.noAnswer = !state.noAnswer;
			return state;
		},
		setFirstAttempt: (state, action) => {
			state.firstAttempt = action.payload;
			return state;
		},
		setFirstAttemptBy: (state, action) => {
			state.firstAttemptBy = action.payload;
			return state;
		},
		setSecondAttempt: (state, action) => {
			state.secondAttempt = action.payload;
			return state;
		},
		setSecondAttemptBy: (state, action) => {
			state.secondAttemptBy = action.payload;
			return state;
		},
		setThirdAttempt: (state, action) => {
			state.thirdAttempt = action.payload;
			return state;
		},
		setThirdAttemptBy: (state, action) => {
			state.thirdAttemptBy = action.payload;
			return state;
		},
		toggleClosedReferral: (state) => {
			state.closedReferral = !state.closedReferral;
			return state;
		},
		setReferralFollowedUpBy: (state, action) => {
			state.referralFollowedUpBy = action.payload;
			return state;
		},
		setReferralFollowedUpDate: (state, action) => {
			state.referralFollowedUpDate = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getReferralForm.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setReferralForm.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getReferralForm.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.agree = action.payload?.agree;
				state.person = action.payload?.person;
				state.dateOfBirth = action.payload?.dateOfBirth;
				state.address = action.payload?.address;
				state.phone = action.payload?.phone;
				state.referral = action.payload?.referral;
				state.contact = action.payload?.contact;
				state.reason = action.payload?.reason;
				state.request = action.payload?.request;
				state.appt = action.payload?.appt;
				state.apptComment = action.payload?.apptComment;
				state.declined = action.payload?.declined;
				state.declinedComment = action.payload?.declinedComment;
				state.noAnswer = action.payload?.noAnswer;
				state.firstAttempt = action.payload?.firstAttempt;
				state.firstAttemptBy = action.payload?.firstAttemptBy;
				state.secondAttempt = action.payload?.secondAttempt;
				state.secondAttemptBy = action.payload?.secondAttemptBy;
				state.thirdAttempt = action.payload?.thirdAttempt;
				state.thirdAttemptBy = action.payload?.thirdAttemptBy;
				state.closedReferral = action.payload?.closedReferral;
				state.referralFollowedUpBy = action.payload?.referralFollowedUpBy;
				state.referralFollowedUpDate = action.payload?.referralFollowedUpDate || CURRENT_DATE;

				state.loading = false;
			})
			.addCase(setReferralForm.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Referral to Behavioral Health saved successfully");
			})
			.addCase(getReferralForm.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Referral to Behavioral Health: ${action.error.message}`);
			})
			.addCase(setReferralForm.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Referral to Behavioral Health: ${action.error.message}`);
			})
			.addCase(getReferralFormByID.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError  = null;
			})
			.addCase(getReferralFormByID.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.agree = action.payload?.agree;
				state.person = action.payload?.person;
				state.dateOfBirth = action.payload?.dateOfBirth;
				state.address = action.payload?.address;
				state.phone = action.payload?.phone;
				state.referral = action.payload?.referral;
				state.contact = action.payload?.contact;
				state.reason = action.payload?.reason;
				state.request = action.payload?.request;
				state.appt = action.payload?.appt;
				state.apptComment = action.payload?.apptComment;
				state.declined = action.payload?.declined;
				state.declinedComment = action.payload?.declinedComment;
				state.noAnswer = action.payload?.noAnswer;
				state.firstAttempt = action.payload?.firstAttempt;
				state.firstAttemptBy = action.payload?.firstAttemptBy;
				state.secondAttempt = action.payload?.secondAttempt;
				state.secondAttemptBy = action.payload?.secondAttemptBy;
				state.thirdAttempt = action.payload?.thirdAttempt;
				state.thirdAttemptBy = action.payload?.thirdAttemptBy;
				state.closedReferral = action.payload?.closedReferral;
				state.referralFollowedUpBy = action.payload?.referralFollowedUpBy;
				state.referralFollowedUpDate = action.payload?.referralFollowedUpDate || CURRENT_DATE;

				state.loading = false;
			})
			.addCase(getReferralFormByID.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError  = action.error;
				state.loading   = false;

				showToast('error', `Get Referral to Behavioral Health: ${action.error.message}`);
			})
			.addCase(setReferralFormByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError  = null;
			})
			.addCase(setReferralFormByID.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError  = null;
				showToast('success', 'Referral to Behavioral Health saved successfully');
			})
			.addCase(setReferralFormByID.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError  = action.error;
				showToast('error', `Set Referral to Behavioral Health: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,

	toggleAgree,
	setPerson,
	setDateOfBirth,
	setAddress,
	setPhone,
	setReferral,
	setContact,
	setReason,
	setRequest,
	toggleAppt,
	setApptComment,
	toggleDeclined,
	setDeclinedComment,
	toggleNoAnswer,
	setFirstAttempt,
	setFirstAttemptBy,
	setSecondAttempt,
	setSecondAttemptBy,
	setThirdAttempt,
	setThirdAttemptBy,
	toggleClosedReferral,
	setReferralFollowedUpBy,
	setReferralFollowedUpDate,
} = referralForm.actions;

export default referralForm.reducer;