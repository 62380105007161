import { Wrapper, Image, Title, Form, Logo } from './Background.style';
import WidgetList from '../WidgetList/WidgetList';
import { Outlet } from 'react-router-dom';
import './Background.style.jsx';

const Background = () => {
    return (
        <Wrapper>
            <Image>
                <Title>APIA <br /> Web Portal</Title>
                <WidgetList />
            </Image>
            <Form>
                <div>
                    <Logo />
                </div>
                <Outlet />
            </Form>
        </Wrapper>
    );
};

export default Background;