import { Wrapper, Item, Label, TextBox, SelectBox, Buttons } from '../../BasicInformation.style';
import { EMPLOYMENT, MAX_WIDTH, ZIP_MASK, GRID_XS } from '../../../../../../utils/constants';
import { onKeyDownHandle } from '../../../../../../utils/helper';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
} from 'devextreme-react/validator';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200 && width <= 1500): return 3;
		case (width > 1500): return 2;
		default: return 12;
	};
};

export const Employment = ({
	showButton,

	states,
	employments,
	primaryIncomeSources,

	getEntityPatientProfileEmployment,
	onChangeFunc,

	onCancelEmployment,
	onSaveEmployment,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);
	return (
		<Wrapper id={EMPLOYMENT} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileEmployment('loading')}
				position={{ of: `#${EMPLOYMENT}` }} />
			{
				!getEntityPatientProfileEmployment('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="Employment Status *">
							<span>
								<Label>Employment Status:</Label>
								<SelectBox
									label="Employment Status *"
									defaultValue={getEntityPatientProfileEmployment('employment')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={employments}
									onValueChanged={(e) => onChangeFunc('Employment', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-employment' }}
								>
									<Validator validationGroup={EMPLOYMENT}>
										<RequiredRule
											message="Employment is required"
										/>
									</Validator>
								</SelectBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Employer">
							<span>
								<Label>Employer:</Label>
								<TextBox
									label="Employer"
									value={getEntityPatientProfileEmployment('employer')}
									onValueChanged={(e) => onChangeFunc('Employer', e, true)}
									maxLength={140}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-employer' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Address">
							<span>
								<Label>Address:</Label>
								<TextBox
									label="Address"
									value={getEntityPatientProfileEmployment('address')}
									onValueChanged={(e) => onChangeFunc('Address', e, true)}
									maxLength={150}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="State">
							<span>
								<Label>State:</Label>
								<SelectBox
									label="State"
									defaultValue={getEntityPatientProfileEmployment('state')}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={(e) => onChangeFunc('State', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="City">
							<span>
								<Label>City:</Label>
								<TextBox
									label="City"
									value={getEntityPatientProfileEmployment('city')}
									onValueChanged={(e) => onChangeFunc('City', e, true)}
									maxLength={20}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-city' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Zip">
							<span>
								<Label>Zip:</Label>
								<TextBox
									label="Zip"
									value={getEntityPatientProfileEmployment('zip')}
									onValueChanged={(e) => onChangeFunc('Zip', e)}
									labelMode="hidden"
									stylingMode="underlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Primary Income Source">
							<span>
								<Label>Primary Income Source:</Label>
								<SelectBox
									label="Primary Income Source"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={getEntityPatientProfileEmployment('primaryIncomeSource')}
									dataSource={primaryIncomeSources}
									onValueChanged={(e) => onChangeFunc('PrimaryIncomeSource', e)}
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-primaryIncomeSource' }}
								/>
							</span>
						</Tooltip>
					</Item>
					{
						getEntityPatientProfileEmployment('primaryIncomeSource') === (primaryIncomeSources.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Specify Primary Income Source">
								<span>
									<Label>Specified Income Source:</Label>
									<TextBox
										label="Specify Primary Income Source"
										value={getEntityPatientProfileEmployment('otherPrimaryIncomeSource')}
										onValueChanged={(e) => onChangeFunc('OtherPrimaryIncomeSource', e)}
										maxLength={64}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-otherPrimaryIncomeSources' }} />
								</span>
							</Tooltip>
						</Item>
					}
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={EMPLOYMENT}
								onClick={onSaveEmployment} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelEmployment} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};