import { useParams } from 'react-router-dom';
import { setReason, setOtherReferralInfo, setReferralInfo, toggleContactedByPhysicalExam, toggleRefferalForMedicalRN } from "../../../../../store/forms/newPatientRegistration/referralInfo/slice";
import { selectLoading, selectReason, selectContactedByPhysicalExam, selectOtherReferralInfo, selectReferralInfo, selectRefferalForMedicalRN } from "../../../../../store/forms/newPatientRegistration/referralInfo/selectors";
import { completeReferralInfo } from "../../../../../store/forms/newPatientRegistration/slice";

import { getReferralInfo, saveReferralInfo, getReferralInfoByID, saveReferralInfoByID } from "../../../../../store/forms/newPatientRegistration/referralInfo/middlewares";
import { getReferralStatuses } from "../../../../../store/lookups/referralStatus/middlewares";
import { selectReferralStatuses } from "../../../../../store/lookups/referralStatus/selectors";

import { validateGroup } from "../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { ReferralInfo } from "./ReferralInfo"
import { useEffect } from "react";
//import { answers } from '../HealthAndTreatment/HealthAndTreatmentContainer';

const answers = [
	{
		"Oid": true,
		"Name": "Yes",
	}, {
		"Oid": false,
		"Name": "No",
	},
];

export const ReferralInfoContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const referralStatuses = useSelector(selectReferralStatuses);

    const reason = useSelector(selectReason);
    const referralInfo = useSelector(selectReferralInfo);
    const otherReferralInfo = useSelector(selectOtherReferralInfo);
    const refferalForMedicalRN = useSelector(selectRefferalForMedicalRN);
    const contactedByPhysicalExam = useSelector(selectContactedByPhysicalExam);

    useEffect(() => {
        dispatch(id ? getReferralInfoByID(id) : getReferralInfo());
        dispatch(getReferralStatuses());
    }, [dispatch, id]);

    const onChangeReason = (event) => {
        dispatch(setReason(event.value.trim()));
    }

    const onChangeReferralInfo = (event) => {
        dispatch(setReferralInfo(event.value));
    }

    const onChangeOtherReferralInfo = (event) => {
        dispatch(setOtherReferralInfo(event.value.trim()));
    }

    const onToggleRefferalForMedicalRN = () => {
        dispatch(toggleRefferalForMedicalRN());
    }

    const onToggleContactedByPhysicalExam = () => {
        dispatch(toggleContactedByPhysicalExam());
    }

    const onSaveReferralInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveReferralInfoByID(id) : saveReferralInfo());
            dispatch(completeReferralInfo(true));
        }
    }

    const onSaveAndContinueReferralInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveReferralInfoByID(id) : saveReferralInfo());
            dispatch(completeReferralInfo(true));
            onClickNext();
        }
    }

    return (
        <ReferralInfo
            loading={loading}

            referralStatuses={referralStatuses}
            answers={answers}
            reason={reason}
            referralInfo={referralInfo}
            otherReferralInfo={otherReferralInfo}
            refferalForMedicalRN={refferalForMedicalRN}
            contactedByPhysicalExam={contactedByPhysicalExam}

            onChangeReason={onChangeReason}
            onChangeReferralInfo={onChangeReferralInfo}
            onChangeOtherReferralInfo={onChangeOtherReferralInfo}
            onToggleRefferalForMedicalRN={onToggleRefferalForMedicalRN}
            onToggleContactedByPhysicalExam={onToggleContactedByPhysicalExam}

            onSaveReferralInfo={onSaveReferralInfo}
            onSaveAndContinueReferralInfo={onSaveAndContinueReferralInfo}
        />
    );
}