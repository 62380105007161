import { Wrapper, CheckboxGrid } from '../../../../../components/Main/Main.style';
import { GRID_XS, MAX_WIDTH, TRIBAL_AFFILIATION } from '../../../../../utils/constants';
import { NAME_RULE } from '../../../../../utils/rules';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	PatternRule,
} from 'devextreme-react/validator';

export const TribalAffiliation = ({
	loading,

	ethnicities,
	races,
	languages,

	corporation,
	enrollment,
	race,
	raceAlaska,
	raceAmerican,
	language,
	otherLanguage,
	CIB,
	CIBCard,
	ethnicity,
	translator,

	onChangeCorporation,
	onChangeEnrollment,
	onChangeEthnicity,
	onChangeRace,
	onChangeRaceAlaska,
	onChangeRaceAmerican,
	onChangeLanguage,
	onChangeOtherLanguage,
	onToggleCIB,
	onToggleCIBCard,
	onToggleTranslator,

	onSaveTribalAffiliation,
	onSaveAndContinueTribalAffiliation,
}) => {
	return (
		<Wrapper id={TRIBAL_AFFILIATION} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${TRIBAL_AFFILIATION}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Tribal/Native Corporation">
							<>
								<TextBox
									label="Tribal/Native Corporation"
									value={corporation}
									onValueChanged={onChangeCorporation}
									maxLength={140}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-corporation' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Tribal Enrollment #">
							<>
								<TextBox
									label="Tribal Enrollment #"
									value={enrollment}
									onValueChanged={onChangeEnrollment}
									maxLength={140}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-enrollment' }} />
							</>
						</Tooltip>
					</Grid>
					<CheckboxGrid item xs={GRID_XS}>
						<Tooltip title="CIB">
							<>
								<CheckBox
									text="CIB"
									value={CIB}
									onValueChanged={onToggleCIB} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<CheckboxGrid item xs={GRID_XS}>
						<Tooltip title="CIB Card in Medical Record?">
							<>
								<CheckBox
									text="CIB Card in Medical Record?"
									value={CIBCard}
									onValueChanged={onToggleCIBCard} />
							</>
						</Tooltip>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Ethnicity">
							<>
								<SelectBox
									label="Ethnicity"
									defaultValue={ethnicity}
									dataSource={ethnicities}
									onValueChanged={onChangeEthnicity}
									valueExpr="Oid"
									displayExpr="Name"
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-ethnicity' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Race">
							<>
								<SelectBox
									label="Race"
									defaultValue={race}
									dataSource={races}
									onValueChanged={onChangeRace}
									valueExpr="Oid"
									displayExpr="Name"
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-race' }}
								/>
							</>
						</Tooltip>
					</Grid>
					{
						!![race] && [race].includes(races.find((e) => e?.Name === "Alaska Native")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Alaska Native Group">
								<>
									<TextBox
										label="Alaska Native Group"
										value={raceAlaska}
										onValueChanged={onChangeRaceAlaska}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-raceAlaska' }}
									>
										<Validator validationGroup={TRIBAL_AFFILIATION}>
											<PatternRule
												message="Don't use numbers"
												pattern={NAME_RULE}
											/>
										</Validator>
									</TextBox>
								</>
							</Tooltip>
						</Grid>
					}
					{
						!![race] && [race].includes(races.find((e) => e?.Name === "American Indian")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="American Indian Group">
								<>
									<TextBox
										label="American Indian Group"
										value={raceAmerican}
										onValueChanged={onChangeRaceAmerican}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-raceAmerican' }}
									>
										<Validator validationGroup={TRIBAL_AFFILIATION}>
											<PatternRule
												message="Don't use numbers"
												pattern={NAME_RULE}
											/>
										</Validator>
									</TextBox>
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="Primary Language if not English">
							<>
								<SelectBox
									label="Primary Language if not English"
									defaultValue={Array.isArray(language) ? language[0] : language}
									dataSource={languages}
									onValueChanged={onChangeLanguage}
									valueExpr="Oid"
									displayExpr="Name"
									showDropDownButton={true}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-languages' }}
								/>
							</>
						</Tooltip>
					</Grid>
					{
						!![language] && [language].includes(languages.find((e) => e?.Name === "Other")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Specify Primary Language">
								<>
									<TextBox
										label="Specify Primary Language"
										value={otherLanguage}
										onValueChanged={onChangeOtherLanguage}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-otherLanguage' }}
									>
										<Validator validationGroup={TRIBAL_AFFILIATION}>
											<PatternRule
												message="Don't use numbers"
												pattern={NAME_RULE}
											/>
										</Validator>
									</TextBox>
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="Translator/Interpreter Required">
							<>
								<CheckBox
									text="Translator/Interpreter Required"
									value={translator}
									onValueChanged={onToggleTranslator} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={TRIBAL_AFFILIATION}
							onClick={onSaveTribalAffiliation} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={TRIBAL_AFFILIATION}
							onClick={onSaveAndContinueTribalAffiliation} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	);
};