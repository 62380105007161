export const selectLoading = state => state.newPatientRegistrationEmployment.loading;

export const selectEmployment = state => state.newPatientRegistrationEmployment.employment;
export const selectEmployer = state => state.newPatientRegistrationEmployment.employer;
export const selectAddress = state => state.newPatientRegistrationEmployment.address;
export const selectCity = state => state.newPatientRegistrationEmployment.city;
export const selectState = state => state.newPatientRegistrationEmployment.state;
export const selectZip = state => state.newPatientRegistrationEmployment.zip;
export const selectPrimaryIncomeSource = state => state.newPatientRegistrationEmployment.primaryIncomeSource;
export const selectOtherPrimaryIncomeSource = state => state.newPatientRegistrationEmployment.otherPrimaryIncomeSource;
