import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px 20px 20px',
}));

export const Panel = styled('div')(() => ({
  padding: '10px',
  order: '0',
}));

export const Title = styled('h1')(() => ({
  color: 'var(--dark-blue-color)',
  fontFamily: 'Montserrat',
  lineHeight: '20px',
  fontWeight: '600',
  fontSize: '16px',
  margin: '0px',
  padding: '0 24px',
}));