import { setAddress, setAllMembers, toggleBenefitsVA, setCity, setCompanyInsurance, setDenaliKid, setDenaliKidExpDate, setDenaliKidNumber, setDOB, toggleDoNotOtherInsurance, setEffectiveDate, setPolicyHolderGroupNumber, setOtherInsuranceGroupNumber, setMedicaid, setMedicaidExpDate, setMedicaidNumber, setMedicare, setMedicareExpDate, setMedicareNumber, setOtherInsurance, setPhone, setPolicyHolder, setPolicyHolderNumber, setOtherInsuranceNumber, togglePrivateInsurance, setRelationship, setSelfPay, setServiceBranch, setServiceExitDate, setState, setZip, setTotalIncome, toggleVeteran, toggleVietnamService, setServiceEntryDate, setMedicareEffDate, setDenaliKidEffDate, setMedicaidEffDate } from "../../../../../../store/users/patientProfile/basicInformation/insuranceInfo/slice";
import { selectAddress, selectTotalIncome, selectMedicare, selectMedicaid, selectDenaliKid, selectMedicaidExpDate, selectMedicareExpDate, selectMedicareNumber, selectAllMembers, selectBenefitsVA, selectCity, selectCompanyInsurance, selectDenaliKidExpDate, selectDenaliKidNumber, selectDOB, selectDoNotOtherInsurance, selectEffectiveDate, selectMedicaidNumber, selectPhone, selectPolicyHolderGroupNumber, selectPolicyHolder, selectPolicyHolderNumber, selectPrivateInsurance, selectRelationship, selectSelfPay, selectServiceBranch, selectServiceEntryDate, selectServiceExitDate, selectState, selectZip, selectVeteran, selectVietnamService, selectOtherInsurance, selectOtherInsuranceNumber, selectotherInsuranceGroupNumber, selectMedicareEffDate, selecMedicaidEffDate, selectDenaliKidEffDate, selectLoading } from "../../../../../../store/users/patientProfile/basicInformation/insuranceInfo/selectors";

import { selectTotalHouseholdIncomes } from "../../../../../../store/lookups/totalHouseholdIncome/selectors";
import { selectDenaliKidStatuses } from "../../../../../../store/lookups/denaliKidStatus/selectors";
import { selectMedicaidStatuses } from "../../../../../../store/lookups/medicaidStatus/selectors";
import { selectMedicareStatuses } from "../../../../../../store/lookups/medicareStatus/selectors";
import { selectStates } from "../../../../../../store/lookups/state/selectors";

import { getInsuranceInfoById, saveInsuranceInfoById } from "../../../../../../store/users/patientProfile/basicInformation/insuranceInfo/middlewares";
import { getTotalHouseholdIncomes } from "../../../../../../store/lookups/totalHouseholdIncome/middlewares";
import { getDenaliKidStatuses } from "../../../../../../store/lookups/denaliKidStatus/middlewares";
import { getMedicaidStatuses } from "../../../../../../store/lookups/medicaidStatus/middlewares";
import { getMedicareStatuses } from "../../../../../../store/lookups/medicareStatus/middlewares";
import { getState } from "../../../../../../store/lookups/state/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { InsuranceInfo } from "./InsuranceInfo";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const InsuranceInfoContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const totalHouseholdIncomes = useSelector(selectTotalHouseholdIncomes);
    const denaliKidStatuses = useSelector(selectDenaliKidStatuses);
    const medicaidStatuses = useSelector(selectMedicaidStatuses);
    const medicareStatuses = useSelector(selectMedicareStatuses);
    const states = useSelector(selectStates);

    const familyMembers = useSelector(selectAllMembers);
    const totalHouseholdIncome = useSelector(selectTotalIncome);
    const medicareStatus = useSelector(selectMedicare);
    const medicareEffDate = useSelector(selectMedicareEffDate);
    const medicareExpDate = useSelector(selectMedicareExpDate);
    const medicareNumber = useSelector(selectMedicareNumber);
    const medicaidStatus = useSelector(selectMedicaid);
    const medicaidEffDate = useSelector(selecMedicaidEffDate);
    const medicaidExpDate = useSelector(selectMedicaidExpDate);
    const medicaidNumber = useSelector(selectMedicaidNumber);
    const denaliKidStatus = useSelector(selectDenaliKid);
    const denaliKidEffDate = useSelector(selectDenaliKidEffDate);
    const denaliKidExpDate = useSelector(selectDenaliKidExpDate);
    const denaliKidNumber = useSelector(selectDenaliKidNumber);
    const privateInsurance = useSelector(selectPrivateInsurance);
    const companyInsurance = useSelector(selectCompanyInsurance);
    const policyHolder = useSelector(selectPolicyHolder);
    const policyHolderNumber = useSelector(selectPolicyHolderNumber);
    const policyHolderGroupNumber = useSelector(selectPolicyHolderGroupNumber);
    const otherInsurance = useSelector(selectOtherInsurance);
    const otherInsuranceNumber = useSelector(selectOtherInsuranceNumber);
    const otherInsuranceGroupNumber = useSelector(selectotherInsuranceGroupNumber);
    const veteran = useSelector(selectVeteran);
    const benefitsVA = useSelector(selectBenefitsVA);
    const serviceBranch = useSelector(selectServiceBranch);
    const serviceEntryDate = useSelector(selectServiceEntryDate);
    const serviceExitDate = useSelector(selectServiceExitDate);
    const vietnamService = useSelector(selectVietnamService);
    const doNotOtherInsurance = useSelector(selectDoNotOtherInsurance);
    const selfPay = useSelector(selectSelfPay);
    const relationship = useSelector(selectRelationship);
    const DOB = useSelector(selectDOB);
    const phone = useSelector(selectPhone);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const effectiveDate = useSelector(selectEffectiveDate);

    useEffect(() => {
        dispatch(getInsuranceInfoById(id));
        dispatch(getTotalHouseholdIncomes());
        dispatch(getDenaliKidStatuses());
        dispatch(getMedicaidStatuses());
        dispatch(getMedicareStatuses());
        dispatch(getState());
    }, [dispatch, id]);

    const setMembers = (event, newValue) => {
        event.cancel = true;
        event.component.cancelEditData();
        dispatch(setAllMembers(newValue));
        setShowButton(true);
    };

    const onRowInsertingFamilyMembers = (event) => {
        const newValue = [...familyMembers, {
            id: new Date().toISOString(),
            name: event.data?.name,
            relationship: event.data?.relationship,
            age: event.data?.age
        }];
        setMembers(event, newValue);
    }

    const onRowRemovingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = newValue.findIndex(el => el.id === event.data?.id);
        newValue.splice(newId, 1);
        setMembers(event, newValue);
    }

    const onRowUpdatingFamilyMembers = (event) => {
        let newValue = [...familyMembers];
        const newId = familyMembers.findIndex(el => el.id === event.oldData?.id);
        newValue[newId] = { ...familyMembers[newId], ...event.newData };
        setMembers(event, newValue);
    }

    const FN_TO_CALL = {
		Income: setTotalIncome,
        Medicare: setMedicare,
        MedicareEffDate: setMedicareEffDate,
        MedicareExpDate: setMedicareExpDate,
        MedicareNumber: setMedicareNumber,
        Medicaid: setMedicaid,
        MedicaidEffDate: setMedicaidEffDate,
        MedicaidExpDate: setMedicaidExpDate,
        MedicaidNumber: setMedicaidNumber,
        DenaliKid: setDenaliKid,
        DenaliKidEffDate: setDenaliKidEffDate,
        DenaliKidExpDate: setDenaliKidExpDate,
        DenaliKidNumber: setDenaliKidNumber,
        PrivateInsurance: togglePrivateInsurance,
        CompanyInsurance: setCompanyInsurance,
        PolicyHolder: setPolicyHolder,
        PolicyHolderNumber: setPolicyHolderNumber,
        PolicyHolderGroupNumber: setPolicyHolderGroupNumber,
        OtherInsurance: setOtherInsurance,
        OtherInsuranceNumber: setOtherInsuranceNumber,
        OtherInsuranceGroupNumber: setOtherInsuranceGroupNumber,
        Veteran: toggleVeteran,
        BenefitsVA: toggleBenefitsVA,
        ServiceBranch: setServiceBranch,
        ServiceEntryDate: setServiceEntryDate,
        ServiceExitDate: setServiceExitDate,
        VietnamService: toggleVietnamService,
        DoNotOtherInsurance: toggleDoNotOtherInsurance,
        SelfPay: setSelfPay,
        Relationship: setRelationship,
        DOB: setDOB,
        Phone: setPhone,
        Address: setAddress,
        City: setCity,
        State: setState,
        Zip: setZip,
        EffectiveDate: setEffectiveDate,
	};

    const onChangeFunc = (name, event = null, trim = false) => {
		if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
		else dispatch(FN_TO_CALL[name]());
		setShowButton(true);
	};

    const onCancelInsuranceInfo = () => {
        dispatch(getInsuranceInfoById(id));
        setShowButton(false);
    }

    const onSaveInsuranceInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveInsuranceInfoById(id));
            setShowButton(false);
        }
    }

    return (
        <InsuranceInfo
            loading={loading}
            showButton={showButton}

            totalHouseholdIncomes={totalHouseholdIncomes}
            denaliKidStatuses={denaliKidStatuses}
            medicaidStatuses={medicaidStatuses}
            medicareStatuses={medicareStatuses}
            states={states}

            familyMembers={familyMembers}
            totalHouseholdIncome={totalHouseholdIncome}
            medicareStatus={medicareStatus}
            medicareEffDate={medicareEffDate}
            medicareExpDate={medicareExpDate}
            medicareNumber={medicareNumber}
            medicaidStatus={medicaidStatus}
            medicaidEffDate={medicaidEffDate}
            medicaidExpDate={medicaidExpDate}
            medicaidNumber={medicaidNumber}
            denaliKidStatus={denaliKidStatus}
            denaliKidEffDate={denaliKidEffDate}
            denaliKidExpDate={denaliKidExpDate}
            denaliKidNumber={denaliKidNumber}
            privateInsurance={privateInsurance}
            companyInsurance={companyInsurance}
            policyHolder={policyHolder}
            policyHolderNumber={policyHolderNumber}
            policyHolderGroupNumber={policyHolderGroupNumber}
            otherInsurance={otherInsurance}
            otherInsuranceNumber={otherInsuranceNumber}
            otherInsuranceGroupNumber={otherInsuranceGroupNumber}
            veteran={veteran}
            benefitsVA={benefitsVA}
            serviceBranch={serviceBranch}
            serviceEntryDate={serviceEntryDate}
            serviceExitDate={serviceExitDate}
            vietnamService={vietnamService}
            doNotOtherInsurance={doNotOtherInsurance}
            selfPay={selfPay}
            relationship={relationship}
            DOB={DOB}
            phone={phone}
            address={address}
            city={city}
            state={state}
            zip={zip}
            effectiveDate={effectiveDate}

            onRowInsertingFamilyMembers={onRowInsertingFamilyMembers}
            onRowRemovingFamilyMembers={onRowRemovingFamilyMembers}
            onRowUpdatingFamilyMembers={onRowUpdatingFamilyMembers}

            onChangeFunc={onChangeFunc}

            onCancelInsuranceInfo={onCancelInsuranceInfo}
            onSaveInsuranceInfo={onSaveInsuranceInfo}
        />
    )
};