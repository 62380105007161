import { getEmployment, postEmployment, getEmploymentByID, postEmploymentByID } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    employment: null,
    employer: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    primaryIncomeSource: "",
    otherPrimaryIncomeSource: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationEmployment = createSlice({
    name: "newPatientRegistrationEmployment",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setEmployment: (state, action) => {
            state.employment = action.payload;
            return state;
        },
        setEmployer: (state, action) => {
            state.employer = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setPrimaryIncomeSource: (state, action) => {
            state.primaryIncomeSource = action.payload;
            return state;
        },
        setOtherPrimaryIncomeSource: (state, action) => {
            state.otherPrimaryIncomeSource = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmployment.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postEmployment.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getEmployment.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.employment = action.payload?.employmentStatus;
                state.employer = action.payload?.employer;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.primaryIncomeSource = action.payload?.primatyIncomeSource;
                state.otherPrimaryIncomeSource = action.payload?.source;

                state.loading = false;
            })
            .addCase(postEmployment.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Employment saved successfully");
            })
            .addCase(getEmployment.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Employment: ${action.error.message}`);
            })
            .addCase(postEmployment.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Employment: ${action.error.message}`);
            })
            .addCase(getEmploymentByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getEmploymentByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.employment = action.payload?.employmentStatus;
                state.employer = action.payload?.employer;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.otherPrimaryIncomeSource = action.payload?.source;
                state.primaryIncomeSource = action.payload?.primatyIncomeSource;

                state.loading = false;
            })
            .addCase(getEmploymentByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;
                showToast('error', `Get Employment: ${action.error.message}`);
            })
            .addCase(postEmploymentByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(postEmploymentByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;
                showToast('success', 'Employment saved successfully');
            })
            .addCase(postEmploymentByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;
                showToast('error', `Set Employment: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setEmployment,
    setEmployer,
    setAddress,
    setCity,
    setState,
    setZip,
    setPrimaryIncomeSource,
    setOtherPrimaryIncomeSource,
} = newPatientRegistrationEmployment.actions;

export default newPatientRegistrationEmployment.reducer;
