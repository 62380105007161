import { BASE_URL } from "../../utils/constants";
import { decodeToken } from 'react-jwt';

export const createTokenProvider = () => {
    let refreshTokenRequest = null;
    let observers = [];

    const getTokenData = () => JSON.parse(localStorage.getItem('AUTH_TOKEN_DATA')) || null;

    const isLoggedIn = () => !!getTokenData();

    /**
     * @param {*} param: Id, UserLogin, email, sub, jti, role, nbf, exp, iat
     */
    const getUserData = (param = 'Id') => decodeToken(getTokenData().accessToken)[param];

    const subscribe = (observer) => {
        observers.push(observer);
    };

    const unsubscribe = (observer) => {
        observers = observers.filter(_observer => _observer !== observer);
    };

    const notify = () => {
        const isLogged = isLoggedIn();
        observers.forEach(observer => observer(isLogged));
    };

    const isExpired = (exp) => {
        if (!exp) return false;
        return Date.now() > exp - 1000;
    };

    const getExpirationDate = (token) => {
        if (token) {
            const [, payload] = token.split('.');
            const { exp: expires } = JSON.parse(window.atob(payload));
            if (typeof expires === 'number') {
                return expires * 1000;
            }
        }
        return null;
    };

    const refreshToken = async () => {
        let _token = getTokenData();

        let response = await fetch(`${BASE_URL}/AuthManagement/RefreshToken`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "token": _token.accessToken,
                "refreshToken": _token.refreshToken,
            })
        })

        if (response.ok) {
            let result = await response.json();

            _token = { 'accessToken': result.token, 'refreshToken': result.refreshToken };
            setToken(_token);
        } else {
            console.log("Ошибка HTTP: " + refreshTokenRequest.status);
            setToken(null);
        }

        return _token;
    };

    const getToken = async () => {
        let _token = getTokenData();
        if (!_token) return null;

        if (isExpired(getExpirationDate(_token.accessToken))) {
            if (refreshTokenRequest === null) {
                refreshTokenRequest = refreshToken();
            }

            _token = await refreshTokenRequest;

            refreshTokenRequest = null;
        }

        return _token?.accessToken;
    };

    const setToken = (token) => {
        if (token) {
            localStorage.setItem('AUTH_TOKEN_DATA', JSON.stringify(token));
        } else {
            localStorage.removeItem('AUTH_TOKEN_DATA');
        }
        notify();
    };


    return {
        refreshToken,
        getToken,
        isLoggedIn,
        setToken,
        subscribe,
        unsubscribe,
        getUserData,
    };
};