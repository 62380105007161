import { EMERGENCY_CONTACT, GRID_MD, GRID_SM, GRID_XS, MAX_WIDTH, PHONE_MASK, ZIP_MASK } from '../../../../../utils/constants';
import { NAME_RULE } from '../../../../../utils/rules';
import { onKeyDownHandle } from '../../../../../utils/helper';
import { Wrapper } from '../../../../../components/Main/Main.style';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
} from 'devextreme-react/validator';

export const EmergencyContact = ({
	loading,
	states,
	contact,
	relationship,
	address,
	city,
	state,
	zip,
	phone,
	email,

	onChangeContact,
	onChangeRelationship,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangePhone,
	onChangeEmail,

	onSaveEmergencyContact,
	onSaveAndContinueEmergencyContact,
}) => {
	return (
		<Wrapper id={EMERGENCY_CONTACT} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${EMERGENCY_CONTACT}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid></Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Emergency Contact *">
							<>
								<TextBox
									label="Emergency Contact *"
									value={contact}
									onValueChanged={onChangeContact}
									maxLength={120}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-contact' }}
								>
									<Validator validationGroup={EMERGENCY_CONTACT}>
										<RequiredRule
											message="Emergency Contact is required"
										/>
										<PatternRule
											message="Do not use digits in the Emergency Contact"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Relationship">
							<>
								<TextBox
									label="Relationship"
									value={relationship}
									onValueChanged={onChangeRelationship}
									maxLength={60}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-relationship' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={city}
									onValueChanged={onChangeCity}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={zip}
									onValueChanged={onChangeZip}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Phone *">
							<>
								<TextBox
									label="Phone *"
									value={phone}
									onValueChanged={onChangePhone}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }}>
									<Validator validationGroup={EMERGENCY_CONTACT}>
										<RequiredRule
											message="Phone is required"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Email">
							<>
								<TextBox
									label="Email"
									value={email}
									onValueChanged={onChangeEmail}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}
								>
									<Validator validationGroup={EMERGENCY_CONTACT}>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={EMERGENCY_CONTACT}
							onClick={onSaveEmergencyContact} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={EMERGENCY_CONTACT}
							onClick={onSaveAndContinueEmergencyContact} />
					</Grid>
				</Grid>
			}
		</Wrapper>
	);
};