import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getIdAndInsuranceCard = createAsyncThunk(
    "getIdAndInsuranceCard",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/InsuranceCardAndId");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getIdAndInsuranceCardByID = createAsyncThunk(
	"getIdAndInsuranceCardByID",
	async (patienID, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + `/InsuranceCardAndId/${patienID}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setIdAndInsuranceCard = createAsyncThunk(
    "setIdAndInsuranceCard",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + "/InsuranceCardAndId", data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setIdAndInsuranceCardByID = createAsyncThunk(
    "setIdAndInsuranceCardByID",
    async ({ patienID, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/InsuranceCardAndId/${patienID}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveIdAndInsuranceCard = () => (dispatch, getState) => {
    const store = getState().idAndInsuranceCard;

    const data = {
        "firstName": store.firstName,
        "middleName": store.middleName,
        "lastName": store.lastName,
        "date": dateFormatForApi(store.date),
        "email": store.email,
        "frontId": store.frontId,
        "backId": store.backId,
        "frontCard": store.frontCard,
        "backCard": store.backCard,
        "cib": store.cib,
        "tribalCard": store.tribalCard,
        "tribalEnrollment": store.tribalEnrollment,
    }

    dispatch(setIdAndInsuranceCard(data));
};

export const saveIdAndInsuranceCardByID = (patienID) => (dispatch, getState) => {
    const store = getState().idAndInsuranceCard;

    const data = {
        "firstName": store.firstName,
        "middleName": store.middleName,
        "lastName": store.lastName,
        "date": dateFormatForApi(store.date),
        "email": store.email,
        "frontId": store.frontId,
        "backId": store.backId,
        "frontCard": store.frontCard,
        "backCard": store.backCard,
        "cib": store.cib,
        "tribalCard": store.tribalCard,
        "tribalEnrollment": store.tribalEnrollment,
    }

    dispatch(setIdAndInsuranceCardByID({ patienID, data }));
};