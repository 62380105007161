import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const BtnMatetialCustom = styled(Button)(() => ({
	display: 'inline-flex',
	alignContent: 'center',
	gap: '7px',
	fontSize: '12px',
	textTransform: 'uppercase',
	letterSpacing: '.04em'
}));

export const Panel = styled('div')(() => ({
	padding: '10px',
	order: '0',
}));

export const Title = styled('h1')(() => ({
	color: 'var(--dark-blue-color)',
	fontFamily: 'Montserrat',
	lineHeight: '20px',
	fontWeight: '600',
	fontSize: '16px',
	margin: '0px',
	padding: '0 24px',
}));

export const Wrapper = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	padding: '30px 20px 20px',
}));