import { Wrapper, Grid, Item, Label, DateBox, SelectBox, NumberBox, Buttons } from '../../BasicInformation.style';
import { CURRENT_DATE, HEALTH_INFO, MAX_WIDTH, MIN_DATE, GRID_XS } from '../../../../../../utils/constants';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
} from 'devextreme-react/validator';

export const HealthAndTreatment = ({
	showButton,

	answers,
	pregnantStatuses,
	hospitalInSixMonths,
	hospitalResidentialTreatment,

	getEntityPatientProfileHealthAndTreatment,
	onChangeFunc,

	onCancelHealthAndTreatment,
	onSaveHealthAndTreatment,
}) => {
	return (
		<Wrapper id={HEALTH_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileHealthAndTreatment('loading')}
				position={{ of: `#${HEALTH_INFO}` }} />
			{
				!getEntityPatientProfileHealthAndTreatment('loading') &&
				<Grid container spacing={2}>
					<Item item xs={GRID_XS}>
						<Tooltip title="If Female, Are you pregnant?">
							<Label>If Female, Are you pregnant:</Label>
						</Tooltip>
						<SelectBox
							label="If Female, Are you pregnant?"
							defaultValue={getEntityPatientProfileHealthAndTreatment('pregnantStatus')}
							dataSource={pregnantStatuses}
							onValueChanged={e => onChangeFunc('PregnantStatus', e)}
							showDropDownButton={false}
							valueExpr="Oid"
							displayExpr="Name"
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-pregnantStatus' }}
						/>
					</Item>
					<Item item sm={GRID_XS}>
						<Tooltip title="Are you an IV Drug User?">
							<Label>Are you an IV Drug User:</Label>
						</Tooltip>
						<SelectBox
							label="Are you an IV Drug User?"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileHealthAndTreatment('drugUser')}
							dataSource={answers}
							onValueChanged={e => onChangeFunc('DrugUser', e)}
							showDropDownButton={false}
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-drugUser' }}
						/>
					</Item>
					<Item item sm={GRID_XS}>
						<Tooltip title="Date of last physical exam?">
							<Label>Date of last physical exam:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Date of last physical exam?"
							value={dateFormatFromApi(getEntityPatientProfileHealthAndTreatment('physicalExam'))}
							onValueChanged={e => onChangeFunc('PhysicalExam', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={HEALTH_INFO}>
								<RangeRule
									message="Date of last physical exam cannot be later than the current date and earlier than 1900"
									min={MIN_DATE}
									max={CURRENT_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS}>
						<Tooltip title="Have you ever been in the hospital or residential treatment for substance abuse?">
							<Label>Have you ever been in the hospital or residential treatment for substance abuse:</Label>
						</Tooltip>
						<SelectBox
							label="Have you ever been in the hospital or residential treatment for substance abuse?"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileHealthAndTreatment('haveEverBeenInHospital')}
							dataSource={hospitalResidentialTreatment}
							onValueChanged={e => onChangeFunc('HaveEverBeenInHospital', e)}
							showDropDownButton={false}
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-haveEverBeenInHospital' }}
						/>
					</Item>
					<Item item sm={GRID_XS}>
						<Tooltip title="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse?">
							<Label>Have you been in the hospital in the last 6 months for medical problems cause by substance abuse:</Label>
						</Tooltip>
						<SelectBox
							label="Have you been in the hospital in the last 6 months for medical problems cause by substance abuse?"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileHealthAndTreatment('haveBeenInHospitalLast6Months')}
							dataSource={hospitalInSixMonths}
							onValueChanged={e => onChangeFunc('HaveBeenInHospitalLast6Months', e)}
							showDropDownButton={false}
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-haveBeenInHospitalLast6Months' }}
						/>
					</Item>
					<Item item sm={GRID_XS}>
						<Tooltip title="How many inpatient mental health hospitalizations have you had? (If none, enter 0)">
							<Label>How many inpatient mental health hospitalizations have you had? (If none, enter 0):</Label>
						</Tooltip>
						<NumberBox
							label="How many inpatient mental health hospitalizations have you had? (If none, enter 0)"
							value={getEntityPatientProfileHealthAndTreatment('howManyMentalHealthHospitals') ? getEntityPatientProfileHealthAndTreatment('howManyMentalHealthHospitals') : 0}
							onValueChanged={e => onChangeFunc('HowManyMentalHealthHospitals', e)}
							labelMode="hidden"
							stylingMode="underlined"
							showSpinButtons={true}
							min={0} />
					</Item>
					<Item item sm={GRID_XS}>
						<Tooltip title="Legal History: Number of Arrests in the past 30 days (If none, enter 0)">
							<Label>Legal History: Number of Arrests in the past 30 days (If none, enter 0):</Label>
						</Tooltip>
						<NumberBox
							label="Legal History: Number of Arrests in the past 30 days (If none, enter 0)"
							value={getEntityPatientProfileHealthAndTreatment('numberOfArrests') ? getEntityPatientProfileHealthAndTreatment('numberOfArrests') : 0}
							onValueChanged={e => onChangeFunc('NumberOfArrests', e)}
							labelMode="hidden"
							stylingMode="underlined"
							showSpinButtons={true}
							min={0} />
					</Item>
					<Item item xs={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={HEALTH_INFO}
								onClick={onSaveHealthAndTreatment} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelHealthAndTreatment} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};