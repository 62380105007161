import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { selectSignPicture, selectLoading, selectName, selectDate } from '../../../store/forms/noticeOfPrivacyPractices/selectors';
import { setSignPicture, setLoading, setDate, setName } from '../../../store/forms/noticeOfPrivacyPractices/slice';
import { getNoticeOfPrivacyPractices, saveNoticeOfPrivacyPractices, getNoticeOfPrivacyPracticesByID, saveNoticeOfPrivacyPracticesByID } from '../../../store/forms/noticeOfPrivacyPractices/middlewares';
import { completeNoticeOfPrivacyPractices } from '../../../store/forms/newPatientRegistrationPacket/slice';
import { setNewActiveStep } from '../../../store/forms/newPatientRegistrationPacket/middlewares';

import { NoticeOfPrivacyPractices } from './NoticeOfPrivacyPractices';
import { useSelector, useDispatch } from 'react-redux';
import { validateGroup } from '../../../utils/helper';
import { useEffect, useState } from 'react';
import { USER_PATH } from '../../../utils/constants';

export const NoticeOfPrivacyPracticesContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const [signature, setSignature] = useState({});
	const signPicture = useSelector(selectSignPicture);

	const [error, setError] = useState(false);
	const loading = useSelector(selectLoading);

	const name = useSelector(selectName);
	const date = useSelector(selectDate);

	useEffect(() => {
		dispatch(setLoading(true));
		id ? dispatch(getNoticeOfPrivacyPracticesByID(id)) : dispatch(getNoticeOfPrivacyPractices());
	}, [dispatch, id]);

	useEffect(() => {
		if (!!signature?.fromDataURL && !!signature?.clear && signPicture) {
			signature.clear();
			signature.fromDataURL(signPicture);
		};
	}, [signPicture, signature]);

	const onChangeName = (event) => {
		dispatch(setName(event.value.trim()));
	};

	const onChangeDate = (event) => {
		dispatch(setDate(event.value));
	};

	const onInitSignature = (event) => {
		setSignature(event);
	};

	const onChangeSignature = () => {
		setError(false);
	};

	const onClearSignature = () => {
		if (!!signature.clear) {
			signature.clear();
		};
	};

	const onSaveNoticeOfPrivacyPractices = (event) => {
		const picture = signature.toDataURL('image/png');
		const isValid = validateGroup(event);

		if (signature.isEmpty()) {
			setError(true);
		};

		if (isValid && !signature.isEmpty()) {
			dispatch(setSignPicture(picture));
			id ? dispatch(saveNoticeOfPrivacyPracticesByID(id)) : dispatch(saveNoticeOfPrivacyPractices());
			dispatch(completeNoticeOfPrivacyPractices(true));
			dispatch(setNewActiveStep());
		};
	};

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

	return (
		<NoticeOfPrivacyPractices
			loading={loading}
			error={error}

			name={name}
			date={date}
			signPicture={signPicture}

			onChangeName={onChangeName}
			onChangeDate={onChangeDate}

			onInitSignature={onInitSignature}
			onChangeSignature={onChangeSignature}
			onClearSignature={onClearSignature}
			onSaveNoticeOfPrivacyPractices={onSaveNoticeOfPrivacyPractices}
			onClickBack={onClickBack}
		/>
	);
}