import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { postAdminChangePassword } from '../../../../../../store/users/adminsProfile/basicInformation/admin/middlewares';
import { selectShowButton } from "../../../../../../store/users/adminsProfile/basicInformation/admin/selectors";
import { selectAdminsList } from "../../../../../../store/users/adminsList/selectors";
import { Admin } from "./Admin";
import { showToast } from '../../../../../Main/MainContainer';

export const AdminContainer = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const showButton = useSelector(selectShowButton);
	const adminsList = useSelector(selectAdminsList);

	const changeFirstMode = () => setNewPassMode(newPassMode === 'text' ? 'password' : 'text');
	const changeSecondMode = () => setConfirmNewPassMode(confirmNewPassMode === "text" ? "password" : "text");

	const validatorRef = useRef(null);

	const [newPass, setPass] = useState('');
	const [confirmNewPass, setConfirmNewPass] = useState('');

	const [newPassMode, setNewPassMode] = useState('password');
	const [confirmNewPassMode, setConfirmNewPassMode] = useState('password');

	const passComparison = useCallback(() => newPass, [newPass]);

	const onChangedNewPass = useCallback(
		(e) => {
			setPass(e.value.trim());
			if (confirmNewPass) {
				validatorRef.current.instance.validate();
			}
		},
		[confirmNewPass, setPass],
	);

	const onChangedConfirmNewPass = useCallback((e) => {
		setConfirmNewPass(e.value.trim());
	}, []);

	const onCancelPatientInfo = () => {
		setPass("");
		setConfirmNewPass("");
	}

	const onSavePatientInfo = () => {
		const { isValid } = validatorRef.current.instance.validate();

		if (isValid) {
			
			const getAdminEmail = adminsList.filter(elem => elem.id === id)[0];

			dispatch(postAdminChangePassword(
				{
					email: getAdminEmail.email,
					newPassword: newPass,
				}
			)).then(data => {
				if (data.payload.success) {
					showToast('success', 'Password changed successfully');
				}
			})
		};

		if (!isValid) {
			showToast('error', 'Something went wrong');
		}
	}

	return (
		<Admin
			showButton={showButton}
			validatorRef={validatorRef}
			newPass={newPass}
			confirmNewPass={confirmNewPass}
			onChangedNewPass={onChangedNewPass}
			onChangedConfirmNewPass={onChangedConfirmNewPass}
			newPassMode={newPassMode}
			confirmNewPassMode={confirmNewPassMode}
			changeFirstMode={changeFirstMode}
			changeSecondMode={changeSecondMode}
			passComparison={passComparison}
			onCancelPatientInfo={onCancelPatientInfo}
			onSavePatientInfo={onSavePatientInfo}
		/>
	);
};
