import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { showToast } from "../../../screens/Main/MainContainer";
import { getPregnantStatuses } from "./middlewares";

const initialState = {
    value: [],
    status: FETCH_STATUSES.IDLE,
    error: null,
};

export const pregnantStatus = createSlice({
    name: "pregnantStatus",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPregnantStatuses.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
            })
            .addCase(getPregnantStatuses.fulfilled, (state, action) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.value = action.payload;
                state.error = null;
            })
            .addCase(getPregnantStatuses.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = action.error;

                showToast('error', `Get pregnant status: ${action.error.message}`);
            });
    },
});

export default pregnantStatus.reducer;
