import { Wrapper, Item, Label, Buttons } from '../../BasicInformation.style';
import { TextBoxStyled, IconButtonStyled } from './Admin.style';
import { Icon } from '@iconify/react';
import { MAX_WIDTH, PATIENT_INFO } from '../../../../../../utils/constants';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Button from 'devextreme-react/button';
import { Validator, RequiredRule, CompareRule, PatternRule, StringLengthRule } from 'devextreme-react/validator';
import { PASS_PATTERN_RULE } from '../../../../../../utils/rules';
import Tooltip from '@mui/material/Tooltip';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 900): return 12;
		case (width > 1500): return 6;
		default: return 12;
	};
};

export const Admin = ({
	showButton,
	validatorRef,
	newPass,
	confirmNewPass,
	onChangedNewPass,
	onChangedConfirmNewPass,
	newPassMode,
	confirmNewPassMode,
	changeFirstMode,
	changeSecondMode,
	passComparison,
	onCancelPatientInfo,
	onSavePatientInfo,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={PATIENT_INFO} ref={ref} maxWidth={MAX_WIDTH} >
			<Grid container spacing={2}>
				<Item item xs={12} style={{ marginBottom: '20px' }}>
					Enter a new password below to change your password.<br /><br />
					Your new password must be different from previously used passwords. Password must be at least 8 characters long and contain 1 lowercase, 1 uppercase, 1 number, and 1 special character (@#!_$%&).
				</Item>
				<Item item sm={size}>
					<Tooltip title="New Password *">
						<span>
							<Label>New Password:</Label>
							<TextBoxStyled
								labelMode="hidden"
								stylingMode="underlined"
								mode={newPassMode}
								value={newPass}
								inputAttr={{ autocomplete: 'new-password' }}
								onValueChanged={onChangedNewPass}
							>
								<IconButtonStyled
									name="password"
									location="after"
									onClick={changeFirstMode}
								>
									<Icon
										icon={newPassMode === "text" ? "bx:hide" : "bx:show"}
										height="30px"
										width="30px" />
								</IconButtonStyled>
								<Validator>
									<RequiredRule message="Password is required" />
									<PatternRule
										message="Do not use digits in the New Password"
										pattern={PASS_PATTERN_RULE}
									/>
									<StringLengthRule
										message="New Password must have at least 8 symbols"
										min={8}
									/>
								</Validator>
							</TextBoxStyled>
						</span>
					</Tooltip>
				</Item>
				<Item item sm={size}>
					<Tooltip title="Confirm New Password *">
						<span>
							<Label>Confirm New Password:</Label>
							<TextBoxStyled
								labelMode="hidden"
								stylingMode="underlined"
								value={confirmNewPass}
								inputAttr={{ autocomplete: 'new-confirmPassword' }}
								onValueChanged={onChangedConfirmNewPass}
								mode={confirmNewPassMode}
							>
								<IconButtonStyled
									name="password"
									location="after"
									onClick={changeSecondMode}
								>
									<Icon
										icon={confirmNewPassMode === "text" ? "bx:hide" : "bx:show"}
										height="30px"
										width="30px" />
								</IconButtonStyled>
								<Validator ref={validatorRef}>
									<RequiredRule message="Confirm Password is required" />
									<CompareRule
										message="Password and Confirm Password do not match"
										comparisonTarget={passComparison}
									/>
									<PatternRule
										message="Do not use digits in the Confirm New Password"
										pattern={PASS_PATTERN_RULE}
									/>
									<StringLengthRule
										message="Confirm New Password must have at least 8 symbols"
										min={8}
									/>
								</Validator>
							</TextBoxStyled>
						</span>
					</Tooltip>
				</Item>
				<Buttons>
					<Button
						text="Save"
						type="default"
						stylingMode="contained"
						visible={showButton}
						validationGroup={PATIENT_INFO}
						onClick={onSavePatientInfo} />
					<Button
						text="Cancel"
						type="default"
						stylingMode="outlined"
						visible={showButton}
						onClick={onCancelPatientInfo} />
				</Buttons>
			</Grid>
		</Wrapper >
	);
};
