export const selectLoading = state => state.eCommunicationConsent.loading;

export const selectDeclineTelehealth = state => state.eCommunicationConsent.telehealth.decline;
export const selectDateTelehealth = state => state.eCommunicationConsent.telehealth.date;
export const selectNameTelehealth = state => state.eCommunicationConsent.telehealth.name;
export const selectEmailTelehealth = state => state.eCommunicationConsent.telehealth.email;
export const selectCheckboxTelehealth = state => state.eCommunicationConsent.telehealth.checkbox;

export const selectDeclineEmail = state => state.eCommunicationConsent.email.decline;
export const selectDateEmail = state => state.eCommunicationConsent.email.date;
export const selectNameEmail = state => state.eCommunicationConsent.email.name;
export const selectEmailEmail = state => state.eCommunicationConsent.email.email;
export const selectCheckboxEmail = state => state.eCommunicationConsent.email.checkbox;
export const selectSignPicture = state => state.eCommunicationConsent.email.signPicture;