import { styled } from '@mui/material/styles';

export const Div = styled('div')(() => ({
  fontSize: '14px',
  marginLeft: '9px',
}));

export const Span = styled('span')(() => ({
  fontWeight: '600',
}));
