import { styled } from '@mui/material/styles';

export const Text = styled('p')(() => ({
  color: 'var(--black-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  margin: 'unset',
  '&.pb20': {
    paddingBottom: '20px',
  },
}));

export const Link = styled('a')(() => ({
  color: 'var(--light-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  margin: 'unset',
  textDecoration: 'none',
}));

export const Footer = styled('footer')(() => ({
  paddingTop: '20px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '36px',
  '@media (max-width: 1224px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 768px)': {
    paddingTop: '10px',
    gap: '16px',
  },
}));

export const Box = styled('section')(({ width }) => ({
  width: `${Math.round((width - 155) / 2)}px`,
  height: '300px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '24px',
  '@media (max-width: 1224px)': {
    width: '100%',
    height: 'auto',
  },
}));

export const BoxTitle = styled('h5')(() => ({
  paddingBottom: '32px',
  color: 'var(--dark-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '22px',
  margin: 'unset',
  '@media (max-width: 768px)': {
    paddingBottom: '16px',
  },
}));
