import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postForgotPassword = createAsyncThunk(
    "postForgotPassword",
    async (email, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "post",
                url: `${BASE_URL}/AuthManagement/SendResetPasswordMail?userEmail=${email}`,
            });
            return response.data;
        } catch (error) {
            //console.log("catch", error.response.data)
            return rejectWithValue(error.response.data);
        };
    }
);