export const selectLoading = state => state.consentForRelease.loading;

export const selectDecline = state => state.consentForRelease.decline;
export const selectFirstName = state => state.consentForRelease.firstName;
export const selectMiddleName = state => state.consentForRelease.middleName;
export const selectLastName = state => state.consentForRelease.lastName;
export const selectDOB = state => state.consentForRelease.dob;
export const selectAddress = state => state.consentForRelease.address;
export const selectCity = state => state.consentForRelease.city;
export const selectState = state => state.consentForRelease.state;
export const selectZip = state => state.consentForRelease.zip;
export const selectPhone = state => state.consentForRelease.phone;
export const selectLegalGuardian = state => state.consentForRelease.legalGuardian;

export const selectAtkaHealthClinic = state => state.consentForRelease.atkaHealthClinic;
export const selectAnchorageBehavioralHealth = state => state.consentForRelease.anchorageBehavioralHealth;
export const selectNikolskiHealthCenter = state => state.consentForRelease.nikolskiHealthCenter;
export const selectSaintGeorgeHealthCenter = state => state.consentForRelease.saintGeorgeHealthCenter;
export const selectOonalaskaPrimaryCare = state => state.consentForRelease.oonalaskaPrimaryCare;
export const selectOonalaskaBehavioralHealth = state => state.consentForRelease.oonalaskaBehavioralHealth;

export const selectReleaseInformationTo = state => state.consentForRelease.releaseInformationTo;
export const selectObtainInformationFrom = state => state.consentForRelease.obtainInformationFrom;
export const selectVerbally = state => state.consentForRelease.verbally;
export const selectInWritingElectronically = state => state.consentForRelease.inWritingElectronically;

export const selectAgency = state => state.consentForRelease.agency;
export const selectAgencyName = state => state.consentForRelease.agencyName;
export const selectAgencyAddress = state => state.consentForRelease.agencyAddress;
export const selectAgencyCity = state => state.consentForRelease.agencyCity;
export const selectAgencyState = state => state.consentForRelease.agencyState;
export const selectAgencyZip = state => state.consentForRelease.agencyZip;
export const selectAgencyPhone = state => state.consentForRelease.agencyPhone;

export const selectAssessmentRecommendations = state => state.consentForRelease.assessmentRecommendations;
export const selectAdmissionSummary = state => state.consentForRelease.admissionSummary;
export const selectResultsOfUrinalysis = state => state.consentForRelease.resultsOfUrinalysis;
export const selectAttendanceCompliance = state => state.consentForRelease.attendanceCompliance;
export const selectTreatmentPlan = state => state.consentForRelease.treatmentPlan;
export const selectPsychiatricEvaluations = state => state.consentForRelease.psychiatricEvaluations;
export const selectTreatmentStatus = state => state.consentForRelease.treatmentStatus;
export const selectDischargeDateAndSummary = state => state.consentForRelease.dischargeDateAndSummary;
export const selectBhMedicationNeeds = state => state.consentForRelease.bhMedicationNeeds;
export const selectRegardingOther = state => state.consentForRelease.regardingOther;
export const selectRegardingOtherText = state => state.consentForRelease.regardingOtherText;

export const selectReferralTreatmentPlacement = state => state.consentForRelease.referralTreatmentPlacement;
export const selectProvideReferralInformation = state => state.consentForRelease.provideReferralInformation;
export const selectVerifyParticipationInTreatment = state => state.consentForRelease.verifyParticipationInTreatment;
export const selectCoordinationOfServices = state => state.consentForRelease.coordinationOfServices;
export const selectPurposesOther = state => state.consentForRelease.purposesOther;
export const selectPurposesOtherText = state => state.consentForRelease.purposesOtherText;

export const selectPrintedName = state => state.consentForRelease.signedName;
export const selectSignedDate = state => state.consentForRelease.signedDate;
export const selectExpiredDate = state => state.consentForRelease.expiredDate;
export const selectSignPicture = state => state.consentForRelease.signPicture;