import { getEducation, postEducation, getEducationByID, postEducationByID } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    education: "",
    otherEducation: "",
    lastGradeEducation: "",
    gradeSchoolEducation: false,
    livingArrangement: "",
    otherLivingArrangement: "",
    livingCondition: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationEducation = createSlice({
    name: "newPatientRegistrationEducation",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setEducation: (state, action) => {
            state.education = action.payload;
            return state;
        },
        setOtherEducation: (state, action) => {
            state.otherEducation = action.payload;
            return state;
        },
        setLastGradeEducation: (state, action) => {
            state.lastGradeEducation = action.payload;
            return state;
        },
        toggleGradeSchoolEducation: (state) => {
            state.gradeSchoolEducation = !state.gradeSchoolEducation;
            return state;
        },
        setLivingArrangement: (state, action) => {
            state.livingArrangement = action.payload ? action.payload : 0;
            return state;
        },
        setOtherLivingArrangement: (state, action) => {
            state.otherLivingArrangement = action.payload;
            return state;
        },
        setLivingCondition: (state, action) => {
            state.livingCondition = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEducation.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postEducation.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getEducation.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.education = action.payload?.education;
                state.otherEducation = action.payload?.otherEducation;
                state.lastGradeEducation = action.payload?.lastGradeEducation;
                state.gradeSchoolEducation = action.payload?.gradeSchoolEducation;
                state.livingArrangement = action.payload?.arrangement;
                state.otherLivingArrangement = action.payload?.otherArrangement;
                state.livingCondition = action.payload?.conditions;

                state.loading = false;
            })
            .addCase(postEducation.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", " Education / Housing saved successfully");
            })
            .addCase(getEducation.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Education / Housing: ${action.error.message}`);
            })
            .addCase(postEducation.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Education / Housing: ${action.error.message}`);
            })
            .addCase(getEducationByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError  = null;
                state.loading   = true;
            })
            .addCase(getEducationByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError  = null;

                state.education              = action.payload?.education;
                state.otherEducation         = action.payload?.otherEducation;
                state.lastGradeEducation     = action.payload?.lastGradeEducation;
                state.gradeSchoolEducation   = action.payload?.gradeSchoolEducation;
                state.livingArrangement      = action.payload?.arrangement;
                state.otherLivingArrangement = action.payload?.otherArrangement;
                state.livingCondition        = action.payload?.conditions;
                state.loading                = false;
            })
            .addCase(getEducationByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError  = action.error;
                state.loading   = false;

                showToast('error', `Get Education / Housing: ${action.error.message}`);
            })
            .addCase(postEducationByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError  = null;
            })
            .addCase(postEducationByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError  = null;
                showToast('success', ' Education / Housing saved successfully');
            })
            .addCase(postEducationByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError  = action.error;
                showToast('error', `Set Education / Housing: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setEducation,
    setOtherEducation,
    setLastGradeEducation,
    toggleGradeSchoolEducation,
    setLivingArrangement,
    setOtherLivingArrangement,
    setLivingCondition,
} = newPatientRegistrationEducation.actions;

export default newPatientRegistrationEducation.reducer;
