import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Wrapper } from "./Attachments.style";
import DataGrid from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import { DOWNLOAD, SHOW } from '../../../../utils/constants';
import Tooltip from '@mui/material/Tooltip';

export const Attachments = ({
    data, loading, onClickHandler, loadIndicator, showIndicator
}) => {
    const sectionName = 'patientAttachments';
    return (
        <Wrapper>
            <LoadPanel
                visible={loading}
                position={{ of: `#${sectionName}` }} />
            {!loading &&
                <DataGrid
                    height={window.innerHeight - 200}
                    columnMinWidth={100}
                    dataSource={data}
                    showBorders={false}
                    showRowLines={true}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    wordWrapEnabled={true}>
                    <Column dataField="description" caption="Description" cellRender={({ data }) => {
                        return <div style={!data.loaded ? { color: 'var(--grey-text-color)' } : {}}>{data.description}</div>
                    }} />
                    <Column dataField="uploadedDate" caption="uploaded" />
                    <Column
                        alignment="right"
                        width="100px"
                        cellRender={
                            (d) =>
                                <div style={{ display: "flex" }}>
                                    <Tooltip title={SHOW}>
                                        <span>
                                            <IconButton onClick={() => onClickHandler(d.data.id, d.data.typesUploadFile, SHOW)} disabled={!d.data.loaded || showIndicator === d.data.id} >
                                                {showIndicator === d.data.id ? (
                                                    <LoadIndicator
                                                        className="button-indicator"
                                                        visible={showIndicator && true}
                                                        id="small-indicator"
                                                        height={24}
                                                        width={24}
                                                    />
                                                ) : (
                                                    <Icon
                                                        icon="ic:outline-remove-red-eye"
                                                        height="24px"
                                                        width="24px"
                                                        color={d.data.loaded ? "var(--light-blue-color)" : "var(--grey-text-color)"}
                                                    />
                                                )}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title={DOWNLOAD}>
                                        <span>
                                            <IconButton title={DOWNLOAD} disabled={!d.data.loaded || loadIndicator === d.data.id} onClick={() => onClickHandler(d.data.id, d.data.typesUploadFile, DOWNLOAD)} >
                                                {loadIndicator === d.data.id ? (
                                                    <LoadIndicator
                                                        className="button-indicator"
                                                        visible={loadIndicator && true}
                                                        id="small-indicator"
                                                        height={24}
                                                        width={24}
                                                    />
                                                ) : (
                                                    <Icon
                                                        icon="material-symbols:download-rounded"
                                                        height="24px"
                                                        width="24px"
                                                        color={d.data.loaded ? "var(--light-blue-color)" : "var(--grey-text-color)"}
                                                    />
                                                )}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </div>
                        } />
                    <Pager
                        visible={true}
                        displayMode="full"
                        label="Rows per page:"
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                        allowedPageSizes={[5, 10, 'all']} />
                    <Paging defaultPageSize={10} />
                </DataGrid>
            }
        </Wrapper >
    );
}