export const selectLoading = state => state.patientProfileInsuranceInfo.loading;

export const selectDontHaveInsurance = state => state.patientProfileInsuranceInfo.dontHaveInsurance;
export const selectAllMembers = state => state.patientProfileInsuranceInfo.allMembers;
export const selectTotalIncome = state => state.patientProfileInsuranceInfo.totalIncome;
export const selectMedicare = state => state.patientProfileInsuranceInfo.medicare;
export const selectMedicareEffDate = state => state.patientProfileInsuranceInfo.medicareEffDate;
export const selectMedicareExpDate = state => state.patientProfileInsuranceInfo.medicareExpDate;
export const selectMedicareNumber = state => state.patientProfileInsuranceInfo.medicareNumber;
export const selectMedicaid = state => state.patientProfileInsuranceInfo.medicaid;
export const selecMedicaidEffDate = state => state.patientProfileInsuranceInfo.medicaidEffDate;
export const selectMedicaidExpDate = state => state.patientProfileInsuranceInfo.medicaidExpDate;
export const selectMedicaidNumber = state => state.patientProfileInsuranceInfo.medicaidNumber;
export const selectDenaliKid = state => state.patientProfileInsuranceInfo.denaliKid;
export const selectDenaliKidEffDate = state => state.patientProfileInsuranceInfo.denaliKidEffDate;
export const selectDenaliKidExpDate = state => state.patientProfileInsuranceInfo.denaliKidExpDate;
export const selectDenaliKidNumber = state => state.patientProfileInsuranceInfo.denaliKidNumber;
export const selectPrivateInsurance = state => state.patientProfileInsuranceInfo.privateInsurance;
export const selectCompanyInsurance = state => state.patientProfileInsuranceInfo.companyInsurance;
export const selectPolicyHolder = state => state.patientProfileInsuranceInfo.policyHolder;
export const selectPolicyHolderNumber = state => state.patientProfileInsuranceInfo.policyHolderNumber;
export const selectPolicyHolderGroupNumber = state => state.patientProfileInsuranceInfo.policyHolderGroupNumber;
export const selectOtherInsurance = state => state.patientProfileInsuranceInfo.otherInsurance;
export const selectOtherInsuranceNumber = state => state.patientProfileInsuranceInfo.otherInsuranceNumber;
export const selectotherInsuranceGroupNumber = state => state.patientProfileInsuranceInfo.otherInsuranceGroupNumber;
export const selectVeteran = state => state.patientProfileInsuranceInfo.veteran;
export const selectBenefitsVA = state => state.patientProfileInsuranceInfo.benefitsVA;
export const selectServiceBranch = state => state.patientProfileInsuranceInfo.serviceBranch;
export const selectServiceEntryDate = state => state.patientProfileInsuranceInfo.serviceEntryDate;
export const selectServiceExitDate = state => state.patientProfileInsuranceInfo.serviceExitDate;
export const selectVietnamService = state => state.patientProfileInsuranceInfo.vietnamService;
export const selectDoNotOtherInsurance = state => state.patientProfileInsuranceInfo.doNotOtherInsurance;
export const selectSelfPay = state => state.patientProfileInsuranceInfo.selfPay;
export const selectRelationship = state => state.patientProfileInsuranceInfo.relationship;
export const selectDOB = state => state.patientProfileInsuranceInfo.DOB;
export const selectPhone = state => state.patientProfileInsuranceInfo.phone;
export const selectAddress = state => state.patientProfileInsuranceInfo.address;
export const selectCity = state => state.patientProfileInsuranceInfo.city;
export const selectState = state => state.patientProfileInsuranceInfo.state;
export const selectZip = state => state.patientProfileInsuranceInfo.zip;
export const selectEffectiveDate = state => state.patientProfileInsuranceInfo.effectiveDate;