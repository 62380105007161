import { useParams } from 'react-router-dom';
import { setHaveLivingWill, setPsychiatricAdvanceDirective, toggleAdvanceHealthCareDirectiveInfo, toggleAdvanceHealthCareDirectiveOnFile, setHaveLivingWillReason, setLastDateReview, setPsychiatricAdvanceDirectiveReason } from "../../../../../store/forms/newPatientRegistration/advanceCare/slice";
import { selectLoading, selectHaveLivingWillReason, selectLastDateReview, selectPsychiatricAdvanceDirectiveReason, selectAdvanceHealthCareDirectiveInfo, selectAdvanceHealthCareDirectiveOnFile, selectHaveLivingWill, selectPsychiatricAdvanceDirective } from "../../../../../store/forms/newPatientRegistration/advanceCare/selectors";
import { completeAdvanceCare } from "../../../../../store/forms/newPatientRegistration/slice";

import { getAdvanceCare, getAdvanceCareByID, saveAdvanceCare, saveAdvanceCareByID } from "../../../../../store/forms/newPatientRegistration/advanceCare/middlewares";
import { getAdvHCareDirectiveStatuses } from "../../../../../store/lookups/advHCareDirectiveStatus/middlewares";
import { getAdvPsychDirectiveStatuses } from "../../../../../store/lookups/advPsychDirectiveStatus/middlewares";
import { selectAdvHCareDirectiveStatuses } from "../../../../../store/lookups/advHCareDirectiveStatus/selectors";
import { selectAdvPsychDirectiveStatuses } from "../../../../../store/lookups/advPsychDirectiveStatus/selectors";

import { validateGroup } from "../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { AdvanceCare } from "./AdvanceCare";
import { useEffect } from "react";

const answers = [
    {
        "Oid": true,
        "Name": "Yes",
    }, 
    {
        "Oid": false,
        "Name": "No",
    },
];

export const AdvanceCareContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const advHCareDirectiveStatuses = useSelector(selectAdvHCareDirectiveStatuses);
    const advPsychDirectiveStatuses = useSelector(selectAdvPsychDirectiveStatuses);

    const lastDateReview = useSelector(selectLastDateReview);
    const haveLivingWill = useSelector(selectHaveLivingWill);
    const haveLivingWillReason = useSelector(selectHaveLivingWillReason);
    const psychiatricAdvanceDirective = useSelector(selectPsychiatricAdvanceDirective);
    const psychiatricAdvanceDirectiveReason = useSelector(selectPsychiatricAdvanceDirectiveReason);
    const advanceHealthCareDirectiveOnFile = useSelector(selectAdvanceHealthCareDirectiveOnFile);
    const advanceHealthCareDirectiveInfo = useSelector(selectAdvanceHealthCareDirectiveInfo);

    useEffect(() => {
        dispatch(id ? getAdvanceCareByID(id) : getAdvanceCare());
        dispatch(getAdvHCareDirectiveStatuses());
        dispatch(getAdvPsychDirectiveStatuses());
    }, [dispatch, id]);

    const onChangeLastDateReview = (event) => {
        dispatch(setLastDateReview(event.value));
    }

    const onChangeHaveLivingWill = (event) => {
        dispatch(setHaveLivingWill(event.value));
    }

    const onChangeHaveLivingWillReason = (event) => {
        dispatch(setHaveLivingWillReason(event.value.trim()));
    }

    const onChangePsychiatricAdvanceDirective = (event) => {
        dispatch(setPsychiatricAdvanceDirective(event.value));
    }

    const onChangePsychiatricAdvanceDirectiveReason = (event) => {
        dispatch(setPsychiatricAdvanceDirectiveReason(event.value.trim()));
    }

    const onToggleAdvanceHealthCareDirectiveOnFile = (event) => {
        dispatch(toggleAdvanceHealthCareDirectiveOnFile(event.value));
    }

    const onToggleAdvanceHealthCareDirectiveInfo = (event) => {
        dispatch(toggleAdvanceHealthCareDirectiveInfo(event.value));
    }

    const onSaveAdvanceCare = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveAdvanceCareByID(id) : saveAdvanceCare());
            dispatch(completeAdvanceCare(true));
        }
    }

    const onSaveAndContinueAdvanceCare = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveAdvanceCareByID(id) : saveAdvanceCare());
            dispatch(completeAdvanceCare(true));
            onClickNext();
        };
    }


    return (
        <AdvanceCare
            loading={loading}

            advHCareDirectiveStatuses={advHCareDirectiveStatuses}
            advPsychDirectiveStatuses={advPsychDirectiveStatuses}

            lastDateReview={lastDateReview}
            haveLivingWill={haveLivingWill}
            haveLivingWillReason={haveLivingWillReason}
            psychiatricAdvanceDirective={psychiatricAdvanceDirective}
            psychiatricAdvanceDirectiveReason={psychiatricAdvanceDirectiveReason}
            advanceHealthCareDirectiveOnFile={advanceHealthCareDirectiveOnFile}
            advanceHealthCareDirectiveInfo={advanceHealthCareDirectiveInfo}
            answers={answers}

            onChangeLastDateReview={onChangeLastDateReview}
            onChangeHaveLivingWill={onChangeHaveLivingWill}
            onChangeHaveLivingWillReason={onChangeHaveLivingWillReason}
            onChangePsychiatricAdvanceDirective={onChangePsychiatricAdvanceDirective}
            onChangePsychiatricAdvanceDirectiveReason={onChangePsychiatricAdvanceDirectiveReason}
            onToggleAdvanceHealthCareDirectiveOnFile={onToggleAdvanceHealthCareDirectiveOnFile}
            onToggleAdvanceHealthCareDirectiveInfo={onToggleAdvanceHealthCareDirectiveInfo}

            onSaveAdvanceCare={onSaveAdvanceCare}
            onSaveAndContinueAdvanceCare={onSaveAndContinueAdvanceCare}
        />
    );
}