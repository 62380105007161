import { BASE_URL, ADVANCE_CARE } from "../../../../../utils/constants";
import { dateFormatForApi } from "../../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getAdvanceCareById = createAsyncThunk(
    "getAdvanceCareById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${ADVANCE_CARE}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postAdvanceCareById = createAsyncThunk(
    "postAdvanceCareById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${ADVANCE_CARE}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveAdvanceCareById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileAdvanceCare;

    const data = {
        "haveLivingWill": store.haveLivingWill,
        "haveLivingWillReason": store.haveLivingWillReason,
        "psychiatricAdvanceDirective": store.psychiatricAdvanceDirective,
        "psychiatricAdvanceDirectiveReason": store.psychiatricAdvanceDirectiveReason,
        "advanceHealthCareDirectiveOnFile": store.advanceHealthCareDirectiveOnFile,
        "lastDateReview": dateFormatForApi(store.lastDateReview),
        "advanceHealthCareDirectiveInfo": store.advanceHealthCareDirectiveInfo
    }

    dispatch(postAdvanceCareById({ id, data }));
}