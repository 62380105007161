export const selectLoading = state => state.patientChecklist.loading;

export const selectFrontDeskStaffCheck = state => state.patientChecklist.frontDeskStaffCheck;
export const selectFrontDeskStaffValue = state => state.patientChecklist.frontDeskStaffValue;
export const selectPatientNameCheck = state => state.patientChecklist.patientNameCheck;
export const selectPatientNameValueFirstName = state => state.patientChecklist.patientNameValueFirstName;
export const selectPatientNameValueMiddleName = state => state.patientChecklist.patientNameValueMiddleName;
export const selectPatientNameValueLastName = state => state.patientChecklist.patientNameValueLastName;
export const selectDobCheck = state => state.patientChecklist.dobCheck;
export const selectDobValue = state => state.patientChecklist.dobValue;
export const selectDateReferralReceviedCheck = state => state.patientChecklist.dateReferralReceviedCheck;
export const selectDateReferralReceviedValue = state => state.patientChecklist.dateReferralReceviedValue;
export const selectDateReferralApprovedCheck = state => state.patientChecklist.dateReferralApprovedCheck;
export const selectDateReferralApprovedValue = state => state.patientChecklist.dateReferralApprovedValue;
export const selectReferralSourceCheck = state => state.patientChecklist.referralSourceCheck;
export const selectReferralSourceValue = state => state.patientChecklist.referralSourceValue;
export const selectReferralTypeCheck = state => state.patientChecklist.referralTypeCheck;
export const selectReferralTypeValue = state => state.patientChecklist.referralTypeValue;
export const selectRegFormCheck = state => state.patientChecklist.regFormCheck;
export const selectRegFormValue = state => state.patientChecklist.regFormValue;
export const selectNoppCheck = state => state.patientChecklist.noppCheck;
export const selectNoppValue = state => state.patientChecklist.noppValue;
export const selectCommunicationConsentCheck = state => state.patientChecklist.communicationConsentCheck;
export const selectCommunicationConsentValueTelehealth = state => state.patientChecklist.communicationConsentValueTelehealth;
export const selectCommunicationConsentValueEmail = state => state.patientChecklist.communicationConsentValueEmail;
export const selectDateChartCreatedCernerCheck = state => state.patientChecklist.dateChartCreatedCernerCheck;
export const selectDateChartCreatedCernerValue = state => state.patientChecklist.dateChartCreatedCernerValue;
export const selectRegistrationPacketUploadCernerCheck = state => state.patientChecklist.registrationPacketUploadCernerCheck;
export const selectRegistrationPacketUploadCernerValue = state => state.patientChecklist.registrationPacketUploadCernerValue;
export const selectIdSubmittedCheck = state => state.patientChecklist.idSubmittedCheck;
export const selectIdSubmittedValue = state => state.patientChecklist.idSubmittedValue;
export const selectIdVerifiedCheck = state => state.patientChecklist.idVerifiedCheck;
export const selectIdVerifiedValue = state => state.patientChecklist.idVerifiedValue;
export const selectIdUploadedCernerCheck = state => state.patientChecklist.idUploadedCernerCheck;
export const selectIdUploadedCernerValue = state => state.patientChecklist.idUploadedCernerValue;
export const selectInsuranceSubmittedCheck = state => state.patientChecklist.insuranceSubmittedCheck;
export const selectInsuranceSubmittedValue = state => state.patientChecklist.insuranceSubmittedValue;
export const selectInsuranceVerifiedCheck = state => state.patientChecklist.insuranceVerifiedCheck;
export const selectInsuranceVerifiedValue = state => state.patientChecklist.insuranceVerifiedValue;
export const selectInsuranceUploadedCernerCheck = state => state.patientChecklist.insuranceUploadedCernerCheck;
export const selectInsuranceUploadedCernerValue = state => state.patientChecklist.insuranceUploadedCernerValue;
export const selectProfileCratedInCernerCheck = state => state.patientChecklist.profileCratedInCernerCheck;
export const selectProfileCratedInCernerValue = state => state.patientChecklist.profileCratedInCernerValue;
export const selectRoiCheck = state => state.patientChecklist.roiCheck;
export const selectRoiValue = state => state.patientChecklist.roiValue;
