import { setHaveLivingWill, setPsychiatricAdvanceDirective, toggleAdvanceHealthCareDirectiveInfo, toggleAdvanceHealthCareDirectiveOnFile, setHaveLivingWillReason, setLastDateReview, setPsychiatricAdvanceDirectiveReason } from "../../../../../../store/users/patientProfile/basicInformation/advanceCare/slice";
import { selectLoading, selectHaveLivingWillReason, selectLastDateReview, selectPsychiatricAdvanceDirectiveReason, selectAdvanceHealthCareDirectiveInfo, selectAdvanceHealthCareDirectiveOnFile, selectHaveLivingWill, selectPsychiatricAdvanceDirective } from "../../../../../../store/users/patientProfile/basicInformation/advanceCare/selectors";
import { getAdvanceCareById, saveAdvanceCareById } from "../../../../../../store/users/patientProfile/basicInformation/advanceCare/middlewares";

import { getAdvHCareDirectiveStatuses } from "../../../../../../store/lookups/advHCareDirectiveStatus/middlewares";
import { getAdvPsychDirectiveStatuses } from "../../../../../../store/lookups/advPsychDirectiveStatus/middlewares";
import { selectAdvHCareDirectiveStatuses } from "../../../../../../store/lookups/advHCareDirectiveStatus/selectors";
import { selectAdvPsychDirectiveStatuses } from "../../../../../../store/lookups/advPsychDirectiveStatus/selectors";

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdvanceCare } from "./AdvanceCare"

export const AdvanceCareContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const advHCareDirectiveStatuses = useSelector(selectAdvHCareDirectiveStatuses);
    const advPsychDirectiveStatuses = useSelector(selectAdvPsychDirectiveStatuses);

    const lastDateReview = useSelector(selectLastDateReview);
    const haveLivingWill = useSelector(selectHaveLivingWill);
    const haveLivingWillReason = useSelector(selectHaveLivingWillReason);
    const psychiatricAdvanceDirective = useSelector(selectPsychiatricAdvanceDirective);
    const psychiatricAdvanceDirectiveReason = useSelector(selectPsychiatricAdvanceDirectiveReason);
    const advanceHealthCareDirectiveOnFile = useSelector(selectAdvanceHealthCareDirectiveOnFile);
    const advanceHealthCareDirectiveInfo = useSelector(selectAdvanceHealthCareDirectiveInfo);

    useEffect(() => {
        dispatch(getAdvanceCareById(id));
        dispatch(getAdvHCareDirectiveStatuses());
        dispatch(getAdvPsychDirectiveStatuses());
    }, [dispatch, id]);

    const FN_TO_CALL = {
        LastDateReview: setLastDateReview,
        HaveLivingWill: setHaveLivingWill,
        HaveLivingWillReason: setHaveLivingWillReason,
        PsychiatricAdvanceDirective: setPsychiatricAdvanceDirective,
        PsychiatricAdvanceDirectiveReason: setPsychiatricAdvanceDirectiveReason,
        AdvanceHealthCareDirectiveOnFile: toggleAdvanceHealthCareDirectiveOnFile,
        AdvanceHealthCareDirectiveInfo: toggleAdvanceHealthCareDirectiveInfo,
    };

    const onChangeFunc = (name, event = null, trim = false) => {
        if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        else dispatch(FN_TO_CALL[name]());
        setShowButton(true);
    };

    const onCancelAdvanceCare = () => {
        dispatch(getAdvanceCareById(id));
        setShowButton(false);
    }

    const onSaveAdvanceCare = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveAdvanceCareById(id));
            setShowButton(false);
        }
    }

    return (
        <AdvanceCare
            loading={loading}
            showButton={showButton}

            advHCareDirectiveStatuses={advHCareDirectiveStatuses}
            advPsychDirectiveStatuses={advPsychDirectiveStatuses}

            lastDateReview={lastDateReview}
            haveLivingWill={haveLivingWill}
            haveLivingWillReason={haveLivingWillReason}
            psychiatricAdvanceDirective={psychiatricAdvanceDirective}
            psychiatricAdvanceDirectiveReason={psychiatricAdvanceDirectiveReason}
            advanceHealthCareDirectiveOnFile={advanceHealthCareDirectiveOnFile}
            advanceHealthCareDirectiveInfo={advanceHealthCareDirectiveInfo}

            onChangeFunc={onChangeFunc}

            onCancelAdvanceCare={onCancelAdvanceCare}
            onSaveAdvanceCare={onSaveAdvanceCare}
        />
    );
}