import { isAdmin } from '../../../core/auth/authProvider';
import { Accordion, AccordionSummary, AccordionDetails } from '../../../components/Accordion/Accordion.style';
import { FixedContainer, TitleAccordion } from './NewPatientRegistration.style';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { Wrapper, TitleWrapper, Title, Text } from '../../../components/Main/Main.style';
import { useResizeDetector } from 'react-resize-detector';
import { MAX_WIDTH } from '../../../utils/constants';
import Button from 'devextreme-react/button';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export const NewPatientRegistration = ({
	fixed,
	expanded,
	steps,
	stepsTaken,

	onClickExpand,
	onClickExpandAll,
	onClickCollapseAll,
	onClickBack,
}) => {
	const { width, ref } = useResizeDetector();

	return (
		<Wrapper ref={ref} maxWidth={MAX_WIDTH}>
			<Grid container spacing={{ xs: 1, md: 2 }}>
				<Grid item xs={12}>
					<TitleWrapper>
						{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
						<Tooltip title="New Patient Registration">
							<Title>New Patient Registration</Title >
						</Tooltip>
					</TitleWrapper>
				</Grid>
				<Grid item xs={12}>
					<Text>
						This form is <span style={{ color: 'var(--red-text-color)' }}>required</span>.
					</Text>
				</Grid>
				<Grid item xs={12}>
					<Text>
						"New Patient Registration" form consists of {steps.length ? steps.length : 0} steps. To open/close a step, click on its title or the "v" icon. You can also open/close all form steps at once by clicking "Expand All"/"Collapse All" buttons. Please fill out and save each step by clicking the "Save" button. You can view your progress through this form, as well as number of steps remaining under the corresponding section below.
					</Text>
				</Grid>
				<Grid item xs={12} >
					<FixedContainer width={width} fixed={fixed}>
						<ProgressBar steps={steps} stepsTaken={stepsTaken} />
					</FixedContainer>
				</Grid>
				<Grid item xs={12}>
					<Button
						text="Expand all"
						icon="chevrondown"
						stylingMode="text"
						type="default"
						onClick={onClickExpandAll}
					/>
					<Button
						text="Collapse all"
						icon="chevronup"
						stylingMode="text"
						type="default"
						onClick={onClickCollapseAll}
					/>
				</Grid>
				<Grid item xs={12} style={{ paddingBottom: '40px' }}>
					{steps?.map((step) => (
						<Accordion
							key={step.id}
							expanded={expanded.includes(step.id)}
							onChange={() => onClickExpand(step.id)}
							TransitionProps={{ unmountOnExit: true }}>
							<AccordionSummary completed={step?.isCompleted?.toString() || "false"}>
								<div style={{ width: "32px", height: "32px" }}>
									<Icon
										icon={step.isCompleted ? "lucide:check-circle-2" : "lucide:minus-circle"}
										color={step.isCompleted ? "var(--green-text-color)" : "var(--grey-icon-color)"}
										width="32px" height="32px" />
								</div>
								<Tooltip title={step.title} placement="top-start">
									<TitleAccordion>{step.title}</TitleAccordion>
								</Tooltip>
							</AccordionSummary>
							<AccordionDetails>
								{step.body}
							</AccordionDetails>
						</Accordion>
					))}
				</Grid>
			</Grid>
		</Wrapper >
	);
}