import DataGrid, { Column, Toolbar, Item, Pager, Selection, SearchPanel } from 'devextreme-react/data-grid';
import { Wrapper, Title } from '../../../components/Main/Main.style';
import { MAX_WIDTH } from '../../../utils/constants';
import { Switcher } from '../Switcher/Switcher';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import Tooltip from '@mui/material/Tooltip';
import Button from '../../../components/Button/Button';
import LoadPanel from 'devextreme-react/load-panel';
import GenerationAdminsContainer from '../GenerationAdmins/GenerationAdminsContainer';

export const AdminsList = ({
	loading,
	dataSource,
	showProfile,
	showAdmin,
}) => {
	return (
		<>
			<Wrapper id="providersList" maxWidth={MAX_WIDTH}>
				<LoadPanel
					visible={loading}
					position={{ of: '#consentForRelease' }} />
				{
					!loading &&
					<>
						<Title style={{ marginBottom: '20px' }}>Admins</Title>
						<DataGrid
							dataSource={dataSource}
							height={window.innerHeight - 200}
							columnMinWidth={100}
							showBorders={false}
							showRowLines={true}
							showColumnLines={false}
							hoverStateEnabled={true}>
							<SearchPanel visible={true} placeholder="Search" />
							<Toolbar>
								<Item location="before">
									<Switcher />
								</Item>
								<Item name="searchPanel" />
								<Item location="after" >
									<Button
										label="add"
										variant="contained"
										startIcon="ant-design:plus-square-outlined"
										onClick={() => showAdmin()} />
								</Item>
							</Toolbar>
							<Selection mode="multiple" />
							<Column dataField="id" visible={false} />
							<Column dataField="firstName" caption="User Name" />
							<Column dataField="email" caption="Email" />
							<Column
								fixed={true}
								fixedPosition="right"
								alignment="center"
								width={80}
								minWidth={80}
								cellRender={(event) =>
									<IconButton
										onClick={() => showProfile(event.data.id)}
									>
										<Tooltip title="Go to profile">
											<Icon
												icon="ic:baseline-output"
												height="24px"
												width="24px"
												color="var(--light-blue-color)" />
										</Tooltip>
									</IconButton>
								} />
							<Pager
								visible={true}
								displayMode="full"
								label="Rows per page:"
								showPageSizeSelector={true}
								showNavigationButtons={true}
								allowedPageSizes={[5, 10, 'all']} />
						</DataGrid>
					</>
				}
			</Wrapper >
			<GenerationAdminsContainer />
		</>
	);
}