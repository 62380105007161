import { getReferralInfoById, postReferralInfoById } from "./middlewares";
import { showToast } from "../../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reason: "dfgdd",
    referralInfo: [],
    otherReferralInfo: "fdg",
    refferalForMedicalRN: false,
    contactedByPhysicalExam: false,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const patientProfileReferralInfo = createSlice({
    name: "patientProfileReferralInfo",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setReason: (state, action) => {
            state.reason = action.payload;
            return state;
        },
        setReferralInfo: (state, action) => {
            state.referralInfo = action.payload;
            return state;
        },
        setOtherReferralInfo: (state, action) => {
            state.otherReferralInfo = action.payload;
            return state;
        },
        toggleRefferalForMedicalRN: (state) => {
            state.refferalForMedicalRN = !state.refferalForMedicalRN;
            return state;
        },
        toggleContactedByPhysicalExam: (state) => {
            state.contactedByPhysicalExam = !state.contactedByPhysicalExam;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReferralInfoById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postReferralInfoById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getReferralInfoById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.reason = action.payload?.reason;
                state.referralInfo = action.payload?.referralInfo;
                state.otherReferralInfo = action.payload?.otherReferralInfo;
                state.refferalForMedicalRN = action.payload?.refferalForMedicalRN;
                state.contactedByPhysicalExam = action.payload?.contactedByPhysicalExam;

                state.loading = false;
            })
            .addCase(postReferralInfoById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Referral Information saved successfully");
            })
            .addCase(getReferralInfoById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Referral Information: ${action.error.message}`);
            })
            .addCase(postReferralInfoById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Referral Information: ${action.error.message}`);
            });
    },
});

export const {
    setLoading,

    setReason,
    setReferralInfo,
    setOtherReferralInfo,
    toggleRefferalForMedicalRN,
    toggleContactedByPhysicalExam,

} = patientProfileReferralInfo.actions;

export default patientProfileReferralInfo.reducer;

