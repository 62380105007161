import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { AdminsList } from './AdminsList';

import { getAdminsList } from '../../../store/users/adminsList/middlewares';
import { setLoading } from '../../../store/users/adminsList/slice';
import { selectLoading, selectAdminsList } from "../../../store/users/adminsList/selectors";

import { setShowAdmin } from "../../../store/users/generationAdmins/slice";

export const AdminsListContainer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loading = useSelector(selectLoading);
	const dataSource = useSelector(selectAdminsList);

	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(getAdminsList());
	}, [dispatch]);

	const showProfile = (id) => {
		navigate(`profile/${id}`);
	}

	const showAdmin = () => {
		dispatch(setShowAdmin(true));
	}

	return (
		<AdminsList
			loading={loading}
			dataSource={dataSource}
			showProfile={showProfile}
			showAdmin={showAdmin}
		/>
	);
}