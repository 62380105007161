import ContactBox from '../../../components/ContactBox/ContactBox';
import { hotlineNumbers, regionalClinicsContacts, APIAContacts } from './contactsData';
import { Wrapper, Main, Header, Img, Title, SubTitle, Footer } from './ContactPage.style';
import { useResizeDetector } from 'react-resize-detector';

export const ContactPage = () => {
  const { width, ref } = useResizeDetector();

  return (
    <Wrapper maxWidth="xl" ref={ref}>
      <Header>
        <Title>
          Do You <br /> Need Help?
        </Title>
        <Img />
      </Header>
      <Main>
        <SubTitle>Hotline Numbers</SubTitle>
        <Footer>
          {hotlineNumbers?.map((data, ind) => (
            <ContactBox key={ind} data={data} width={width} color={'red'} />
          ))}
        </Footer>
        <SubTitle>Regional Clinics Contacts</SubTitle>
        <Footer>
          {regionalClinicsContacts?.map((data, ind) => (
            <ContactBox key={ind} data={data} width={width} color={'blue'} />
          ))}
        </Footer>
        <SubTitle>APIA Contacts</SubTitle>
        <Footer>
          {APIAContacts?.map((data, ind) => (
            <ContactBox key={ind} data={data} width={width} />
          ))}
        </Footer>
      </Main>
    </Wrapper>
  );
};
