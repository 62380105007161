import { Wrapper } from '../../../../../components/Main/Main.style';
import { EDUCATION, GRID_XS, MAX_WIDTH } from '../../../../../utils/constants';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
	RequiredRule,
} from 'devextreme-react/validator';

export const Education = ({
	loading,

	educations,
	livingArrangements,
	livingConditions,

	education,
	otherEducation,
	lastGradeEducation,
	gradeSchoolEducation,
	livingArrangement,
	otherLivingArrangement,
	livingCondition,

	onChangeEducation,
	onChangeOtherEducation,
	onChangeLastGradeEducation,
	onToggleGradeSchoolEducation,
	onChangeLivingArrangement,
	onChangeOtherLivingArrangement,
	onChangeLivingCondition,

	onSaveEducation,
	onSaveeAndContinueEducation,
}) => {
	return (
		<Wrapper id={EDUCATION} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${EDUCATION}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Education *">
							<>
								<SelectBox
									label="Education *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={education}
									dataSource={educations}
									onValueChanged={onChangeEducation}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-education' }}
								>
									<Validator validationGroup={EDUCATION}>
										<RequiredRule
											message="Education is required"
										/>
										<RangeRule
											message="Education is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					{
						education === (educations.find((e) => e?.Name === "Other")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Specify Education *">
								<>
									<TextBox
										label="Specify Education *"
										value={otherEducation}
										onValueChanged={onChangeOtherEducation}
										maxLength={64}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-otherEducation' }}>
										<Validator validationGroup={EDUCATION}>
											<RequiredRule
												message="Specify Education is required"
											/>
										</Validator>
									</TextBox>
								</>
							</Tooltip>
						</Grid>
					}
					{
						education === (educations.find((e) => e?.Name === "K-11")?.Oid) &&
						<>
							<Grid item xs={GRID_XS}>
								<Tooltip title="What is the last grade you completed? *">
									<>
										<TextBox
											label="What is the last grade you completed? *"
											value={lastGradeEducation}
											onValueChanged={onChangeLastGradeEducation}
											maxLength={64}
											labelMode="floating"
											stylingMode="outlined"
											inputAttr={{ autocomplete: 'new-lastGradeEducation' }}>
											<Validator validationGroup={EDUCATION}>
												<RequiredRule
													message="Last grade is required"
												/>
											</Validator>
										</TextBox>
									</>
								</Tooltip>
							</Grid>
							<Grid item xs={GRID_XS}>
								<Tooltip title="Currently attending grade school?">
									<>
										<CheckBox
											text="Currently attending grade school?"
											value={gradeSchoolEducation}
											onValueChanged={onToggleGradeSchoolEducation} />
									</>
								</Tooltip>
							</Grid>
						</>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="Living Arrangement">
							<>
								<SelectBox
									label="Living Arrangement"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={livingArrangement}
									dataSource={livingArrangements}
									onValueChanged={onChangeLivingArrangement}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									showClearButton={true}
									inputAttr={{ autocomplete: 'new-livingArrangement' }}
								/>
							</>
						</Tooltip>
					</Grid>
					{
						livingArrangement === (livingArrangements.find((e) => e?.Name === "Other")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Specify Living Arrangement *">
								<>
									<TextBox
										label="Specify Living Arrangement *"
										value={otherLivingArrangement}
										onValueChanged={onChangeOtherLivingArrangement}
										maxLength={64}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-otherLivingArrangement' }}>
										<Validator validationGroup={EDUCATION}>
											<RequiredRule
												message="Specify Living Arrangement is required"
											/>
										</Validator>
									</TextBox>
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="How satisfied are you with your living conditions? *">
							<>
								<SelectBox
									label="How satisfied are you with your living conditions? *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={livingCondition}
									dataSource={livingConditions.filter(d => d.IsActive)}
									onValueChanged={onChangeLivingCondition}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-livingCondition' }}
								>
									<Validator validationGroup={EDUCATION}>
										<RequiredRule
											message="Living Condition is required"
										/>
										<RangeRule
											message="Living Condition is required"
											min={0} />
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={EDUCATION}
							onClick={onSaveEducation} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={EDUCATION}
							onClick={onSaveeAndContinueEducation} />
					</Grid>
				</Grid>
			}
		</Wrapper>
	);
};