import { Wrapper, Title, Text, LinkWrapper, HelperText, IconButtonStyled } from './SignUp.style';
import { Validator, RequiredRule, EmailRule } from 'devextreme-react/validator';

import TextBoxStyled from '../../../components/Auth/Textbox/Textbox.style';
import ButtonStyled from "../../../components/Auth/Button/Button.style";
import Link from '../../../components/Link/Link.style';
import { GRID_XS, GRID_SM } from '../../../utils/constants';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';
import { useState } from 'react';


export const SignUp = ({
    errorStatus,

    userName,
    email,
    password,
    confirmPassword,

    onChangeUserName,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,

    handleClick,
    onKeyPressEnter,
    btnStatus,
}) => {
    const [firstMode, setFirstMode] = useState("password");
    const [secondMode, setSecondMode] = useState("password");

    const changeFirstMode = () => {
        setFirstMode(firstMode === "text" ? "password" : "text");
    };

    const changeSecondMode = () => {
        setSecondMode(secondMode === "text" ? "password" : "text");
    };

    return (
        <Wrapper>
            <Title>Sign up</Title>
            <Grid container spacing={1}>
                <Grid item xs={GRID_XS} title="Name">
                    <TextBoxStyled
                        label="Name"
                        value={userName}
                        onValueChanged={onChangeUserName}
                        onKeyPress={onKeyPressEnter}
                        labelMode="static"
                        stylingMode="outlined"
                    >
                        <Validator>
                            <RequiredRule
                                message="Name is required"
                            />
                        </Validator>
                    </TextBoxStyled>
                </Grid>
                <Grid item xs={GRID_XS} title="Email">
                    <TextBoxStyled
                        label="Email"
                        value={email}
                        onValueChanged={onChangeEmail}
                        onKeyPress={onKeyPressEnter}
                        labelMode="static"
                        stylingMode="outlined"
                    >
                        <Validator>
                            <RequiredRule
                                message="Email is required"
                            />
                            <EmailRule
                                message="Email is invalid"
                            />
                        </Validator>
                    </TextBoxStyled>
                </Grid>
                <Grid item xs={GRID_XS} sm={GRID_SM} title="Password">
                    <TextBoxStyled
                        label="Password"
                        mode={firstMode}
                        value={password}
                        onValueChanged={onChangePassword}
                        onKeyPress={onKeyPressEnter}
                        labelMode="static"
                        stylingMode="outlined"
                    >
                        <IconButtonStyled onClick={changeFirstMode}>
                            <Icon
                                icon={firstMode === "text" ? "bx:hide" : "bx:show"}
                                height="30px"
                                width="30px" />
                        </IconButtonStyled>
                        <Validator>
                            <RequiredRule message="Password is required" />
                        </Validator>
                    </TextBoxStyled>
                </Grid>
                <Grid item xs={GRID_XS} sm={GRID_SM} title="Confirm Password">
                    <TextBoxStyled
                        label="Confirm Password"
                        mode={secondMode}
                        value={confirmPassword}
                        onValueChanged={onChangeConfirmPassword}
                        onKeyPress={onKeyPressEnter}
                        labelMode="static"
                        stylingMode="outlined"
                    >
                        <IconButtonStyled onClick={changeSecondMode}>
                            <Icon
                                icon={firstMode === "text" ? "bx:hide" : "bx:show"}
                                height="30px"
                                width="30px" />
                        </IconButtonStyled>
                        <Validator>
                            <RequiredRule message="Password is required" />
                        </Validator>
                    </TextBoxStyled>
                </Grid>
                <Grid item xs={GRID_XS}>
                    <HelperText>
                        {!!errorStatus && errorStatus?.join(' ')}
                    </HelperText>
                </Grid>
                <Grid item xs={GRID_XS}>
                    <ButtonStyled
                        text="register"
                        onClick={handleClick}
                        disabled={btnStatus}
                    />
                </Grid>
                <Grid item xs={GRID_XS}>
                    <LinkWrapper>
                        <Text>Have an account?</Text>
                        <Link to="/auth/login">log in</Link>
                    </LinkWrapper>
                </Grid>
            </Grid>
        </Wrapper>
    );
}