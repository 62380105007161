import { Wrapper, Grid, Item, Label, TextBox, SelectBox, CheckBoxWrapper, CheckBox, Buttons } from '../../BasicInformation.style';
import { EDUCATION, MAX_WIDTH, GRID_XS, GRID_SM, GRID_LG } from '../../../../../../utils/constants';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
	RequiredRule,
} from 'devextreme-react/validator';

export const Education = ({
	showButton,

	educations,
	livingArrangements,
	livingConditions,

	getEntityPatientProfileEducation,
	onChangeFunc,

	onCancelEducation,
	onSaveEducation,
}) => {
	return (
		<Wrapper id={EDUCATION} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileEducation('loading')}
				position={{ of: `#${EDUCATION}` }} />
			{
				!getEntityPatientProfileEducation('loading') &&
				<Grid container spacing={2}>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Education *">
							<Label>Education:</Label>
						</Tooltip>
						<SelectBox
							label="Education *"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileEducation('education')}
							dataSource={educations}
							onValueChanged={e => onChangeFunc('Education', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-education}' }}
						>
							<Validator validationGroup={EDUCATION}>
								<RequiredRule
									message="Education is required"
								/>
								<RangeRule
									message="Education is required"
									min={0} />
							</Validator>
						</SelectBox>
					</Item>
					{
						getEntityPatientProfileEducation('education') === (educations.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Specify Education *">
								<Label>Specified Education:</Label>
							</Tooltip>
							<TextBox
								label="Specify Education *"
								value={getEntityPatientProfileEducation('otherEducation')}
								onValueChanged={e => onChangeFunc('OtherEducation', e, true)}
								maxLength={64}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-otherEducation' }}
							>
								<Validator validationGroup={EDUCATION}>
									<RequiredRule
										message="Specify Education is required"
									/>
								</Validator>
							</TextBox>
						</Item>
					}
					{
						getEntityPatientProfileEducation('education') === (educations.find((e) => e?.Name === "K-11")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="What is the last grade you completed? *">
								<Label>Last grade completed:</Label>
							</Tooltip>
							<TextBox
								label="What is the last grade you completed? *"
								value={getEntityPatientProfileEducation('lastGradeEducation')}
								onValueChanged={e => onChangeFunc('LastGradeEducation', e, true)}
								maxLength={64}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-lastGradeEducation' }}
							>
								<Validator validationGroup={EDUCATION}>
									<RequiredRule
										message="Last grade is required"
									/>
								</Validator>
							</TextBox>
						</Item>
					}
					{
						getEntityPatientProfileEducation('education') === (educations.find((e) => e?.Name === "K-11")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Currently in grade school">
								<Label>Currently in grade school:</Label>
							</Tooltip>
							<CheckBoxWrapper>
								<CheckBox
									value={getEntityPatientProfileEducation('gradeSchoolEducation')}
									onClick={() => onChangeFunc('GradeSchoolEducation')} >
									{getEntityPatientProfileEducation('gradeSchoolEducation') ? 'Yes' : 'No'}
								</CheckBox>
							</CheckBoxWrapper>
						</Item>
					}
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Living Arrangement *">
							<Label>Living Arrangement:</Label>
						</Tooltip>
						<SelectBox
							label="Living Arrangement *"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileEducation('livingArrangement')}
							dataSource={livingArrangements}
							onValueChanged={e => onChangeFunc('LivingArrangement', e)}
							showDropDownButton={false}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-livingArrangement' }}
						>
							<Validator validationGroup={EDUCATION}>
								<RequiredRule
									message="Living Arrangement is required"
								/>
								<RangeRule
									message="Living Arrangement is required"
									min={0} />
							</Validator>
						</SelectBox>
					</Item>
					{
						getEntityPatientProfileEducation('livingArrangement') === (livingArrangements.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
							<Tooltip title="Specify Living Arrangement *">
								<Label>Specified Living Arrangement:</Label>
							</Tooltip>
							<TextBox
								label="Specify Living Arrangement *"
								value={getEntityPatientProfileEducation('otherLivingArrangement')}
								onValueChanged={e => onChangeFunc('OtherLivingArrangement', e, true)}
								maxLength={64}
								labelMode="hidden"
								stylingMode="underlined"
								inputAttr={{ autocomplete: 'new-otherLivingArrangement' }}
							>
								<Validator validationGroup={EDUCATION}>
									<RequiredRule
										message="Other Living Arrangement is required"
									/>
								</Validator>
							</TextBox>
						</Item>
					}
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="How satisfied are you with your living conditions? *">
							<Label>Living Conditions:</Label>
						</Tooltip>
						<SelectBox
							label="How satisfied are you with your living conditions? *"
							valueExpr="Oid"
							displayExpr="Name"
							defaultValue={getEntityPatientProfileEducation('livingCondition')}
							dataSource={livingConditions.filter(d => d.IsActive)}
							onValueChanged={e => onChangeFunc('LivingCondition', e)}
							validationMessagePosition="bottom"
							labelMode="hidden"
							stylingMode="underlined"
							searchEnabled={true}
							inputAttr={{ autocomplete: 'new-livingCondition' }}
						>
							<Validator validationGroup={EDUCATION}>
								<RequiredRule
									message="Living Condition is required"
								/>
								<RangeRule
									message="Living Condition is required"
									min={0} />
							</Validator>
						</SelectBox>
					</Item>
					<Item item xs={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={EDUCATION}
								onClick={onSaveEducation} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelEducation} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper>
	);
};