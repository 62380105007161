import { useParams } from 'react-router-dom';
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Wrapper } from "./Forms.style";
import Status from '../../../../components/Status/Status';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { SELECT_INVITED } from '../../../../utils/constants';
import { isDisabledFromDownload } from '../../../../utils/helper';
import Tooltip from '@mui/material/Tooltip';

export const Forms = ({
	data, loading, loadIndicator, onClickDownloadForm, onClickGoToForm
}) => {
	const { id } = useParams();
	const sectionName = 'patientForms';
	return (
		<Wrapper>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{!loading && <DataGrid
				height={window.innerHeight - 200}
				columnMinWidth={100}
				dataSource={data}
				showBorders={false}
				showRowLines={true}
				showColumnLines={false}
				hoverStateEnabled={true}
				wordWrapEnabled={true}
				columnAutoWidth={true}>
				<Column
					dataField="readableFormName"
					caption="Form title"
					cellRender={({ data }) => {
						return (
							<div style={!data.completeness ? { color: 'var(--grey-text-color)' } : {}}>
								{data.readableFormName}
							</div>
						);
					}}
				/>
				<Column alignment="left" dataField="completeness" caption="Status" cellRender={({ data }) => Status({ value: data.completeness ? 'complete' : 'incomplete' })} />
				<Column
					dataField="createDate"
					caption="Created"
					cellRender={({ data }) => {
						return (
							<div style={!data.completeness ? { color: 'var(--grey-text-color)' } : {}}>
								{moment(data.createDate, moment.ISO_8601).format('MM/DD/YYYY')}
							</div>
						);
					}}
				/>
				<Column
					dataField="updateDate"
					caption="Last Updated"
					cellRender={({ data }) => {
						return (
							<div style={!data.completeness ? { color: 'var(--grey-text-color)' } : {}}>
								{moment(data.updateDate, moment.ISO_8601).format('MM/DD/YYYY')}
							</div>
						);
					}}
				/>
				<Column
					cellRender={
						({ data }) =>
							<div style={{ display: "flex", justifyContent: 'end' }}>
								<Tooltip title={`Link to "${data.readableFormName}" form`}>
									<span>
										<IconButton
											onClick={() => onClickGoToForm(SELECT_INVITED.toLocaleLowerCase(), `${data.link}/${id}`)}
											title={`Link to "${data.readableFormName}" form`}
										>
											<Icon
												icon="bx:edit"
												height="24px"
												width="24px"
												color="var(--light-blue-color)"
											/>
										</IconButton>
									</span>
								</Tooltip>
								<Tooltip title={`Download "${data.readableFormName}" form`}>
									<span>
										<IconButton
											disabled={isDisabledFromDownload(data.formName) || loadIndicator === data.formName}
											onClick={() => onClickDownloadForm(id, data.formName)}>
											{loadIndicator === data.formName ? (
												<LoadIndicator
													className="button-indicator"
													visible={loadIndicator && true}
													id="small-indicator"
													height={24}
													width={24}
												/>
											) : (
												<Icon
													icon="material-symbols:download-rounded"
													height="24px"
													width="24px"
													color={isDisabledFromDownload(data.formName) ? 'var(--grey-text-color)' : 'var(--light-blue-color)'}
												/>
											)}
										</IconButton>
									</span>
								</Tooltip>
							</div>
					} />
				<Pager
					visible={true}
					displayMode="full"
					label="Rows per page:"
					showPageSizeSelector={true}
					showNavigationButtons={true}
					allowedPageSizes={[5, 10, 'all']} />
				<Paging defaultPageSize={10} />
			</DataGrid>
			}
		</Wrapper >
	);
}