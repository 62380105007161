import { getEducationById, postEducationById } from "./middlewares";
import { showToast } from "../../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    education: "",
    otherEducation: "",
    lastGradeEducation: "",
    gradeSchoolEducation: false,
    livingArrangement: "",
    otherLivingArrangement: "",
    livingCondition: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const patientProfileEducation = createSlice({
    name: "patientProfileEducation",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setEducation: (state, action) => {
            state.education = action.payload;
            return state;
        },
        setOtherEducation: (state, action) => {
            state.otherEducation = action.payload;
            return state;
        },
        setLastGradeEducation: (state, action) => {
            state.lastGradeEducation = action.payload;
            return state;
        },
        toggleGradeSchoolEducation: (state) => {
            state.gradeSchoolEducation = !state.gradeSchoolEducation;
            return state;
        },
        setLivingArrangement: (state, action) => {
            state.livingArrangement = action.payload;
            return state;
        },
        setOtherLivingArrangement: (state, action) => {
            state.otherLivingArrangement = action.payload;
            return state;
        },
        setLivingCondition: (state, action) => {
            state.livingCondition = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEducationById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postEducationById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getEducationById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.education = action.payload?.education;
                state.otherEducation = action.payload?.otherEducation;
                state.lastGradeEducation = action.payload?.lastGradeEducation;
                state.gradeSchoolEducation = action.payload?.gradeSchoolEducation;
                state.livingArrangement = action.payload?.arrangement;
                state.otherLivingArrangement = action.payload?.otherArrangement;
                state.livingCondition = action.payload?.conditions;

                state.loading = false;
            })
            .addCase(postEducationById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", " Education / Housing saved successfully");
            })
            .addCase(getEducationById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Education / Housing: ${action.error.message}`);
            })
            .addCase(postEducationById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Education / Housing: ${action.error.message}`);
            });
    },
});

export const {
    setLoading,

    setEducation,
    setOtherEducation,
    setLastGradeEducation,
    toggleGradeSchoolEducation,
    setLivingArrangement,
    setOtherLivingArrangement,
    setLivingCondition,
} = patientProfileEducation.actions;

export default patientProfileEducation.reducer;
