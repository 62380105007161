import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { postSignup } from "./middlewares";

const initialState = {
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    status: FETCH_STATUSES.IDLE,
    error: null,
    btnStatus: false,
};
export const signup = createSlice({
    name: "signup",
    initialState,
    reducers: {
        setUserName: (state, action) => {
            state.userName = action.payload;
            return state;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
            return state;
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postSignup.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
                state.btnStatus = true;
            })
            .addCase(postSignup.fulfilled, (state, action) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.error = null;
                state.btnStatus = false;
            })
            .addCase(postSignup.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = Object.values(action.payload.errors) ?? ['Server error'];
                state.btnStatus = false;
            });
    },

});

export const {
    setUserName,
    setEmail,
    setPassword,
    setConfirmPassword,
} = signup.actions;

export default signup.reducer;
