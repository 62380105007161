import { Wrapper, Item, Label, TextBox, SelectBox, CheckBox, Buttons } from '../../BasicInformation.style';
import { MAX_WIDTH, TRIBAL_AFFILIATION, GRID_XS } from '../../../../../../utils/constants';
import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	PatternRule,
} from 'devextreme-react/validator';
import { NAME_RULE } from '../../../../../../utils/rules';

const setSizeByWidth = (width) => {
	switch (true) {
		case (width <= 600): return 12;
		case (width > 600 && width <= 900): return 6;
		case (width > 900 && width <= 1200): return 4;
		case (width > 1200 && width <= 1500): return 3;
		case (width > 1500): return 2;
		default: return 12;
	};
};

export const TribalAffiliation = ({
	showButton,

	ethnicities,
	races,
	languages,

	getEntityPatientProfileTribalAffiliation,
	onChangeFunc,

	onCancelTribalAffiliation,
	onSaveTribalAffiliation,
}) => {
	const { width, ref } = useResizeDetector();
	const [size, setSize] = useState(12);

	useEffect(() => {
		setSize(setSizeByWidth(width));
	}, [width]);

	return (
		<Wrapper id={TRIBAL_AFFILIATION} ref={ref} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={getEntityPatientProfileTribalAffiliation('loading')}
				position={{ of: `#${TRIBAL_AFFILIATION}` }} />
			{
				!getEntityPatientProfileTribalAffiliation('loading') &&
				<Grid container spacing={2}>
					<Item item xs={size}>
						<Tooltip title="Tribal/Native Corporation">
							<span>
								<Label>Tribal/Native Corp:</Label>
								<TextBox
									label="Tribal/Native Corporation"
									value={getEntityPatientProfileTribalAffiliation('corporation')}
									onValueChanged={(e) => onChangeFunc('Corporation', e, true)}
									maxLength={140}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-corporation' }}
								>
									<Validator validationGroup={TRIBAL_AFFILIATION}>
										<PatternRule
											message="Don't use numbers"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Tribal Enrollment #">
							<span>
								<Label>Tribal Enrollment #:</Label>
								<TextBox
									label="Tribal Enrollment #"
									value={getEntityPatientProfileTribalAffiliation('enrollment')}
									onValueChanged={(e) => onChangeFunc('Enrollment', e, true)}
									maxLength={140}
									labelMode="hidden"
									stylingMode="underlined"
									inputAttr={{ autocomplete: 'new-enrollment' }} />
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="CIB">
							<span>
								<Label>CIB:</Label>
								<CheckBox
									value={getEntityPatientProfileTribalAffiliation('CIB')}
									onClick={() => onChangeFunc('CIB')} >
									{getEntityPatientProfileTribalAffiliation('CIB') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="CIB Card in Medical Record">
							<span>
								<Label>CIB Card in Medical Record:</Label>
								<CheckBox
									value={getEntityPatientProfileTribalAffiliation('CIBCard')}
									onClick={() => onChangeFunc('CIBCard')} >
									{getEntityPatientProfileTribalAffiliation('CIBCard') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Ethnicity">
							<span>
								<Label>Ethnicity:</Label>
								<SelectBox
									label="Ethnicity"
									defaultValue={getEntityPatientProfileTribalAffiliation('ethnicity')}
									dataSource={ethnicities}
									onValueChanged={(e) => onChangeFunc('Ethnicity', e)}
									valueExpr="Oid"
									displayExpr="Name"
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-ethnicity' }}
								/>
							</span>
						</Tooltip>
					</Item>
					<Item item xs={size}>
						<Tooltip title="Race">
							<span>
								<Label>Race:</Label>
								<SelectBox
									label="Race"
									defaultValue={getEntityPatientProfileTribalAffiliation('race')}
									dataSource={races}
									onValueChanged={(e) => onChangeFunc('Race', e)}
									valueExpr="Oid"
									displayExpr="Name"
									showDropDownButton={false}
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-race' }}
								/>
							</span>
						</Tooltip>
					</Item>
					{
						!![getEntityPatientProfileTribalAffiliation('race')] && [getEntityPatientProfileTribalAffiliation('race')].includes(races.find((e) => e?.Name === "Alaska Native")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Alaska Native Group">
								<span>
									<Label>Alaska Native Group:</Label>
									<TextBox
										label="Alaska Native Group"
										value={getEntityPatientProfileTribalAffiliation('raceAlaska')}
										onValueChanged={(e) => onChangeFunc('RaceAlaska', e, true)}
										maxLength={30}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-raceAlaska' }}
									>
										<Validator validationGroup={TRIBAL_AFFILIATION}>
											<PatternRule
												message="Don't use numbers"
												pattern={NAME_RULE}
											/>
										</Validator>
									</TextBox>
								</span>
							</Tooltip>
						</Item>
					}
					{
						!![getEntityPatientProfileTribalAffiliation('race')] && [getEntityPatientProfileTribalAffiliation('race')].includes(races.find((e) => e?.Name === "American Indian")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="American Indian Group">
								<span>
									<Label>American Indian Group:</Label>
									<TextBox
										label="American Indian Group"
										value={getEntityPatientProfileTribalAffiliation('raceAmerican')}
										onValueChanged={(e) => onChangeFunc('RaceAmerican', e, true)}
										maxLength={30}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-raceAmerican' }}
									>
										<Validator validationGroup={TRIBAL_AFFILIATION}>
											<PatternRule
												message="Don't use numbers"
												pattern={NAME_RULE}
											/>
										</Validator>
									</TextBox>
								</span>
							</Tooltip>
						</Item>
					}
					<Item item xs={size}>
						<Tooltip title="Primary Language if not English">
							<span>
								<Label>Primary Language:</Label>
								<SelectBox
									label="Primary Language if not English"
									defaultValue={Array.isArray(getEntityPatientProfileTribalAffiliation('language')) ? getEntityPatientProfileTribalAffiliation('language')[0] : getEntityPatientProfileTribalAffiliation('language')}
									dataSource={languages}
									onValueChanged={(e) => onChangeFunc('Language', e)}
									valueExpr="Oid"
									displayExpr="Name"
									validationMessagePosition="bottom"
									labelMode="hidden"
									stylingMode="underlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-language' }}
								/>
							</span>
						</Tooltip>
					</Item>
					{
						!![getEntityPatientProfileTribalAffiliation('language')] && [getEntityPatientProfileTribalAffiliation('language')].includes(languages.find((e) => e?.Name === "Other")?.Oid) &&
						<Item item xs={size}>
							<Tooltip title="Specify Primary Language">
								<span>
									<Label>Specified Language:</Label>
									<TextBox
										label="Specify Primary Language"
										value={getEntityPatientProfileTribalAffiliation('otherLanguage')}
										onValueChanged={(e) => onChangeFunc('OtherLanguage', e, true)}
										maxLength={30}
										labelMode="hidden"
										stylingMode="underlined"
										inputAttr={{ autocomplete: 'new-otherLanguage' }}
									>
										<Validator validationGroup={TRIBAL_AFFILIATION}>
											<PatternRule
												message="Don't use numbers"
												pattern={NAME_RULE}
											/>
										</Validator>
									</TextBox>
								</span>
							</Tooltip>
						</Item>
					}
					<Item item xs={size}>
						<Tooltip title="Translator Required">
							<span>
								<Label>Translator Required:</Label>
								<CheckBox
									value={getEntityPatientProfileTribalAffiliation('translator')}
									onClick={() => onChangeFunc('Translator')} >
									{getEntityPatientProfileTribalAffiliation('translator') ? 'Yes' : 'No'}
								</CheckBox>
							</span>
						</Tooltip>
					</Item>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={TRIBAL_AFFILIATION}
								onClick={onSaveTribalAffiliation} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelTribalAffiliation} />							
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};