import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import SelectBox from 'devextreme-react/select-box';

import { setFilteredInvitations, setCurrentFilterState, setActivefilterValue, setSelectedFilterValue } from "../../../store/users/InvitedList/slice";
import { selectInvitedList, selectSelectedFilterValue } from "../../../store/users/InvitedList/selectors";

const values = ['All', 'Active', 'Not Active'];

export const SwitcherInviteStatus = () => {
	const dispatch = useDispatch();
	const dataSource = useSelector(selectInvitedList);
	const selectedFilterValue = useSelector(selectSelectedFilterValue);
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(dataSource);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (event) => {
		switch (event.value) {
			case values[0]:
				dispatch(setSelectedFilterValue(0))
				dispatch(setFilteredInvitations(data));
				dispatch(setCurrentFilterState(false));
				return;
			case values[1]:
				dispatch(setSelectedFilterValue(1))
				const dataActive = data.filter(invite => invite.id);
				dispatch(setActivefilterValue(dataActive));
				dispatch(setFilteredInvitations(dataActive));
				dispatch(setCurrentFilterState(true));
				return;
			case values[2]:
				dispatch(setSelectedFilterValue(2))
				const dataNotActive = data.filter(invite => !invite.id);
				dispatch(setActivefilterValue(dataNotActive));
				dispatch(setFilteredInvitations(dataNotActive));
				dispatch(setCurrentFilterState(true));
				return;
			default:
				dispatch(setSelectedFilterValue(0))
				dispatch(setFilteredInvitations(data));
				dispatch(setCurrentFilterState(false));
				return;
		}
	};

	return (
		<SelectBox
			placeholder="Sort by..."
			value={values[selectedFilterValue]}
			dataSource={values}
			onValueChanged={onChange}
			labelMode="floating"
			stylingMode="outlined"
			style={{ height: '40px' }}
			searchEnabled={true}
			inputAttr={{ autocomplete: 'new-selectedFilterValue' }}
		/>
	);
}