export const selectLoading = state => state.newPatientRegistrationNextOfKin.loading;

export const selectCheckbox = state => state.newPatientRegistrationNextOfKin.checkbox;
export const selectNextOfKin = state => state.newPatientRegistrationNextOfKin.nextOfKin;
export const selectRelationship = state => state.newPatientRegistrationNextOfKin.relationship;
export const selectAddress = state => state.newPatientRegistrationNextOfKin.address;
export const selectCity = state => state.newPatientRegistrationNextOfKin.city;
export const selectState = state => state.newPatientRegistrationNextOfKin.state;
export const selectZip = state => state.newPatientRegistrationNextOfKin.zip;
export const selectPhone = state => state.newPatientRegistrationNextOfKin.phone;
export const selectEmail = state => state.newPatientRegistrationNextOfKin.email;