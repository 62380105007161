import { BASE_URL, TRIBAL_AFFILIATION } from "../../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getTribalAffiliationById = createAsyncThunk(
    "getTribalAffiliationById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${TRIBAL_AFFILIATION}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postTribalAffiliationById = createAsyncThunk(
    "postTribalAffiliationById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${TRIBAL_AFFILIATION}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveTribalAffiliationById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileTribalAffiliation;

    const data = {
        "corporation": store.corporation,
        "enrollment": store.enrollment,
        "ethnicity": store.ethnicity ? store.ethnicity : null,
        "race": store.race ? store.race : null,
        "raceAlaska": store.raceAlaska,
        "raceAmerican": store.raceAmerican,
        "language": Array.isArray(store.language) ? store.language : store.language === null ? null : [store.language],
        "otherLanguage": store.otherLanguage,
        "CIB": store.CIB,
        "CIBCard": store.CIBCard,
        "translator": store.translator
    }

    dispatch(postTribalAffiliationById({ id, data }));
}