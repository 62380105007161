export const selectLoading = state => state.newPatientRegistrationGuardianInfo.loading;

export const selectCheckbox = state => state.newPatientRegistrationGuardianInfo.checkbox;
export const selectFirstName = state => state.newPatientRegistrationGuardianInfo.firstName;
export const selectMiddleName = state => state.newPatientRegistrationGuardianInfo.middleName;
export const selectLastName = state => state.newPatientRegistrationGuardianInfo.lastName;
export const selectDateOfBirth = state => state.newPatientRegistrationGuardianInfo.dateOfBirth;
export const selectAddress = state => state.newPatientRegistrationGuardianInfo.address;
export const selectCity = state => state.newPatientRegistrationGuardianInfo.city;
export const selectState = state => state.newPatientRegistrationGuardianInfo.state;
export const selectZip = state => state.newPatientRegistrationGuardianInfo.zip;
export const selectPhone = state => state.newPatientRegistrationGuardianInfo.phone;
export const selectEmail = state => state.newPatientRegistrationGuardianInfo.email;
export const selectEmployer = state => state.newPatientRegistrationGuardianInfo.employer;
