import { BASE_URL, EMPLOYMENT } from "../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getEmployment = createAsyncThunk(
    "getEmployment",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${EMPLOYMENT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getEmploymentByID = createAsyncThunk(
    "getEmploymentByID",
    async (patientID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${patientID}/${EMPLOYMENT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postEmployment = createAsyncThunk(
    "postEmployment",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${EMPLOYMENT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postEmploymentByID = createAsyncThunk(
    "postEmploymentByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${EMPLOYMENT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveEmployment = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationEmployment;

    const data = {
        "employmentStatus": store.employment,
        "employer": store.employer,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "primatyIncomeSource": store.primaryIncomeSource,
        "source": store.otherPrimaryIncomeSource,
    }

    dispatch(postEmployment(data));
}

export const saveEmploymentByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationEmployment;

    const data = {
        "employmentStatus": store.employment,
        "employer": store.employer,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "primatyIncomeSource": store.primaryIncomeSource,
        "source": store.otherPrimaryIncomeSource,
    }

    dispatch(postEmploymentByID({ id, data }));
}

