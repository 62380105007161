import { Navigate } from 'react-router-dom';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { createTheme } from '@mui/material';
import Notify from 'devextreme/ui/notify';
import { useAuth } from '../../core/auth/authProvider';
import { authRole } from '../../core/auth/authProvider';
import { selectOpen, selectUserName } from '../../store/main/selectors';
import { setOpen } from '../../store/main/slice';
import { Main } from './Main';
import { getUserProfile } from "../../store/main/middlewares";
//import zIndex from '@mui/material/styles/zIndex';

const theme = createTheme({
	typography: {
		fontFamily: 'Montserrat',
		TextField: {
			fontSize: '10px',
		},
	},
});

export const showToast = (type = "info", message = "", displayTime = 3000) => {
	Notify({
		message: message,
		type: type.toLowerCase(),
		minHeight: 45,
		minWidth: 150,
		displayTime: displayTime,
		animation: {
			show: { type: 'fade', duration: 400, from: 0, to: 1 },
			hide: { type: 'fade', duration: 40, to: 0 },
		},
	}, {
		position: {
			bottom: 10,
			right: 10,
		},
		direction: 'up-push',
	});
}

export const MainContainer = () => {
	const dispatch = useDispatch();
	const isLogged = useAuth();

	useEffect(() => {
		dispatch(getUserProfile());
	}, [dispatch]);

	const open = useSelector(selectOpen);
	const userName = useSelector(selectUserName);

	const onChangeOpen = (value) => {
		dispatch(setOpen(value));
	}

	if (!isLogged) {
		return <Navigate to="/" replace />;
	}

	return (
		<Main
			theme={theme}
			open={open}
			onChangeOpen={onChangeOpen}
			userName={userName}
			authRole={authRole}
		/>
	);
}
