import { CssBaseline, Box, IconButton, Toolbar, /*Avatar, Badge,*/ ThemeProvider } from '@mui/material';
import { AppBar, TitleBox, AppBox, Logo, Wrapper, Title, SubTitle, DrawerHeader } from './Main.style';
// import Search from '../../components/Search/Search'
import { Navigation } from './Navigation/Navigation';
// import avatar from '../../images/avatar.png';
import { Outlet } from 'react-router-dom';
import { Icon } from '@iconify/react';

export const Main = ({
	theme,
	open,
	onChangeOpen,
	userName,
	authRole
}) => {
	return (
		<ThemeProvider theme={theme}>
			<Wrapper>
				<CssBaseline />
				<AppBar position="fixed" open={open}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => onChangeOpen(true)} sx={{ ...(open && { display: 'none' }) }}>
							<Icon color="var(--grey-text-color)" icon="quill:hamburger" />
						</IconButton>
						<Logo style={{ ...(open ? { display: 'none' } : { margin: '0 70px 0 35px' }) }} />
						<TitleBox>
							<Title>Welcome back, {userName}!</Title>
							<SubTitle to="#">Aleutian Pribilof Islands Association Web Portal</SubTitle>
						</TitleBox>
						<Box sx={{ flexGrow: 1 }} />
						{/* <Search borderRadius="10px" />
						<IconButton aria-label="notifications" style={{ marginLeft: '30px' }}>
							<Badge variant="dot" color="error">
								<Icon icon="ion:notifications-outline" color="var(--grey-text-color)" height="24px" width="24px" />
							</Badge>
						</IconButton>
						<IconButton aria-label="support" style={{ marginLeft: '10px' }}>
							<Icon icon="fluent:question-circle-16-regular" color="var(--grey-text-color)" height="28px" width="28px" />
						</IconButton>
						<IconButton aria-label="avatar" sx={{ p: 0, marginLeft: '20px' }}>
							<Avatar alt="Andrew Brown" src={avatar} />
						</IconButton> */}
					</Toolbar>
				</AppBar>
				<Navigation authRole={authRole} open={open} onClick={() => onChangeOpen(false)} />
				<AppBox component="main" open={open}>
					<DrawerHeader />
					<Outlet />
				</AppBox>
			</Wrapper >
		</ThemeProvider>
	);
}
