import { BASE_URL, REFERRAL_INFO } from "../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getReferralInfo = createAsyncThunk(
    "getReferralInfo",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${REFERRAL_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getReferralInfoByID = createAsyncThunk(
    "getReferralInfoByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${REFERRAL_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postReferralInfo = createAsyncThunk(
    "postReferralInfo",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${REFERRAL_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postReferralInfoByID = createAsyncThunk(
    "postReferralInfoByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${REFERRAL_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveReferralInfo = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationReferralInfo;

    const data = {
        "reason": store.reason,
        "referralInfo": store.referralInfo,
        "otherReferralInfo": store.otherReferralInfo,
        "refferalForMedicalRN": store.refferalForMedicalRN,
        "contactedByPhysicalExam": store.contactedByPhysicalExam
    }

    dispatch(postReferralInfo(data));
}

export const saveReferralInfoByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationReferralInfo;

    const data = {
        "reason": store.reason,
        "referralInfo": store.referralInfo,
        "otherReferralInfo": store.otherReferralInfo,
        "refferalForMedicalRN": store.refferalForMedicalRN,
        "contactedByPhysicalExam": store.contactedByPhysicalExam
    }

    dispatch(postReferralInfoByID({ id, data }));
}