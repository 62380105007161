import { CURRENT_DATE, MAX_WIDTH, MIN_DATE, MAX_DOB, TEXT_DOB, GUARDIAN_INFO, ZIP_MASK, PHONE_MASK, GRID_XS, GRID_SM, GRID_MD } from '../../../../../utils/constants';
import { Wrapper } from '../../../../../components/Main/Main.style';
import { dateFormatFromApi } from '../../../../../utils/formats';
import { onKeyDownHandle } from '../../../../../utils/helper';
import { NAME_RULE } from '../../../../../utils/rules';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	EmailRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const GuardianInformation = ({
	loading,

	states,

	checkbox,
	firstName,
	middleName,
	lastName,
	dateOfBirth,
	address,
	city,
	state,
	zip,
	phone,
	email,
	employer,

	onToggleCheckbox,
	onChangeFirstName,
	onChangeMiddleName,
	onChangeLastName,
	onChangeDateOfBirth,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangePhone,
	onChangeEmail,
	onChangeEmployer,

	onSaveGuardianInfo,
	onSaveAndContinueGuardianInfo,
}) => {
	return (
		<Wrapper id={GUARDIAN_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${GUARDIAN_INFO}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Same as Patient">
							<>
								<CheckBox
									text="Same as Patient"
									value={checkbox}
									onValueChanged={onToggleCheckbox} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="First Name">
							<>
								<TextBox
									label="First Name"
									value={firstName}
									onValueChanged={onChangeFirstName}
									disabled={checkbox}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-firstName' }}
								>
									<Validator validationGroup={GUARDIAN_INFO}>
										<PatternRule
											message="Do not use digits in the First Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Middle Name">
							<>
								<TextBox
									label="Middle Name"
									value={middleName}
									onValueChanged={onChangeMiddleName}
									disabled={checkbox}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-middleName' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Last Name">
							<>
								<TextBox
									label="Last Name"
									value={lastName}
									onValueChanged={onChangeLastName}
									disabled={checkbox}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-lastName' }}
								>
									<Validator validationGroup={GUARDIAN_INFO}>
										<PatternRule
											message="Do not use digits in the Last Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="date">
							<>
								<DateBox
									type="date"
									label="Date of Birth"
									value={dateFormatFromApi(dateOfBirth)}
									onValueChanged={onChangeDateOfBirth}
									disabled={checkbox}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={GUARDIAN_INFO}>
										<RangeRule
											message="Date cannot be younger than the current date and earlier than 1900"
											min={MIN_DATE}
											max={CURRENT_DATE} />
										<RangeRule
											message={TEXT_DOB}
											max={MAX_DOB} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={8}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									disabled={checkbox}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={city}
									onValueChanged={onChangeCity}
									disabled={checkbox}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									disabled={checkbox}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={zip}
									onValueChanged={onChangeZip}
									disabled={checkbox}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} md={GRID_MD}>
						<Tooltip title="Phone">
							<>
								<TextBox
									label="Phone"
									value={phone}
									onValueChanged={onChangePhone}
									disabled={checkbox}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={8}>
						<Tooltip title="Email">
							<>
								<TextBox
									label="Email"
									value={email}
									onValueChanged={onChangeEmail}
									disabled={checkbox}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Employer">
							<>
								<TextBox
									label="Employer"
									value={employer}
									onValueChanged={onChangeEmployer}
									disabled={checkbox}
									maxLength={120}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-employer' }}
								>
									<Validator>
										<PatternRule
											message="Do not use digits in the Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={GUARDIAN_INFO}
							onClick={onSaveGuardianInfo} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={GUARDIAN_INFO}
							onClick={onSaveAndContinueGuardianInfo} />
					</Grid>
				</Grid>
			}
		</Wrapper>
	);
};