import { Accordion, AccordionSummary, AccordionDetails } from '../../../../components/Accordion/Accordion.style';
import { Wrapper, Panel, Title } from './BasicInformation.style';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

export const BasicInformation = ({
	steps,
	expanded,

	onClickExpand,
	onClickExpandAll,
	onClickCollapseAll,
}) => {
	return (
		<Wrapper style={{ padding: 0 }}>
			<Panel>
				<Button
					text="Expand all"
					icon="chevrondown"
					stylingMode="text"
					type="default"
					onClick={onClickExpandAll}
				/>
				<Button
					text="Collapse all"
					icon="chevronup"
					stylingMode="text"
					type="default"
					onClick={onClickCollapseAll}
				/>
			</Panel>
			<div>
				{steps.map((step, id) => (
					<Accordion
						key={id}
						expanded={expanded.includes(id)}
						onChange={() => onClickExpand(id)}
						TransitionProps={{ unmountOnExit: true }}>
						<AccordionSummary>
							<Tooltip title={step.title}>
								<Title>{step.title}</Title>
							</Tooltip>
						</AccordionSummary>
						<AccordionDetails>
							{step.body}
						</AccordionDetails>
					</Accordion>
				))}</div>
		</Wrapper>
	);
}
