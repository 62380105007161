import { BASE_URL, EMPLOYMENT } from "../../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getEmploymentById = createAsyncThunk(
    "getEmploymentById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${EMPLOYMENT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postEmploymentById = createAsyncThunk(
    "postEmploymentById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${EMPLOYMENT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveEmploymentById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileEmployment;

    const data = {
        "employmentStatus": store.employment,
        "employer": store.employer,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "primatyIncomeSource": store.primaryIncomeSource,
        "source": store.otherPrimaryIncomeSource,
    }

    dispatch(postEmploymentById({ id, data }));
}

