import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}));

export const ListItem = styled('div')(() => ({
    display: 'flex',
    gap: '30px',
    backgroundColor: 'transparent',
}));

export const ListItemIcon = styled('div')(() => ({
    width: '40px',
    height: '40px',
    color: 'var(--white-color)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'var(--grey-blue-color)',
    borderRadius: '50%',
    userSelect: 'none',
}));

export const ListItemText = styled('div')(() => ({
    fontFamily: 'Montserrat',
    color: 'var(--white-color)',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '34px',
    userSelect: 'none',
}));