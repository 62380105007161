import { useParams } from 'react-router-dom';
import { selectLoading, selectCIB, selectCIBCard, selectCorporation, selectEnrollment, selectEthnicity, selectLanguage, selectOtherLanguage, selectRace, selectRaceAlaska, selectRaceAmerican, selectTranslator } from "../../../../../store/forms/newPatientRegistration/tribalAffiliation/selectors";
import { setCorporation, setEnrollment, setEthnicity, setLanguage, setOtherLanguage, setRace, setRaceAlaska, setRaceAmerican, toggleCIB, toggleCIBCard, toggleTranslator } from "../../../../../store/forms/newPatientRegistration/tribalAffiliation/slice";
import { completeTribalAffiliation } from "../../../../../store/forms/newPatientRegistration/slice";

import { getTribalAffiliation, saveTribalAffiliation, getTribalAffiliationByID, saveTribalAffiliationByID } from "../../../../../store/forms/newPatientRegistration/tribalAffiliation/middlewares";
import { getEthnicities } from "../../../../../store/lookups/ethnicity/middlewares";
import { getRaces } from "../../../../../store/lookups/race/middlewares";
import { getLanguages } from "../../../../../store/lookups/language/middlewares";

import { selectEthnicities } from "../../../../../store/lookups/ethnicity/selectors";
import { selectRaces } from "../../../../../store/lookups/race/selectors";
import { selectLanguages } from "../../../../../store/lookups/language/selectors";

import { validateGroup } from "../../../../../utils/helper";
import { TribalAffiliation } from "./TribalAffiliation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const TribalAffiliationContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const ethnicities = useSelector(selectEthnicities);
    const races = useSelector(selectRaces);
    const languages = useSelector(selectLanguages);

    const corporation = useSelector(selectCorporation);
    const enrollment = useSelector(selectEnrollment);
    const race = useSelector(selectRace);
    const raceAlaska = useSelector(selectRaceAlaska);
    const raceAmerican = useSelector(selectRaceAmerican);
    const language = useSelector(selectLanguage);
    const otherLanguage = useSelector(selectOtherLanguage);
    const CIB = useSelector(selectCIB);
    const CIBCard = useSelector(selectCIBCard);
    const ethnicity = useSelector(selectEthnicity);
    const translator = useSelector(selectTranslator);

    useEffect(() => {
        dispatch(id ? getTribalAffiliationByID(id) : getTribalAffiliation());
        dispatch(getEthnicities());
        dispatch(getRaces());
        dispatch(getLanguages());
    }, [dispatch, id]);

    const onChangeCorporation = (event) => {
        dispatch(setCorporation(event.value.trim()));
    }

    const onChangeEnrollment = (event) => {
        dispatch(setEnrollment(event.value.trim()));
    }

    const onChangeEthnicity = (event) => {
        dispatch(setEthnicity(event.value));
    }

    const onChangeRace = (event) => {
        dispatch(setRace(event.value));
    }

    const onChangeRaceAlaska = (event) => {
        dispatch(setRaceAlaska(event.value));
    }

    const onChangeRaceAmerican = (event) => {
        dispatch(setRaceAmerican(event.value));
    }

    const onChangeLanguage = (event) => {
        dispatch(setLanguage(event.value));
    }

    const onChangeOtherLanguage = (event) => {
        dispatch(setOtherLanguage(event.value));
    }

    const onToggleCIB = () => {
        dispatch(toggleCIB());
    }

    const onToggleCIBCard = () => {
        dispatch(toggleCIBCard());
    }

    const onToggleTranslator = () => {
        dispatch(toggleTranslator());
    }

    const onSaveTribalAffiliation = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveTribalAffiliationByID(id) : saveTribalAffiliation());
            dispatch(completeTribalAffiliation(true));
        }
    }

    const onSaveAndContinueTribalAffiliation = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveTribalAffiliationByID(id) : saveTribalAffiliation());
            dispatch(completeTribalAffiliation(true));
            onClickNext();
        }
    }

    return (
        <TribalAffiliation
            loading={loading}

            ethnicities={ethnicities}
            races={races}
            languages={languages}

            corporation={corporation}
            enrollment={enrollment}
            race={race}
            raceAlaska={raceAlaska}
            raceAmerican={raceAmerican}
            language={language}
            otherLanguage={otherLanguage}
            CIB={CIB}
            CIBCard={CIBCard}
            ethnicity={ethnicity}
            translator={translator}

            onChangeCorporation={onChangeCorporation}
            onChangeEnrollment={onChangeEnrollment}
            onChangeEthnicity={onChangeEthnicity}
            onChangeRace={onChangeRace}
            onChangeRaceAlaska={onChangeRaceAlaska}
            onChangeRaceAmerican={onChangeRaceAmerican}
            onChangeLanguage={onChangeLanguage}
            onChangeOtherLanguage={onChangeOtherLanguage}
            onToggleCIB={onToggleCIB}
            onToggleCIBCard={onToggleCIBCard}
            onToggleTranslator={onToggleTranslator}

            onSaveTribalAffiliation={onSaveTribalAffiliation}
            onSaveAndContinueTribalAffiliation={onSaveAndContinueTribalAffiliation}
        />
    );
};