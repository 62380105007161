import { setAddress, setCity, setEmployer, setOtherPrimaryIncomeSource, setPrimaryIncomeSource, setState, setZip, setEmployment } from '../../../../../../store/users/patientProfile/basicInformation/employment/slice';
import { getEmploymentById, saveEmploymentById } from '../../../../../../store/users/patientProfile/basicInformation/employment/middlewares';

import { getPrimaryIncomeSources } from '../../../../../../store/lookups/primaryIncomeSource/middlewares';
import { getEmployments } from '../../../../../../store/lookups/employment/middlewares';
import { getState } from '../../../../../../store/lookups/state/middlewares';

import { selectPrimaryIncomeSources } from '../../../../../../store/lookups/primaryIncomeSource/selectors';
import { selectEmployments } from '../../../../../../store/lookups/employment/selectors';
import { selectStates } from '../../../../../../store/lookups/state/selectors';

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { Employment } from './Employment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const EmploymentContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const states = useSelector(selectStates);
    const employments = useSelector(selectEmployments);
    const primaryIncomeSources = useSelector(selectPrimaryIncomeSources);

    const statePatientProfileEmployment     = useSelector(state => state.patientProfileEmployment);
	const getEntityPatientProfileEmployment = (name) => statePatientProfileEmployment[name];

    useEffect(() => {
        dispatch(getEmploymentById(id));
        dispatch(getState());
        dispatch(getEmployments());
        dispatch(getPrimaryIncomeSources());
    }, [dispatch, id]);

    const FN_TO_CALL = {
        Employment: setEmployment,
        Employer: setEmployer,
        Address: setAddress,
        City: setCity,
        State: setState,
        Zip: setZip,
        PrimaryIncomeSource: setPrimaryIncomeSource,
        OtherPrimaryIncomeSource: setOtherPrimaryIncomeSource,
    };

    const onChangeFunc = (name, event = null, trim = false) => {
        dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        setShowButton(true);
    };

    const onCancelEmployment = () => {
        dispatch(getEmploymentById(id));
        setShowButton(false);
    }

    const onSaveEmployment = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveEmploymentById(id));
            setShowButton(false);
        }
    }

    return (
        <Employment
            showButton={showButton}

            states={states}
            employments={employments}
            primaryIncomeSources={primaryIncomeSources}

            getEntityPatientProfileEmployment={getEntityPatientProfileEmployment}
            onChangeFunc={onChangeFunc}

            onCancelEmployment={onCancelEmployment}
            onSaveEmployment={onSaveEmployment}
        />
    );
};