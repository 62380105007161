
import { BASE_URL, PATIENT_INFO } from "../../../../utils/constants";
import { dateFormatForApi } from "../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const requestMailChange = createAsyncThunk(
    "requestMailChange",
    async ({ ID, email }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${ID}/RequestMailChange?newMailAddress=${email}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getPatientInfo = createAsyncThunk(
    "getPatientInfo",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${PATIENT_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getPatientInfoById = createAsyncThunk(
    "getPatientInfoById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${PATIENT_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setPatientInfo = createAsyncThunk(
    "setPatientInfo",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${PATIENT_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.responseBody);
        };
    },
);

export const setPatientInfoById = createAsyncThunk(
    "setPatientInfoById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${PATIENT_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const savePatientInfo = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationPatientInfo;

    const data = {
        "firstName": store.firstName,
        "lastName": store.lastName,
        "middleName": store.middleName,
        "ssn": store.SSN,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "gender": store.gender,
        "otherGender": store.otherGender,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email,
        "numberOfPeople": store.numberOfPeople,
        "numberOfChildren": store.numberOfChildren,
        "maritalStatus": store.maritalStatus,
        "sendEmail": store.sendEmail,
        "internetAccess": store.internetAccess,
        "canLeaveVoiceMessage": store.canLeaveVoiceMessage,
        "sendRemindersByText": store.sendRemindersByText,
    }

    return dispatch(setPatientInfo(data));
}

export const savePatientInfoById = (id) => (dispatch, getState) => {
    const store = getState().patientProfilePatientInfo;

    const data = {
        "firstName": store.firstName,
        "lastName": store.lastName,
        "middleName": store.middleName,
        "ssn": store.SSN,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "gender": store.gender,
        "otherGender": store.otherGender,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email,
        "numberOfPeople": store.numberOfPeople,
        "numberOfChildren": store.numberOfChildren,
        "maritalStatus": store.maritalStatus,
        "sendEmail": store.sendEmail,
        "internetAccess": store.internetAccess,
        "canLeaveVoiceMessage": store.canLeaveVoiceMessage,
        "sendRemindersByText": store.sendRemindersByText,
    }

    dispatch(setPatientInfoById({ id, data }));
}