import { useParams } from 'react-router-dom';
import { selectLoading, selectSignPicture, selectGuardian, selectGuardianDate, selectName, selectSigned, selectSignedDate } from "../../../../../store/forms/newPatientRegistration/consent/selectors";
import { setSignPicture, setGuardian, setGuardianDate, setName, setSigned, setSignedDate } from "../../../../../store/forms/newPatientRegistration/consent/slice";
import { getConsent, getConsentByID, saveConsent, saveConsentByID } from "../../../../../store/forms/newPatientRegistration/consent/middlewares";
import { completeConsent } from "../../../../../store/forms/newPatientRegistration/slice";

import { validateGroup } from "../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { Consent } from "./Consent";
import { useEffect, useState } from "react";

export const ConsentContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [signature, setSignature] = useState({});
    const signPicture = useSelector(selectSignPicture);

    const [error, setError] = useState(false);
    const loading = useSelector(selectLoading);

    const name = useSelector(selectName);
    const signed = useSelector(selectSigned);
    const guardian = useSelector(selectGuardian);
    const signedDate = useSelector(selectSignedDate);
    const guardianDate = useSelector(selectGuardianDate);

    useEffect(() => {
        dispatch(id ? getConsentByID(id) : getConsent())
    }, [dispatch, id])

    useEffect(() => {
        if (!!signature?.fromDataURL && !!signature?.clear && signPicture) {
            signature.clear();
            signature.fromDataURL(signPicture);
        };
    }, [signPicture, signature]);

    const onChangeName = (event) => {
        dispatch(setName(event.value.trim()));
    }

    const onChangeSigned = (event) => {
        dispatch(setSigned(event.value.trim()));
    }

    const onChangeGuardian = (event) => {
        dispatch(setGuardian(event.value.trim()));
    }

    const onChangeSignedDate = (event) => {
        dispatch(setSignedDate(event.value));
    }

    const onChangeGuardianDate = (event) => {
        dispatch(setGuardianDate(event.value));
    }

    const onInitSignature = (event) => {
        setSignature(event);
    }

    const onChangeSignature = () => {
        setError(false);
    }

    const onClearSignature = () => {
        if (!!signature.clear) {
            signature.clear();
        }
    }

    const onSaveConsent = (event) => {
        const picture = signature.toDataURL('image/png');
        const isValid = validateGroup(event);

        if (signature.isEmpty()) {
            setError(true);
        }

        if (isValid && !signature.isEmpty()) {
            dispatch(setSignPicture(picture));
            dispatch(id ? saveConsentByID(id) : saveConsent());
            dispatch(completeConsent(true));
        }
    }

    const onSaveAndContinueConsent = (event) => {
        const picture = signature.toDataURL('image/png');
        const isValid = validateGroup(event);

        if (signature.isEmpty()) {
            setError(true);
        }

        if (isValid && !signature.isEmpty()) {
            dispatch(setSignPicture(picture));
            dispatch(id ? saveConsentByID(id) : saveConsent());
            dispatch(completeConsent(true));
            onClickNext();
        };
    }

    return (
        <Consent
            loading={loading}
            error={error}

            name={name}
            signed={signed}
            guardian={guardian}
            signedDate={signedDate}
            guardianDate={guardianDate}
            signPicture={signPicture}

            onChangeName={onChangeName}
            onChangeSigned={onChangeSigned}
            onChangeGuardian={onChangeGuardian}
            onChangeSignedDate={onChangeSignedDate}
            onChangeGuardianDate={onChangeGuardianDate}

            onInitSignature={onInitSignature}
            onChangeSignature={onChangeSignature}
            onClearSignature={onClearSignature}

            onSaveConsent={onSaveConsent}
            onSaveAndContinueConsent={onSaveAndContinueConsent}
        />
    );
}