import { MuiChip } from "./Status.style";

const Status = ({ value }) => {
    let color = 'var(--grey-text-color)';
    let background = 'var(--light-grey-color)';

    switch (value.toLowerCase()) {
        case 'active':
            color = 'var(--green-text-color)';
            background = 'var(--light-green-color)';
            break;
        case 'blocked':
            color = 'var(--red-text-color)';
            background = 'var(--light-red-color)';
            break;
        case 'pending':
            color = 'var(--yellow-text-color)';
            background = 'var(--light-yellow-color)';
            break;
        case 'inactive':
            color = 'var(--grey-text-color)';
            background = 'var(--light-grey-color)';
            break;
        case 'complete':
            color = 'var(--green-text-color)';
            background = 'var(--light-green-color)';
            break;
        case 'incomplete':
            color = 'var(--red-text-color)';
            background = 'var(--light-red-color)';
            break;
        case 'in progress':
            color = 'var(--yellow-text-color)';
            background = 'var(--light-yellow-color)';
            break;
        default:
            color = 'var(--grey-text-color)';
            background = 'var(--light-grey-color)';
    }

    return (
        <MuiChip
            label={value}
            sx={{
                color: color,
                backgroundColor: background,
            }}
        />
    );
};

export default Status;