import { getNoticeOfPrivacyPractices, setNoticeOfPrivacyPractices, getNoticeOfPrivacyPracticesByID, setNoticeOfPrivacyPracticesByID } from './middlewares';
import { showToast } from '../../../screens/Main/MainContainer';
import { CURRENT_DATE, FETCH_STATUSES } from '../../../utils/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: "",
	date: CURRENT_DATE,
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
	signPicture: "",
};

const noticeOfPrivacyPractices = createSlice({
	name: 'noticeOfPrivacyPractices',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		setName: (state, action) => {
			state.name = action.payload;
			return state;
		},
		setDate: (state, action) => {
			state.date = action.payload;
			return state;
		},
		setSignPicture: (state, action) => {
			state.signPicture = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getNoticeOfPrivacyPractices.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setNoticeOfPrivacyPractices.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getNoticeOfPrivacyPractices.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.name = action.payload?.name;
				state.date = action.payload?.date || CURRENT_DATE;
				state.signPicture = action.payload?.signPicture;

				state.loading = false;
			})
			.addCase(setNoticeOfPrivacyPractices.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Notice of Privacy Practices& Patient Rights and Responsibilities saved successfully");
			})
			.addCase(getNoticeOfPrivacyPractices.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Notice of Privacy Practices& Patient Rights and Responsibilities: ${action.error.message}`);
			})
			.addCase(setNoticeOfPrivacyPractices.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Notice of Privacy Practices& Patient Rights and Responsibilities: ${action.error.message}`);
			})
			.addCase(getNoticeOfPrivacyPracticesByID.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(getNoticeOfPrivacyPracticesByID.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.name = action.payload?.name;
				state.date = action.payload?.date;
				state.signPicture = action.payload?.signPicture;

				state.loading = false;
			})
			.addCase(getNoticeOfPrivacyPracticesByID.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Notice of Privacy Practices& Patient Rights and Responsibilities: ${action.error.message}`);
			})
			.addCase(setNoticeOfPrivacyPracticesByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(setNoticeOfPrivacyPracticesByID.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Notice of Privacy Practices& Patient Rights and Responsibilities saved successfully");
			})
			.addCase(setNoticeOfPrivacyPracticesByID.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Notice of Privacy Practices& Patient Rights and Responsibilities: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,
	setName,
	setDate,
	setSignPicture,
} = noticeOfPrivacyPractices.actions;

export default noticeOfPrivacyPractices.reducer;