import { selectEmail, selectPassword, selectCheckbox, selectError, selectBtnStatus } from '../../../store/auth/login/selectors';
import { setEmail, setPassword, toggleCheckbox } from '../../../store/auth/login/slice';
import { postLogin } from '../../../store/auth/login/middlewares';
import { login, logout, isAdmin } from '../../../core/auth/authProvider';
import { useDispatch, useSelector } from 'react-redux';
import { validateGroup } from '../../../utils/helper';
import { useNavigate } from "react-router-dom";
import { Login } from './Login';

export const LoginContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const email = useSelector(selectEmail);
    const password = useSelector(selectPassword);
    const checkbox = useSelector(selectCheckbox);
    const btnStatus = useSelector(selectBtnStatus);

    const error = useSelector(selectError);

    const onChangeEmail = (event) => {
        const value = event.value.trim();
        dispatch(setEmail(value));
    }

    const onChangePassword = (event) => {
        const value = event.value;
        dispatch(setPassword(value));
    }

    const onToggleCheckbox = () => {
        dispatch(toggleCheckbox());
    }

    const handleClick = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(postLogin({ email, password })).then((data) => {
                if (!data.payload.errors) {
                    login({ 'accessToken': data.payload.token, 'refreshToken': data.payload.refreshToken });
                    isAdmin() ? navigate('/main/users/patients') : navigate("/main/welcome");
                }
            }).catch(() => logout());
        };
    }

    const onKeyPressEnter = (event) => {
        if (event.key === 'Enter')
            handleClick();
    }

    return (
        <Login
            email={email}
            password={password}
            checkbox={checkbox}

            error={error}

            onChangeEmail={onChangeEmail}
            onChangePassword={onChangePassword}
            onToggleCheckbox={onToggleCheckbox}

            handleClick={handleClick}
            onKeyPressEnter={onKeyPressEnter}
            btnStatus={btnStatus}
        />
    );
};