export const selectLoading = state => state.discountSchedule.loading;
export const selectDecline = state => state.discountSchedule.decline;
export const selectFirstName = state => state.discountSchedule.firstName;
export const selectMiddleName = state => state.discountSchedule.middleName;
export const selectLastName = state => state.discountSchedule.lastName;
export const selectSSN = state => state.discountSchedule.ssn;
export const selectDate = state => state.discountSchedule.date;
export const selectPhone = state => state.discountSchedule.phone;
export const selectDateOfBirth = state => state.discountSchedule.dateOfBirth;
export const selectAddress = state => state.discountSchedule.address;
export const selectCity = state => state.discountSchedule.city;
export const selectState = state => state.discountSchedule.state;
export const selectZip = state => state.discountSchedule.zip;
export const selectEmployment = state => state.discountSchedule.employment;
export const selectEmployer = state => state.discountSchedule.employer;
export const selectWorkPhone = state => state.discountSchedule.workPhone;
export const selectMonths = state => state.discountSchedule.months;
export const selectWorkedOnBoat = state => state.discountSchedule.workedOnBoat;
export const selectWorkedOnBoatContact = state => state.discountSchedule.workedOnBoatContact;
export const selectProofOfIncome = state => state.discountSchedule.proofOfIncome;
export const selectOtherProofOfIncome = state => state.discountSchedule.otherProofOfIncome;
export const selectFileProofOfIncome = state => state.discountSchedule.fileProofOfIncome;
export const selectTotalIncome = state => state.discountSchedule.totalIncome;
export const selectHousehold = state => state.discountSchedule.household;
export const selectHealthInsurance = state => state.discountSchedule.healthInsurance;
export const selectPrivateHealthInsurance = state => state.discountSchedule.privateHealthInsurance;
export const selectMedicare = state => state.discountSchedule.medicare;
export const selectMedicaid = state => state.discountSchedule.medicaid;
export const selectApplied = state => state.discountSchedule.applied;
export const selectEffectiveDate = state => state.discountSchedule.effectiveDate;
export const selectWhoInsurance = state => state.discountSchedule.whoInsurance;
export const selectDateOfBirthInsured = state => state.discountSchedule.dateOfBirthInsured;
export const selectFrontCard = state => state.discountSchedule.frontCard;
export const selectBackCard = state => state.discountSchedule.backCard;
export const selectVerification = state => state.discountSchedule.verification;
export const selectOtherVerification = state => state.discountSchedule.otherVerification;
export const selectApplicantMayQualify = state => state.discountSchedule.applicantMayQualify;
export const selectEligibilityDate = state => state.discountSchedule.eligibilityDate;
export const selectRenewalDate = state => state.discountSchedule.renewalDate;
export const selectDiscount = state => state.discountSchedule.discount;
export const selectVerifiedBy = state => state.discountSchedule.verifiedBy;
export const selectVerifiedDate = state => state.discountSchedule.verifiedDate;
export const selectSignPicture = state => state.discountSchedule.signPicture;
