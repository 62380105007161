import { getReferralInfo, postReferralInfo, getReferralInfoByID, postReferralInfoByID } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reason: "",
    referralInfo: [],
    otherReferralInfo: "",
    //refferalForMedicalRN: false,
    refferalForMedicalRN: "",
    //contactedByPhysicalExam: false,
    contactedByPhysicalExam: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationReferralInfo = createSlice({
    name: "newPatientRegistrationReferralInfo",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setReason: (state, action) => {
            state.reason = action.payload;
            return state;
        },
        setReferralInfo: (state, action) => {
            state.referralInfo = action.payload;
            return state;
        },
        setOtherReferralInfo: (state, action) => {
            state.otherReferralInfo = action.payload;
            return state;
        },
        toggleRefferalForMedicalRN: (state) => {
            state.refferalForMedicalRN = !state.refferalForMedicalRN;
            return state;
        },
        toggleContactedByPhysicalExam: (state) => {
            state.contactedByPhysicalExam = !state.contactedByPhysicalExam;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReferralInfo.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postReferralInfo.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getReferralInfo.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.reason = action.payload?.reason;
                state.referralInfo = action.payload?.referralInfo;
                state.otherReferralInfo = action.payload?.otherReferralInfo;
                state.refferalForMedicalRN = action.payload?.refferalForMedicalRN;
                state.contactedByPhysicalExam = action.payload?.contactedByPhysicalExam;

                state.loading = false;
            })
            .addCase(postReferralInfo.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Referral Information saved successfully");
            })
            .addCase(getReferralInfo.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Referral Information: ${action.error.message}`);
            })
            .addCase(postReferralInfo.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Referral Information: ${action.error.message}`);
            })
            .addCase(getReferralInfoByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getReferralInfoByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.reason = action.payload?.reason;
                state.referralInfo = action.payload?.referralInfo;
                state.otherReferralInfo = action.payload?.otherReferralInfo;
                state.refferalForMedicalRN = action.payload?.refferalForMedicalRN;
                state.contactedByPhysicalExam = action.payload?.contactedByPhysicalExam;

                state.loading = false;
            })
            .addCase(getReferralInfoByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;
                showToast('error', `Get Referral Information: ${action.error.message}`);
            })
            .addCase(postReferralInfoByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(postReferralInfoByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;
                showToast('success', 'Referral Information saved successfully');
            })
            .addCase(postReferralInfoByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;
                showToast('error', `Set Referral Information: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setReason,
    setReferralInfo,
    setOtherReferralInfo,
    toggleRefferalForMedicalRN,
    toggleContactedByPhysicalExam,

} = newPatientRegistrationReferralInfo.actions;

export default newPatientRegistrationReferralInfo.reducer;

