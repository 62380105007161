import { useState } from 'react';
import DataGrid, { Column, Toolbar, Item, Pager, Selection, SearchPanel } from 'devextreme-react/data-grid';
import { Wrapper, Title } from '../../../components/Main/Main.style';
import { MAX_WIDTH } from '../../../utils/constants';
import { Switcher } from '../Switcher/Switcher';
import { SwitcherInviteStatus } from '../Switcher/SwitcherInviteStatus';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import Tooltip from '@mui/material/Tooltip';
import Button from '../../../components/Button/Button';
import LoadPanel from 'devextreme-react/load-panel';
import GenerationInvitesContainer from '../GenerationInvites/GenerationInvitesContainer';

export const InvitedList = ({
	loading,
	dataSource,
	statusBtnInvite,
	showInviteLink,
	showProfile,
	showInvite,
	onOpenDialog,
}) => {
	const [disabled, setDisabled] = useState(true);
	const [selected, setSelected] = useState([]);

	const onSelectionChanged = ({ selectedRowsData }) => {
		if (!selectedRowsData.length) setDisabled(true);
		else {
			const withoutIDs = !selectedRowsData.every((el) => el.id === '');
			setDisabled(withoutIDs);
		}
		setSelected(selectedRowsData);
	};

	return (
		<>
			<Wrapper id="invitedList" maxWidth={MAX_WIDTH}>
				<LoadPanel
					visible={loading}
					position={{ of: '#consentForRelease' }} />
				{
					!loading &&
					<>
						<Title style={{ marginBottom: '20px' }}>Invited</Title>
						<DataGrid
							dataSource={dataSource}
							height={window.innerHeight - 200}
							columnMinWidth={100}
							showBorders={false}
							showRowLines={true}
							showColumnLines={false}
							hoverStateEnabled={true}
							onSelectionChanged={onSelectionChanged}>
							<SearchPanel visible={true} placeholder="Search" />
							<Toolbar>
								<Item location="before">
									<Switcher />
								</Item>
								<Item name="searchPanel" />
								<Item location="after" >
									<SwitcherInviteStatus />
								</Item>
								<Item location="after" >
									<Button
										label="Invite"
										variant="contained"
										startIcon="akar-icons:paper-airplane"
										onClick={() => showInvite()} />
								</Item>
								<Item location="after" >
									<Button
										disabled={disabled}
										label="Delete"
										variant="contained"
										startIcon="ant-design:minus-square-outlined"
										onClick={() => onOpenDialog(selected)} />
								</Item>
							</Toolbar>
							<Selection mode="multiple" />
							<Column dataField="id" visible={false} />
							<Column dataField="firstName" caption="Name" />
							<Column dataField="email" caption="Email" />
							<Column dataField="phone" caption="Phone" />
							<Column dataField="dob" caption="Date Of Birth" dataType="date" />
							<Column
								fixed={true}
								fixedPosition="right"
								alignment="center"
								width={57}
								minWidth={57}
								cellRender={({ data }) =>
									<IconButton
										disabled={statusBtnInvite}
										onClick={() => showInviteLink(data.email)}>
										<Tooltip title="Copy invite link">
											<Icon
												icon="ic:baseline-content-copy"
												height="24px"
												width="24px"
												color="var(--light-blue-color)"
											/>
										</Tooltip>
									</IconButton>
								}
							/>
							<Column
								fixed={true}
								fixedPosition="right"
								alignment="center"
								width={75}
								minWidth={75}
								cellRender={({ data }) =>
									<IconButton
										disabled={!data.id ? true : false}
										onClick={() => showProfile(data.id)}>
										<Tooltip title="Go to profile">
											<Icon
												icon="ic:baseline-output"
												height="24px"
												width="24px"
												color={!data.id ? "var(--grey-text-color)" : "var(--light-blue-color)"}
											/>
										</Tooltip>
									</IconButton>
								}
							/>
							<Pager
								visible={true}
								displayMode="full"
								label="Rows per page:"
								showPageSizeSelector={true}
								showNavigationButtons={true}
								allowedPageSizes={[5, 10, 'all']} />
						</DataGrid>
					</>
				}
			</Wrapper >
			<GenerationInvitesContainer />
		</>
	);
}