import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getPaymentAgreement = createAsyncThunk(
    "getPaymentAgreement",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/PaymentAgreement");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setPaymentAgreement = createAsyncThunk(
    "setPaymentAgreement",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + "/PaymentAgreement", data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const savePaymentAgreement = () => (dispatch, getState) => {
    const store = getState().paymentAgreement;

    const data = {
        "decline": store.decline,
        "firstName": store.firstName,
        "middleName": store.middleName,
        "lastName": store.lastName,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "phone": store.phone,
        "address": store.address,
        "amountDue": store.amountDue,
        "amountDueDate": dateFormatForApi(store.amountDueDate),
        "date": dateFormatForApi(store.date),
        "cashOrCheck": store.cashOrCheck,
        "creditCard": store.creditCard,
        "monthlyAmount": store.monthlyAmount,
        "dateOfEachMonth": store.dateOfEachMonth,
        "duration": store.duration,
        "creditCardNumber": store.creditCardNumber,
        "creditCardExp": dateFormatForApi(store.creditCardExp),
        "creditCardCode": store.creditCardCode,
        "creditCardName": store.creditCardName,
        "differentGuarantorName": store.differentGuarantorName,
        "differentGuarantorAddress": store.differentGuarantorAddress,
        "differentGuarantorPhone": store.differentGuarantorPhone,
    }

    dispatch(setPaymentAgreement(data));
};