import { selectLoading, selectContact, selectRelationship, selectAddress, selectCity, selectState, selectZip, selectPhone, selectEmail } from "../../../../../../store/users/patientProfile/basicInformation/emergencyContact/selectors";
import { setAddress, setCity, setContact, setEmail, setPhone, setRelationship, setState, setZip } from "../../../../../../store/users/patientProfile/basicInformation/emergencyContact/slice";

import { getEmergencyContactById, saveEmergencyContactById } from "../../../../../../store/users/patientProfile/basicInformation/emergencyContact/middlewares";
import { selectStates } from "../../../../../../store/lookups/state/selectors";
import { getState } from "../../../../../../store/lookups/state/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { EmergencyContact } from "./EmergencyContact";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const EmergencyContactContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const states = useSelector(selectStates);

    const contact = useSelector(selectContact);
    const relationship = useSelector(selectRelationship);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const phone = useSelector(selectPhone);
    const email = useSelector(selectEmail);

    useEffect(() => {
        dispatch(getState());
        dispatch(getEmergencyContactById(id));
    }, [dispatch, id]);

    const FN_TO_CALL = {
		Contact: setContact,
        Relationship: setRelationship,
        Address: setAddress,
        City: setCity,
        State: setState,
        Zip: setZip,
        Phone: setPhone,
        Email: setEmail,
	};

    const onChangeFunc = (name, event, trim = false) => {
        dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        setShowButton(true);
    };

    const onCancelEmergencyContact = () => {
        dispatch(getEmergencyContactById(id));
        setShowButton(false);
    }

    const onSaveEmergencyContact = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveEmergencyContactById(id));
            setShowButton(false);
        }
    }

    return (
        <EmergencyContact
            loading={loading}
            showButton={showButton}

            states={states}

            contact={contact}
            relationship={relationship}
            address={address}
            city={city}
            state={state}
            zip={zip}
            phone={phone}
            email={email}

            onChangeFunc={onChangeFunc}

            onCancelEmergencyContact={onCancelEmergencyContact}
            onSaveEmergencyContact={onSaveEmergencyContact}
        />
    )
};