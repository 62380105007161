import { getIdAndInsuranceCard, setIdAndInsuranceCard, getIdAndInsuranceCardByID, setIdAndInsuranceCardByID } from './middlewares';
import { CURRENT_DATE, FETCH_STATUSES } from '../../../utils/constants';
import { showToast } from '../../../screens/Main/MainContainer';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	firstName: "",
	middleName: "",
	lastName: "",
	date: CURRENT_DATE,
	email: "",
	frontId: "",
	backId: "",
	frontCard: "",
	backCard: "",
	cib: "",
	tribalCard: "",
	tribalEnrollment: "",
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
};

const idAndInsuranceCard = createSlice({
	name: 'idAndInsuranceCard',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
			return state;
		},
		setMiddleName: (state, action) => {
			state.middleName = action.payload;
			return state;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
			return state;
		},
		setDate: (state, action) => {
			state.date = action.payload;
			return state;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
			return state;
		},
		setFrontId: (state, action) => {
			state.frontId = action.payload;
			return state;
		},
		setBackId: (state, action) => {
			state.backId = action.payload;
			return state;
		},
		setFrontCard: (state, action) => {
			state.frontCard = action.payload;
			return state;
		},
		setBackCard: (state, action) => {
			state.backCard = action.payload;
			return state;
		},
		setCib: (state, action) => {
			state.cib = action.payload;
			return state;
		},
		setTribalCard: (state, action) => {
			state.tribalCard = action.payload;
			return state;
		},
		setTribalEnrollment: (state, action) => {
			state.tribalEnrollment = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getIdAndInsuranceCard.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setIdAndInsuranceCard.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getIdAndInsuranceCard.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.firstName = action.payload?.firstName;
				state.middleName = action.payload?.middleName;
				state.lastName = action.payload?.lastName;
				state.date = action.payload?.date || CURRENT_DATE;
				state.email = action.payload?.email;
				state.frontId = action.payload?.frontId;
				state.backId = action.payload?.backId;
				state.frontCard = action.payload?.frontCard;
				state.backCard = action.payload?.backCard;
				state.cib = action.payload?.cib;
				state.tribalCard = action.payload?.tribalCard;
				state.tribalEnrollment = action.payload?.tribalEnrollment;

				state.loading = false;
			})
			.addCase(setIdAndInsuranceCard.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "ID, Insurance Card and Proof of Tribal Enrollment saved successfully");
			})
			.addCase(getIdAndInsuranceCard.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Id And Insurance Cardh: ${action.error.message}`);
			})
			.addCase(setIdAndInsuranceCard.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Id And Insurance Card: ${action.error.message}`);
			})
			.addCase(getIdAndInsuranceCardByID.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError  = null;
			})
			.addCase(getIdAndInsuranceCardByID.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError  = null;

				state.firstName = action.payload?.firstName;
				state.middleName = action.payload?.middleName;
				state.lastName = action.payload?.lastName;
				state.date = action.payload?.date || CURRENT_DATE;
				state.email = action.payload?.email;
				state.frontId = action.payload?.frontId;
				state.backId = action.payload?.backId;
				state.frontCard = action.payload?.frontCard;
				state.backCard = action.payload?.backCard;
				state.cib = action.payload?.cib;
				state.tribalCard = action.payload?.tribalCard;
				state.tribalEnrollment = action.payload?.tribalEnrollment;

				state.loading = false;
			})
			.addCase(getIdAndInsuranceCardByID.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError  = action.error;
				state.loading   = false;

				showToast('error', `Get Id And Insurance Cardh: ${action.error.message}`);
			})
			.addCase(setIdAndInsuranceCardByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError  = null;
			})
			.addCase(setIdAndInsuranceCardByID.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError  = null;

				showToast("success", "ID, Insurance Card and Proof of Tribal Enrollment saved successfully");
			})
			.addCase(setIdAndInsuranceCardByID.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError  = action.error;

				showToast('error', `Set Id And Insurance Card: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,

	setFirstName,
	setMiddleName,
	setLastName,
	setDate,
	setEmail,
	setFrontId,
	setBackId,
	setFrontCard,
	setBackCard,
	setCib,
	setTribalCard,
	setTribalEnrollment,
} = idAndInsuranceCard.actions;

export default idAndInsuranceCard.reducer;