import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from '../../../components/Main/Main.style';
import { TitleWrapper, Title } from './AdminsProfile.style';
import { BasicInformationContainer } from './BasicInformation/BasicInformationContainer';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

export const AdminsProfile = ({
	onClickBack,
	dataSource
}) => {
	const [index, setIndex] = useState(0);
	const location = useLocation();

	const onSelectionChanged = (args) => {
		if (args.name === 'selectedIndex') {
			setIndex(args.value);
		}
	};

	useEffect(() => {
		setIndex(location.state?.indexTab || 0);
	}, [location.state?.indexTab]);

	return (
		<Wrapper style={{ overflowX: 'hidden', margin: '30px 30px 0px 40px' }}>
			<TitleWrapper>
			<Tooltip title="Go back to Admins list">
				<span>
					<Button
						icon="back" onClick={onClickBack}
						//hint="Go back to Admins list"
					/>
				</span>
				</Tooltip>
				<Title>{`Admin: ${dataSource?.firstName} ${dataSource?.middleName} ${dataSource?.lastName}`}</Title>
			</TitleWrapper>
			<div style={{ display: 'flex' }}>
				<TabPanel
					width="100%"
					scrollByContent={true}
					showNavButtons={true}
					selectedIndex={index}
					onOptionChanged={onSelectionChanged}
				>
					<Item title="Basic information">
						<BasicInformationContainer />
					</Item>
				</TabPanel>
			</div>
		</Wrapper >
	);
}