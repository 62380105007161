import { getGuardianInfoById, setGuardianInfoById } from "./middlewares";
import { showToast } from "../../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: null,
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    employer: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getPatientStatus: FETCH_STATUSES.IDLE,
    getPatientError: null,
    getError: null,
    setError: null,
    loading: true,
};

export const patientProfileGuardianInfo = createSlice({
    name: "patientProfileGuardianInfo",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
            return state;
        },
        setMiddleName: (state, action) => {
            state.middleName = action.payload;
            return state;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
            return state;
        },
        setDateOfBirth: (state, action) => {
            state.dateOfBirth = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
        setEmployer: (state, action) => {
            state.employer = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGuardianInfoById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(setGuardianInfoById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getGuardianInfoById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.firstName = action.payload?.firstName;
                state.middleName = action.payload?.middleName;
                state.lastName = action.payload?.lastName;
                state.dateOfBirth = action.payload?.dateOfBirth;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;
                state.employer = action.payload?.employer;

                state.loading = false;
            })
            .addCase(setGuardianInfoById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Additional Parent / Guardian Information (Grantor) saved successfully");
            })
            .addCase(getGuardianInfoById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Additional Parent / Guardian Information (Grantor): ${action.error.message}`);
            })
            .addCase(setGuardianInfoById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Additional Parent / Guardian Information (Grantor): ${action.error.message}`);
            });
    },
});

export const {
    setLoading,

    setFirstName,
    setMiddleName,
    setLastName,
    setDateOfBirth,
    setAddress,
    setCity,
    setState,
    setZip,
    setPhone,
    setEmail,
    setEmployer,
} = patientProfileGuardianInfo.actions;

export default patientProfileGuardianInfo.reducer;