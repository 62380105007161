import { MAX_WIDTH, MAX_CURRENT_DATE, MIN_CURRENT_DATE, CONSENT, GRID_XS, GRID_SM, GRID_LG } from '../../../../../../utils/constants';
import { Wrapper, Grid, Item, Label, TextBox, DateBox, Buttons } from '../../BasicInformation.style';
import { dateFormatFromApi } from '../../../../../../utils/formats';
import { NAME_RULE } from '../../../../../../utils/rules';
import { Signature } from '../../../../../../components/Signature/Signature.style';
import LoadPanel from 'devextreme-react/load-panel';
import SignatureCanvas from 'react-signature-canvas';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const Consent = ({
	loading,
	showButton,

	signed,
	guardian,
	signedDate,
	guardianDate,

	onChangeFunc,
	onInitSignature,

	onCancelConsent,
	onSaveConsent,
}) => {
	return (
		<Wrapper id={CONSENT} maxWidth={MAX_WIDTH} >
			<LoadPanel
				visible={loading}
				position={{ of: `#${CONSENT}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Signed *">
							<Label>Signed by:</Label>
						</Tooltip>
						<TextBox
							label="Signed *"
							value={signed}
							onValueChanged={(e) => onChangeFunc('Signed', e, true)}
							maxLength={90}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-signed' }}
						>
							<Validator validationGroup={CONSENT}>
								<RequiredRule
									message="Signed is required"
								/>
								<PatternRule
									message="Do not use digits in the Signed"
									pattern={NAME_RULE}
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Date *">
							<Label>Date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Date *"
							value={dateFormatFromApi(signedDate)}
							onValueChanged={(e) => onChangeFunc('SignedDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={CONSENT}>
								<RequiredRule
									message="Date is required"
								/>
								<RangeRule
									message="Date cannot be earlier or later than that year"
									min={MIN_CURRENT_DATE}
									max={MAX_CURRENT_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Guardian">
							<Label>Guardian:</Label>
						</Tooltip>
						<TextBox
							label="Guardian"
							value={guardian}
							onValueChanged={(e) => onChangeFunc('Guardian', e, true)}
							maxLength={90}
							labelMode="hidden"
							stylingMode="underlined"
							inputAttr={{ autocomplete: 'new-guardian' }}
						>
							<Validator validationGroup={CONSENT}>
								<PatternRule
									message="Do not use digits in the Printed Name"
									pattern={NAME_RULE}
								/>
							</Validator>
						</TextBox>
					</Item>
					<Item item sm={GRID_XS} md={GRID_SM} xl={GRID_LG}>
						<Tooltip title="Date">
							<Label>Date:</Label>
						</Tooltip>
						<DateBox
							type="date"
							label="Date"
							value={dateFormatFromApi(guardianDate)}
							onValueChanged={(e) => onChangeFunc('GuardianDate', e)}
							showDropDownButton={false}
							useMaskBehavior={true}
							displayFormat="MM/dd/yyyy"
							invalidDateMessage="The date must have the following format: MM/dd/yyyy"
							labelMode="hidden"
							stylingMode="underlined"
						>
							<Validator validationGroup={CONSENT}>
								<RangeRule
									message="Date cannot be earlier or later than that year"
									min={MIN_CURRENT_DATE}
									max={MAX_CURRENT_DATE} />
							</Validator>
						</DateBox>
					</Item>
					<Grid item xs={GRID_XS}>
						<Signature
							disabled={true}>
							<legend>Signature</legend>
							<SignatureCanvas
								ref={onInitSignature}
								clearOnResize={false}
								canvasProps={{
									id: 'responsive-canvas'
								}}
							/>
						</Signature>
					</Grid>
					<Item item sm={GRID_XS}>
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								visible={showButton}
								validationGroup={CONSENT}
								onClick={onSaveConsent} />
							<Button
								text="Cancel"
								type="default"
								stylingMode="outlined"
								visible={showButton}
								onClick={onCancelConsent} />
						</Buttons>
					</Item>
				</Grid>
			}
		</Wrapper >
	);
};