import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../../../../utils/constants';
import { get } from '../../../../core/rest';

export const getPatientForms = createAsyncThunk(
  'getPatientForms',
  async (userID, { rejectWithValue }) => {
    try {
      return await get(BASE_URL + `/FormsInfo/${userID}`);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
