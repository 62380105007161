import { getDiscountSchedule, setDiscountSchedule, getDiscountScheduleByID, setDiscountScheduleByID } from './middlewares';
import { CURRENT_DATE, FETCH_STATUSES } from '../../../utils/constants';
import { showToast } from '../../../screens/Main/MainContainer';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	decline: false,
	firstName: "",
	middleName: "",
	lastName: "",
	ssn: "",
	date: CURRENT_DATE,
	phone: "",
	dateOfBirth: null,
	address: "",
	city: "",
	state: 0,
	zip: "",
	employment: [],
	employer: "",
	workPhone: "",
	months: 0,
	workedOnBoat: false,
	workedOnBoatContact: "",
	proofOfIncome: 0,
	fileProofOfIncome: "",
	otherProofOfIncome: "",
	totalIncome: 0,
	household: [],
	healthInsurance: false,
	privateHealthInsurance: false,
	medicare: false,
	medicaid: false,
	applied: false,
	effectiveDate: null,
	whoInsurance: "",
	dateOfBirthInsured: null,
	frontCard: "",
	backCard: "",
	verification: "",
	otherVerification: "",
	applicantMayQualify: "",
	eligibilityDate: null,
	renewalDate: null,
	discount: "",
	verifiedBy: "",
	verifiedDate: CURRENT_DATE,
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
	signPicture: "",
};

export const discountSchedule = createSlice({
	name: 'discountSchedule',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		toggleDecline: (state) => {
			state.decline = !state.decline;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
		},
		setMiddleName: (state, action) => {
			state.middleName = action.payload;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
		},
		setSSN: (state, action) => {
			state.ssn = action.payload;
		},
		setDate: (state, action) => {
			state.date = action.payload;
		},
		setPhone: (state, action) => {
			state.phone = action.payload;
		},
		setDateOfBirth: (state, action) => {
			state.dateOfBirth = action.payload;
		},
		setAddress: (state, action) => {
			state.address = action.payload;
		},
		setCity: (state, action) => {
			state.city = action.payload;
		},
		setState: (state, action) => {
			state.state = action.payload;
		},
		setZip: (state, action) => {
			state.zip = action.payload;
		},
		setEmployment: (state, action) => {
			state.employment = action.payload;
		},
		setEmployer: (state, action) => {
			state.employer = action.payload;
		},
		setWorkPhone: (state, action) => {
			state.workPhone = action.payload;
		},
		setMonths: (state, action) => {
			state.months = action.payload;
		},
		toggleWorkedOnBoat: (state) => {
			state.workedOnBoat = !state.workedOnBoat;
		},
		setWorkedOnBoatContact: (state, action) => {
			state.workedOnBoatContact = action.payload;
		},
		setProofOfIncome: (state, action) => {
			state.proofOfIncome = action.payload;
		},
		setOtherProofOfIncome: (state, action) => {
			state.otherProofOfIncome = action.payload;
		},
		setFileProofOfIncome: (state, action) => {
			state.fileProofOfIncome = action.payload;
		},
		setTotalIncome: (state, action) => {
			state.totalIncome = action.payload;
		},
		setHousehold: (state, action) => {
			state.household = action.payload;
		},
		setHealthInsurance: (state, action) => {
			state.healthInsurance = action.payload;
		},
		setPrivateHealthInsurance: (state, action) => {
			state.privateHealthInsurance = action.payload;
		},
		setMedicare: (state, action) => {
			state.medicare = action.payload;
		},
		setMedicaid: (state, action) => {
			state.medicaid = action.payload;
		},
		setApplied: (state, action) => {
			state.applied = action.payload;
		},
		setEffectiveDate: (state, action) => {
			state.effectiveDate = action.payload;
		},
		setWhoInsurance: (state, action) => {
			state.whoInsurance = action.payload;
		},
		setDateOfBirthInsured: (state, action) => {
			state.dateOfBirthInsured = action.payload;
		},
		setFrontCard: (state, action) => {
			state.frontCard = action.payload;
		},
		setBackCard: (state, action) => {
			state.backCard = action.payload;
		},
		setVerification: (state, action) => {
			state.verification = action.payload;
		},
		setOtherVerification: (state, action) => {
			state.otherVerification = action.payload;
		},
		setApplicantMayQualify: (state, action) => {
			state.applicantMayQualify = action.payload;
		},
		setEligibilityDate: (state, action) => {
			state.eligibilityDate = action.payload;
		},
		setRenewalDate: (state, action) => {
			state.renewalDate = action.payload;
		},
		setDiscount: (state, action) => {
			state.discount = action.payload;
		},
		setVerifiedBy: (state, action) => {
			state.verifiedBy = action.payload;
		},
		setVerifiedDate: (state, action) => {
			state.verifiedDate = action.payload;
		},
		setSignPicture: (state, action) => {
			state.signPicture = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDiscountSchedule.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setDiscountSchedule.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getDiscountSchedule.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;
				state.decline = action.payload?.decline;
				state.firstName = action.payload?.firstName;
				state.middleName = action.payload?.middleName;
				state.lastName = action.payload?.lastName;
				state.ssn = action.payload?.ssn;
				state.date = action.payload?.date || CURRENT_DATE;
				state.phone = action.payload?.phone;
				state.dateOfBirth = action.payload?.dateOfBirth;
				state.address = action.payload?.address;
				state.city = action.payload?.city;
				state.state = action.payload?.state;
				state.zip = action.payload?.zip;
				state.employment = action.payload?.employment;
				state.employer = action.payload?.employer;
				state.workPhone = action.payload?.workPhone;
				state.months = action.payload?.months;
				state.workedOnBoat = action.payload?.workedOnBoat;
				state.workedOnBoatContact = action.payload?.workedOnBoatContact;
				state.proofOfIncome = action.payload?.proofOfIncome;
				state.fileProofOfIncome = action.payload?.fileProofOfIncome;
				state.otherProofOfIncome = action.payload?.otherProofOfIncome;
				state.totalIncome = action.payload?.totalIncome;
				state.household = action.payload?.household;
				state.healthInsurance = action.payload?.healthInsurance;
				state.privateHealthInsurance = action.payload?.privateHealthInsurance;
				state.medicare = action.payload?.medicare;
				state.medicaid = action.payload?.medicaid;
				state.applied = action.payload?.applied;
				state.effectiveDate = action.payload?.effectiveDate;
				state.whoInsurance = action.payload?.whoInsurance;
				state.dateOfBirthInsured = action.payload?.dateOfBirthInsured;
				state.frontCard = action.payload?.frontCard;
				state.backCard = action.payload?.backCard;
				state.signPicture = action.payload?.signPicture;

				state.loading = false;
			})
			.addCase(setDiscountSchedule.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Sliding Fee / Discount Schedule Application saved successfully");
			})
			.addCase(getDiscountSchedule.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Sliding Fee / Discount Schedule Application: ${action.error.message}`);
			})
			.addCase(setDiscountSchedule.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Sliding Fee / Discount Schedule Application: ${action.error.message}`);
			})
			.addCase(getDiscountScheduleByID.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(getDiscountScheduleByID.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;
				state.decline = action.payload?.decline;
				state.firstName = action.payload?.firstName;
				state.middleName = action.payload?.middleName;
				state.lastName = action.payload?.lastName;
				state.ssn = action.payload?.ssn;
				state.date = action.payload?.date || CURRENT_DATE;
				state.phone = action.payload?.phone;
				state.dateOfBirth = action.payload?.dateOfBirth;
				state.address = action.payload?.address;
				state.city = action.payload?.city;
				state.state = action.payload?.state;
				state.zip = action.payload?.zip;
				state.employment = action.payload?.employment;
				state.employer = action.payload?.employer;
				state.workPhone = action.payload?.workPhone;
				state.months = action.payload?.months;
				state.workedOnBoat = action.payload?.workedOnBoat;
				state.workedOnBoatContact = action.payload?.workedOnBoatContact;
				state.proofOfIncome = action.payload?.proofOfIncome;
				state.fileProofOfIncome = action.payload?.fileProofOfIncome;
				state.otherProofOfIncome = action.payload?.otherProofOfIncome;
				state.totalIncome = action.payload?.totalIncome;
				state.household = action.payload?.household;
				state.healthInsurance = action.payload?.healthInsurance;
				state.privateHealthInsurance = action.payload?.privateHealthInsurance;
				state.medicare = action.payload?.medicare;
				state.medicaid = action.payload?.medicaid;
				state.applied = action.payload?.applied;
				state.effectiveDate = action.payload?.effectiveDate;
				state.whoInsurance = action.payload?.whoInsurance;
				state.dateOfBirthInsured = action.payload?.dateOfBirthInsured;
				state.frontCard = action.payload?.frontCard;
				state.backCard = action.payload?.backCard;
				state.signPicture = action.payload?.signPicture;

				state.loading = false;
			})
			.addCase(getDiscountScheduleByID.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Sliding Fee / Discount Schedule Application: ${action.error.message}`);
			})
			.addCase(setDiscountScheduleByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(setDiscountScheduleByID.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Sliding Fee / Discount Schedule Application saved successfully");
			})
			.addCase(setDiscountScheduleByID.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast("error", `Get Sliding Fee / Discount Schedule Application: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,
	toggleDecline,
	setFirstName,
	setMiddleName,
	setLastName,
	setSSN,
	setDate,
	setPhone,
	setDateOfBirth,
	setAddress,
	setCity,
	setState,
	setZip,
	setEmployment,
	setEmployer,
	setWorkPhone,
	setMonths,
	toggleWorkedOnBoat,
	setWorkedOnBoatContact,
	setProofOfIncome,
	setOtherProofOfIncome,
	setFileProofOfIncome,
	setTotalIncome,
	setHousehold,
	setHealthInsurance,
	setPrivateHealthInsurance,
	setMedicare,
	setMedicaid,
	setApplied,
	setEffectiveDate,
	setWhoInsurance,
	setDateOfBirthInsured,
	setFrontCard,
	setBackCard,
	setVerification,
	setOtherVerification,
	setApplicantMayQualify,
	setEligibilityDate,
	setRenewalDate,
	setDiscount,
	setVerifiedBy,
	setVerifiedDate,
	setSignPicture,
} = discountSchedule.actions;

export default discountSchedule.reducer;