import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../../../../utils/constants';
import { get } from '../../../../core/rest';

export const getPatientAttachments = createAsyncThunk(
  'getPatientAttachments',
  async (userID, { rejectWithValue }) => {
    try {
      return await get(BASE_URL + `/FileUploader/${userID}/attachments`);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
