import { getPatientAttachments } from './middlewares';
import { FETCH_STATUSES } from '../../../../utils/constants';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
  getStatus: FETCH_STATUSES.IDLE,
  getError: null,
  loading: false,
};

const patientAttachments = createSlice({
  name: 'patientAttachments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPatientAttachments.pending, (state) => {
        state.getStatus = FETCH_STATUSES.REQUEST;
        state.getError  = null;
        state.value     = null;
        state.loading   = true;
      })
      .addCase(getPatientAttachments.fulfilled, (state, action) => {
        state.getStatus = FETCH_STATUSES.SUCCESS;
        state.getError  = null;
        state.value     = action.payload;
        state.loading   = false;
      })
      .addCase(getPatientAttachments.rejected, (state, action) => {
        state.getStatus = FETCH_STATUSES.FAILURE;
        state.getError  = action.error;
        state.value     = null;
        state.loading   = false;
      });
  },
});

export default patientAttachments.reducer;
