import login from "./auth/login/slice";
import signup from "./auth/signup/slice";
import forgotPassword from "./auth/forgotPassword/slice";
import changePassword from "./auth/changePassword/slice";
import invite from "./auth/invite/slice";
import main from "./main/slice";

import newPatientRegistration from "./forms/newPatientRegistration/slice";
import newPatientRegistrationEmergencyContact from "./forms/newPatientRegistration/emergencyContact/slice";
import newPatientRegistrationNextOfKin from "./forms/newPatientRegistration/nextOfKin/slice";
import newPatientRegistrationTribalAffiliation from "./forms/newPatientRegistration/tribalAffiliation/slice";
import newPatientRegistrationHealthAndTreatment from "./forms/newPatientRegistration/healthAndTreatment/slice";
import newPatientRegistrationReferralInfo from "./forms/newPatientRegistration/referralInfo/slice";
import newPatientRegistrationAdvanceCare from "./forms/newPatientRegistration/advanceCare/slice";
import newPatientRegistrationEmployment from "./forms/newPatientRegistration/employment/slice";
import newPatientRegistrationEducation from "./forms/newPatientRegistration/education/slice";
import newPatientRegistrationPatientInfo from "./forms/newPatientRegistration/patientInfo/slice";
import newPatientRegistrationGuardianInfo from "./forms/newPatientRegistration/guardianInfo/slice";
import newPatientRegistrationInsuranceInfo from "./forms/newPatientRegistration/insuranceInfo/slice";
import newPatientRegistrationConsent from "./forms/newPatientRegistration/consent/slice";

import newPatientRegistrationPacket from "./forms/newPatientRegistrationPacket/slice";
import annualPatientRegistration from "./forms/annualPatientRegistration/slice";
import idAndInsuranceCard from "./forms/idAndInsuranceCard/slice";
import noticeOfPrivacyPractices from "./forms/noticeOfPrivacyPractices/slice";
import consentForRelease from "./forms/consentForRelease/slice";
import eCommunicationConsent from "./forms/eCommunicationConsent/slice";
import paymentAgreement from "./forms/paymentAgreement/slice";
import grievanceFeedbackForm from "./forms/grievanceFeedbackForm/slice";
import referralForm from "./forms/referralForm/slice";
import discountSchedule from "./forms/discountSchedule/slice";

import patientList from "./users/patientsList/slice";
import patientChecklist from "./users/patientProfile/checklist/slice";
import invitedChecklist from "./users/invitedProfile/checklist/slice";

import patientAttachments from "./users/patientProfile/attachments/slice";
import patientForms from "./users/patientProfile/forms/slice";

import patientProfilePatientInfo from "./users/patientProfile/basicInformation/patientInfo/slice";
import patientProfileReferralInfo from "./users/patientProfile/basicInformation/referralInfo/slice";
import patientProfileTribalAffiliation from "./users/patientProfile/basicInformation/tribalAffiliation/slice";
import patientProfileNextOfKin from "./users/patientProfile/basicInformation/nextOfKin/slice";
import patientProfileEmergencyContact from "./users/patientProfile/basicInformation/emergencyContact/slice";
import patientProfileInsuranceInfo from "./users/patientProfile/basicInformation/insuranceInfo/slice";
import patientProfileHealthAndTreatment from "./users/patientProfile/basicInformation/healthAndTreatment/slice";
import patientProfileGuardianInfo from "./users/patientProfile/basicInformation/guardianInfo/slice";
import patientProfileEmployment from "./users/patientProfile/basicInformation/employment/slice";
import patientProfileEducation from "./users/patientProfile/basicInformation/education/slice";
import patientProfileConsent from "./users/patientProfile/basicInformation/consent/slice";
import patientProfileAdvanceCare from "./users/patientProfile/basicInformation/advanceCare/slice";

import adminsList from "./users/adminsList/slice";
import admin from "./users/adminsProfile/basicInformation/admin/slice";
import invitedList from "./users/InvitedList/slice";

import providerGenerationInvites from "./users/generationInvites/slice";
import providerGenerationAdmins from "./users/generationAdmins/slice";

import advHCareDirectiveStatus from "./lookups/advHCareDirectiveStatus/slice";
import advPsychDirectiveStatus from "./lookups/advPsychDirectiveStatus/slice";
import denaliKidStatus from "./lookups/denaliKidStatus/slice";
import education from "./lookups/education/slice";
import employment from "./lookups/employment/slice";
import ethnicity from "./lookups/ethnicity/slice";
import gender from "./lookups/gender/slice";
import hospitalInSixMonths from "./lookups/hospitalInSixMonths/slice";
import hospitalResidentialTreatment from "./lookups/hospitalResidentialTreatment/slice";
import illnessInjury12months from "./lookups/illnessInjury12months/slice";
import language from "./lookups/language/slice";
import livingArrangement from "./lookups/livingArrangement/slice";
import livingCondition from "./lookups/livingCondition/slice";
import maritalStatus from "./lookups/maritalStatus/slice";
import medicaidStatus from "./lookups/medicaidStatus/slice";
import medicareStatus from "./lookups/medicareStatus/slice";
import pregnantStatus from "./lookups/pregnantStatus/slice";
import primaryIncomeSource from "./lookups/primaryIncomeSource/slice";
import proofOfIncome from "./lookups/proofOfIncome/slice";
import race from "./lookups/race/slice";
import referralStatus from "./lookups/referralStatus/slice";
import state from "./lookups/state/slice";
import totalHouseholdIncome from "./lookups/totalHouseholdIncome/slice";

import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
	reducer: {
		login,
		signup,
		forgotPassword,
		changePassword,
		invite,
		main,

		advHCareDirectiveStatus,
		advPsychDirectiveStatus,
		denaliKidStatus,
		education,
		employment,
		ethnicity,
		gender,
		hospitalInSixMonths,
		hospitalResidentialTreatment,
		illnessInjury12months,
		language,
		livingArrangement,
		livingCondition,
		maritalStatus,
		medicaidStatus,
		medicareStatus,
		pregnantStatus,
		primaryIncomeSource,
		proofOfIncome,
		race,
		referralStatus,
		state,
		totalHouseholdIncome,

		newPatientRegistrationPacket,
		idAndInsuranceCard,
		noticeOfPrivacyPractices,
		consentForRelease,
		eCommunicationConsent,
		paymentAgreement,
		annualPatientRegistration,
		grievanceFeedbackForm,
		referralForm,
		discountSchedule,

		newPatientRegistration,
		newPatientRegistrationPatientInfo,
		newPatientRegistrationGuardianInfo,
		newPatientRegistrationTribalAffiliation,
		newPatientRegistrationHealthAndTreatment,
		newPatientRegistrationEmployment,
		newPatientRegistrationEducation,
		newPatientRegistrationEmergencyContact,
		newPatientRegistrationNextOfKin,
		newPatientRegistrationInsuranceInfo,
		newPatientRegistrationReferralInfo,
		newPatientRegistrationAdvanceCare,
		newPatientRegistrationConsent,

		patientList,
		patientChecklist,
		invitedChecklist,
		patientAttachments,
		patientForms,

		adminsList,
		admin,
		invitedList,
		providerGenerationInvites,
		providerGenerationAdmins,

		patientProfilePatientInfo,
		patientProfileReferralInfo,
		patientProfileTribalAffiliation,
		patientProfileNextOfKin,
		patientProfileEmergencyContact,
		patientProfileInsuranceInfo,
		patientProfileHealthAndTreatment,
		patientProfileGuardianInfo,
		patientProfileEmployment,
		patientProfileEducation,
		patientProfileConsent,
		patientProfileAdvanceCare,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
