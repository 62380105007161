import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getAnnualPatientRegistration = createAsyncThunk(
    "getAnnualPatientRegistration",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/AnnualPatientUpdate");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getAnnualPatientRegistrationByID = createAsyncThunk(
    "getAnnualPatientRegistrationByID",
    async (patienID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/AnnualPatientUpdate/${patienID}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setAnnualPatientRegistration = createAsyncThunk(
    "setAnnualPatientRegistration",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + "/AnnualPatientUpdate", data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setAnnualPatientRegistrationByID = createAsyncThunk(
    "setAnnualPatientRegistrationByID",
    async ({ patienID, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/AnnualPatientUpdate/${patienID}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveAnnualPatientRegistration = () => (dispatch, getState) => {
    const store = getState().annualPatientRegistration;

    const data = {
        "firstName": store.firstName,
        "middleName": store.middleName,
        "lastName": store.lastName,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "ssn": store.SSN,
        "gender": store.gender,
        "otherGender": store.otherGender,
        "email": store.email,
        "phone": store.phone,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "maritalStatus": store.maritalStatus,
        "numberOfPeople": store.numberOfPeople,
        "numberOfChildren": store.numberOfChildren,
        "canLeaveVoiceMessage": store.canLeaveVoiceMessage,
        "anyCustodyArrangements": store.anyCustodyArrangements,
        "contactFullName": store.contactFullName,
        "contactRelationship": store.contactRelationship,
        "contactEmail": store.contactEmail,
        "contactPhone": store.contactPhone,
        "insuranceProvider": store.insuranceProvider,
        "policyNumber": store.policyNumber,
        "groupNumber": store.groupNumber,
        "familyMembers": store.familyMembers,
        "incomeEstimate": store.incomeEstimate,
        "employment": store.employment,
        "employer": store.employer,
        "employerAddress": store.employerAddress,
        "employerCity": store.employerCity,
        "employerState": store.employerState,
        "employerZip": store.employerZip,
        "numberOfArrests": store.numberOfArrests,
        "pregnant": store.pregnant,
        "drugUser": store.drugUser,
        "haveEverBeenInHospital": store.haveEverBeenInHospital,
        "haveBeenInHospitalLast6Months": store.haveBeenInHospitalLast6Months,
        "haveHadIllnessOrInjury": store.haveHadIllnessOrInjury,
        "livingWill": store.livingWill,
        "physicalExam": dateFormatForApi(store.physicalExam),
        "dateConsent": dateFormatForApi(store.dateConsent),
        "contactedByMedicalRN": store.contactedByMedicalRN,
    }

    return dispatch(setAnnualPatientRegistration(data));
};

export const saveAnnualPatientRegistrationByID = (patienID) => (dispatch, getState) => {
    const store = getState().annualPatientRegistration;

    const data = {
        "firstName": store.firstName,
        "middleName": store.middleName,
        "lastName": store.lastName,
        "dateOfBirth": dateFormatForApi(store.dateOfBirth),
        "ssn": store.SSN,
        "gender": store.gender,
        "otherGender": store.otherGender,
        "email": store.email,
        "phone": store.phone,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "maritalStatus": store.maritalStatus,
        "numberOfPeople": store.numberOfPeople,
        "numberOfChildren": store.numberOfChildren,
        "canLeaveVoiceMessage": store.canLeaveVoiceMessage,
        "anyCustodyArrangements": store.anyCustodyArrangements,
        "contactFullName": store.contactFullName,
        "contactRelationship": store.contactRelationship,
        "contactEmail": store.contactEmail,
        "contactPhone": store.contactPhone,
        "insuranceProvider": store.insuranceProvider,
        "policyNumber": store.policyNumber,
        "groupNumber": store.groupNumber,
        "familyMembers": store.familyMembers,
        "incomeEstimate": store.incomeEstimate,
        "employment": store.employment,
        "employer": store.employer,
        "employerAddress": store.employerAddress,
        "employerCity": store.employerCity,
        "employerState": store.employerState,
        "employerZip": store.employerZip,
        "numberOfArrests": store.numberOfArrests,
        "pregnant": store.pregnant,
        "drugUser": store.drugUser,
        "haveEverBeenInHospital": store.haveEverBeenInHospital,
        "haveBeenInHospitalLast6Months": store.haveBeenInHospitalLast6Months,
        "haveHadIllnessOrInjury": store.haveHadIllnessOrInjury,
        "livingWill": store.livingWill,
        "physicalExam": dateFormatForApi(store.physicalExam),
        "dateConsent": dateFormatForApi(store.dateConsent),
        "contactedByMedicalRN": store.contactedByMedicalRN,
    }

    return dispatch(setAnnualPatientRegistrationByID({ patienID, data }));
};