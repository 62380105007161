import { createTokenProvider } from "./tokenProvider";
import { useEffect } from "react";
import { useState } from "react";
import { ADMIN, PATIENT, INVITEE, USER_PATH } from '../../utils/constants';

const createAuthProvider = () => {
    const tokenProvider = createTokenProvider();

    const login = (newTokens) => {
        tokenProvider.setToken(newTokens);
    };

    const logout = () => {
        tokenProvider.setToken(null);
        window.localStorage.removeItem(USER_PATH);
    };

    const authRole  = () => tokenProvider.getUserData('role');
    const isAdmin   = () => tokenProvider.getUserData('role') === ADMIN;
    const isPatient = () => tokenProvider.getUserData('role') === PATIENT;
    const isInvitee = () => tokenProvider.getUserData('role') === INVITEE;

    const authFetch = async (url, options) => {
        const token = await tokenProvider.getToken();

        options = options || {};

        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`,
        };

        return fetch(url, options);
    };

    const useAuth = () => {
        const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());

        useEffect(() => {
            const listener = (newIsLogged) => {
                setIsLogged(newIsLogged);
            };

            tokenProvider.subscribe(listener);
            return () => {
                tokenProvider.unsubscribe(listener);
            };
        }, []);

        return isLogged;
    };

    return {
        useAuth,
        authFetch,
        login,
        logout,
        isAdmin,
        isPatient,
        isInvitee,
        authRole,
    }
};

export const { useAuth, authFetch, login, logout, isAdmin, isPatient, isInvitee, authRole } = createAuthProvider();