import { Wrapper, Title, Text, LinkWrapper, IconButtonStyled } from './ChangePassword.style';
import TextBoxStyled from '../../../components/Auth/Textbox/Textbox.style';
import ButtonStyled from "../../../components/Auth/Button/Button.style";
import Link from '../../../components/Link/Link.style';
import { Validator, RequiredRule, CompareRule, PatternRule, StringLengthRule } from 'devextreme-react/validator';
import { PASS_PATTERN_RULE } from '../../../utils/rules';
import { GRID_XS } from '../../../utils/constants';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';

export const ChangePassword = ({
    newPass,
    confirmNewPass,
    onChangedNewPass,
    onChangedConfirmNewPass,
    newPassMode,
    confirmNewPassMode,
    changeFirstMode,
    changeSecondMode,
    validatorRef,
    passComparison,
    handleClick,
    btnStatus,
}) => {
    return (
        <Wrapper>
            <Title>Change your password</Title>
            <Grid container spacing={2}>
                <Grid item xs={GRID_XS}>
                    <Text>
                        Enter a new password below to change your password.<br /><br />
                        Your new password must be different from previously used passwords. Password must be at least 8 characters long and contain 1 lowercase, 1 uppercase, 1 number, and 1 special character (@#!_$%&).
                    </Text>
                </Grid>
                <Grid item xs={GRID_XS} title="New Password">
                    <TextBoxStyled
                        label="New Password"
                        labelMode="static"
                        stylingMode="outlined"
                        mode={newPassMode}
                        value={newPass}
                        inputAttr={{ 'aria-label': 'new-password' }}
                        onValueChanged={onChangedNewPass}
                    >
                        <IconButtonStyled
                            name="password"
                            location="after"
                            onClick={changeFirstMode}
                        >
                            <Icon
                                icon={newPassMode === "text" ? "bx:hide" : "bx:show"}
                                height="30px"
                                width="30px" />
                        </IconButtonStyled>
                        <Validator>
                            <RequiredRule message="Password is required" />
                            <PatternRule
                                message="Do not use digits in the Password"
                                pattern={PASS_PATTERN_RULE}
                            />
                            <StringLengthRule
                                message="Password must have at least 8 symbols"
                                min={8}
                            />
                        </Validator>
                    </TextBoxStyled>
                </Grid>
                <Grid item xs={GRID_XS} title="Confirm New Password">
                    <TextBoxStyled
                        label="Confirm New Password"
                        labelMode="static"
                        stylingMode="outlined"
                        value={confirmNewPass}
                        inputAttr={{ 'aria-label': 'confirm-new-password' }}
                        onValueChanged={onChangedConfirmNewPass}
                        mode={confirmNewPassMode}
                    >
                        <IconButtonStyled
                            name="password"
                            location="after"
                            onClick={changeSecondMode}
                        >
                            <Icon
                                icon={confirmNewPassMode === "text" ? "bx:hide" : "bx:show"}
                                height="30px"
                                width="30px" />
                        </IconButtonStyled>
                        <Validator ref={validatorRef}>
                            <RequiredRule message="Confirm Password is required" />
                            <CompareRule
                                message="Password and Confirm Password do not match"
                                comparisonTarget={passComparison}
                            />
                            <PatternRule
                                message="Do not use digits in the Confirm Password"
                                pattern={PASS_PATTERN_RULE}
                            />
                            <StringLengthRule
                                message="Confirm Password must have at least 8 symbols"
                                min={8}
                            />
                        </Validator>
                    </TextBoxStyled>
                    <ButtonStyled
                        text="reset password"
                        onClick={handleClick}
                        disabled={btnStatus}
                    />
                    <LinkWrapper>
                        <Icon icon="bi:arrow-left" height="20px" color="var(--light-blue-color)" />
                        <Link to="/auth/login">back to log in</Link>
                    </LinkWrapper>
                </Grid>
            </Grid>
        </Wrapper>
    );
}