import { useParams } from 'react-router-dom';
import { selectLoading, selectContact, selectRelationship, selectAddress, selectCity, selectState, selectZip, selectPhone, selectEmail } from "../../../../../store/forms/newPatientRegistration/emergencyContact/selectors";
import { setAddress, setCity, setContact, setEmail, setPhone, setRelationship, setState, setZip } from "../../../../../store/forms/newPatientRegistration/emergencyContact/slice";

import { getEmergencyContact, saveEmergencyContact, getEmergencyContactByID, saveEmergencyContactByID } from "../../../../../store/forms/newPatientRegistration/emergencyContact/middlewares";
import { completeEmergencyContact } from "../../../../../store/forms/newPatientRegistration/slice";
import { selectStates } from "../../../../../store/lookups/state/selectors";
import { getState } from "../../../../../store/lookups/state/middlewares";

import { validateGroup } from "../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { EmergencyContact } from "./EmergencyContact";
import { useEffect } from "react";

export const EmergencyContactContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const states = useSelector(selectStates);

    const contact = useSelector(selectContact);
    const relationship = useSelector(selectRelationship);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const phone = useSelector(selectPhone);
    const email = useSelector(selectEmail);

    useEffect(() => {
        dispatch(getState());
        dispatch(id ? getEmergencyContactByID(id) : getEmergencyContact());
    }, [dispatch, id]);

    const onChangeContact = (event) => {
        dispatch(setContact(event.value.trim()));
    }

    const onChangeRelationship = (event) => {
        dispatch(setRelationship(event.value.trim()));
    }

    const onChangeAddress = (event) => {
        dispatch(setAddress(event.value.trim()));
    }

    const onChangeCity = (event) => {
        dispatch(setCity(event.value.trim()));
    }

    const onChangeState = (event) => {
        dispatch(setState(event.value));
    }

    const onChangeZip = (event) => {
        dispatch(setZip(event.value));
    }

    const onChangePhone = (event) => {
        dispatch(setPhone(event.value));
    }

    const onChangeEmail = (event) => {
        dispatch(setEmail(event.value.trim()));
    }

    const onSaveEmergencyContact = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveEmergencyContactByID(id) : saveEmergencyContact());
            dispatch(completeEmergencyContact(true));
        }
    }

    const onSaveAndContinueEmergencyContact = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveEmergencyContactByID(id) : saveEmergencyContact());
            dispatch(completeEmergencyContact(true));
            onClickNext();
        }
    }

    return (
        <EmergencyContact
            loading={loading}

            states={states}

            contact={contact}
            relationship={relationship}
            address={address}
            city={city}
            state={state}
            zip={zip}
            phone={phone}
            email={email}

            onChangeContact={onChangeContact}
            onChangeRelationship={onChangeRelationship}
            onChangeAddress={onChangeAddress}
            onChangeCity={onChangeCity}
            onChangeState={onChangeState}
            onChangeZip={onChangeZip}
            onChangePhone={onChangePhone}
            onChangeEmail={onChangeEmail}

            onSaveEmergencyContact={onSaveEmergencyContact}
            onSaveAndContinueEmergencyContact={onSaveAndContinueEmergencyContact}
        />
    )
};