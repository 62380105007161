import { createSlice } from '@reduxjs/toolkit';
import { postInviteLink, postSendInvite, getInvate } from './middlewares';
import { showToast } from '../../../screens/Main/MainContainer';
import { FETCH_STATUSES } from '../../../utils/constants';

const initialState = {
	data: {
		name: "",
		email: "",
		phone: "",
		dob: null,
	},
	linkStatus: FETCH_STATUSES.IDLE,
	sendStatus: FETCH_STATUSES.IDLE,
	invateStatus: FETCH_STATUSES.IDLE,
	linkError: null,
	sendError: null,
	invateError: null,
	showEdit: false,
	inviteLink: "",
	inviteKey: "",
};

export const providerGenerationInvites = createSlice({
	name: 'providerGenerationInvites',
	initialState,
	reducers: {
		setShowInvite: (state, action) => {
			state.showEdit = action.payload;
		},
		setName: (state, action) => {
			state.data.name = action.payload;
		},
		setEmail: (state, action) => {
			state.data.email = action.payload;
		},
		setPhone: (state, action) => {
			state.data.phone = action.payload;
		},
		setDOB: (state, action) => {
			state.data.dob = action.payload;
		},
		setClear: (state) => {
			state.data = initialState.data;
			state.inviteKey = "";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(postInviteLink.pending, (state) => {
				state.linkStatus = FETCH_STATUSES.REQUEST;
				state.linkError = null;
			})
			.addCase(postSendInvite.pending, (state) => {
				state.sendStatus = FETCH_STATUSES.REQUEST;
				state.sendError = null;
			})
			.addCase(getInvate.pending, (state) => {
				state.invateStatus = FETCH_STATUSES.REQUEST;
				state.invateError = null;
			})
			.addCase(postInviteLink.fulfilled, (state, action) => {
				state.inviteLink = action.payload;
				showToast("success", "Invite link created successfully");
			})
			.addCase(postSendInvite.fulfilled, (state, action) => {
				state.sendStatus = FETCH_STATUSES.SUCCESS;
				state.data = initialState.data;
				state.inviteKey = "";
				state.sendError = null;
				state.showEdit = false;

				showToast("success", "Invite link sent successfully");
			})
			.addCase(getInvate.fulfilled, (state, action) => {
				state.inviteKey = action.payload.link.value;
			})


			.addCase(postInviteLink.rejected, (state, action) => {
				state.linkStatus = FETCH_STATUSES.FAILURE;
				state.linkError = action.error;
				
				showToast("error", action.payload ? action.payload : "This user already exists");
			})


			.addCase(postSendInvite.rejected, (state, action) => {
				state.sendStatus = FETCH_STATUSES.FAILURE;
				state.sendError = action.error;
			})
			.addCase(getInvate.rejected, (state, action) => {
				state.invateStatus = FETCH_STATUSES.FAILURE;
				state.invateError = action.error;

				showToast("error", "Unable to generate invite link");
			})
	},
});

export const {
	setShowInvite,
	setName,
	setEmail,
	setPhone,
	setDOB,
	setClear,
} = providerGenerationInvites.actions;

export default providerGenerationInvites.reducer;