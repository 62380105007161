import { Wrapper } from '../../../../../components/Main/Main.style';
import { ADVANCE_CARE, CURRENT_DATE, GRID_XS, MAX_WIDTH, MIN_DATE } from '../../../../../utils/constants';
import { dateFormatFromApi } from '../../../../../utils/formats';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RangeRule,
} from 'devextreme-react/validator';

export const AdvanceCare = ({
	loading,

	advHCareDirectiveStatuses,
	advPsychDirectiveStatuses,

	lastDateReview,
	haveLivingWill,
	haveLivingWillReason,
	psychiatricAdvanceDirective,
	psychiatricAdvanceDirectiveReason,
	advanceHealthCareDirectiveOnFile,
	advanceHealthCareDirectiveInfo,
	answers,

	onChangeLastDateReview,
	onChangeHaveLivingWill,
	onChangeHaveLivingWillReason,
	onChangePsychiatricAdvanceDirective,
	onChangePsychiatricAdvanceDirectiveReason,
	onToggleAdvanceHealthCareDirectiveOnFile,
	onToggleAdvanceHealthCareDirectiveInfo,

	onSaveAdvanceCare,
	onSaveAndContinueAdvanceCare,
}) => {

	return (
		<Wrapper id={ADVANCE_CARE} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${ADVANCE_CARE}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Tooltip title="I have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One">
							<>
								<SelectBox
									label="I have an Advance Health Care Directive, also known as a living will, Durable Power of Attorney, POLST or Comfort One"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={haveLivingWill}
									dataSource={advHCareDirectiveStatuses}
									onValueChanged={onChangeHaveLivingWill}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-haveLivingWill' }}
								/>
							</>
						</Tooltip>
					</Grid>
					{
						haveLivingWill === (advHCareDirectiveStatuses.find((e) => e?.Name === "Unable to Access")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Reason">
								<>
									<TextBox
										label="Reason"
										value={haveLivingWillReason}
										onValueChanged={onChangeHaveLivingWillReason}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-haveLivingWillReason' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="I have a Psychiatric Advance Directive">
							<>
								<SelectBox
									label="I have a Psychiatric Advance Directive"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={psychiatricAdvanceDirective}
									dataSource={advPsychDirectiveStatuses}
									onValueChanged={onChangePsychiatricAdvanceDirective}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-psychiatricAdvanceDirective' }}
								/>
							</>
						</Tooltip>
					</Grid>
					{
						psychiatricAdvanceDirective === (advPsychDirectiveStatuses.find((e) => e?.Name === "Unable to Access")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Reason">
								<>
									<TextBox
										label="Reason"
										value={psychiatricAdvanceDirectiveReason}
										onValueChanged={onChangePsychiatricAdvanceDirectiveReason}
										maxLength={30}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-psychiatricAdvanceDirectiveReason' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="If yes, is your Advance Health Care Directive on file?">
							<>
								<SelectBox
									label="If yes, is your Advance Health Care Directive on file?"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={advanceHealthCareDirectiveOnFile}
									dataSource={answers}
									onValueChanged={onToggleAdvanceHealthCareDirectiveOnFile}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-advanceHealthCareDirectiveOnFile' }} />
							</>
						</Tooltip>
					</Grid>
					{
						advanceHealthCareDirectiveOnFile &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Last Date Reviewed">
								<>
									<DateBox
										type="date"
										label="Last Date Reviewed"
										value={dateFormatFromApi(lastDateReview)}
										onValueChanged={onChangeLastDateReview}
										useMaskBehavior={true}
										displayFormat="MM/dd/yyyy"
										invalidDateMessage="The date must have the following format: MM/dd/yyyy"
										labelMode="floating"
										stylingMode="outlined"
									>
										<Validator validationGroup={ADVANCE_CARE}>
											<RangeRule
												message="Date cannot be later than the current date and earlier than 1900"
												min={MIN_DATE}
												max={CURRENT_DATE} />
										</Validator>
									</DateBox>
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS}>
						<Tooltip title="If no, would you like information regarding Advance Health Care Directives?">
							<>
								<SelectBox
									label="If no, would you like information regarding Advance Health Care Directives?"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={advanceHealthCareDirectiveInfo}
									dataSource={answers}
									onValueChanged={onToggleAdvanceHealthCareDirectiveInfo}
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-advanceHealthCareDirectiveInfo' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={ADVANCE_CARE}
							onClick={onSaveAdvanceCare} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={ADVANCE_CARE}
							onClick={onSaveAndContinueAdvanceCare} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	);
};