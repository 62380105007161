import { styled } from '@mui/material/styles';

export const Signature = styled('fieldset')(({
    error,
    disabled,
}) => ({
    cursor: 'pointer',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: 'var(--white-color)',
    borderColor: error ? 'rgba(240, 57, 57, .4)' : 'var(--border-color)',
    '& > legend': {
        fontFamily: 'Montserrat',
        lineHeight: '22px',
        fontWeight: '500',
        fontSize: '12px',
        color: error ? 'var(--red-status-color)' : 'var(--border-color)',
    },
    '&:active': {
        ...(!disabled && {
            borderColor: error ? 'var(--red-status-color)' : 'var(--light-blue-color)',
            '& > legend': {
                color: error ? 'var(--red-status-color)' : 'var(--light-blue-color)',
            },
        }),
    },
    '&:hover': {
        ...(!disabled && error && {
            borderColor: 'var(--red-status-color)',
        }),
    },
    ...(disabled && {
        opacity: '.38',
        userSelect: 'none',
        pointerEvents: 'none',
    }),
}));