import background from '../../../images/background.png';
import logo from '../../../images/logo.png';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
    height: '100vh',
    background: `url(${background}) no-repeat`,
    backgroundSize: 'cover',
    display: 'flex',
    '@media (max-width: 768px)': {
        background: 'none',
    },
}));

export const Image = styled('div')(() => ({
    height: '100vh',
    width: '60%',
    padding: '0 10vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: "border-box",
    '@media (max-width: 768px)': {
        display: 'none',
    },
}));

export const Title = styled('div')(() => ({
    fontFamily: 'Butler',
    userSelect: 'none',
    width: '410px',
    fontWeight: '700',
    fontSize: '80px',
    lineHeight: '86px',
    color: 'var(--white-color)',
}));

export const List = styled('div')(() => ({
    fontFamily: 'Butler',
    userSelect: 'none',
    width: '410px',
    fontWeight: '700',
    fontSize: '80px',
    lineHeight: '96px',
    color: 'var(--white-color)',
}));

export const Form = styled('div')(() => ({
    backgroundColor: 'var(--white-color)',
    height: '100vh',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
        width: '100%',
    },
}));

export const Logo = styled('div')(() => ({
    background: `url(${logo}) no-repeat`,
    backgroundSize: 'cover',
    position: 'absolute',
    top: '0px',
    height: '90px',
    width: '300px',
    marginTop: '40px',
    marginLeft: '80px',
    userSelect: 'none',
    '@media (max-width: 1400px)': {
        marginLeft: '20px',
    },
}));