import { Footer, Text, Link, Box, BoxTitle } from './FooterBlock.style';

export const FooterBlock = ({ width }) => {
  return (
    <Footer>
      <Box width={width} style={{ backgroundColor: 'var(--background-blue-color)' }}>
        <BoxTitle>Contact</BoxTitle>
        <Text className="pb20">
          If you have any questions, or need to reschedule or cancel an appointment, please contact
          us at:
        </Text>
        <Text>
          <b>APIA Behavioral Health</b>
        </Text>
        <Text>1131 E. International Airport Rd.,</Text>
        <Text>Anchorage, Alaska, 99518</Text>
        <Text>
          Email: <Link href="mailto:registration@apiai.org">registration@apiai.org</Link>
        </Text>
        <Text>Phone: 1-907-222-9764 or 1-844-375-2742</Text>
      </Box>
      <Box width={width} style={{ backgroundColor: 'var(--background-red-color)' }}>
        <BoxTitle>Crisis</BoxTitle>
        <Text className="pb20">
          If you are in an immediate crisis, please call 911, or visit the emergency room at a local
          hospital.
        </Text>
        <Text className="pb20">
          If you are in the Aleutian and Pribilof Islands Region, please contact Public Safety or
          Village Public Safety Officer (VPSO).
        </Text>
        <Text className="pb20">
          The number for CARELINE (Alaska’s Suicide & Someone-to-talk-to-line) is 1-877-266-4357 or
          you may dial 988 for the Suicide & Crisis Lifeline.
        </Text>
      </Box>
    </Footer>
  );
};
