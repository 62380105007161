import { GuardianInformationContainer } from './Steps/GuardianInformation/GuardianInformationContainer';
import { PatientInformationContainer } from './Steps/PatientInformation/PatientInformationContainer';
import { EmergencyContactContainer } from './Steps/EmergencyContact/EmergencyContactContainer';
import { NextOfKinContainer } from './Steps/NextOfKin/NextOfKinContainer';
import { TribalAffiliationContainer } from './Steps/TribalAffiliation/TribalAffiliationContainer';
import { HealthAndTreatmentContainer } from './Steps/HealthAndTreatment/HealthAndTreatmentContainer';
import { InsuranceInfoContainer } from './Steps/InsuranceInfo/InsuranceInfoContainer';
import { ReferralInfoContainer } from './Steps/ReferralInfo/ReferralInfoContainer';
import { AdvanceCareContainer } from './Steps/AdvanceCare/AdvanceCareContainer';
import { EmploymentContainer } from './Steps/Employment/EmploymentContainer';
import { EducationContainer } from './Steps/Education/EducationContainer';
import { ConsentContainer } from './Steps/Consent/ConsentContainer';
import { BasicInformation } from './BasicInformation';
import { useState } from 'react';

export const BasicInformationContainer = () => {
    const [expanded, setExpanded] = useState([]);

    const steps = [{
        title: 'Patient Information',
        body: <PatientInformationContainer />,
    }, {
        title: 'Additional Parent / Guardian Information',
        body: <GuardianInformationContainer />,
    }, {
        title: 'Tribal Affiliation / Ethnicity / Language',
        body: <TribalAffiliationContainer />,
    }, {
        title: 'Health and Treatment Information',
        body: <HealthAndTreatmentContainer />,
    }, {
        title: 'Employment',
        body: <EmploymentContainer />,
    }, {
        title: 'Education/Housing',
        body: <EducationContainer />,
    }, {
        title: 'Emergency Contact',
        body: <EmergencyContactContainer />,
    }, {
        title: 'Next Of Kin',
        body: <NextOfKinContainer />,
    }, {
        title: 'Insurance Information',
        body: <InsuranceInfoContainer />,
    }, {
        title: 'Referral Information',
        body: <ReferralInfoContainer />,
    }, {
        title: 'Advance Care Planning',
        body: <AdvanceCareContainer />,
    }, {
        title: 'Consent',
        body: <ConsentContainer />,
    }];

    const onClickExpand = (panel) => {
        let newItems = [...expanded];
        if (newItems.includes(panel)) {
            const index = newItems.indexOf(panel);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        } else {
            newItems = [...newItems, panel];
        }
        setExpanded(newItems);
    };

    const onClickExpandAll = () => {
        const keys = Object.keys(steps).map((key) => parseInt(key, 10));
        setExpanded(keys);
    }

    const onClickCollapseAll = () => {
        setExpanded([]);
    }

    return (
        <BasicInformation
            steps={steps}
            expanded={expanded}
            onClickExpand={onClickExpand}
            onClickExpandAll={onClickExpandAll}
            onClickCollapseAll={onClickCollapseAll}
        />
    );
}