import { isAdmin } from '../../../core/auth/authProvider';
import { Wrapper, TitleWrapper, Title, Text, Fieldset } from '../../../components/Main/Main.style';
import { GRID_MD, GRID_SM, GRID_XS, MAX_DATE, MAX_WIDTH, MIN_DATE, PHONE_MASK } from '../../../utils/constants';
import { dateFormatFromApi } from '../../../utils/formats';
import { onKeyDownHandle } from '../../../utils/helper';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	EmailRule,
	RangeRule,
} from 'devextreme-react/validator';

export const GrievanceFeedbackForm = ({
	loading,

	name,
	address,
	phone,
	email,
	typeConnectionPhone,
	typeConnectionEmail,
	typeConnectionMail,
	bestConnectionMorning,
	bestConnectionAfternoon,
	bestConnectionEvening,
	statusPatientClient,
	statusVisitor,
	commentTypeGreatJob,
	commentTypeInquiry,
	commentTypeSuggestion,
	commentTypeComplaintGrievance,
	commentTypeSlidingScale,
	commentTypeOther,
	commentTypeOtherText,
	date,
	clinicDepartment,
	experience,
	allowExperience,
	onChangeName,
	onChangeAddress,
	onChangePhone,
	onChangeEmail,
	onToggleTypeConnectionPhone,
	onToggleTypeConnectionEmail,
	onToggleTypeConnectionMail,
	onToggleBestConnectionMorning,
	onToggleBestConnectionAfternoon,
	onToggleBestConnectionEvening,
	onToggleStatusPatientClient,
	onToggleStatusVisitor,
	onToggleCommentTypeGreatJob,
	onToggleCommentTypeInquiry,
	onToggleCommentTypeSuggestion,
	onToggleCommentTypeComplaintGrievance,
	onToggleCommentTypeSlidingScale,
	onToggleCommentTypeOther,
	onChangeCommentTypeOtherText,
	onChangeDate,
	onChangeClinicDepartment,
	onChangeExperience,
	onChangeAllowExperience,

	onSaveGrievanceFeedbackForm,
	onClickBack,
}) => {
	const sectionName = "grievanceFeedbackForm";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Grievance & Feedback Form">
								<Title>Grievance & Feedback Form</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>Information is required to better serve you. You may return this form to any employee.</Text>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Name">
							<>
								<TextBox
									label="Name"
									value={name}
									onValueChanged={onChangeName}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-name' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Phone">
							<>
								<TextBox
									label="Phone"
									value={phone}
									onValueChanged={onChangePhone}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={PHONE_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="The phone must have a correct USA phone format"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-phone' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Email">
							<>
								<TextBox
									label="Email"
									value={email}
									onValueChanged={onChangeEmail}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-email' }}>
									<Validator validationGroup={sectionName}>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>How would you like to be contacted?</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Phone">
										<>
											<CheckBox
												text="Phone"
												value={typeConnectionPhone}
												onValueChanged={onToggleTypeConnectionPhone} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Email">
										<>
											<CheckBox
												text="Email"
												value={typeConnectionEmail}
												onValueChanged={onToggleTypeConnectionEmail} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Mail">
										<>
											<CheckBox
												text="Mail"
												value={typeConnectionMail}
												onValueChanged={onToggleTypeConnectionMail} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>Best time to contact you</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Morning">
										<>
											<CheckBox
												text="Morning"
												value={bestConnectionMorning}
												onValueChanged={onToggleBestConnectionMorning} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Afternoon">
										<>
											<CheckBox
												text="Afternoon"
												value={bestConnectionAfternoon}
												onValueChanged={onToggleBestConnectionAfternoon} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Evening">
										<>
											<CheckBox
												text="Evening"
												value={bestConnectionEvening}
												onValueChanged={onToggleBestConnectionEvening} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>Status</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Patient/Client">
										<>
											<CheckBox
												text="Patient/Client"
												value={statusPatientClient}
												onValueChanged={onToggleStatusPatientClient} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Visitor">
										<>
											<CheckBox
												text="Visitor"
												value={statusVisitor}
												onValueChanged={onToggleStatusVisitor} />
										</>
									</Tooltip>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS}>
									<Text><strong>Comment Type</strong></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Great job">
										<>
											<CheckBox
												text="Great job"
												value={commentTypeGreatJob}
												onValueChanged={onToggleCommentTypeGreatJob} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Inquiry">
										<>
											<CheckBox
												text="Inquiry"
												value={commentTypeInquiry}
												onValueChanged={onToggleCommentTypeInquiry} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Suggestion">
										<>
											<CheckBox
												text="Suggestion"
												value={commentTypeSuggestion}
												onValueChanged={onToggleCommentTypeSuggestion} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Complaint/Grievance">
										<>
											<CheckBox
												text="Complaint/Grievance"
												value={commentTypeComplaintGrievance}
												onValueChanged={onToggleCommentTypeComplaintGrievance} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Sliding Scale">
										<>
											<CheckBox
												text="Sliding Scale"
												value={commentTypeSlidingScale}
												onValueChanged={onToggleCommentTypeSlidingScale} />
										</>
									</Tooltip>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Tooltip title="Other">
										<>
											<CheckBox
												text="Other"
												value={commentTypeOther}
												onValueChanged={onToggleCommentTypeOther} />
										</>
									</Tooltip>
								</Grid>
								{commentTypeOther &&
									<Grid item xs={GRID_XS}>
										<Tooltip title="Comment">
											<>
												<TextBox
													label="Comment"
													value={commentTypeOtherText}
													onValueChanged={onChangeCommentTypeOtherText}
													maxLength={30}
													labelMode="floating"
													stylingMode="outlined"
													inputAttr={{ autocomplete: 'new-commentTypeOtherText' }} />
											</>
										</Tooltip>
									</Grid>
								}
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_SM}>
						<Tooltip title="Date of Event">
							<>
								<DateBox
									label="Date of Event"
									type="date"
									value={dateFormatFromApi(date)}
									onValueChanged={onChangeDate}
									useMaskBehavior={true}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RangeRule
											message="Date cannot be earlier than 1900 and later than 2100"
											min={MIN_DATE}
											max={MAX_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_SM}>
						<Tooltip title="Clinic/Department">
							<>
								<TextBox
									label="Clinic/Department"
									value={clinicDepartment}
									onValueChanged={onChangeClinicDepartment}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-clinicDepartment' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Please describe your experience">
							<>
								<TextBox
									label="Please describe your experience"
									value={experience}
									onValueChanged={onChangeExperience}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-experience' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="How would you like this experience resolved?">
							<>
								<TextBox
									label="How would you like this experience resolved?"
									value={allowExperience}
									onValueChanged={onChangeAllowExperience}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-allowExperience' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Thank you for using this card to communicate with us regarding our services.  It is designed for use by our clients/patients/visitors to share their compliments, suggestions and grievances regarding services.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							The information provided will be kept strictly confidential and used only to improve our services.  All comments will be reviewed in a timely manner.  Your input is sincerely valued and appreciated.  Thank you!
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
						<Text><strong>ATTN: Compliance Administrator</strong></Text>
						<Text><strong>Aleutian Pribilof Islands Association, Inc.</strong></Text>
						<Text><strong>1131 E. International Airport Road</strong></Text>
						<Text><strong>Anchorage, AK 99518</strong></Text>
						<Text><strong>Phone: 1-800-478-2742, 907-276-2700 (main)</strong></Text>
						<Text><strong>Fax: 907-222-4279 (Health Dept. Fax)</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text>
							Our clients/patients/visitors also have the option to contact the following agencies concerning a grievance:
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
						<Text><strong>State of Alaska</strong></Text>
						<Text><strong>Dept. of Health and Social Services</strong></Text>
						<Text><strong>Div. of Health Care Services</strong></Text>
						<Text><strong>Certification/Licensing</strong></Text>
						<Text><strong>4501 Business Park Blvd., STE24</strong></Text>
						<Text><strong>Anchorage, AK 99503</strong></Text>
						<Text><strong>Phone: 907-334-2483   Fax: 907-334-2682</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
						<Text><strong>Office of Quality Monitoring</strong></Text>
						<Text><strong>The Joint Commission</strong></Text>
						<Text><strong>One Renaissance Boulevard</strong></Text>
						<Text><strong>Oakbrook Terrace, Illinois 60181</strong></Text>
						<Text><strong>Email: complaint@jointcommission.org</strong></Text>
						<Text><strong>Phone: 800-994-6610</strong></Text>
						<Text><strong>Fax: 630-792-5636</strong></Text>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="contained"
							validationGroup={sectionName}
							onClick={onSaveGrievanceFeedbackForm} />
					</Grid>
				</Grid >
			}
		</Wrapper >
	);
};