import { BASE_URL, ADVANCE_CARE } from "../../../../utils/constants";
import { dateFormatForApi } from "../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getAdvanceCare = createAsyncThunk(
    "getAdvanceCare",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${ADVANCE_CARE}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getAdvanceCareByID = createAsyncThunk(
    "getAdvanceCareByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${ADVANCE_CARE}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postAdvanceCare = createAsyncThunk(
    "postAdvanceCare",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${ADVANCE_CARE}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postAdvanceCareByID = createAsyncThunk(
    "postAdvanceCareByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${ADVANCE_CARE}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveAdvanceCare = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationAdvanceCare;

    const data = {
        "haveLivingWill": store.haveLivingWill,
        "haveLivingWillReason": store.haveLivingWillReason,
        "psychiatricAdvanceDirective": store.psychiatricAdvanceDirective,
        "psychiatricAdvanceDirectiveReason": store.psychiatricAdvanceDirectiveReason,
        "advanceHealthCareDirectiveOnFile": store.advanceHealthCareDirectiveOnFile,
        "lastDateReview": dateFormatForApi(store.lastDateReview),
        "advanceHealthCareDirectiveInfo": store.advanceHealthCareDirectiveInfo
    }

    dispatch(postAdvanceCare(data));
}

export const saveAdvanceCareByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationAdvanceCare;

    const data = {
        "haveLivingWill": store.haveLivingWill,
        "haveLivingWillReason": store.haveLivingWillReason,
        "psychiatricAdvanceDirective": store.psychiatricAdvanceDirective,
        "psychiatricAdvanceDirectiveReason": store.psychiatricAdvanceDirectiveReason,
        "advanceHealthCareDirectiveOnFile": store.advanceHealthCareDirectiveOnFile,
        "lastDateReview": dateFormatForApi(store.lastDateReview),
        "advanceHealthCareDirectiveInfo": store.advanceHealthCareDirectiveInfo
    }

    dispatch(postAdvanceCareByID({ id, data }));
}