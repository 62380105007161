import { CURRENT_DATE, FETCH_STATUSES } from "../../../../utils/constants";
import { showToast } from "../../../../screens/Main/MainContainer";
import { getConsent, setConsent, getConsentByID, setConsentByID } from "./middlewares";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    signed: "",
    guardian: "",
    signedDate: CURRENT_DATE,
    guardianDate: CURRENT_DATE,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
    signPicture: "",
};

export const newPatientRegistrationConsent = createSlice({
    name: "newPatientRegistrationConsent",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setName: (state, action) => {
            state.name = action.payload;
            return state;
        },
        setSigned: (state, action) => {
            state.signed = action.payload;
            return state;
        },
        setGuardian: (state, action) => {
            state.guardian = action.payload;
            return state;
        },
        setSignedDate: (state, action) => {
            state.signedDate = action.payload;
            return state;
        },
        setGuardianDate: (state, action) => {
            state.guardianDate = action.payload;
            return state;
        },
        setSignPicture: (state, action) => {
            state.signPicture = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConsent.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(setConsent.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getConsent.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.name = action.payload?.name;
                state.signed = action.payload?.signed;
                state.guardian = action.payload?.guardian;
                state.signedDate = action.payload?.signedDate || CURRENT_DATE;
                state.guardianDate = action.payload?.guardianDate || CURRENT_DATE;
                state.signPicture = action.payload?.signPicture;

                state.loading = false;
            })
            .addCase(setConsent.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Consent saved successfully");
            })
            .addCase(getConsent.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Consent: ${action.error.message}`);
            })
            .addCase(setConsent.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Consent: ${action.error.message}`);
            })
            .addCase(getConsentByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })

            .addCase(getConsentByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;
                showToast('error', `Get Consent: ${action.error.message}`);
            })
            .addCase(getConsentByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.name = action.payload?.name;
                state.signed = action.payload?.signed;
                state.guardian = action.payload?.guardian;
                state.signedDate = action.payload?.signedDate || CURRENT_DATE;
                state.guardianDate = action.payload?.guardianDate || CURRENT_DATE;
                state.signPicture = action.payload?.signPicture;
                state.loading = false;
            })
            .addCase(setConsentByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })

            .addCase(setConsentByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;
                showToast('error', `Set Consent: ${action.error.message}`);
            })
            .addCase(setConsentByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;
                showToast('success', 'Consent saved successfully');
            })
    },
});

export const {
    setLoading,

    setName,
    setSigned,
    setGuardian,
    setSignedDate,
    setGuardianDate,
    setSignPicture,
} = newPatientRegistrationConsent.actions;

export default newPatientRegistrationConsent.reducer;

