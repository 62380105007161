import { selectConsent, selectEmergencyContact, selectNextOfKin, selectTribalAffiliation, selectHealthAndTreatment, selectGuardianInfo, selectInsuranceInfo, selectPatientInfo, selectReferralInfo, selectEmployment, selectEducation, selectAdvanceCare, selectExpanded, selectFixed } from '../../../store/forms/newPatientRegistration/selectors';
import { getNewPatientRegistration, getNewPatientRegistrationById } from '../../../store/forms/newPatientRegistration/middlewares';
import { completeNewPatientRegistration } from "../../../store/forms/newPatientRegistrationPacket/slice";
import { setNewActiveStep } from "../../../store/forms/newPatientRegistrationPacket/middlewares";
import { changeExpanded, changeFixed } from '../../../store/forms/newPatientRegistration/slice';


import { GuardianInformationContainer } from './Steps/GuardianInformation/GuardianInformationContainer';
import { PatientInformationContainer } from './Steps/PatientInformation/PatientInformationContainer';
import { EmergencyContactContainer } from './Steps/EmergencyContact/EmergencyContactContainer';
import { NextOfKinContainer } from './Steps/NextOfKin/NextOfKinContainer';
import { TribalAffiliationContainer } from './Steps/TribalAffiliation/TribalAffiliationContainer';
import { HealthAndTreatmentContainer } from './Steps/HealthAndTreatment/HealthAndTreatmentContainer';
import { InsuranceInfoContainer } from './Steps/InsuranceInfo/InsuranceInfoContainer';
import { ReferralInfoContainer } from './Steps/ReferralInfo/ReferralInfoContainer';
import { AdvanceCareContainer } from './Steps/AdvanceCare/AdvanceCareContainer';
import { EmploymentContainer } from './Steps/Employment/EmploymentContainer';
import { EducationContainer } from './Steps/Education/EducationContainer';
import { ConsentContainer } from './Steps/Consent/ConsentContainer';

import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { NewPatientRegistration } from './NewPatientRegistration';
import { useDispatch, useSelector } from "react-redux";
import { USER_PATH } from '../../../utils/constants';
import { useEffect } from 'react';

export const NewPatientRegistrationContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const fixed = useSelector(selectFixed);
	const expanded = useSelector(selectExpanded);

	const patientInfo = useSelector(selectPatientInfo);
	const guardianInfo = useSelector(selectGuardianInfo);
	const tribalAffiliation = useSelector(selectTribalAffiliation);
	const healthAndTreatment = useSelector(selectHealthAndTreatment);
	const employment = useSelector(selectEmployment);
	const education = useSelector(selectEducation);
	const emergencyContact = useSelector(selectEmergencyContact);
	const nextOfKin = useSelector(selectNextOfKin);
	const insuranceInfo = useSelector(selectInsuranceInfo);
	const referralInfo = useSelector(selectReferralInfo);
	const advanceCare = useSelector(selectAdvanceCare);
	const consent = useSelector(selectConsent);

	const onClickNext = (panel) => {
		let newItems = [...expanded];

		if (newItems.includes(panel)) {
			const index = newItems.indexOf(panel);

			if (index >= 0) {
				newItems.splice(index, 1);
			};
		};

		if (panel !== steps.length - 1) {
			if (!newItems.includes(panel + 1)) {
				newItems = [...newItems, panel + 1];
			};
		} else {
			dispatch(completeNewPatientRegistration(true));
			dispatch(setNewActiveStep());
		};

		dispatch(changeExpanded(newItems));
	};

	const steps = [{
		id: 0,
		title: 'Patient Information',
		body: <PatientInformationContainer onClickNext={(el) => onClickNext(0)} />,
		isCompleted: patientInfo,
	}, {
		id: 1,
		title: 'Additional Parent / Guardian Information',
		body: <GuardianInformationContainer onClickNext={() => onClickNext(1)} />,
		isCompleted: guardianInfo,
	}, {
		id: 2,
		title: 'Tribal Affiliation / Ethnicity / Language',
		body: <TribalAffiliationContainer onClickNext={() => onClickNext(2)} />,
		isCompleted: tribalAffiliation,
	}, {
		id: 3,
		title: 'Health and Treatment Information',
		body: <HealthAndTreatmentContainer onClickNext={() => onClickNext(3)} />,
		isCompleted: healthAndTreatment,
	}, {
		id: 4,
		title: 'Employment',
		body: <EmploymentContainer onClickNext={() => onClickNext(4)} />,
		isCompleted: employment,
	}, {
		id: 5,
		title: 'Education / Housing',
		body: <EducationContainer onClickNext={() => onClickNext(5)} />,
		isCompleted: education,
	}, {
		id: 6,
		title: 'Emergency Contact',
		body: <EmergencyContactContainer onClickNext={() => onClickNext(6)} />,
		isCompleted: emergencyContact,
	}, {
		id: 7,
		title: 'Next of Kin',
		body: <NextOfKinContainer onClickNext={() => onClickNext(7)} />,
		isCompleted: nextOfKin,
	}, {
		id: 8,
		title: 'Insurance Information',
		body: <InsuranceInfoContainer onClickNext={() => onClickNext(8)} />,
		isCompleted: insuranceInfo,
	}, {
		id: 9,
		title: 'Referral Information',
		body: <ReferralInfoContainer onClickNext={() => onClickNext(9)} />,
		isCompleted: referralInfo,
	}, {
		id: 10,
		title: 'Advance Care Planning',
		body: <AdvanceCareContainer onClickNext={() => onClickNext(10)} />,
		isCompleted: advanceCare,
	}, {
		id: 11,
		title: 'Consent',
		body: <ConsentContainer onClickNext={() => onClickNext(11)} />,
		isCompleted: consent,
	}];

	const stepsTaken = (steps.length) - (steps.filter(item => item.isCompleted).length);

	useEffect(() => {
		if (id !== undefined) {
			dispatch(getNewPatientRegistrationById(id));
		} else {
			dispatch(getNewPatientRegistration());
		}
	}, [dispatch, id]);

	useEffect(() => {
	}, [
		advanceCare,
		consent,
		education,
		emergencyContact,
		employment,
		guardianInfo,
		healthAndTreatment,
		insuranceInfo,
		nextOfKin,
		patientInfo,
		referralInfo,
		tribalAffiliation
	]);

	useEffect(() => {
		const handleScroll = () => {
			dispatch(changeFixed(window.scrollY > 160 ? true : false));
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [dispatch]);

	const onClickExpand = (panel) => {
		let newItems = [...expanded];
		if (newItems.includes(panel)) {
			const index = newItems.indexOf(panel);
			if (index >= 0) {
				newItems.splice(index, 1);
			}
		} else {
			newItems = [...newItems, panel];
		}
		dispatch(changeExpanded(newItems));
	};

	const onClickExpandAll = () => {
		const keys = Object.keys(steps).map((key) => parseInt(key, 10));
		dispatch(changeExpanded(keys));
	}

	const onClickCollapseAll = () => {
		dispatch(changeExpanded([]));
	}

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

	return (
		<NewPatientRegistration
			fixed={fixed}
			expanded={expanded}
			steps={steps}
			stepsTaken={stepsTaken}

			onClickExpand={onClickExpand}
			onClickExpandAll={onClickExpandAll}
			onClickCollapseAll={onClickCollapseAll}
			onClickBack={onClickBack}
		/>
	);
}
