import { authFetch } from "./auth/authProvider";

export function request(uri, method = 'get', data = {}, params = {}) {

  let headers = {};

  // Custom headers:
  if (params.headers) {
    Object.keys(params.headers).forEach(key => {
      headers[key] = params.headers[key];
    })
  }

  // Transform data depends of type: JSON|FormUrlEncoded|FormMultipart
  if (data && (!params.requestType || params.requestType === 'json')) {
    headers['Content-Type'] = 'application/json';
    data = JSON.stringify(data);
  }

  if (data && params.requestType === 'form-url') {
    data = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
  }

  if (data && params.requestType === 'form-multipart') {
    var formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]))
    data = formData;
  }

  // Expose rels and fields
  if (params.exposeRels) {
    headers['exposeRels'] = params.exposeRels;
  }
  if (params.special) {
    headers['special'] = params.special;
  }
  if (params.exportFields) {
    headers['exportFields'] = params.exportFields;
  }
  if (params.exportHeaders) {
    headers['exportHeaders'] = params.exportHeaders;
  }

  const options = {
    method: method,
    mode: 'cors',
    cache: 'default',
    headers: headers,
    body: data
  };

  return new Promise((resolve, reject) => {
    authFetch(uri, options)
      .then(async response => {
        if (!response.ok) {
          reject({
            message: `${response.statusText} (${response.status})`,
            response: response.clone(),
            responseBody: await response.clone().json(),
            request: options
          })
        }

        const contentType = response.headers.get('content-type');

        if (contentType && ['application/json', 'application/schema+json'].find(el => contentType.startsWith(el)) && (!!!params?.responseType)) {
          resolve(response.json());
        }
        else {
          let result = null;
          if (params?.responseType) {
            switch (params.responseType) {
              case 'json': result = response.json(); break;
              case 'arrayBuffer': result = response.arrayBuffer(); break;
              case 'blob': result = response.blob(); break;
              case 'formData': result = response.formData(); break;
              case 'text': result = response.text(); break;
              default: result = null;
            }
          }

          if (result) resolve(result);
          else resolve({ response, contentType });
        }
      })
      .catch(error => {
        reject({
          request: options,
          ...error
        })
      });

  });
}

export function get(uri, params) {
  return request(uri, 'GET', null, params)
}

export function post(uri, data, params) {
  return request(uri, 'POST', data, params)
}

export function put(uri, data, params) {
  return request(uri, 'PUT', data, params)
}

export function patch(uri, data, params) {
  return request(uri, 'PATCH', data, params)
}

export function remove(uri, data) {
  return request(uri, 'DELETE', data)
}

