import { Wrapper, Container, ScrollableWrapper, Stepper, Step, StepButton, FixedWrapper } from './NewPatientRegistrationPacket.style';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useResizeDetector } from 'react-resize-detector';
import { MAX_WIDTH } from '../../../utils/constants';
import { useMediaQuery } from 'react-responsive';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import Tooltip from '@mui/material/Tooltip';

export const NewPatientRegistrationPacket = ({
	steps,
	activeStep,
	handleStep,
	handleNext,
	handleBack,
	useViewportHeight,
}) => {
	const isMobile = useMediaQuery({ query: '(max-width: 425px)' });
	const isTablet = useMediaQuery({ query: '(max-width: 768px)' });
	const { ref, width } = useResizeDetector();

	const height = useViewportHeight();

	return (
		<Wrapper ref={ref}>
			{
				(isMobile || isTablet) ? <>
					<Container maxWidth={MAX_WIDTH}>
						{steps[activeStep]?.body}
					</Container>
					<FixedWrapper style={{ width: `${width}px` }}>
						<IconButton onClick={handleBack}>
							<KeyboardArrowLeft />
						</IconButton>
						<Stepper
							nonLinear
							height={56}
							orientation="horizontal"
							activeStep={activeStep}>
							{steps.map((step, index) => (
								<Step
									key={step.title}
									completed={step.isCompleted}
								>
									<StepButton
										onClick={handleStep(index)}
										complete={step.isCompleted}
										style={{ paddingLeft: '2px' }}
										icon={
											<Icon
												icon={step.icon}
												height="16px"
												width="16px" />
										}>
									</StepButton>
								</Step>
							))}
						</Stepper>
						<IconButton onClick={handleNext}>
							<KeyboardArrowRight />
						</IconButton>
					</FixedWrapper>
				</> : <>
					<ScrollableWrapper>
						<Stepper
							nonLinear
							height={height}
							orientation="vertical"
							activeStep={activeStep}>
							{steps.map((step, index) => (
								<Step
									key={step.title}
									completed={step.isCompleted}
								>
									<StepButton
										onClick={handleStep(index)}
										complete={step.isCompleted}
										style={{ paddingLeft: '2px' }}
										icon={
											<Icon
												icon={step.icon}
												height="22px"
												width="22px" />
										}>
										<Tooltip title={step.title} placement="bottom-start">
											<>
												{step.title}
											</>
										</Tooltip>
									</StepButton>
								</Step>
							))}
						</Stepper>
					</ScrollableWrapper>
					<Container maxWidth={MAX_WIDTH}>
						{steps[activeStep]?.body}
					</Container>
				</>
			}
		</Wrapper >
	);
};
