import { getNextKinById, setNextKinById } from "./middlewares";
import { showToast } from "../../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    nextOfKin: "",
    relationship: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    getContactStatus: FETCH_STATUSES.IDLE,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getContactError: null,
    getError: null,
    setError: null,
    loading: true,
};

export const patientProfileNextOfKin = createSlice({
    name: "patientProfileNextOfKin",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setNextOfKin: (state, action) => {
            state.nextOfKin = action.payload;
            return state;
        },
        setRelationship: (state, action) => {
            state.relationship = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
            return state;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNextKinById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(setNextKinById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getNextKinById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.checkbox = action.payload?.checkbox;
                state.nextOfKin = action.payload?.nextOfKin;
                state.relationship = action.payload?.relationship;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;

                state.loading = false;
            })
            .addCase(setNextKinById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Next Of Kin saved successfully");
            })
            .addCase(getNextKinById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Next Of Kin: ${action.error.message}`);
            })
            .addCase(setNextKinById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Next Of Kin: ${action.error.message}`);
            });
    },
});

export const {
    setLoading,

    setNextOfKin,
    setRelationship,
    setAddress,
    setCity,
    setState,
    setZip,
    setPhone,
    setEmail,
} = patientProfileNextOfKin.actions;

export default patientProfileNextOfKin.reducer;