import { dateFormatForApi } from "../../../utils/formats";
import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../core/rest";

export const getNoticeOfPrivacyPractices = createAsyncThunk(
	"getNoticeOfPrivacyPractices",
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + "/NoticeOfPrivacyPractices");
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const getNoticeOfPrivacyPracticesByID = createAsyncThunk(
	"getNoticeOfPrivacyPracticesByID",
	async (patienID, { rejectWithValue }) => {
		try {
			return await get(BASE_URL + `/NoticeOfPrivacyPractices/${patienID}`);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setNoticeOfPrivacyPractices = createAsyncThunk(
	"setNoticeOfPrivacyPractices",
	async (data, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + "/NoticeOfPrivacyPractices", data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const setNoticeOfPrivacyPracticesByID = createAsyncThunk(
	"setNoticeOfPrivacyPracticesByID",
	async ({ patienID, data }, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + `/NoticeOfPrivacyPractices/${patienID}`, data);
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);

export const saveNoticeOfPrivacyPracticesByID = (patienID) => (dispatch, getState) => {
	const store = getState().noticeOfPrivacyPractices;

	const data = {
		"name": store.name,
		"date": dateFormatForApi(store.date),
		"signPicture": store.signPicture,
	}

	dispatch(setNoticeOfPrivacyPracticesByID({ patienID, data }));
};

export const saveNoticeOfPrivacyPractices = () => (dispatch, getState) => {
	const store = getState().noticeOfPrivacyPractices;

	const data = {
		"name": store.name,
		"date": dateFormatForApi(store.date),
		"signPicture": store.signPicture,
	}

	dispatch(setNoticeOfPrivacyPractices(data));
};