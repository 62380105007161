import { Wrapper, Main, Header, Img, Title, SubTitle, Text, Link } from "./RegistrationCompleted.style";
import { UnorderedList, Item } from "../../../../components/Main/Main.style";
import DataGrid, { Column } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { useResizeDetector } from "react-resize-detector";
import { Link as RDLink } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { FooterBlock } from "../../../Main/FooterBlock/FooterBlock";
import { isDisabledFromDownload } from '../../../../utils/helper';

export const RegistrationCompleted = ({ data, loading, patientID, loadIndicator, onClickDownloadForm }) => {
	const { width, ref } = useResizeDetector();
	const sectionName = 'patientForms';
	return (
		<Wrapper maxWidth="xl" ref={ref}>
			<Header>
				<Title>Registration Packet<br /> Completed</Title>
				<Img />
			</Header>
			<Main>
				<SubTitle>Summary</SubTitle>
				<Text>
					Thank you for submitting the registration packet to APIA Behavioral Health. One of our registration and scheduling staff will contact you to schedule an appointment. If you do not hear from us in a few days, or if you have questions, please contact us at the number or email listed under the “Contact” information below.
				</Text>
				<SubTitle>Forms</SubTitle>
				<Text style={{ paddingBottom: '32px' }}>
					Below is the list of forms you submitted. You can save needed form by clicking on the corresponding download icon.
				</Text>
				<LoadPanel
					visible={loading}
					position={{ of: `#${sectionName}` }} />
				{!loading && <DataGrid
					id=""
					columnMinWidth={100}
					dataSource={data}
					showBorders={false}
					showRowLines={true}
					showColumnLines={false}
					hoverStateEnabled={true}
					wordWrapEnabled={true}
					columnAutoWidth={true}>
					<Column
						dataField="readableFormName"
						caption="Form title"
						cellRender={({ data }) => {
							return (
								<div style={!data.completeness ? { color: 'var(--grey-text-color)' } : {}}>
									{data.readableFormName}
								</div>
							);
						}}
					/>
					<Column
						dataField="createDate"
						caption="Created"
						cellRender={({ data }) => {
							return (
								<div style={!data.completeness ? { color: 'var(--grey-text-color)' } : {}}>
									{moment(data.createDate, moment.ISO_8601).format('MM/DD/YYYY')}
								</div>
							);
						}}
					/>
					<Column
						dataField="updateDate"
						caption="Last Updated"
						cellRender={({ data }) => {
							return (
								<div style={!data.completeness ? { color: 'var(--grey-text-color)' } : {}}>
									{moment(data.updateDate, moment.ISO_8601).format('MM/DD/YYYY')}
								</div>
							);
						}}
					/>
					<Column
						cellRender={
							({ data }) =>
								<div style={{ display: "flex", justifyContent: 'end' }}>
									<RDLink title={data.readableFormName} to={data.link}>
										<IconButton >
											<Icon
												icon="bx:edit"
												height="24px"
												width="24px"
												color="var(--light-blue-color)" />
										</IconButton>
									</RDLink>
									<IconButton
										disabled={isDisabledFromDownload(data.formName) || loadIndicator === data.formName}
										title={`Download "${data.readableFormName}" form`}
										onClick={() => onClickDownloadForm(patientID, data.formName)}>
										{loadIndicator === data.formName ? (
											<LoadIndicator
												className="button-indicator"
												visible={loadIndicator && true}
												id="small-indicator"
												height={24}
												width={24}
											/>
										) : (
											<Icon
												icon="material-symbols:download-rounded"
												height="24px"
												width="24px"
												color={isDisabledFromDownload(data.formName) ? 'var(--grey-text-color)' : 'var(--light-blue-color)'
												}
											/>
										)}
									</IconButton>
								</div>
						} />
				</DataGrid>
				}
				{/* <Button
					label="download all"
					variant="contained"
					startIcon="material-symbols:download-rounded"
					sx={{ marginTop: '32px' }} /> */}
				<SubTitle>Additional Information</SubTitle>
				<Text className="pb20">
					<b>Insurance</b>
				</Text>
				<Text className="pb20">
					We recommend contacting your insurance directly for pre-authorization and cost. APIA accepts IHS Benefits, Self-Pay, Medicaid, Medicare and Premera Blue Cross Blue Shield (BCBS). We are not considered “in-network” or contracted with any other insurance provider at this time.
				</Text>
				<UnorderedList style={{ paddingBottom: '20px' }}>
					<Item>
						<Text>To see if you qualify for Medicaid and/or Denali Kid Care, go to <Link href="https://www.healthcare.gov" target="_blank">www.healthcare.gov</Link></Text>
					</Item>
					<Item>
						<Text>For billing or insurance questions, go to <Link href="https://www.hrgpros.com" target="_blank">www.hrgpros.com</Link></Text>
					</Item>
				</UnorderedList>
				<Text className="pb20">
					<b>Our Website</b>
				</Text>
				<Text className="pb20">
					Please visit our <Link href="https://www.apiai.org/services/health-care/behavioral-health" target="_blank">website</Link> to learn more about our providers and behavioral health services.
				</Text>
				<FooterBlock width={width} />
			</Main>
		</Wrapper >
	);
};