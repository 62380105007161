import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { showToast } from "../../../screens/Main/MainContainer";
import { getHospitalResidentialTreatments } from "./middlewares";

const initialState = {
    value: [],
    status: FETCH_STATUSES.IDLE,
    error: null,
};

export const hospitalResidentialTreatment = createSlice({
    name: "hospitalResidentialTreatment",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getHospitalResidentialTreatments.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
            })
            .addCase(getHospitalResidentialTreatments.fulfilled, (state, action) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.value = action.payload;
                state.error = null;
            })
            .addCase(getHospitalResidentialTreatments.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = action.error;

                showToast('error', `Get Have you EVER been in the hospital or residential treatment for substance abuse or mental health reasons: ${action.error.message}`);
            });
    },
});

export default hospitalResidentialTreatment.reducer;
