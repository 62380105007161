import { dateFormatForApi } from "../../../../utils/formats";
import { BASE_URL, PATIENT } from "../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getPatientChecklist = createAsyncThunk(
    "getPatientChecklist",
    async (patientID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/CheckList/${patientID}?role=${PATIENT}`);
            //return await get(BASE_URL + `/CheckList`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getPatientChecklistById = createAsyncThunk(
    "getPatientChecklistById",
    async (patientID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/CheckList/${patientID}?role=${PATIENT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setPatientChecklist = createAsyncThunk(
    "setPatientChecklist",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + "/CheckList", data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setPatientChecklistById = createAsyncThunk(
    "setPatientChecklistById",
    async ({ patientID, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/CheckList/${patientID}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const savePatientChecklist = () => (dispatch, getState) => {
    const store = getState().patientChecklist;

    const data = {
        "frontDeskStaffCheck": store.frontDeskStaffCheck,
        "frontDeskStaffValue": store.frontDeskStaffValue,
        "patientNameCheck": store.patientNameCheck,
        "patientNameValueFirstName": store.patientNameValueFirstName,
        "patientNameValueMiddleName": store.patientNameValueMiddleName,
        "patientNameValueLastName": store.patientNameValueLastName,
        "dobCheck": store.dobCheck,
        "dobValue": dateFormatForApi(store.dobValue),
        "dateReferralReceviedCheck": store.dateReferralReceviedCheck,
        "dateReferralReceviedValue": dateFormatForApi(store.dateReferralReceviedValue),
        "dateReferralApprovedCheck": store.dateReferralApprovedCheck,
        "dateReferralApprovedValue": dateFormatForApi(store.dateReferralApprovedValue),
        "referralSourceCheck": store.referralSourceCheck,
        "referralSourceValue": store.referralSourceValue,
        "referralTypeCheck": store.referralTypeCheck,
        "referralTypeValue": store.referralTypeValue,
        "regFormCheck": store.regFormCheck,
        "regFormValue": store.regFormValue,
        "noppCheck": store.noppCheck,
        "noppValue": store.noppValue,
        "communicationConsentCheck": store.communicationConsentCheck,
        "communicationConsentValueTelehealth": store.communicationConsentValueTelehealth,
        "communicationConsentValueEmail": store.communicationConsentValueEmail,
        "dateChartCreatedCernerCheck": store.dateChartCreatedCernerCheck,
        "dateChartCreatedCernerValue": dateFormatForApi(store.dateChartCreatedCernerValue),
        "registrationPacketUploadCernerCheck": store.registrationPacketUploadCernerCheck,
        "registrationPacketUploadCernerValue": store.registrationPacketUploadCernerValue,
        "idSubmittedCheck": store.idSubmittedCheck,
        "idSubmittedValue": store.idSubmittedValue,
        "idVerifiedCheck": store.idVerifiedCheck,
        "idVerifiedValue": store.idVerifiedValue,
        "idUploadedCernerCheck": store.idUploadedCernerCheck,
        "idUploadedCernerValue": store.idUploadedCernerValue,
        "insuranceSubmittedCheck": store.insuranceSubmittedCheck,
        "insuranceSubmittedValue": store.insuranceSubmittedValue,
        "insuranceVerifiedCheck": store.insuranceVerifiedCheck,
        "insuranceVerifiedValue": store.insuranceVerifiedValue,
        "insuranceUploadedCernerCheck": store.insuranceUploadedCernerCheck,
        "insuranceUploadedCernerValue": store.insuranceUploadedCernerValue,
        "profileCratedInCernerCheck": store.profileCratedInCernerCheck,
        "profileCratedInCernerValue": store.profileCratedInCernerValue,
        "roiCheck": store.roiCheck,
        "roiValue": store.roiValue,
    }

    dispatch(setPatientChecklist(data));
};

export const savePatientChecklistById = (patientID) => (dispatch, getState) => {
    const store = getState().patientChecklist;

    const data = {
        "frontDeskStaffCheck": store.frontDeskStaffCheck,
        "frontDeskStaffValue": store.frontDeskStaffValue,
        "patientNameCheck": store.patientNameCheck,
        "patientNameValueFirstName": store.patientNameValueFirstName,
        "patientNameValueMiddleName": store.patientNameValueMiddleName,
        "patientNameValueLastName": store.patientNameValueLastName,
        "dobCheck": store.dobCheck,
        "dobValue": dateFormatForApi(store.dobValue),
        "dateReferralReceviedCheck": store.dateReferralReceviedCheck,
        "dateReferralReceviedValue": dateFormatForApi(store.dateReferralReceviedValue),
        "dateReferralApprovedCheck": store.dateReferralApprovedCheck,
        "dateReferralApprovedValue": dateFormatForApi(store.dateReferralApprovedValue),
        "referralSourceCheck": store.referralSourceCheck,
        "referralSourceValue": store.referralSourceValue,
        "referralTypeCheck": store.referralTypeCheck,
        "referralTypeValue": store.referralTypeValue,
        "regFormCheck": store.regFormCheck,
        "regFormValue": store.regFormValue,
        "noppCheck": store.noppCheck,
        "noppValue": store.noppValue,
        "communicationConsentCheck": store.communicationConsentCheck,
        "communicationConsentValueTelehealth": store.communicationConsentValueTelehealth,
        "communicationConsentValueEmail": store.communicationConsentValueEmail,
        "dateChartCreatedCernerCheck": store.dateChartCreatedCernerCheck,
        "dateChartCreatedCernerValue": dateFormatForApi(store.dateChartCreatedCernerValue),
        "registrationPacketUploadCernerCheck": store.registrationPacketUploadCernerCheck,
        "registrationPacketUploadCernerValue": store.registrationPacketUploadCernerValue,
        "idSubmittedCheck": store.idSubmittedCheck,
        "idSubmittedValue": store.idSubmittedValue,
        "idVerifiedCheck": store.idVerifiedCheck,
        "idVerifiedValue": store.idVerifiedValue,
        "idUploadedCernerCheck": store.idUploadedCernerCheck,
        "idUploadedCernerValue": store.idUploadedCernerValue,
        "insuranceSubmittedCheck": store.insuranceSubmittedCheck,
        "insuranceSubmittedValue": store.insuranceSubmittedValue,
        "insuranceVerifiedCheck": store.insuranceVerifiedCheck,
        "insuranceVerifiedValue": store.insuranceVerifiedValue,
        "insuranceUploadedCernerCheck": store.insuranceUploadedCernerCheck,
        "insuranceUploadedCernerValue": store.insuranceUploadedCernerValue,
        "profileCratedInCernerCheck": store.profileCratedInCernerCheck,
        "profileCratedInCernerValue": store.profileCratedInCernerValue,
        "roiCheck": store.roiCheck,
        "roiValue": store.roiValue,
    }

    dispatch(setPatientChecklistById({ patientID, data }));
};