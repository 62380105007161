import { Wrapper } from '../../../../../components/Main/Main.style';
import { GRID_SM, GRID_XS, MAX_WIDTH, REFERRAL_INFO } from '../../../../../utils/constants';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import TagBox from 'devextreme-react/tag-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

export const ReferralInfo = ({
	loading,

	referralStatuses,
	answers,
	reason,
	referralInfo,
	otherReferralInfo,
	refferalForMedicalRN,
	contactedByPhysicalExam,

	onChangeReason,
	onChangeReferralInfo,
	onChangeOtherReferralInfo,
	onToggleRefferalForMedicalRN,
	onToggleContactedByPhysicalExam,

	onSaveReferralInfo,
	onSaveAndContinueReferralInfo,
}) => {
	return (
		<Wrapper id={REFERRAL_INFO} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${REFERRAL_INFO}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Reason you are seeking Behavioral Health services today">
							<>
								<TextArea
									label="Reason you are seeking Behavioral Health services today"
									value={reason}
									onValueChanged={onChangeReason}
									labelMode="floating"
									stylingMode="outlined" />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Referral Information">
							<>
								<TagBox
									label="Referral Information"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={referralInfo}
									dataSource={referralStatuses}
									onValueChanged={onChangeReferralInfo}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									showDropDownButton={true} />
							</>
						</Tooltip>
					</Grid>
					{
						!!referralInfo && referralInfo.includes(referralStatuses.find((e) => e?.Name === "Other")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Specify Referral Source">
								<>
									<TextBox
										label="Specify Referral Source"
										value={otherReferralInfo}
										onValueChanged={onChangeOtherReferralInfo}
										maxLength={140}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-rotherReferralInfo' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Would you like to be contacted by the Medical RN?">
							<>
								<SelectBox
									valueExpr="Oid"
									displayExpr="Name"
									label="Would you like to be contacted by the Medical RN?"
									stylingMode="outlined"
									defaultValue={refferalForMedicalRN}
									dataSource={answers}
									onValueChanged={onToggleRefferalForMedicalRN}
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-refferalForMedicalRN' }}>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Would you like a referral for a physical exam?">
							<>
								<SelectBox
									valueExpr="Oid"
									displayExpr="Name"
									label="Would you like a referral for a physical exam?"
									stylingMode="outlined"
									defaultValue={contactedByPhysicalExam}
									dataSource={answers}
									onValueChanged={onToggleContactedByPhysicalExam}
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-contactedByPhysicalExam' }}>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={REFERRAL_INFO}
							onClick={onSaveReferralInfo} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={REFERRAL_INFO}
							onClick={onSaveAndContinueReferralInfo} />
					</Grid>
				</Grid>
			}
		</Wrapper >
	);
};