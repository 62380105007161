import { BASE_URL, CONSENT } from "../../../../utils/constants";
import { dateFormatForApi } from "../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getConsent = createAsyncThunk(
    "getConsent",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${CONSENT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getConsentByID = createAsyncThunk(
    "getConsentByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${CONSENT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setConsent = createAsyncThunk(
    "setConsent",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${CONSENT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setConsentByID = createAsyncThunk(
    "setConsentByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${CONSENT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveConsent = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationConsent;

    const data = {
        "name": store.name,
        "signed": store.signed,
        "guardian": store.guardian,
        "signedDate": dateFormatForApi(store.signedDate),
        "guardianDate": dateFormatForApi(store.guardianDate),
        "signPicture": store.signPicture,
    }

    dispatch(setConsent(data));
};

export const saveConsentByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationConsent;

    const data = {
        "name": store.name,
        "signed": store.signed,
        "guardian": store.guardian,
        "signedDate": dateFormatForApi(store.signedDate),
        "guardianDate": dateFormatForApi(store.guardianDate),
        "signPicture": store.signPicture,
    }

    dispatch(setConsentByID({ id, data }));
};