import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postLogin = createAsyncThunk(
    "postLogin",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios({
                method: "post",
                url: `${BASE_URL}/AuthManagement/Login`,
                data: data,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    }
);