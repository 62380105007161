import { FETCH_STATUSES } from '../../../utils/constants';
import { getAdminsList } from './middlewares';
import { showToast } from "../../../screens/Main/MainContainer";
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	value: [],
	getStatus: FETCH_STATUSES.IDLE,
	getError: null,
	loading: true,
	activeValue: {},
	activeAdminId: ""
};

const adminsList = createSlice({
	name: 'adminsList',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
			return state;
		},
		setActiveAdminId: (state, action) => {
			state.activeAdminId = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAdminsList.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(getAdminsList.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;
				state.value = action.payload.reverse();
				const getActiveValue = action.payload.filter(value => value.id === state.activeAdminId)[0];
				state.activeValue = getActiveValue;

				state.loading = false;
			})
			.addCase(getAdminsList.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;
				showToast('error', `Get Admins List: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,
	setActiveValue,
	setActiveAdminId,
} = adminsList.actions;

export default adminsList.reducer;