import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

export const Dialog = styled(MuiDialog)(() => ({
}));

export const DialogTitle = styled(MuiDialogTitle)(() => ({
    color: 'var(--dark-blue-color)',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    paddingTop: '30px',
}));

export const DialogContent = styled(MuiDialogContent)(() => ({
}));

export const DialogContentRow = styled('div')(() => ({
    width: '420px',
    paddingTop: '20px',
    display: 'flex',
    gap: '20px',
    '& > div': {
        flex: '1 0',
    },
}));

export const DialogText = styled(MuiDialogContent)(() => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '0',
    margin: '0',
}));

export const DialogActions = styled(MuiDialogActions)(() => ({
    padding: '20px 24px 40px',
}));