import { useDispatch, useSelector } from "react-redux";
import { selectShowEdit, selectInviteLink, selectName, selectEmail, selectPhone, selectDOB, selectInviteKey } from "../../../store/users/generationInvites/selectors";
import { setName, setEmail, setPhone, setDOB, setShowInvite, setClear } from "../../../store/users/generationInvites/slice";
import { getInvate, postInviteLink, postSendInvite } from "../../../store/users/generationInvites/middlewares";
import { getInvitedList } from '../../../store/users/InvitedList/middlewares';
import { dateFormatForApi } from "../../../utils/formats";
import { validateGroup } from "../../../utils/helper";
import { showToast } from '../../Main/MainContainer';
import GenerationInvites from './GenerationInvites';

const GenerationInvitesContainer = () => {
	const dispatch = useDispatch();

	const open = useSelector(selectShowEdit);
	const link = useSelector(selectInviteLink);
	const name = useSelector(selectName);
	const email = useSelector(selectEmail);
	const phone = useSelector(selectPhone);
	const dob = useSelector(selectDOB);
	const inviteKey = useSelector(selectInviteKey);

	const onChangeName = (event) => {
		dispatch(setName(event.value.trim()));
	};

	const onChangeEmail = (event) => {
		dispatch(setEmail(event.value.trim()));
	};

	const onChangePhone = (event) => {
		dispatch(setPhone(event.value));
	};

	const onChangeDOB = (event) => {
		dispatch(setDOB(event.value));
	};

	const onClickCreateLink = (event) => {
		const isValid = validateGroup(event);
		const data = { name, dob: dateFormatForApi(dob), email, phone };
		if (isValid) {
			dispatch(postInviteLink(data)).then((res) => {
				if (!res.error) {
					dispatch(getInvate());
				};
			})
		};
	};

	const onClickSendEmail = () => {
		if (!!link) {
			dispatch(postSendInvite()).then(({ payload }) => {
				if (payload.response.ok) dispatch(getInvitedList());
			});
		} else {
			showToast("error", "Please create an invite link first");
		}
	};

	const onClickClose = () => {
		dispatch(getInvitedList());
		dispatch(setShowInvite(false));
		dispatch(setClear());
	};

	return (
		<GenerationInvites
			open={open}

			name={name}
			email={email}
			phone={phone}
			dob={dob}
			inviteKey={inviteKey}

			onChangeName={onChangeName}
			onChangeEmail={onChangeEmail}
			onChangePhone={onChangePhone}
			onChangeDOB={onChangeDOB}

			onClickClose={onClickClose}
			onClickCreateLink={onClickCreateLink}
			onClickSendEmail={onClickSendEmail}
		/>
	)
};

export default GenerationInvitesContainer;