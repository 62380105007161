import Container from '@mui/material/Container';
import img from '../../../images/dance.png';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Container)(() => ({
  padding: '20px 0px 40px',
}));

export const Header = styled('header')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'linear-gradient(90deg, #4800A4 0%, #936DFF 100%)',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px 10px 0px 0px',
}));

export const Main = styled('main')(() => ({
  border: '1px solid var(--grey-status-color)',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '0px 0px 10px 10px',
  padding: '0px 56px 50px',
  '@media (max-width: 768px)': {
    padding: '0px 26px 20px',
  },
}));

export const Img = styled('div')(() => ({
  height: '323px',
  width: '690px',
  background: `url(${img}) no-repeat`,
  '@media (max-width: 768px)': {
    display: 'none',
  },
}));

export const Title = styled('h1')(() => ({
  paddingLeft: '64px',
  color: 'var(--white-color)',
  fontFamily: 'Butler',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '60px',
  lineHeight: '72px',
  '@media (max-width: 768px)': {
    paddingLeft: '20px',
    fontSize: '50px',
  },
}));

export const SubTitle = styled('h3')(() => ({
  paddingTop: '40px',
  paddingBottom: '20px',
  color: 'var(--dark-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '22px',
  lineHeight: '44px',
  margin: 'unset',
  '@media (max-width: 768px)': {
    paddingTop: '20px',
  },
}));

export const Footer = styled('footer')(() => ({
  paddingTop: '20px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  rowGap: '32px',
  columnGap: '36px',
  '@media (max-width: 1224px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 768px)': {
    paddingTop: 'unset',
  },
}));
