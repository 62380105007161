import { getEmergencyContact, getEmergencyContactByID, getNextKin, setNextKin, getNextKinByID, setNextKinByID } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checkbox: false,
    nextOfKin: "",
    relationship: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    getContactStatus: FETCH_STATUSES.IDLE,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getContactError: null,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationNextOfKin = createSlice({
    name: "newPatientRegistrationNextOfKin",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setNextOfKin: (state, action) => {
            state.nextOfKin = action.payload;
            return state;
        },
        setRelationship: (state, action) => {
            state.relationship = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
            return state;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
        toggleCheckbox: (state) => {
            state.checkbox = !state.checkbox;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNextKin.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getNextKin.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.checkbox = action.payload?.checkbox;
                state.nextOfKin = action.payload?.nextOfKin;
                state.relationship = action.payload?.relationship;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;

                state.loading = false;
            })
            .addCase(getNextKin.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Next Of Kin: ${action.error.message}`);
            })
            .addCase(getNextKinByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getNextKinByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.checkbox = action.payload?.checkbox;
                state.nextOfKin = action.payload?.nextOfKin;
                state.relationship = action.payload?.relationship;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;
                state.loading = false;
            })
            .addCase(getNextKinByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;
                showToast('error', `Get Next Of Kin: ${action.error.message}`);
            })
            .addCase(setNextKin.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(setNextKin.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Next Of Kin saved successfully");
            })
            .addCase(setNextKin.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Next Of Kin: ${action.error.message}`);
            })
            .addCase(setNextKinByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(setNextKinByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;
                showToast('success', 'Next Of Kin saved successfully');
            })
            .addCase(setNextKinByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;
                showToast('error', `Set Next Of Kin: ${action.error.message}`);
            })
            .addCase(getEmergencyContact.pending, (state) => {
                state.getContactStatus = FETCH_STATUSES.REQUEST;
                state.getContactError = null;
                state.loading = true;
            })
            .addCase(getEmergencyContact.fulfilled, (state, action) => {
                state.getContactStatus = FETCH_STATUSES.SUCCESS;
                state.getContactError = null;

                state.nextOfKin = action.payload?.contact;
                state.relationship = action.payload?.relationship;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;

                state.loading = false;
            })
            .addCase(getEmergencyContact.rejected, (state, action) => {
                state.getContactStatus = FETCH_STATUSES.FAILURE;
                state.getContactError = action.error;
                state.loading = false;

                showToast('error', `Get Emergency Contact: ${action.error.message}`);
            })
            .addCase(getEmergencyContactByID.pending, (state) => {
                state.getContactStatus = FETCH_STATUSES.REQUEST;
                state.getContactError = null;
                state.loading = true;
            })
            .addCase(getEmergencyContactByID.fulfilled, (state, action) => {
                state.getContactStatus = FETCH_STATUSES.SUCCESS;
                state.getContactError = null;

                state.nextOfKin = action.payload?.contact;
                state.relationship = action.payload?.relationship;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;

                state.loading = false;
            })
            .addCase(getEmergencyContactByID.rejected, (state, action) => {
                state.getContactStatus = FETCH_STATUSES.FAILURE;
                state.getContactError = action.error;
                state.loading = false;

                showToast('error', `Get Emergency Contact By Id: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    toggleCheckbox,
    setNextOfKin,
    setRelationship,
    setAddress,
    setCity,
    setState,
    setZip,
    setPhone,
    setEmail,
} = newPatientRegistrationNextOfKin.actions;

export default newPatientRegistrationNextOfKin.reducer;