import { BASE_URL, TRIBAL_AFFILIATION } from "../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getTribalAffiliation = createAsyncThunk(
    "getTribalAffiliation",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${TRIBAL_AFFILIATION}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getTribalAffiliationByID = createAsyncThunk(
    "getTribalAffiliationByID",
    async (patienID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${patienID}/${TRIBAL_AFFILIATION}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postTribalAffiliation = createAsyncThunk(
    "postTribalAffiliation",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${TRIBAL_AFFILIATION}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postTribalAffiliationByID = createAsyncThunk(
    "postTribalAffiliationByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${TRIBAL_AFFILIATION}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveTribalAffiliation = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationTribalAffiliation;

    const data = {
        "corporation": store.corporation,
        "enrollment": store.enrollment,
        "ethnicity": store.ethnicity ? store.ethnicity : null,
        "race": store.race ? store.race : null,
        "raceAlaska": store.raceAlaska,
        "raceAmerican": store.raceAmerican,
        "language": Array.isArray(store.language) ? store.language : store.language === null ? null : [store.language],
        "otherLanguage": store.otherLanguage,
        "CIB": store.CIB,
        "CIBCard": store.CIBCard,
        "translator": store.translator
    }

    dispatch(postTribalAffiliation(data));
}

export const saveTribalAffiliationByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationTribalAffiliation;

    const data = {
        "corporation": store.corporation,
        "enrollment": store.enrollment,
        "ethnicity": store.ethnicity ? store.ethnicity : null,
        "race": store.race ? store.race : null,
        "raceAlaska": store.raceAlaska,
        "raceAmerican": store.raceAmerican,
        "language": Array.isArray(store.language) ? store.language : store.language === null ? null : [store.language],
        "otherLanguage": store.otherLanguage,
        "CIB": store.CIB,
        "CIBCard": store.CIBCard,
        "translator": store.translator
    }

    dispatch(postTribalAffiliationByID({ id, data }));
}