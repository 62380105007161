import StyledButton from '../../../components/Button/Button';
import Container from '@mui/material/Container';
import img from '../../../images/welcome.png';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Container)(() => ({
  padding: '20px 0px 40px',
}));

export const Header = styled('header')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: 'linear-gradient(90deg, #004F93 0%, #306FFF 100%)',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px 10px 0px 0px',
}));

export const Main = styled('main')(() => ({
  border: '1px solid var(--grey-status-color)',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '0px 0px 10px 10px',
  padding: '0px 56px 50px',
  '@media (max-width: 768px)': {
    padding: '0px 26px 20px',
  },
}));

export const Img = styled('div')(() => ({
  height: '320px',
  width: '550px',
  background: `url(${img}) no-repeat`,
  '@media (max-width: 768px)': {
    display: 'none',
  },
}));

export const Title = styled('h1')(() => ({
  paddingLeft: '64px',
  color: 'var(--white-color)',
  fontFamily: 'Butler',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '60px',
  lineHeight: '72px',
  '@media (max-width: 768px)': {
    paddingLeft: '20px',
    fontSize: '50px',
  },
}));

export const SubTitle = styled('h3')(() => ({
  paddingTop: '40px',
  paddingBottom: '20px',
  color: 'var(--dark-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '22px',
  lineHeight: '44px',
  margin: 'unset',
  '@media (max-width: 768px)': {
    paddingTop: '20px',
  },
}));

export const Text = styled('p')(() => ({
  color: 'var(--black-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  margin: 'unset',
  '&.pb20': {
    paddingBottom: '20px',
  },
}));

export const Button = styled(StyledButton)(() => ({
  margin: '40px 0 40px 0',
  '@media (max-width: 768px)': {
    margin: '20px 0 20px 0',
  },
}));
