import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { postLogin } from "./middlewares";

const initialState = {
    email: "",
    password: "",
    checkbox: false,
    status: FETCH_STATUSES.IDLE,
    error: null,
    btnStatus: false,
};

export const login = createSlice({
    name: "login",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
            return state;
        },
        toggleCheckbox: (state) => {
            state.checkbox = !state.checkbox;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postLogin.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
                state.btnStatus = true;
            })
            .addCase(postLogin.fulfilled, (state, action) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.error = null;
                state.btnStatus = false;
            })
            .addCase(postLogin.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = Object.values(action.payload.errors) ?? ['Server error'];
                state.btnStatus = false;
            });
    },
});

export const {
    setEmail,
    setPassword,
    toggleCheckbox,
} = login.actions;

export default login.reducer;
