import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { getCheckAttemptId, postChangePassword } from "./middlewares";

const initialState = {
    btnStatus: false,
    status: FETCH_STATUSES.IDLE,
    error: null,
    keyStatus: null,
};

export const changePassword = createSlice({
    name: "changePassword",
    initialState,
    reducers: {
        setBtnStatus: (state, action) => {
            state.btnStatus = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postChangePassword.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
                state.btnStatus = true;
            })
            .addCase(postChangePassword.fulfilled, (state) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.error = null;
                state.btnStatus = false;
            })
            .addCase(postChangePassword.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = Object.values(action.payload.errors) ?? ['Server error'];
                state.btnStatus = false;
            })
            .addCase(getCheckAttemptId.pending, (state) => {
                state.keyStatus = FETCH_STATUSES.REQUEST;
                state.error = null;
            })
            .addCase(getCheckAttemptId.fulfilled, (state, action) => {
                state.keyStatus = action.payload.data;
            })
            .addCase(getCheckAttemptId.rejected, (state, action) => {
                state.keyStatus = FETCH_STATUSES.FAILURE;
                state.error = Object.values(action.payload.errors) ?? ['Server error'];
            })
    },
});

export const { setBtnStatus, } = changePassword.actions;

export default changePassword.reducer;
