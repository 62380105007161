import { styled } from '@mui/material/styles';

export const Box = styled('section')(({ width }) => ({
  width: `${Math.round((width - 155) / 2)}px`,
  height: '120px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  padding: '24px',
  '@media (max-width: 1224px)': {
    width: '100%',
    height: 'auto',
  },
}));

export const FlexDIV = styled('div')(() => ({
  display: 'flex',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
}));

export const WrapIconPNG = styled('div')(() => ({
  marginRight: '16px',
  height: '56px',
  width: '56px',
  borderRadius: '25px',
  '@media (max-width: 768px)': {
    margin: '0 auto',
  },
}));

export const BlockDIV = styled('div')(() => ({
  display: 'block',
}));

export const BoxTitle = styled('h5')(() => ({
  paddingBottom: '12px',
  color: 'var(--dark-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '22px',
  margin: 'unset',
  '@media (max-width: 768px)': {
    paddingTop: '12px',
    textAlign: 'center',
    overflowWrap: 'break-word',
  },
}));

export const Text = styled('p')(() => ({
  color: 'var(--black-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  margin: 'unset',
  '&.pb20': {
    paddingBottom: '20px',
  },
  '@media (max-width: 768px)': {
    textAlign: 'center',
  },
}));

export const Link = styled('a')(() => ({
  color: 'var(--light-blue-color)',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '22px',
  margin: 'unset',
  textDecoration: 'none',
}));
