import { selectNextOfKin, selectCheckbox, selectRelationship, selectAddress, selectCity, selectState, selectZip, selectPhone, selectEmail, selectLoading } from "../../../../../store/forms/newPatientRegistration/nextOfKin/selectors";
import { setNextOfKin, toggleCheckbox, setAddress, setCity, setEmail, setPhone, setRelationship, setState, setZip } from "../../../../../store/forms/newPatientRegistration/nextOfKin/slice";

import { getNextKin, saveNextOfKin, getEmergencyContact, getEmergencyContactByID, getNextKinByID, saveNextOfKinByID } from "../../../../../store/forms/newPatientRegistration/nextOfKin/middlewares";
import { completeNextOfKin } from "../../../../../store/forms/newPatientRegistration/slice";
import { selectStates } from "../../../../../store/lookups/state/selectors";
import { getState } from "../../../../../store/lookups/state/middlewares";

import { validateGroup } from "../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { NextOfKin } from "./NextOfKin";
import { useEffect } from "react";

export const NextOfKinContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const states = useSelector(selectStates);

    const checkbox = useSelector(selectCheckbox);
    const nextOfKin = useSelector(selectNextOfKin);
    const relationship = useSelector(selectRelationship);
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const phone = useSelector(selectPhone);
    const email = useSelector(selectEmail);

    useEffect(() => {
        dispatch(getState());
        dispatch(id ? getNextKinByID(id) : getNextKin());
    }, [dispatch, id]);

    const onToggleCheckbox = (event) => {
        dispatch(toggleCheckbox());

        if (event.value) {
            dispatch(id ? getEmergencyContactByID(id) : getEmergencyContact()).then(() => {
                dispatch(id ? saveNextOfKinByID(id) : saveNextOfKin());
                dispatch(completeNextOfKin(true));
            });
        };
    }

    const onChangeNextOfKin = (event) => {
        dispatch(setNextOfKin(event.value.trim()));
    }

    const onChangeRelationship = (event) => {
        dispatch(setRelationship(event.value.trim()));
    }

    const onChangeAddress = (event) => {
        dispatch(setAddress(event.value.trim()));
    }

    const onChangeCity = (event) => {
        dispatch(setCity(event.value.trim()));
    }

    const onChangeState = (event) => {
        dispatch(setState(event.value));
    }

    const onChangeZip = (event) => {
        dispatch(setZip(event.value));
    }

    const onChangePhone = (event) => {
        dispatch(setPhone(event.value));
    }

    const onChangeEmail = (event) => {
        dispatch(setEmail(event.value.trim()));
    }

    const onSaveNextOfKin = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveNextOfKinByID(id) : saveNextOfKin());
            dispatch(completeNextOfKin(true));
        }
    }

    const onSaveAndContinueNextOfKin = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveNextOfKinByID(id) : saveNextOfKin());
            dispatch(completeNextOfKin(true));
            onClickNext();
        }
    }

    return (
        <NextOfKin
            loading={loading}

            states={states}

            checkbox={checkbox}
            nextOfKin={nextOfKin}
            relationship={relationship}
            address={address}
            city={city}
            state={state}
            zip={zip}
            phone={phone}
            email={email}

            onToggleCheckbox={onToggleCheckbox}
            onChangeNextOfKin={onChangeNextOfKin}
            onChangeRelationship={onChangeRelationship}
            onChangeAddress={onChangeAddress}
            onChangeCity={onChangeCity}
            onChangeState={onChangeState}
            onChangeZip={onChangeZip}
            onChangePhone={onChangePhone}
            onChangeEmail={onChangeEmail}

            onSaveNextOfKin={onSaveNextOfKin}
            onSaveAndContinueNextOfKin={onSaveAndContinueNextOfKin}
        />
    )
};