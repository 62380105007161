import { FETCH_STATUSES } from "../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { postInvite } from "./middlewares";

const initialState = {
    password: "",
    confirmPassword: "",
    status: FETCH_STATUSES.IDLE,
    error: null,
};
export const invite = createSlice({
    name: "invite",
    initialState,
    reducers: {
        setPassword: (state, action) => {
            state.password = action.payload;
            return state;
        },
        setConfirmPassword: (state, action) => {
            state.confirmPassword = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postInvite.pending, (state) => {
                state.status = FETCH_STATUSES.REQUEST;
                state.error = null;
            })
            .addCase(postInvite.fulfilled, (state, action) => {
                state.status = FETCH_STATUSES.SUCCESS;
                state.error = null;
            })
            .addCase(postInvite.rejected, (state, action) => {
                state.status = FETCH_STATUSES.FAILURE;
                state.error = Object.values(action.payload.errors) ?? ['Server error'];
            });
    },

});

export const {
    setPassword,
    setConfirmPassword,
} = invite.actions;

export default invite.reducer;
