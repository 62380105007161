import { Wrapper, ListItem, ListItemIcon, ListItemText } from './WidgetList.style.jsx';
import { Icon } from '@iconify/react';

const listItems = [{
    icon: "radix-icons:dashboard",
    text: "Personalized Dashboard",
    height: "20px",
}, {
    icon: "fluent:form-20-regular",
    text: "Surveys and Forms",
    height: "24px",
}, {
    icon: "icon-park-outline:sleep",
    text: "Sleep Tracking",
    height: "20px",
}, {
    icon: "bx:spreadsheet",
    text: "Worksheets",
    height: "22px",
}, {
    icon: "carbon:phone-voice",
    text: "Hotline Numbers",
    height: "20px",
}];

const WidgetList = () => {
    return (
        <Wrapper>
            {listItems.map((item, id) => (
                <ListItem key={id}>
                    <ListItemIcon>
                        <Icon icon={item.icon} height={item.height} />
                    </ListItemIcon>
                    <ListItemText>{item.text}</ListItemText>
                </ListItem>
            ))}
        </Wrapper>

    );
};

export default WidgetList;