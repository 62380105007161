import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../core/rest";

export const getHospitalInSixMonths = createAsyncThunk(
    "getHospitalInSixMonths",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/HospitalInSixMonths");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);