export const selectLoading = state => state.newPatientRegistrationEducation.loading;

export const selectEducation = state => state.newPatientRegistrationEducation.education;
export const selectOtherEducation = state => state.newPatientRegistrationEducation.otherEducation;
export const selectLastGradeEducation = state => state.newPatientRegistrationEducation.lastGradeEducation;
export const selectGradeSchoolEducation = state => state.newPatientRegistrationEducation.gradeSchoolEducation;
export const selectLivingArrangement = state => state.newPatientRegistrationEducation.livingArrangement;
export const selectOtherLivingArrangement = state => state.newPatientRegistrationEducation.otherLivingArrangement;
export const selectLivingCondition = state => state.newPatientRegistrationEducation.livingCondition;

