import './dx/css/dx.common.css';
import './dx/css/dx.material.custom-scheme.css';

import { ForgotPasswordContainer } from './screens/Auth/ForgotPassword/ForgotPasswordContainer';
import { ChangePasswordContainer } from './screens/Auth/ChangePassword/ChangePasswordContainer';

import { ResetPassword } from './screens/Auth/ResetPassword/ResetPassword';

import { InviteContainer } from './screens/Auth/Invite/InviteContainer';
import { SignUpContainer } from './screens/Auth/SignUp/SignUpContainer';
import { LoginContainer } from './screens/Auth/Login/LoginContainer';

import { RegistrationCompletedContainer } from './screens/Forms/NewPatientRegistrationPacket/RegistrationCompleted/RegistrationCompletedContainer';
import { NewPatientRegistrationPacketContainer } from './screens/Forms/NewPatientRegistrationPacket/NewPatientRegistrationPacketContainer';
import { AnnualPatientRegistrationContainer } from './screens/Forms/AnnualPatientRegistration/AnnualPatientRegistrationContainer';
import { NewPatientRegistrationContainer } from './screens/Forms/NewPatientRegistration/NewPatientRegistrationContainer';
import { NoticeOfPrivacyPracticesContainer } from './screens/Forms/NoticeOfPrivacyPractices/NoticeOfPrivacyPracticesContainer';
import { IDAndInsuranceCardContainer } from './screens/Forms/IDAndInsuranceCard/IDAndInsuranceCardContainer';
import { ECommunicationConsentContainer } from './screens/Forms/ECommunicationConsent/ECommunicationConsentContainer';
import { ReferralFormContainer } from './screens/Forms/ReferralForm/ReferralFormContainer';
import { ConsentForReleaseContainer } from './screens/Forms/ConsentForRelease/ConsentForReleaseContainer';
import { PaymentAgreementContainer } from './screens/Forms/PaymentAgreement/PaymentAgreementContainer';
import { GrievanceFeedbackFormContainer } from './screens/Forms/GrievanceFeedbackForm/GrievanceFeedbackFormContainer';
import { DiscountScheduleContainer } from './screens/Forms/DiscountSchedule/DiscountScheduleContainer';

import { AdminsListContainer } from './screens/Users/AdminsList/AdminsListContainer';
import { AdminsProfileContainer } from './screens/Users/AdminsProfile/AdminsProfileContainer';
import { PatientsListContainer } from './screens/Users/PatientsList/PatientsListContainer';
import { PatientProfileContainer } from './screens/Users/PatientProfile/PatientProfileContainer';
import { InvitedListContainer } from './screens/Users/InvitedList/InvitedListContainer';
import { InvitedProfileContainer } from './screens/Users/InvitedProfile/InvitedProfileContainer';

import { PrivateRoute } from '../src/components/PrivateRoute/PrivateRoute';
import { ADMIN, INVITEE, PATIENT } from './utils/constants';

import Background from './components/Auth/Background/Background';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { MainContainer } from './screens/Main/MainContainer';
import { Welcome } from './screens/Main/Welcome/Welcome';
import { ConfirmEmail } from './screens/Main/ConfirmEmail/ConfirmEmail';
import { ErrorPage } from './screens/Main/ErrorPage/ErrorPage';
import { Nav } from './screens/Forms/Nav';
import { Provider } from 'react-redux';
import { store } from './store';
import './App.css';
import { ContactPage } from './screens/Main/ContactPage/ContactPage';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Navigate to="/auth/login" replace={true} />} ></Route>
					<Route path="auth" element={<Background />} >
						<Route path="login" element={<LoginContainer />} ></Route>
						<Route path="sign-up" element={<SignUpContainer />} ></Route>
						<Route path="forgot-password" element={<ForgotPasswordContainer />} ></Route>
						<Route path="change-password" element={<ChangePasswordContainer />} ></Route>
						<Route path="reset-password" element={<ResetPassword />} ></Route>
						<Route path="invite" element={<InviteContainer />} ></Route>
					</Route>
					<Route path="main" element={<MainContainer />} >
						<Route path="welcome" element={<Welcome />} ></Route>
						<Route path="contacts" element={<ContactPage />} ></Route>
						<Route path="confirm-email" element={<ConfirmEmail />} ></Route>
						<Route path="*" element={<ErrorPage code={'404'} />}></Route>
						<Route path="forms">
							<Route path="all" element={<PrivateRoute roles={[INVITEE, PATIENT]}><Nav /></PrivateRoute>} ></Route>
							<Route path="new-patient-registration-packet" element={<NewPatientRegistrationPacketContainer />} ></Route>
							<Route path="new-patient-registration-packet/completed" element={<RegistrationCompletedContainer />}></Route>
							<Route path="new-patient-registration/:id" element={<PrivateRoute roles={[ADMIN]}><NewPatientRegistrationContainer /></PrivateRoute>} ></Route>
							<Route path="new-patient-registration" element={<NewPatientRegistrationContainer />} ></Route>
							<Route path="annual-patient-registration" element={<AnnualPatientRegistrationContainer />} ></Route>
							<Route path="annual-patient-registration/:id" element={<PrivateRoute roles={[ADMIN]}><AnnualPatientRegistrationContainer /></PrivateRoute>} ></Route>
							<Route path="notice-of-privacy-practices" element={<NoticeOfPrivacyPracticesContainer />} ></Route>
							<Route path="notice-of-privacy-practices/:id" element={<PrivateRoute roles={[ADMIN]}><NoticeOfPrivacyPracticesContainer /></PrivateRoute>} ></Route>
							<Route path="id-and-insurance-card" element={<IDAndInsuranceCardContainer />} ></Route>
							<Route path="id-and-insurance-card/:id" element={<PrivateRoute roles={[ADMIN]}><IDAndInsuranceCardContainer /></PrivateRoute>} ></Route>
							<Route path="consent-for-release" element={<ConsentForReleaseContainer />} ></Route>
							<Route path="consent-for-release/:id" element={<PrivateRoute roles={[ADMIN]}><ConsentForReleaseContainer /></PrivateRoute>} ></Route>
							<Route path="e-communication-consent" element={<ECommunicationConsentContainer />} ></Route>
							<Route path="e-communication-consent/:id" element={<PrivateRoute roles={[ADMIN]}><ECommunicationConsentContainer /></PrivateRoute>} ></Route>
							<Route path="referral-form" element={<ReferralFormContainer />} ></Route>
							<Route path="referral-form/:id" element={<PrivateRoute roles={[ADMIN]}><ReferralFormContainer /></PrivateRoute>} ></Route>
							<Route path="payment-agreement" element={<PaymentAgreementContainer />} ></Route>
							<Route path="grievance-feedback-form" element={<GrievanceFeedbackFormContainer />} ></Route>
							<Route path="grievance-feedback-form/:id" element={<PrivateRoute roles={[ADMIN]}><GrievanceFeedbackFormContainer /></PrivateRoute>} ></Route>
							<Route path="discount-schedule" element={<DiscountScheduleContainer />} ></Route>
							<Route path="discount-schedule/:id" element={<PrivateRoute roles={[ADMIN]}><DiscountScheduleContainer /></PrivateRoute>} ></Route>
						</Route>
						<Route path="users">
							<Route path="admins" element={<PrivateRoute roles={[ADMIN]}><AdminsListContainer /></PrivateRoute>} ></Route>
							<Route path="admins/profile/:id" element={<PrivateRoute roles={[ADMIN]}><AdminsProfileContainer /></PrivateRoute>} ></Route>
							<Route path="patients" element={<PrivateRoute roles={[ADMIN]}><PatientsListContainer /></PrivateRoute>} ></Route>
							<Route path="patients/profile/:id" element={<PrivateRoute roles={[ADMIN]}><PatientProfileContainer /></PrivateRoute>} ></Route>
							<Route path="invited" element={<PrivateRoute roles={[ADMIN]}><InvitedListContainer /></PrivateRoute>} ></Route>
							<Route path="invited/profile/:id" element={<PrivateRoute roles={[ADMIN]}><InvitedProfileContainer /></PrivateRoute>} ></Route>
						</Route>
					</Route>
					<Route path="*" element={<ErrorPage code={'404'} />}></Route>
				</Routes>
			</BrowserRouter >
		</Provider >
	);
}

export default App;
