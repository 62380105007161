import { Drawer, Wrapper, TitleWrapper, Title, Accordion, AccordionSummary, AccordionDetails, Label, Switcher } from './PatientChecklist.style';
import { dateFormatFromApi } from '../../../../utils/formats';
import { Icon } from '@iconify/react';
import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import Tooltip from '@mui/material/Tooltip';

export const PatientChecklist = ({
    loading,

    frontDeskStaffCheck,
    frontDeskStaffValue,
    patientNameCheck,
    patientNameValueFirstName,
    patientNameValueMiddleName,
    patientNameValueLastName,
    dobCheck,
    dobValue,
    dateReferralReceviedCheck,
    dateReferralReceviedValue,
    dateReferralApprovedCheck,
    dateReferralApprovedValue,
    referralSourceCheck,
    referralSourceValue,
    referralTypeCheck,
    referralTypeValue,
    regFormCheck,
    regFormValue,
    noppCheck,
    noppValue,
    communicationConsentCheck,
    communicationConsentValueTelehealth,
    communicationConsentValueEmail,
    dateChartCreatedCernerCheck,
    dateChartCreatedCernerValue,
    registrationPacketUploadCernerCheck,
    registrationPacketUploadCernerValue,
    idSubmittedCheck,
    idSubmittedValue,
    idVerifiedCheck,
    idVerifiedValue,
    idUploadedCernerCheck,
    idUploadedCernerValue,
    insuranceSubmittedCheck,
    insuranceSubmittedValue,
    insuranceVerifiedCheck,
    insuranceVerifiedValue,
    insuranceUploadedCernerCheck,
    insuranceUploadedCernerValue,
    profileCratedInCernerCheck,
    profileCratedInCernerValue,
    roiCheck,
    roiValue,

    onToggleFrontDeskStaffCheck,
    onChangeFrontDeskStaffValue,
    onTogglePatientNameCheck,
    onChangePatientNameValueFirstName,
    onChangePatientNameValueMiddleName,
    onChangePatientNameValueLastName,
    onToggleDobCheck,
    onChangeDobValue,
    onToggleDateReferralReceviedCheck,
    onChangeDateReferralReceviedValue,
    onToggleDateReferralApprovedCheck,
    onChangeDateReferralApprovedValue,
    onToggleReferralSourceCheck,
    onChangeReferralSourceValue,
    onToggleReferralTypeCheck,
    onChangeReferralTypeValue,
    onToggleRegFormCheck,
    onChangeRegFormValue,
    onToggleNoppCheck,
    onChangeNoppValue,
    onToggleCommunicationConsentCheck,
    onToggleCommunicationConsentValueTelehealth,
    onToggleCommunicationConsentValueEmail,
    onToggleDateChartCreatedCernerCheck,
    onChangeDateChartCreatedCernerValue,
    onToggleRegistrationPacketUploadCernerCheck,
    onChangeRegistrationPacketUploadCernerValue,
    onToggleIdSubmittedCheck,
    onChangeIdSubmittedValue,
    onToggleIdVerifiedCheck,
    onChangeIdVerifiedValue,
    onToggleIdUploadedCernerCheck,
    onChangeIdUploadedCernerValue,
    onToggleIdInsuranceSubmittedCheck,
    onChangeInsuranceSubmittedValue,
    onToggleInsuranceVerifiedCheck,
    onChangeInsuranceVerifiedValue,
    onToggleInsuranceUploadedCernerCheck,
    onChangeInsuranceUploadedCernerValue,
    onToggleProfileCratedInCernerCheck,
    onChangeProfileCratedInCernerValue,
    onToggleRoiCheck,
    onChangeRoiValue,

    onSavePatientChecklist,
    onChangeInviteRole,
}) => {
    const [open, setOpen] = useState(true);
    const sectionName = "patientChecklist";

    return (
        <Drawer open={open}>
            <Wrapper>
                <LoadPanel
                    visible={loading}
                    position={{ of: `#${sectionName}` }} />
                {
                    !loading && <>
                        <TitleWrapper
                            onClick={() => setOpen(!open)}
                            style={{ marginLeft: open ? '0px' : '-100px' }}
                        >
                            {!open && <Icon icon="jam:chevron-left" height="18px" width="18px" />}
                            <Tooltip title="Patient checklist">
                                <Title>Patient checklist</Title>
                            </Tooltip>
                            {open && <Icon icon="jam:chevron-right" height="18px" width="18px" />}
                        </TitleWrapper>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Referral Assigned to Front Desk Staff Member">
                                    <span>
                                        <CheckBox
                                            text="Referral Assigned to Front Desk Staff Member"
                                            value={frontDeskStaffCheck}
                                            onValueChanged={onToggleFrontDeskStaffCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextBox
                                    label="Front Desk Staff Member"
                                    value={frontDeskStaffValue}
                                    onValueChanged={onChangeFrontDeskStaffValue}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-frontDeskStaffValue' }} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Patient Name">
                                    <span>
                                        <CheckBox
                                            text="Patient Name"
                                            value={patientNameCheck}
                                            onValueChanged={onTogglePatientNameCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextBox
                                    label="First Name"
                                    value={patientNameValueFirstName}
                                    onValueChanged={onChangePatientNameValueFirstName}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-patientNameValueFirstName' }} />
                                <TextBox
                                    label="Middle Name"
                                    value={patientNameValueMiddleName}
                                    onValueChanged={onChangePatientNameValueMiddleName}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-patientNameValueMiddleName' }} />
                                <TextBox
                                    label="Last Name"
                                    value={patientNameValueLastName}
                                    onValueChanged={onChangePatientNameValueLastName}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-patientNameValueLastName' }} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date of Birth">
                                    <span>
                                        <CheckBox
                                            text="Date of Birth"
                                            value={dobCheck}
                                            onValueChanged={onToggleDobCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DateBox
                                    type="date"
                                    label="Date of Birth"
                                    value={dateFormatFromApi(dobValue)}
                                    onValueChanged={onChangeDobValue}
                                    useMaskBehavior={true}
                                    displayFormat="MM/dd/yyyy"
                                    invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                    labelMode="floating"
                                    stylingMode="outlined"
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date Referral Received">
                                    <span>
                                        <CheckBox
                                            text="Date Referral Received"
                                            value={dateReferralReceviedCheck}
                                            onValueChanged={onToggleDateReferralReceviedCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DateBox
                                    type="date"
                                    label="Date Referral Received"
                                    value={dateFormatFromApi(dateReferralReceviedValue)}
                                    onValueChanged={onChangeDateReferralReceviedValue}
                                    useMaskBehavior={true}
                                    displayFormat="MM/dd/yyyy"
                                    invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                    labelMode="floating"
                                    stylingMode="outlined"
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date Referral Approved">
                                    <span>
                                        <CheckBox
                                            text="Date Referral Approved"
                                            value={dateReferralApprovedCheck}
                                            onValueChanged={onToggleDateReferralApprovedCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DateBox
                                    type="date"
                                    label="Date Referral Approved"
                                    value={dateFormatFromApi(dateReferralApprovedValue)}
                                    onValueChanged={onChangeDateReferralApprovedValue}
                                    useMaskBehavior={true}
                                    displayFormat="MM/dd/yyyy"
                                    invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                    labelMode="floating"
                                    stylingMode="outlined"
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Referral Source">
                                    <span>
                                        <CheckBox
                                            text="Referral Source"
                                            value={referralSourceCheck}
                                            onValueChanged={onToggleReferralSourceCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextBox
                                    label="Referral Source"
                                    value={referralSourceValue}
                                    onValueChanged={onChangeReferralSourceValue}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-referralSourceValue' }} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} title="Referral Type">
                                <Tooltip title="Referral Type">
                                    <span>
                                        <CheckBox
                                            text="Referral Type"
                                            value={referralTypeCheck}
                                            onValueChanged={onToggleReferralTypeCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextBox
                                    label="Referral Type"
                                    value={referralTypeValue}
                                    onValueChanged={onChangeReferralTypeValue}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-referralTypeValue' }} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Reg Form">
                                    <span>
                                        <CheckBox
                                            text="Reg Form"
                                            value={regFormCheck}
                                            onValueChanged={onToggleRegFormCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextBox
                                    label="Reg Form"
                                    value={regFormValue}
                                    onValueChanged={onChangeRegFormValue}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-regFormValue' }} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="NOPP">
                                    <span>
                                        <CheckBox
                                            text="NOPP"
                                            value={noppCheck}
                                            onValueChanged={onToggleNoppCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextBox
                                    label="NOPP"
                                    value={noppValue}
                                    onValueChanged={onChangeNoppValue}
                                    labelMode="floating"
                                    stylingMode="outlined"
                                    inputAttr={{ autocomplete: 'new-noppValue' }} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Electronic Communication Consent">
                                    <span>
                                        <CheckBox
                                            text="Electronic Communication Consent"
                                            value={communicationConsentCheck}
                                            onValueChanged={onToggleCommunicationConsentCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <Label>Telehealth: </Label>
                                    <Switcher
                                        value={communicationConsentValueTelehealth}
                                        onClick={onToggleCommunicationConsentValueTelehealth} >
                                        {communicationConsentValueTelehealth ? 'Yes' : 'No'}
                                    </Switcher>
                                </div>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <Label>Email: </Label>
                                    <Switcher
                                        value={communicationConsentValueEmail}
                                        onClick={onToggleCommunicationConsentValueEmail} >
                                        {communicationConsentValueEmail ? 'Yes' : 'No'}
                                    </Switcher>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Date Chart Created in Cerner">
                                    <span>
                                        <CheckBox
                                            text="Date Chart Created in Cerner"
                                            value={dateChartCreatedCernerCheck}
                                            onValueChanged={onToggleDateChartCreatedCernerCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DateBox
                                    type="date"
                                    label="Date Chart Created in Cerner"
                                    value={dateFormatFromApi(dateChartCreatedCernerValue)}
                                    onValueChanged={onChangeDateChartCreatedCernerValue}
                                    useMaskBehavior={true}
                                    displayFormat="MM/dd/yyyy"
                                    invalidDateMessage="The date must have the following format: MM/dd/yyyy"
                                    labelMode="floating"
                                    stylingMode="outlined"
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Registration Packet Uploaded in Cerner">
                                    <span>
                                        <CheckBox
                                            text="Registration Packet Uploaded in Cerner"
                                            value={registrationPacketUploadCernerCheck}
                                            onValueChanged={onToggleRegistrationPacketUploadCernerCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={registrationPacketUploadCernerValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeRegistrationPacketUploadCernerValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ID Submitted">
                                    <span>
                                        <CheckBox
                                            text="ID Submitted"
                                            value={idSubmittedCheck}
                                            onValueChanged={onToggleIdSubmittedCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={idSubmittedValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeIdSubmittedValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ID Verified (Active)">
                                    <span>
                                        <CheckBox
                                            text="ID Verified (Active)"
                                            value={idVerifiedCheck}
                                            onValueChanged={onToggleIdVerifiedCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={idVerifiedValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeIdVerifiedValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ID Uploaded in Cerner">
                                    <span>
                                        <CheckBox
                                            text="ID Uploaded in Cerner"
                                            value={idUploadedCernerCheck}
                                            onValueChanged={onToggleIdUploadedCernerCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={idUploadedCernerValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeIdUploadedCernerValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Insurance Submitted">
                                    <span>
                                        <CheckBox
                                            text="Insurance Submitted"
                                            value={insuranceSubmittedCheck}
                                            onValueChanged={onToggleIdInsuranceSubmittedCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={insuranceSubmittedValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeInsuranceSubmittedValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Insurance Verified (Active)">
                                    <span>
                                        <CheckBox
                                            text="Insurance Verified (Active)"
                                            value={insuranceVerifiedCheck}
                                            onValueChanged={onToggleInsuranceVerifiedCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={insuranceVerifiedValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeInsuranceVerifiedValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="Insurance Uploaded in Cerner">
                                    <span>
                                        <CheckBox
                                            text="Insurance Uploaded in Cerner"
                                            value={insuranceUploadedCernerCheck}
                                            onValueChanged={onToggleInsuranceUploadedCernerCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={insuranceUploadedCernerValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeInsuranceUploadedCernerValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="APIA BH Case Management Profile Created in Cerner">
                                    <span>
                                        <CheckBox
                                            text="APIA BH Case Management Profile Created in Cerner"
                                            value={profileCratedInCernerCheck}
                                            onValueChanged={onToggleProfileCratedInCernerCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={profileCratedInCernerValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeProfileCratedInCernerValue} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Tooltip title="ROI">
                                    <span>
                                        <CheckBox
                                            text="ROI"
                                            value={roiCheck}
                                            onValueChanged={onToggleRoiCheck} />
                                    </span>
                                </Tooltip>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RadioGroup
                                    layout="horizontal"
                                    items={[true, false]}
                                    value={roiValue}
                                    displayExpr={(item) => item ? "YES" : "NO"}
                                    onValueChanged={onChangeRoiValue} />
                            </AccordionDetails>
                        </Accordion>
                        <div className="patientChecklistButtonsWrap">
                            <Button
                                hint="Save patient checklist"
                                className="left"
                                width="50%"
                                text="Save"
                                type="default"
                                stylingMode="outlined"
                                onClick={onSavePatientChecklist}
                            />
                            <Button
                                hint="Change role to patient"
                                className="right"
                                width="50%"
                                text="Change Role"
                                type="default"
                                stylingMode="outlined"
                                onClick={onChangeInviteRole}
                            />
                        </div>
                    </>
                }
            </Wrapper>
        </Drawer >
    );
}