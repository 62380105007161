import ButtonStyled from "../../../components/Auth/Button/Button.style";
import { Wrapper, Title, Text } from './ResetPassword.style';
import { useNavigate } from 'react-router-dom';
import { GRID_XS } from '../../../utils/constants';
import { Grid } from '@mui/material';

export const ResetPassword = () => {
    let navigate = useNavigate();

    const handleClick = () => {
        navigate("/auth/login");
    }

    return (
        <Wrapper>
            <Title>Password reset</Title>
            <Grid container spacing={2}>
                <Grid item xs={GRID_XS}>
                    <Text>
                        Your password has been reset successfully. You can now log in with your new password.
                    </Text>
                </Grid>
                <Grid item xs={GRID_XS}>
                    <ButtonStyled text="login" onClick={handleClick} />
                </Grid>
            </Grid>
        </Wrapper>
    );
}