import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateGroup } from "../../../../../utils/helper";
import { HealthAndTreatment } from "./HealthAndTreatment";

import { selectLoading, selectDrugUser, selectPhysicalExam, selectPregnantStatus, selectHaveEverBeenInHospital, selectHaveBeenInHospitalLast6Months, selectHowManyMentalHealthHospitals, selectNumberOfArrests } from "../../../../../store/forms/newPatientRegistration/healthAndTreatment/selectors";
import { setDrugUser, setPhysicalExam, setPregnantStatus, setNumberOfArrests, setHowManyMentalHealthHospitals, setHaveBeenInHospitalLast6Months, setHaveEverBeenInHospital } from "../../../../../store/forms/newPatientRegistration/healthAndTreatment/slice";
import { completeHealthAndTreatment } from "../../../../../store/forms/newPatientRegistration/slice";

import { getHealthAndTreatment, saveHealthAndTreatment, getHealthAndTreatmentByID, saveHealthAndTreatmentByID } from "../../../../../store/forms/newPatientRegistration/healthAndTreatment/middlewares";
import { getPregnantStatuses } from "../../../../../store/lookups/pregnantStatus/middlewares";
import { getHospitalInSixMonths } from "../../../../../store/lookups/hospitalInSixMonths/middlewares";
import { getHospitalResidentialTreatments } from "../../../../../store/lookups/hospitalResidentialTreatment/middlewares";
import { selectPregnantStatuses } from "../../../../../store/lookups/pregnantStatus/selectors";
import { selectHospitalInSixMonths } from "../../../../../store/lookups/hospitalInSixMonths/selectors";
import { selectHospitalResidentialTreatments } from "../../../../../store/lookups/hospitalResidentialTreatment/selectors";

// export const answers = [
//     {
//         "Oid": true,
//         "Name": "Yes",
//     }, {
//         "Oid": false,
//         "Name": "No",
//     },
// ];

export const answers = [
    {
        "Oid": 0,
        "Name": "Yes",
    }, {
        "Oid": 1,
        "Name": "No",
    },
];


export const HealthAndTreatmentContainer = ({ onClickNext }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const pregnantStatuses = useSelector(selectPregnantStatuses);
    const hospitalInSixMonths = useSelector(selectHospitalInSixMonths);
    const hospitalResidentialTreatment = useSelector(selectHospitalResidentialTreatments);

    const pregnantStatus = useSelector(selectPregnantStatus);
    const physicalExam = useSelector(selectPhysicalExam);
    const drugUser = useSelector(selectDrugUser);
    const haveEverBeenInHospital = useSelector(selectHaveEverBeenInHospital);
    const haveBeenInHospitalLast6Months = useSelector(selectHaveBeenInHospitalLast6Months);
    const howManyMentalHealthHospitals = useSelector(selectHowManyMentalHealthHospitals);
    const numberOfArrests = useSelector(selectNumberOfArrests);

    useEffect(() => {
        dispatch(id ? getHealthAndTreatmentByID(id) : getHealthAndTreatment());
        dispatch(getPregnantStatuses());
        dispatch(getHospitalInSixMonths());
        dispatch(getHospitalResidentialTreatments());
    }, [dispatch, id]);

    const onChangePregnantStatus = (event) => {
        dispatch(setPregnantStatus(event.value));
    }

    const onChangePhysicalExam = (event) => {
        dispatch(setPhysicalExam(event.value));
    }

    const onChangeDrugUser = (event) => {
        dispatch(setDrugUser(event.value === 0 ? true : false));
    }

    const onChangeHaveEverBeenInHospital = (event) => {
        dispatch(setHaveEverBeenInHospital(event.value));
    }

    const onChangeHaveBeenInHospitalLast6Months = (event) => {
        dispatch(setHaveBeenInHospitalLast6Months(event.value));
    }

    const onChangeHowManyMentalHealthHospitals = (event) => {
        dispatch(setHowManyMentalHealthHospitals(event.value));
    }

    const onChangeNumberOfArrests = (event) => {
        dispatch(setNumberOfArrests(event.value));
    }

    const onSaveHealthAndTreatment = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveHealthAndTreatmentByID(id) : saveHealthAndTreatment());
            dispatch(completeHealthAndTreatment(true));
        }
    }

    const onSaveAndContinueHealthAndTreatment = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveHealthAndTreatmentByID(id) : saveHealthAndTreatment());
            dispatch(completeHealthAndTreatment(true));
            onClickNext();
        }
    }

    return (
        <HealthAndTreatment
            loading={loading}

            answers={answers}
            pregnantStatuses={pregnantStatuses}
            hospitalInSixMonths={hospitalInSixMonths}
            hospitalResidentialTreatment={hospitalResidentialTreatment}

            pregnantStatus={pregnantStatus}
            physicalExam={physicalExam}
            drugUser={drugUser}
            haveEverBeenInHospital={haveEverBeenInHospital}
            haveBeenInHospitalLast6Months={haveBeenInHospitalLast6Months}
            howManyMentalHealthHospitals={howManyMentalHealthHospitals}
            numberOfArrests={numberOfArrests}

            onChangePregnantStatus={onChangePregnantStatus}
            onChangePhysicalExam={onChangePhysicalExam}
            onChangeDrugUser={onChangeDrugUser}
            onChangeHaveEverBeenInHospital={onChangeHaveEverBeenInHospital}
            onChangeHaveBeenInHospitalLast6Months={onChangeHaveBeenInHospitalLast6Months}
            onChangeHowManyMentalHealthHospitals={onChangeHowManyMentalHealthHospitals}
            onChangeNumberOfArrests={onChangeNumberOfArrests}

            onSaveHealthAndTreatment={onSaveHealthAndTreatment}
            onSaveAndContinueHealthAndTreatment={onSaveAndContinueHealthAndTreatment}
        />
    );
};