import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { selectLoading, selectAddress, selectAgree, selectAppt, selectApptComment, selectClosedReferral, selectContact, selectDateOfBirth, selectDeclined, selectDeclinedComment, selectFirstAttempt, selectFirstAttemptBy, selectNoAnswer, selectPerson, selectPhone, selectReason, selectReferral, selectReferralFollowedUpBy, selectReferralFollowedUpDate, selectRequest, selectSecondAttempt, selectSecondAttemptBy, selectThirdAttempt, selectThirdAttemptBy } from '../../../store/forms/referralForm/selectors';
import { setLoading, setAddress, setApptComment, setContact, setDateOfBirth, setDeclinedComment, setFirstAttempt, setFirstAttemptBy, setPerson, setPhone, setReason, setReferral, setReferralFollowedUpBy, setReferralFollowedUpDate, setRequest, setSecondAttempt, setSecondAttemptBy, setThirdAttempt, setThirdAttemptBy, toggleAgree, toggleAppt, toggleDeclined, toggleNoAnswer, toggleClosedReferral } from '../../../store/forms/referralForm/slice';
import { getReferralForm, saveReferralForm, getReferralFormByID, saveReferralFormByID } from '../../../store/forms/referralForm/middlewares';

import { ReferralForm } from './ReferralForm';
import { useDispatch, useSelector } from "react-redux";
import { validateGroup } from '../../../utils/helper';
import { useEffect } from 'react';
import { showToast } from '../../Main/MainContainer';
import { isAdmin } from '../../../core/auth/authProvider';
import { USER_PATH } from '../../../utils/constants';

export const ReferralFormContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id }   = useParams();

    const loading = useSelector(selectLoading);

    const agree = useSelector(selectAgree);
    const person = useSelector(selectPerson);
    const dateOfBirth = useSelector(selectDateOfBirth);
    const address = useSelector(selectAddress);
    const phone = useSelector(selectPhone);
    const referral = useSelector(selectReferral);
    const contact = useSelector(selectContact);
    const reason = useSelector(selectReason);
    const request = useSelector(selectRequest);
    const appt = useSelector(selectAppt);
    const apptComment = useSelector(selectApptComment);
    const declined = useSelector(selectDeclined);
    const declinedComment = useSelector(selectDeclinedComment);
    const noAnswer = useSelector(selectNoAnswer);
    const firstAttempt = useSelector(selectFirstAttempt);
    const firstAttemptBy = useSelector(selectFirstAttemptBy);
    const secondAttempt = useSelector(selectSecondAttempt);
    const secondAttemptBy = useSelector(selectSecondAttemptBy);
    const thirdAttempt = useSelector(selectThirdAttempt);
    const thirdAttemptBy = useSelector(selectThirdAttemptBy);
    const closedReferral = useSelector(selectClosedReferral);
    const referralFollowedUpBy = useSelector(selectReferralFollowedUpBy);
    const referralFollowedUpDate = useSelector(selectReferralFollowedUpDate);

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(id ? getReferralFormByID(id) : getReferralForm());
    }, [dispatch, id]);

    const onToggleAgree = () => {
        dispatch(toggleAgree());
    }

    const onChangePerson = (event) => {
        dispatch(setPerson(event.value));
    }

    const onChangeDateOfBirth = (event) => {
        dispatch(setDateOfBirth(event.value));
    }

    const onChangeAddress = (event) => {
        dispatch(setAddress(event.value.trim()));
    }

    const onChangePhone = (event) => {
        dispatch(setPhone(event.value));
    }

    const onChangeReferral = (event) => {
        dispatch(setReferral(event.value));
    }

    const onChangeContact = (event) => {
        dispatch(setContact(event.value));
    }

    const onChangeReason = (event) => {
        dispatch(setReason(event.value));
    }

    const onChangeRequest = (event) => {
        dispatch(setRequest(event.value));
    }

    const onToggleAppt = () => {
        dispatch(toggleAppt());
    }

    const onChangeApptComment = (event) => {
        dispatch(setApptComment(event.value));
    }

    const onToggleDeclined = () => {
        dispatch(toggleDeclined());
    }

    const onChangeDeclinedComment = (event) => {
        dispatch(setDeclinedComment(event.value));
    }

    const onToggleNoAnswer = () => {
        dispatch(toggleNoAnswer());
    }

    const onChangeFirstAttempt = (event) => {
        dispatch(setFirstAttempt(event.value));
    }

    const onChangeFirstAttemptBy = (event) => {
        dispatch(setFirstAttemptBy(event.value));
    }

    const onChangeSecondAttempt = (event) => {
        dispatch(setSecondAttempt(event.value));
    }

    const onChangeSecondAttemptBy = (event) => {
        dispatch(setSecondAttemptBy(event.value));
    }

    const onChangeThirdAttempt = (event) => {
        dispatch(setThirdAttempt(event.value));
    }

    const onChangeThirdAttemptBy = (event) => {
        dispatch(setThirdAttemptBy(event.value));
    }

    const onToggleClosedReferral = () => {
        dispatch(toggleClosedReferral());
    }

    const onChangeReferralFollowedUpBy = (event) => {
        dispatch(setReferralFollowedUpBy(event.value));
    }

    const onChangeReferralFollowedUpDate = (event) => {
        dispatch(setReferralFollowedUpDate(event.value));
    }

    const onSaveReferralForm = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(id ? saveReferralFormByID(id) : saveReferralForm()).then(() => {
				!isAdmin() && navigate('/main/forms/all');
			}).catch(() => {
				showToast('error', 'Error to save Referral to Behavioral Health form');
			});
        }
    }

	const onClickBack = () => {
		const path = location.state?.path ? location.state.path : window.localStorage.getItem(USER_PATH);
		navigate(`/main/users/${path}/profile/${id}`, { state: { indexTab: 2 } });
	};

    return (
        <ReferralForm
            loading={loading}

            agree={agree}
            person={person}
            dateOfBirth={dateOfBirth}
            address={address}
            phone={phone}
            referral={referral}
            contact={contact}
            reason={reason}
            request={request}
            appt={appt}
            apptComment={apptComment}
            declined={declined}
            declinedComment={declinedComment}
            noAnswer={noAnswer}
            firstAttempt={firstAttempt}
            firstAttemptBy={firstAttemptBy}
            secondAttempt={secondAttempt}
            secondAttemptBy={secondAttemptBy}
            thirdAttempt={thirdAttempt}
            thirdAttemptBy={thirdAttemptBy}
            closedReferral={closedReferral}
            referralFollowedUpBy={referralFollowedUpBy}
            referralFollowedUpDate={referralFollowedUpDate}

            onToggleAgree={onToggleAgree}
            onChangePerson={onChangePerson}
            onChangeDateOfBirth={onChangeDateOfBirth}
            onChangeAddress={onChangeAddress}
            onChangePhone={onChangePhone}
            onChangeReferral={onChangeReferral}
            onChangeContact={onChangeContact}
            onChangeReason={onChangeReason}
            onChangeRequest={onChangeRequest}
            onToggleAppt={onToggleAppt}
            onChangeApptComment={onChangeApptComment}
            onToggleDeclined={onToggleDeclined}
            onChangeDeclinedComment={onChangeDeclinedComment}
            onToggleNoAnswer={onToggleNoAnswer}
            onChangeFirstAttempt={onChangeFirstAttempt}
            onChangeFirstAttemptBy={onChangeFirstAttemptBy}
            onChangeSecondAttempt={onChangeSecondAttempt}
            onChangeSecondAttemptBy={onChangeSecondAttemptBy}
            onChangeThirdAttempt={onChangeThirdAttempt}
            onChangeThirdAttemptBy={onChangeThirdAttemptBy}
            onToggleClosedReferral={onToggleClosedReferral}
            onChangeReferralFollowedUpBy={onChangeReferralFollowedUpBy}
            onChangeReferralFollowedUpDate={onChangeReferralFollowedUpDate}

            onSaveReferralForm={onSaveReferralForm}
            onClickBack={onClickBack}
        />
    )
}