import { isAdmin } from '../../../core/auth/authProvider';
import { Wrapper, TitleWrapper, Title, SubTitle, Fieldset, Text, UnorderedList, UnorderedListLowerRoman, Link, Item, Buttons, CheckboxGrid } from '../../../components/Main/Main.style';
import { GRID_MD, GRID_XS, MAX_CURRENT_DATE, MAX_WIDTH, MIN_CURRENT_DATE } from '../../../utils/constants';
import { Signature } from '../../../components/Signature/Signature.style';
import { dateFormatFromApi } from '../../../utils/formats';
import SignatureCanvas from 'react-signature-canvas';
import { NAME_RULE } from '../../../utils/rules';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
	EmailRule,
	PatternRule,
	RangeRule,
} from 'devextreme-react/validator';

export const ECommunicationConsent = ({
	loading,
	error,

	declineTelehealth,
	emailTelehealth,
	nameTelehealth,
	dateTelehealth,
	checkboxTelehealth,

	declineEmail,
	checkboxEmail,
	emailEmail,
	nameEmail,
	dateEmail,

	onToggleDeclineTelehealth,
	onChangeEmailTelehealth,
	onChangeNameTelehealth,
	onChangeDateTelehealth,
	onToggleCheckboxTelehealth,

	onToggleDeclineEmail,
	onToggleCheckboxEmail,
	onChangeEmailEmail,
	onChangeNameEmail,
	onChangeDateEmail,

	onInitSignature,
	onChangeSignature,
	onClearSignature,

	onSaveECommunicationConsent,
	onClickBack,
}) => {
	const sectionName = "eCommunicationConsent";

	return (
		<Wrapper id={sectionName} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${sectionName}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<TitleWrapper>
							{isAdmin() && <Button hint='Go back to forms' icon="back" onClick={onClickBack} />}
							<Tooltip title="Telehealth Consent">
								<Title>Telehealth Consent</Title>
							</Tooltip>
						</TitleWrapper>
					</Grid>
					<CheckboxGrid container item spacing={2}>
						<CheckboxGrid item>
							<Tooltip title="This form is required. Check this checkbox if you REFUSE Telehealth Consent.">
								<Text>This form is <span style={{ color: 'var(--red-text-color)' }}>required</span>. Check this checkbox if you REFUSE Telehealth Consent.</Text>
							</Tooltip>
						</CheckboxGrid>
						<CheckboxGrid item>
							<CheckBox
								value={declineTelehealth}
								onValueChanged={onToggleDeclineTelehealth} />
						</CheckboxGrid>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<SubTitle disabled={declineTelehealth}>Description</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineTelehealth}>
							Telehealth services are provided for easy access to our behavioral health services. Telehealth and video therapy sessions allow you to get the care you need — when, where and how you need it. Telehealth service is not required and is only used if it is a good fit with the provider and patient.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineTelehealth}>
							If you are concerned about COVID-19, or just want the convenience of staying at home, we can provide telehealth care to patients in Atka, Nikolski, Saint George, Unalaska/Dutch Harbor and Anchorage.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset disabled={declineTelehealth}>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
									<Text><b>To access telehealth services:</b></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										A quiet, private location is the most beneficial. APIA telehealth services use ZOOM to connect patients using a device, such as a smart phone, tablet or computer with video. We use ZOOM through CERNER, an electronic secure platform.
									</Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										Prior to the first appointment, the APIA front desk staff will contact the patient by email or phone with directions on how to use ZOOM telehealth services for the first appointment. A link will be sent that is unique to the patient and can be used at each appointment.
									</Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										A valid email address is needed for the purpose of scheduling and generating an appointment with a device. Emails are not a secure form of transmitting information and should be used just for the purposes of scheduling and not to share sensitive information with your provider.
									</Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										Internet service (usage) fees are not covered by insurance or APIA.
									</Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										Telehealth services may also be provided through a landline telephone.
									</Text>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineTelehealth}>
							Telehealth services are subject to the following:
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<UnorderedList disabled={declineTelehealth}>
							<Item>
								<Text>
									Telehealth services are not the same as an in-person visit, as you will not be in the same room as your provider. If your provider decides that telehealth is not a good fit for you or your situation, the provider may choose to end the session and request an in-person session at a later date.
								</Text>
							</Item>
							<Item>
								<Text>
									Telehealth services will be scheduled in advance.
								</Text>
							</Item>
							<Item>
								<Text>
									Telehealth services provided through a device should be accessed through a safe and secure connection. Be sure to use a device that is in a confidential or private area and always fully close all online counseling sessions when they are complete.
								</Text>
							</Item>
							<Item>
								<Text>
									Telehealth services may also include ways to talk with your provider, such as posting of notes or chat logs during the session. This information may be printed by your provider, and if so, it will be treated as confidential.
								</Text>
							</Item>
							<Item>
								<Text>
									If telehealth services cannot be done due to technical issues, you should immediately contact your provider by telephone or email them to schedule a new session.
								</Text>
							</Item>
							<Item>
								<Text>
									Telehealth services are not appropriate for emergency situations.
								</Text>
							</Item>
							<Item>
								<Text>
									Some videoconferencing services may retain certain personal information for its users. This could include user contacts and addresses, and other personal information you provide to the service. You should review the privacy policy for the internet service provider if you have any questions about the confidentiality of such information.
								</Text>
							</Item>
						</UnorderedList>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle disabled={declineTelehealth}>Consent</SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineTelehealth}>
							Using telehealth services is entirely voluntary and will not impact the quality of care you receive from the organization should you decide not to use these services.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineTelehealth}>
							Aleutian Pribilof Islands Association, Inc. (APIA) is not liable for any claims and/or damages arising from following:
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<UnorderedListLowerRoman>
							<Item disabled={declineTelehealth}>
								Interruption in the ability to conduct telehealth services due to technical difficulties, technical maintenance, or system failure.
							</Item>
							<Item disabled={declineTelehealth}>
								Access by friends, family members or other third parties who may enter the room on the client side during telehealth sessions.
							</Item>
							<Item disabled={declineTelehealth}>
								Breaches of privacy and security due to the fault of the third-party videoconferencing provider (such as Zoom, Skype, Vidyo, etc.).
							</Item>
						</UnorderedListLowerRoman>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineTelehealth}>
							By signing below, you consent to the conditions described herein and agree to adhere to the policies set forth above, as well as any other guidelines that the Organization may impose for using electronic communications.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<CheckBox
							text="I have access to a device, such as a smart phone, tablet or computer with a camera in a secure location."
							value={checkboxTelehealth}
							onValueChanged={onToggleCheckboxTelehealth}
							disabled={declineTelehealth} />
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineTelehealth}>
							I would like my telehealth invite to be sent to:
						</Text>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Email *">
							<>
								<TextBox
									label="Email *"
									value={emailTelehealth}
									onValueChanged={onChangeEmailTelehealth}
									disabled={declineTelehealth}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-emailTelehealth' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Email is required"
										/>
										<EmailRule
											message="Email is invalid"
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Printed Name *">
							<>
								<TextBox
									label="Printed Name *"
									value={nameTelehealth}
									onValueChanged={onChangeNameTelehealth}
									disabled={declineTelehealth}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-nameTelehealth' }}
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Printed Name is required"
										/>
										<PatternRule
											message="Do not use digits in the Printed Name"
											pattern={NAME_RULE}
										/>
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Date *">
							<>
								<DateBox
									label="Date *"
									type="date"
									value={dateFormatFromApi(dateTelehealth)}
									useMaskBehavior={true}
									onValueChanged={onChangeDateTelehealth}
									disabled={declineTelehealth}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined"
								>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date is required"
										/>
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} style={{ paddingTop: '40px' }}>
						<Title>Email Consent</Title>
					</Grid>
					<CheckboxGrid container item spacing={2}>
						<CheckboxGrid item>
							<Tooltip title="This form is equired. Check this checkbox if you REFUSE Email Consent.">
								<Text>This form is <span style={{ color: 'var(--red-text-color)' }}>required</span>. Check this checkbox if you REFUSE Email Consent.</Text>
							</Tooltip>
						</CheckboxGrid>
						<CheckboxGrid item>
							<CheckBox
							value={declineEmail}
							onValueChanged={onToggleDeclineEmail} />
						</CheckboxGrid>
					</CheckboxGrid>
					<Grid item xs={GRID_XS}>
						<SubTitle disabled={declineEmail}> Description </SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineEmail}>
							E-mail communication is a convenience and not appropriate for all circumstances.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Fieldset disabled={declineEmail}>
							<Grid container spacing={2}>
								<Grid item xs={GRID_XS} style={{ textAlign: 'center' }}>
									<Text><b>Please remember the following:</b></Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										Emails are not to be used for emergencies or time-sensitive issues. In case of emergency, please dial 911.
									</Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										Emails are not to be used as a therapy session.
									</Text>
								</Grid>
								<Grid item xs={GRID_XS}>
									<Text>
										When we send you an email, or you send us an email, the information that is sent is not always encrypted. This means a third party may be able to access the information and read it since it is
										transmitted over the Internet. In addition, once the email is received by you, someone may be able to
										access your email account and read it.
									</Text>
								</Grid>
							</Grid>
						</Fieldset>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineEmail}>
							In their latest modification to the HIPAA Privacy Rule, the federal government provided guidance on email and
							HIPAA. The information is available on the U.S. Department of Health and Human Services website at  <Link href="https://www.hhs.gov/hipaa/for-professionals/faq/570/does-hipaa-permit-health-care-providers-to-use-email-to-discuss-health-issues-with-patients" target="_blank">www.hhs.gov/hipaa/for-professionals/faq/570/does-hipaa-permit-health-care-providers-to-use-email-to-discuss-health-issues-with-patients/</Link>.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Text disabled={declineEmail}>
							The guidelines state that if a patient has been made aware of the risks of unencrypted email, and that same patient
							provides consent to receive health information via email, then a health entity may send that patient personal medical
							information via unencrypted email.
						</Text>
					</Grid>
					<Grid item xs={GRID_XS}>
						<SubTitle disabled={declineEmail}> Consent </SubTitle>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="I understand the risks of unencrypted email and do hereby give permission to APIA to send me personal health information via unencrypted email.">
							<>
								<CheckBox
									text="I understand the risks of unencrypted email and do hereby give permission to APIA to send me personal health information via unencrypted email."
									value={checkboxEmail}
									onValueChanged={onToggleCheckboxEmail}
									disabled={declineEmail} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Email *">
							<>
								<TextBox
									label="Email *"
									value={emailEmail}
									onValueChanged={onChangeEmailEmail}
									disabled={declineEmail || !checkboxEmail}
									maxLength={64}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-emailEmail' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Email is required" />
										<EmailRule
											message="Email is invalid" />
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Printed Name *">
							<>
								<TextBox
									label="Printed Name *"
									value={nameEmail}
									onValueChanged={onChangeNameEmail}
									disabled={declineEmail || !checkboxEmail}
									maxLength={30}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-onChangeNameEmail' }}>
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Printed Name  is required" />
										<PatternRule
											message="Do not use digits in the Printed Name "
											pattern={NAME_RULE} />
									</Validator>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} md={GRID_MD}>
						<Tooltip title="Date *">
							<>
								<DateBox
									label="Date *"
									type="date"
									value={dateFormatFromApi(dateEmail)}
									useMaskBehavior={true}
									onValueChanged={onChangeDateEmail}
									disabled={declineEmail || !checkboxEmail}
									displayFormat="MM/dd/yyyy"
									invalidDateMessage="The date must have the following format: MM/dd/yyyy"
									labelMode="floating"
									stylingMode="outlined" >
									<Validator validationGroup={sectionName}>
										<RequiredRule
											message="Date is required" />
										<RangeRule
											message="Date cannot be earlier or later than that year"
											min={MIN_CURRENT_DATE}
											max={MAX_CURRENT_DATE} />
									</Validator>
								</DateBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Signature error={error}>
							<legend>Signature *</legend>
							<SignatureCanvas
								ref={onInitSignature}
								onEnd={onChangeSignature}
								clearOnResize={false}
								canvasProps={{
									id: 'responsive-canvas'
								}} />
						</Signature>
					</Grid>
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right' }} >
						<Buttons>
							<Button
								text="Save"
								type="default"
								stylingMode="contained"
								validationGroup={sectionName}
								onClick={onSaveECommunicationConsent} />
							<Button
								text="Clear Signature"
								type="default"
								stylingMode="outlined"
								onClick={onClearSignature} />
						</Buttons>
					</Grid>
				</Grid>
			}
		</Wrapper >
	)
}