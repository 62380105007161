import { getHealthAndTreatment, postHealthAndTreatment, getHealthAndTreatmentByID, postHealthAndTreatmentByID } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pregnantStatus: "",
    physicalExam: null,
    drugUser: "",
    haveEverBeenInHospital: "",
    haveBeenInHospitalLast6Months: "",
    howManyMentalHealthHospitals: "",
    numberOfArrests: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationHealthAndTreatment = createSlice({
    name: "newPatientRegistrationHealthAndTreatment",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setPregnantStatus: (state, action) => {
            state.pregnantStatus = action.payload;
            return state;
        },
        setPhysicalExam: (state, action) => {
            state.physicalExam = action.payload;
            return state;
        },
        setDrugUser: (state, action) => {
            state.drugUser = action.payload;
            return state;
        },
        setHaveEverBeenInHospital: (state, action) => {
            state.haveEverBeenInHospital = action.payload;
            return state;
        },
        setHaveBeenInHospitalLast6Months: (state, action) => {
            state.haveBeenInHospitalLast6Months = action.payload;
            return state;
        },
        setHowManyMentalHealthHospitals: (state, action) => {
            state.howManyMentalHealthHospitals = action.payload;
            return state;
        },
        setNumberOfArrests: (state, action) => {
            state.numberOfArrests = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getHealthAndTreatment.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postHealthAndTreatment.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getHealthAndTreatment.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.pregnantStatus = action.payload?.pregnant;
                state.physicalExam = action.payload?.physicalExam;
                state.drugUser = action.payload?.drugUser;
                state.haveEverBeenInHospital = action.payload?.haveEverBeenInHospital;
                state.haveBeenInHospitalLast6Months = action.payload?.haveBeenInHospitalLast6Months;
                state.howManyMentalHealthHospitals = action.payload?.howManyMentalHealthHospitals;
                state.numberOfArrests = action.payload?.numberOfArrests;

                state.loading = false;
            })
            .addCase(postHealthAndTreatment.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Health And Treatment Information saved successfully");
            })
            .addCase(getHealthAndTreatment.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Health And Treatment Information: ${action.error.message}`);
            })
            .addCase(postHealthAndTreatment.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Health And Treatment Information: ${action.error.message}`);
            })
            .addCase(getHealthAndTreatmentByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError  = null;
                state.loading   = true;
            })
            .addCase(getHealthAndTreatmentByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError  = null;

                state.pregnantStatus = action.payload?.pregnant;
                state.physicalExam   = action.payload?.physicalExam;
                state.drugUser       = action.payload?.drugUser;
                state.loading        = false;
                state.haveEverBeenInHospital        = action.payload?.haveEverBeenInHospital;
                state.haveBeenInHospitalLast6Months = action.payload?.haveBeenInHospitalLast6Months;
                state.howManyMentalHealthHospitals  = action.payload?.howManyMentalHealthHospitals;
                state.numberOfArrests               = action.payload?.numberOfArrests;
            })
            .addCase(getHealthAndTreatmentByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError  = action.error;
                state.loading   = false;
                showToast('error', `Get Health And Treatment Information: ${action.error.message}`);
            })
            .addCase(postHealthAndTreatmentByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError  = null;
            })
            .addCase(postHealthAndTreatmentByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError  = null;
                showToast('success', 'Health And Treatment Information saved successfully');
            })
            .addCase(postHealthAndTreatmentByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError  = action.error;
                showToast('error', `Set Health And Treatment Information: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setPregnantStatus,
    setPhysicalExam,
    setDrugUser,
    setHaveEverBeenInHospital,
    setHaveBeenInHospitalLast6Months,
    setHowManyMentalHealthHospitals,
    setNumberOfArrests,
} = newPatientRegistrationHealthAndTreatment.actions;

export default newPatientRegistrationHealthAndTreatment.reducer;