import React, { useCallback, useState, } from 'react';
import { selectError, selectConfirmPassword, selectPassword } from '../../../store/auth/invite/selectors';
import { setConfirmPassword, setPassword } from '../../../store/auth/invite/slice';
import { postInvite } from '../../../store/auth/invite/middlewares';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { login, logout } from '../../../core/auth/authProvider';
import { useDispatch, useSelector } from 'react-redux';
import { validateGroup } from '../../../utils/helper';
import { Invite } from './Invite';
import { showToast } from '../../Main/MainContainer';

export const InviteContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const error = useSelector(selectError);

    const password = useSelector(selectPassword);
    const confirmPassword = useSelector(selectConfirmPassword);

	const [firstMode, setFirstMode] = useState("password");
	const [secondMode, setSecondMode] = useState("password");

	const changeFirstMode = () => {
		setFirstMode(firstMode === "text" ? "password" : "text");
	};

	const changeSecondMode = () => {
		setSecondMode(secondMode === "text" ? "password" : "text");
	};

	const passComparison = useCallback(() => password, [password]);

    const onChangePassword = (event) => {
        const value = event.value;
        dispatch(setPassword(value));
    };

    const onChangeConfirmPassword = (event) => {
        const value = event.value;
        dispatch(setConfirmPassword(value));
    };

    const handleClick = (event) => {
        const isValid = validateGroup(event);
        const link = searchParams.get('id');

        if (isValid && password === confirmPassword) {
            dispatch(postInvite({ link, password })).then((data) => {
                if (!data.payload.errors) {
                    login({ 'accessToken': data.payload.token, 'refreshToken': data.payload.refreshToken });
                    navigate("/main/welcome");
                }
            }).catch(() => logout());
        }
        else {
            showToast('error', 'Passwords do not match');
        }
    };

    const onKeyPressEnter = (event) => {
        if (event.key === 'Enter')
            handleClick();
    };

    return (
        <Invite
            error={error}

            password={password}
            confirmPassword={confirmPassword}

            onChangePassword={onChangePassword}
            onChangeConfirmPassword={onChangeConfirmPassword}

            firstMode={firstMode}
            secondMode={secondMode}
            changeFirstMode={changeFirstMode}
            changeSecondMode={changeSecondMode}
            passComparison={passComparison}

            handleClick={handleClick}
            onKeyPressEnter={onKeyPressEnter}
        />
    );
}