import { setReason, setOtherReferralInfo, setReferralInfo, toggleContactedByPhysicalExam, toggleRefferalForMedicalRN } from "../../../../../../store/users/patientProfile/basicInformation/referralInfo/slice";
import { selectLoading, selectContactedByPhysicalExam, selectOtherReferralInfo, selectReferralInfo, selectRefferalForMedicalRN, selectReason } from "../../../../../../store/users/patientProfile/basicInformation/referralInfo/selectors";

import { getReferralStatuses } from "../../../../../../store/lookups/referralStatus/middlewares";
import { selectReferralStatuses } from "../../../../../../store/lookups/referralStatus/selectors";
import { getReferralInfoById, saveReferralInfoById } from "../../../../../../store/users/patientProfile/basicInformation/referralInfo/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { ReferralInfo } from "./ReferralInfo"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ReferralInfoContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const loading = useSelector(selectLoading);

    const referralStatuses = useSelector(selectReferralStatuses);

    const reason = useSelector(selectReason);
    const referralInfo = useSelector(selectReferralInfo);
    const otherReferralInfo = useSelector(selectOtherReferralInfo);
    const refferalForMedicalRN = useSelector(selectRefferalForMedicalRN);
    const contactedByPhysicalExam = useSelector(selectContactedByPhysicalExam);

    useEffect(() => {
        dispatch(getReferralInfoById(id));
        dispatch(getReferralStatuses());
    }, [dispatch, id]);

    const FN_TO_CALL = {
		Reason: setReason,
        ReferralInfo: setReferralInfo,
        OtherReferralInfo: setOtherReferralInfo,
        RefferalForMedicalRN: toggleRefferalForMedicalRN,
        ContactedByPhysicalExam: toggleContactedByPhysicalExam,
	};

    const onChangeFunc = (name, event = null, trim = false) => {
        if(event) dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        else dispatch(FN_TO_CALL[name]());
        setShowButton(true);
    };

    const onCancelReferralInfo = () => {
        dispatch(getReferralInfoById(id));
        setShowButton(false);
    }

    const onSaveReferralInfo = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveReferralInfoById(id));
            setShowButton(false);
        };
    }

    return (
        <ReferralInfo
            loading={loading}
            showButton={showButton}

            referralStatuses={referralStatuses}

            reason={reason}
            referralInfo={referralInfo}
            otherReferralInfo={otherReferralInfo}
            refferalForMedicalRN={refferalForMedicalRN}
            contactedByPhysicalExam={contactedByPhysicalExam}

            onChangeFunc={onChangeFunc}

            onCancelReferralInfo={onCancelReferralInfo}
            onSaveReferralInfo={onSaveReferralInfo}
        />
    );
}