import { Wrapper, Title, Text, HelperText, IconButtonStyled } from '../Login/Login.style';
import { Validator, RequiredRule, CompareRule, PatternRule, StringLengthRule } from 'devextreme-react/validator';
import { PASS_PATTERN_RULE } from '../../../utils/rules';

import TextBoxStyled from '../../../components/Auth/Textbox/Textbox.style';
import ButtonStyled from '../../../components/Auth/Button/Button.style';
import { GRID_XS } from '../../../utils/constants';
import { Icon } from '@iconify/react';
import { Grid } from '@mui/material';

export const Invite = ({
	error,

	password,
	confirmPassword,

	onChangePassword,
	onChangeConfirmPassword,

	firstMode,
	secondMode,
	changeFirstMode,
	changeSecondMode,
	passComparison,

	onKeyPressEnter,
	handleClick
}) => {
	return (
		<Wrapper>
			<Title>Set your password</Title>
			<Grid container spacing={2}>
				<Grid item xs={GRID_XS}>
					<Text>
						Enter a new password below to change your password.<br /><br />
						Your new password must be different from previously used passwords. Password must be at least 8 characters long and contain 1 lowercase, 1 uppercase, 1 number, and 1 special character (@#!_$%&).
					</Text>
				</Grid>
				<Grid item xs={GRID_XS} title="Password">
					<TextBoxStyled
						label="Password"
						mode={firstMode}
						value={password}
						onValueChanged={onChangePassword}
						onKeyPress={onKeyPressEnter}
						labelMode="static"
						stylingMode="outlined"
					>
						<IconButtonStyled onClick={changeFirstMode} tabIndex={-1}>
							<Icon
								icon={firstMode === "text" ? "bx:hide" : "bx:show"}
								height="30px"
								width="30px" />
						</IconButtonStyled>
						<Validator>
							<RequiredRule message="Password is required" />
							<PatternRule
								message="Do not use digits in the New Password"
								pattern={PASS_PATTERN_RULE}
							/>
							<StringLengthRule
								message="New Password must have at least 8 symbols"
								min={8}
							/>
						</Validator>
					</TextBoxStyled>
				</Grid>
				<Grid item xs={GRID_XS} title="Confirm Password">
					<TextBoxStyled
						label="Confirm Password"
						mode={secondMode}
						value={confirmPassword}
						onValueChanged={onChangeConfirmPassword}
						onKeyPress={onKeyPressEnter}
						labelMode="static"
						stylingMode="outlined"
					>
						<IconButtonStyled onClick={changeSecondMode} tabIndex={-1}>
							<Icon
								icon={secondMode === "text" ? "bx:hide" : "bx:show"}
								height="30px"
								width="30px" />
						</IconButtonStyled>
						<Validator>
							<RequiredRule message="Password is required" />
							<CompareRule
								message="Password and Confirm Password do not match"
								comparisonTarget={passComparison}
							/>
							<PatternRule
								message="Do not use digits in the Confirm New Password"
								pattern={PASS_PATTERN_RULE}
							/>
							<StringLengthRule
								message="Confirm New Password must have at least 8 symbols"
								min={8}
							/>
						</Validator>
					</TextBoxStyled>
				</Grid>
				<Grid item xs={GRID_XS}>
					<HelperText>
						{!!error && error.join(' ')}
					</HelperText>
				</Grid>
				<Grid item xs={GRID_XS}>
					<ButtonStyled
						text="register"
						onClick={handleClick}
					/>
				</Grid>
			</Grid>
		</Wrapper>
	);
};