import { ItemButton, ItemText, ItemIcon, Drawer, DrawerHeader, DrawerBottom } from './Navigation.style';
import { IconButton, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { logout } from '../../../core/auth/authProvider';
import { ADMIN, INVITEE, PATIENT } from '../../../utils/constants';

const items = [{
    icon: "tdesign:user-1",
    navigate: "/main/users/patients",
    text: "Users",
    height: "20px",
    role: [ADMIN]
}, {
    icon: "lucide:file-text",
    navigate: "/main/forms/all",
    text: "Forms",
    height: "20px",
    role: [PATIENT, INVITEE]
}, {
    icon: "lucide:phone-call",
    navigate: "/main/contacts",
    text: "Contacts",
    height: "20px",
    role: [ADMIN, PATIENT, INVITEE]
}];

export const Navigation = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [active, setActive] = useState({ item: items[0].text });

    const onClick = (item) => {
        navigate(item.navigate);
        setActive({ item: item.text });
    }

    return (
        <Drawer variant="permanent" open={props.open}>
            <DrawerHeader>
                <IconButton onClick={props.onClick}>
                    {theme.direction === 'rtl' ? <Icon icon="charm:chevron-right" /> : <Icon icon="charm:chevron-left" />}
                </IconButton>
            </DrawerHeader>
            <List>
                {items.map((item, id) => {
                    return item.role.includes(props.authRole()) &&
                        <ListItem key={id} disablePadding sx={{ display: 'block' }}>
                            <ItemButton
                                open={props.open}
                                active={active.item === item.text}
                                onClick={() => onClick(item)}>
                                <ItemIcon open={props.open} active={active.item === item.text}>
                                    <Icon icon={item.icon} height={item.height} />
                                </ItemIcon>
                                <ItemText
                                    open={props.open}
                                    active={active.item === item.text}
                                    primary={item.text}
                                />
                            </ItemButton>
                        </ListItem>
                })}
            </List>
            <DrawerBottom open={props.open}>
                <List style={{ marginTop: 'auto', }}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ItemButton open={props.open} onClick={() => {
                            logout();
                            onClick({ navigate: "/", text: "Log out" });
                        }}>
                            <ItemIcon open={props.open} >
                                <Icon icon="material-symbols:logout" height="22px" />
                            </ItemIcon>
                            <ItemText open={props.open} primary={"Log out"} />
                        </ItemButton>
                    </ListItem>
                </List>
            </DrawerBottom>
        </Drawer>
    );
}