export const selectLoading = state => state.paymentAgreement.loading;

export const selectDecline = state => state.paymentAgreement.decline;
export const selectFirstName = state => state.paymentAgreement.firstName;
export const selectMiddleName = state => state.paymentAgreement.middleName;
export const selectLastName = state => state.paymentAgreement.lastName;
export const selectDateOfBirth = state => state.paymentAgreement.dateOfBirth;
export const selectPhone = state => state.paymentAgreement.phone;
export const selectAddress = state => state.paymentAgreement.address;
export const selectAmountDue = state => state.paymentAgreement.amountDue;
export const selectAmountDueDate = state => state.paymentAgreement.amountDueDate;
export const selectDate = state => state.paymentAgreement.date;
export const selectCashOrCheck = state => state.paymentAgreement.cashOrCheck;
export const selectCreditCard = state => state.paymentAgreement.creditCard;
export const selectMonthlyAmount = state => state.paymentAgreement.monthlyAmount;
export const selectDateOfEachMonth = state => state.paymentAgreement.dateOfEachMonth;
export const selectDuration = state => state.paymentAgreement.duration;
export const selectCreditCardNumber = state => state.paymentAgreement.creditCardNumber;
export const selectCreditCardExp = state => state.paymentAgreement.creditCardExp;
export const selectCreditCardCode = state => state.paymentAgreement.creditCardCode;
export const selectCreditCardName = state => state.paymentAgreement.creditCardName;
export const selectDifferentGuarantorName = state => state.paymentAgreement.differentGuarantorName;
export const selectDifferentGuarantorAddress = state => state.paymentAgreement.differentGuarantorAddress;
export const selectDifferentGuarantorPhone = state => state.paymentAgreement.differentGuarantorPhone;

