import { getAdvanceCare, postAdvanceCare, getAdvanceCareByID, postAdvanceCareByID } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lastDateReview: null,
    haveLivingWill: "",
    haveLivingWillReason: "",
    psychiatricAdvanceDirective: "",
    psychiatricAdvanceDirectiveReason: "",
    advanceHealthCareDirectiveOnFile: null,
    advanceHealthCareDirectiveInfo: null,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationAdvanceCare = createSlice({
    name: "newPatientRegistrationAdvanceCare",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setLastDateReview: (state, action) => {
            state.lastDateReview = action.payload;
            return state;
        },
        setHaveLivingWill: (state, action) => {
            state.haveLivingWill = action.payload ? action.payload : 0;
            return state;
        },
        setHaveLivingWillReason: (state, action) => {
            state.haveLivingWillReason = action.payload;
            return state;
        },
        setPsychiatricAdvanceDirective: (state, action) => {
            state.psychiatricAdvanceDirective = action.payload ? action.payload : 0;
            return state;
        },
        setPsychiatricAdvanceDirectiveReason: (state, action) => {
            state.psychiatricAdvanceDirectiveReason = action.payload;
            return state;
        },
        toggleAdvanceHealthCareDirectiveOnFile: (state, action) => {
            state.advanceHealthCareDirectiveOnFile = action.payload;
            return state;
        },
        toggleAdvanceHealthCareDirectiveInfo: (state, action) => {
            state.advanceHealthCareDirectiveInfo = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdvanceCare.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postAdvanceCare.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getAdvanceCare.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.lastDateReview = action.payload?.lastDateReview;
                state.haveLivingWill = action.payload?.haveLivingWill;
                state.haveLivingWillReason = action.payload?.haveLivingWillReason;
                state.psychiatricAdvanceDirective = action.payload?.psychiatricAdvanceDirective;
                state.psychiatricAdvanceDirectiveReason = action.payload?.psychiatricAdvanceDirectiveReason;
                state.advanceHealthCareDirectiveOnFile = action.payload?.advanceHealthCareDirectiveOnFile;
                state.advanceHealthCareDirectiveInfo = action.payload?.advanceHealthCareDirectiveInfo;

                state.loading = false;
            })
            .addCase(postAdvanceCare.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Advance Care Planning saved successfully");
            })
            .addCase(getAdvanceCare.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Referral Planning Information and Advance Care: ${action.error.message}`);
            })
            .addCase(postAdvanceCare.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Advance Care Planning: ${action.error.message}`);
            })
            .addCase(getAdvanceCareByID.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getAdvanceCareByID.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.lastDateReview = action.payload?.lastDateReview;
                state.haveLivingWill = action.payload?.haveLivingWill;
                state.haveLivingWillReason = action.payload?.haveLivingWillReason;
                state.psychiatricAdvanceDirective = action.payload?.psychiatricAdvanceDirective;
                state.psychiatricAdvanceDirectiveReason = action.payload?.psychiatricAdvanceDirectiveReason;
                state.advanceHealthCareDirectiveOnFile = action.payload?.advanceHealthCareDirectiveOnFile;
                state.advanceHealthCareDirectiveInfo = action.payload?.advanceHealthCareDirectiveInfo;

                state.loading = false;
            })
            .addCase(getAdvanceCareByID.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;
                showToast('error', `Get Referral Planning Information and Advance Care: ${action.error.message}`);
            })
            .addCase(postAdvanceCareByID.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(postAdvanceCareByID.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;
                showToast('success', 'Advance Care Planning saved successfully');
            })
            .addCase(postAdvanceCareByID.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;
                showToast('error', `Set Advance Care Planning: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setLastDateReview,
    setHaveLivingWill,
    setHaveLivingWillReason,
    setPsychiatricAdvanceDirective,
    setPsychiatricAdvanceDirectiveReason,
    toggleAdvanceHealthCareDirectiveOnFile,
    toggleAdvanceHealthCareDirectiveInfo,
} = newPatientRegistrationAdvanceCare.actions;

export default newPatientRegistrationAdvanceCare.reducer;

