import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../core/rest";

export const getPatientList = createAsyncThunk(
	"getPatientList",
	async (_, { rejectWithValue }) => {
		try {
			return await get(BASE_URL +  "/UserList/userlist?role=Patient");
		} catch (error) {
			return rejectWithValue(error.response.data);
		};
	},
);