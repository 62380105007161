import { BASE_URL, EMERGENCY_CONTACT } from "../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getEmergencyContact = createAsyncThunk(
    "getEmergencyContact",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${EMERGENCY_CONTACT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getEmergencyContactByID = createAsyncThunk(
    "getEmergencyContactByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${EMERGENCY_CONTACT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setEmergencyContact = createAsyncThunk(
    "setEmergencyContact",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${EMERGENCY_CONTACT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setEmergencyContactByID = createAsyncThunk(
    "setEmergencyContactByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${EMERGENCY_CONTACT}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveEmergencyContact = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationEmergencyContact;

    const data = {
        "contact": store.contact,
        "relationship": store.relationship,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email
    }

    dispatch(setEmergencyContact(data));
};

export const saveEmergencyContactByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationEmergencyContact;

    const data = {
        "contact": store.contact,
        "relationship": store.relationship,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email
    }

    dispatch(setEmergencyContactByID({ id, data }));
};