import { getEmploymentById, postEmploymentById } from "./middlewares";
import { showToast } from "../../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    employment: "",
    employer: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    primaryIncomeSource: "",
    otherPrimaryIncomeSource: "",
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const patientProfileEmployment = createSlice({
    name: "patientProfileEmployment",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setEmployment: (state, action) => {
            state.employment = action.payload;
            return state;
        },
        setEmployer: (state, action) => {
            state.employer = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setPrimaryIncomeSource: (state, action) => {
            state.primaryIncomeSource = action.payload;
            return state;
        },
        setOtherPrimaryIncomeSource: (state, action) => {
            state.otherPrimaryIncomeSource = action.payload;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEmploymentById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(postEmploymentById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(getEmploymentById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;


                state.employment = action.payload?.employmentStatus;
                state.employer = action.payload?.employer;
                state.address = action.payload?.address;
                state.city = action.payload?.city;
                state.state = action.payload?.state;
                state.zip = action.payload?.zip;
                state.primaryIncomeSource = action.payload?.primatyIncomeSource;
                state.otherPrimaryIncomeSource = action.payload?.source;

                state.loading = false;
            })
            .addCase(postEmploymentById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Employment saved successfully");
            })
            .addCase(getEmploymentById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Employment: ${action.error.message}`);
            })
            .addCase(postEmploymentById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Employment: ${action.error.message}`);
            });
    },
});

export const {
    setLoading,

    setEmployment,
    setEmployer,
    setAddress,
    setCity,
    setState,
    setZip,
    setPrimaryIncomeSource,
    setOtherPrimaryIncomeSource,
} = patientProfileEmployment.actions;

export default patientProfileEmployment.reducer;
