import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../../core/rest";

export const getNewPatientRegistration = createAsyncThunk(
    "getNewPatientRegistration",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/FormsInfo/completeness/NewPatientRegistration");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getNewPatientRegistrationById = createAsyncThunk(
    "getNewPatientRegistrationById",
    async (patientID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/FormsInfo/completeness/NewPatientRegistration?userId=${patientID}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);
