import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

export const Button = styled(MuiButton)(() => ({
    height: '40px',
    '&.MuiButton-text': {
        fontSize: '12px',
        color: 'var(--light-blue-color)',
    },
    '&.MuiButton-text.Mui-disabled': {
        color: 'var(--grey-text-color)',
    },
    '&.MuiButton-outlined': {
        color: 'var(--black-blue-color)',
        borderColor: 'var(--light-grey-color)',
    },
    '&.MuiButton-contained': {
        color: 'var(--white-color)',
        backgroundColor: 'var(--light-blue-color)',
    },
    '&.MuiButton-outlined:hover': {
        color: 'var(--black-blue-color)',
        borderColor: 'var(--light-grey-color)',
    },
    '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, .1)',
        color: 'rgba(0, 0, 0, .35)',
        userSelect: 'none',
        opacity: '.38',
    },
}));