import { BASE_URL, NEXT_KIN, EMERGENCY_CONTACT } from "../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getNextKin = createAsyncThunk(
    "getNextKin",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${NEXT_KIN}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getNextKinByID = createAsyncThunk(
    "getNextKinByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${NEXT_KIN}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setNextKin = createAsyncThunk(
    "setNextKin",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${NEXT_KIN}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setNextKinByID = createAsyncThunk(
    "setNextKinByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${NEXT_KIN}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getEmergencyContact = createAsyncThunk(
    "getEmergencyContact",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${EMERGENCY_CONTACT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getEmergencyContactByID = createAsyncThunk(
    "getEmergencyContactByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${EMERGENCY_CONTACT}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveNextOfKin = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationNextOfKin;

    const data = {
        "checkbox": store.checkbox,
        "nextOfKin": store.nextOfKin,
        "relationship": store.relationship,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email
    }

    dispatch(setNextKin(data));
};

export const saveNextOfKinByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationNextOfKin;

    const data = {
        "checkbox": store.checkbox,
        "nextOfKin": store.nextOfKin,
        "relationship": store.relationship,
        "address": store.address,
        "city": store.city,
        "state": store.state,
        "zip": store.zip,
        "phone": store.phone,
        "email": store.email
    }

    dispatch(setNextKinByID({ id, data }));
};