import { getPatientInfo, setPatientInfo, setPatientInfoById, getPatientInfoById } from "./middlewares";
import { showToast } from "../../../../screens/Main/MainContainer";
import { FETCH_STATUSES } from "../../../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    firstName: "",
    middleName: "",
    lastName: "",
    SSN: "",
    dateOfBirth: null,
    gender: "",
    otherGender: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    numberOfPeople: "",
    numberOfChildren: "",
    maritalStatus: "",
    sendEmail: false,
    internetAccess: false,
    canLeaveVoiceMessage: false,
    sendRemindersByText: false,
    getStatus: FETCH_STATUSES.IDLE,
    setStatus: FETCH_STATUSES.IDLE,
    getError: null,
    setError: null,
    loading: true,
};

export const newPatientRegistrationPatientInfo = createSlice({
    name: "newPatientRegistrationPatientInfo",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
            return state;
        },
        setFirstName: (state, action) => {
            state.firstName = action.payload;
            return state;
        },
        setMiddleName: (state, action) => {
            state.middleName = action.payload;
            return state;
        },
        setLastName: (state, action) => {
            state.lastName = action.payload;
            return state;
        },
        setSSN: (state, action) => {
            state.SSN = action.payload;
            return state;
        },
        setDateOfBirth: (state, action) => {
            state.dateOfBirth = action.payload;
            return state;
        },
        setGender: (state, action) => {
            state.gender = action.payload;
            return state;
        },
        setOtherGender: (state, action) => {
            state.otherGender = action.payload;
            return state;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
            return state;
        },
        setCity: (state, action) => {
            state.city = action.payload;
            return state;
        },
        setState: (state, action) => {
            state.state = action.payload;
            return state;
        },
        setZip: (state, action) => {
            state.zip = action.payload;
            return state;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
            return state;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            return state;
        },
        setNumberOfPeople: (state, action) => {
            state.numberOfPeople = action.payload;
            return state;
        },
        setNumberOfChildren: (state, action) => {
            state.numberOfChildren = action.payload;
            return state;
        },
        setMaritalStatus: (state, action) => {
            state.maritalStatus = action.payload;
            return state;
        },
        toggleSendEmail: (state) => {
            state.sendEmail = !state.sendEmail;
            return state;
        },
        toggleInternetAccess: (state) => {
            state.internetAccess = !state.internetAccess;
            return state;
        },
        toggleCanLeaveVoiceMessage: (state) => {
            state.canLeaveVoiceMessage = !state.canLeaveVoiceMessage;
            return state;
        },
        toggleSendRemindersByText: (state) => {
            state.sendRemindersByText = !state.sendRemindersByText;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPatientInfo.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getPatientInfo.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.firstName = action.payload?.firstName;
                state.middleName = action.payload?.middleName;
                state.lastName = action.payload?.lastName;
                state.SSN = action.payload?.ssn;
                state.dateOfBirth = action.payload?.dateOfBirth;
                state.gender = action.payload?.gender;
                state.otherGender = action.payload?.otherGender;
                state.address = action.payload?.address;
                state.state = action.payload?.state;
                state.city = action.payload?.city;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;
                state.numberOfPeople = action.payload?.numberOfPeople;
                state.numberOfChildren = action.payload?.numberOfChildren;
                state.maritalStatus = action.payload?.maritalStatus;
                state.sendEmail = action.payload?.sendEmail;
                state.internetAccess = action.payload?.internetAccess;
                state.canLeaveVoiceMessage = action.payload?.canLeaveVoiceMessage;
                state.sendRemindersByText = action.payload?.sendRemindersByText;

                state.loading = false;
            })
            .addCase(getPatientInfo.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Patient Information: ${action.error.message}`);
            })
            .addCase(setPatientInfo.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(setPatientInfo.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Patient Information saved successfully");
            })
            .addCase(setPatientInfo.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', action.payload);
            })
            .addCase(getPatientInfoById.pending, (state) => {
                state.getStatus = FETCH_STATUSES.REQUEST;
                state.getError = null;
                state.loading = true;
            })
            .addCase(getPatientInfoById.fulfilled, (state, action) => {
                state.getStatus = FETCH_STATUSES.SUCCESS;
                state.getError = null;

                state.firstName = action.payload?.firstName;
                state.middleName = action.payload?.middleName;
                state.lastName = action.payload?.lastName;
                state.SSN = action.payload?.ssn;
                state.dateOfBirth = action.payload?.dateOfBirth;
                state.gender = action.payload?.gender;
                state.otherGender = action.payload?.otherGender;
                state.address = action.payload?.address;
                state.state = action.payload?.state;
                state.city = action.payload?.city;
                state.zip = action.payload?.zip;
                state.phone = action.payload?.phone;
                state.email = action.payload?.email;
                state.numberOfPeople = action.payload?.numberOfPeople;
                state.numberOfChildren = action.payload?.numberOfChildren;
                state.maritalStatus = action.payload?.maritalStatus;
                state.sendEmail = action.payload?.sendEmail;
                state.internetAccess = action.payload?.internetAccess;
                state.canLeaveVoiceMessage = action.payload?.canLeaveVoiceMessage;
                state.sendRemindersByText = action.payload?.sendRemindersByText;

                state.loading = false;
            })
            .addCase(getPatientInfoById.rejected, (state, action) => {
                state.getStatus = FETCH_STATUSES.FAILURE;
                state.getError = action.error;
                state.loading = false;

                showToast('error', `Get Patient Information By Id: ${action.error.message}`);
            })
            .addCase(setPatientInfoById.pending, (state) => {
                state.setStatus = FETCH_STATUSES.REQUEST;
                state.setError = null;
            })
            .addCase(setPatientInfoById.fulfilled, (state) => {
                state.setStatus = FETCH_STATUSES.SUCCESS;
                state.setError = null;

                showToast("success", "Patient Information saved successfully");
            })
            .addCase(setPatientInfoById.rejected, (state, action) => {
                state.setStatus = FETCH_STATUSES.FAILURE;
                state.setError = action.error;

                showToast('error', `Set Patient Information By Id: ${action.error.message}`);
            })
    },
});

export const {
    setLoading,

    setFirstName,
    setMiddleName,
    setLastName,
    setSSN,
    setDateOfBirth,
    setGender,
    setOtherGender,
    setAddress,
    setCity,
    setState,
    setZip,
    setPhone,
    setEmail,
    setNumberOfPeople,
    setNumberOfChildren,
    setMaritalStatus,
    toggleSendEmail,
    toggleInternetAccess,
    toggleCanLeaveVoiceMessage,
    toggleSendRemindersByText,
} = newPatientRegistrationPatientInfo.actions;

export default newPatientRegistrationPatientInfo.reducer;