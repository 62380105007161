export const selectLoading = state => state.newPatientRegistrationTribalAffiliation.loading;

export const selectCorporation = state => state.newPatientRegistrationTribalAffiliation.corporation;
export const selectEnrollment = state => state.newPatientRegistrationTribalAffiliation.enrollment;
export const selectEthnicity = state => state.newPatientRegistrationTribalAffiliation.ethnicity;
export const selectRace = state => state.newPatientRegistrationTribalAffiliation.race;
export const selectRaceAlaska = state => state.newPatientRegistrationTribalAffiliation.raceAlaska;
export const selectRaceAmerican = state => state.newPatientRegistrationTribalAffiliation.raceAmerican;
export const selectLanguage = state => state.newPatientRegistrationTribalAffiliation.language;
export const selectOtherLanguage = state => state.newPatientRegistrationTribalAffiliation.otherLanguage;
export const selectCIB = state => state.newPatientRegistrationTribalAffiliation.CIB;
export const selectCIBCard = state => state.newPatientRegistrationTribalAffiliation.CIBCard;
export const selectTranslator = state => state.newPatientRegistrationTribalAffiliation.translator;
