import { setNextOfKin, setAddress, setCity, setEmail, setPhone, setRelationship, setState, setZip } from "../../../../../../store/users/patientProfile/basicInformation/nextOfKin/slice";

import { getNextKinById, saveNextOfKinById } from "../../../../../../store/users/patientProfile/basicInformation/nextOfKin/middlewares";
import { selectStates } from "../../../../../../store/lookups/state/selectors";
import { getState } from "../../../../../../store/lookups/state/middlewares";

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { NextOfKin } from "./NextOfKin";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const NextOfKinContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const states = useSelector(selectStates);

    const statePatientProfileNextOfKin     = useSelector(state => state.patientProfileNextOfKin);
	const getEntityPatientProfileNextOfKin = (name) => statePatientProfileNextOfKin[name];

    useEffect(() => {
        dispatch(getState());
        dispatch(getNextKinById(id));
    }, [dispatch, id]);

    const FN_TO_CALL = {
        NextOfKin: setNextOfKin,
        Relationship: setRelationship,
        Address: setAddress,
        City: setCity,
        State: setState,
        Zip: setZip,
        Phone: setPhone,
        Email: setEmail,
    };

    const onChangeFunc = (name, event, trim = false) => {
        dispatch(FN_TO_CALL[name](trim ? event.value.trim() : event.value));
        setShowButton(true);
    };

    const onCancelNextOfKin = () => {
        dispatch(getNextKinById(id));
        setShowButton(false);
    }

    const onSaveNextOfKin = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveNextOfKinById(id));
            setShowButton(false);
        }
    }

    return (
        <NextOfKin
            showButton={showButton}

            states={states}

            getEntityPatientProfileNextOfKin={getEntityPatientProfileNextOfKin}
            onChangeFunc={onChangeFunc}

            onCancelNextOfKin={onCancelNextOfKin}
            onSaveNextOfKin={onSaveNextOfKin}
        />
    )
};