import { selectConfirmPassword, selectEmail, selectError, selectPassword, selectUserName, selectBtnStatus } from '../../../store/auth/signup/selectors';
import { setConfirmPassword, setEmail, setPassword, setUserName } from '../../../store/auth/signup/slice';
import { postSignup } from '../../../store/auth/signup/middlewares';
import { login, logout } from '../../../core/auth/authProvider';
import { useDispatch, useSelector } from 'react-redux';
import { validateGroup } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { SignUp } from './SignUp';

export const SignUpContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const errorStatus = useSelector(selectError);

    const userName = useSelector(selectUserName);
    const email = useSelector(selectEmail);
    const password = useSelector(selectPassword);
    const confirmPassword = useSelector(selectConfirmPassword);
    const btnStatus = useSelector(selectBtnStatus);

    const onChangeUserName = (event) => {
        const value = event.value.trim();
        dispatch(setUserName(value));
    }

    const onChangeEmail = (event) => {
        const value = event.value.trim();
        dispatch(setEmail(value));
    }

    const onChangePassword = (event) => {
        const value = event.value;
        dispatch(setPassword(value));
    }

    const onChangeConfirmPassword = (event) => {
        const value = event.value;
        dispatch(setConfirmPassword(value));
    }

    const handleClick = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(postSignup({ userName, email, password })).then((data) => {
                if (!data.payload.errors) {
                    login({ 'accessToken': data.payload.token, 'refreshToken': data.payload.refreshToken });
                    navigate("/main/welcome");
                }
            }).catch(() => logout());
        };
    }

    const onKeyPressEnter = (event) => {
        if (event.key === 'Enter')
            handleClick();
    }

    return (
        <SignUp
            errorStatus={errorStatus}

            userName={userName}
            email={email}
            password={password}
            confirmPassword={confirmPassword}

            onChangeUserName={onChangeUserName}
            onChangeEmail={onChangeEmail}
            onChangePassword={onChangePassword}
            onChangeConfirmPassword={onChangeConfirmPassword}

            handleClick={handleClick}
            onKeyPressEnter={onKeyPressEnter}
            btnStatus={btnStatus}
        />
    );
}