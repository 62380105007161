import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../../utils/constants";
import { post, get } from "../../../core/rest";

export const postInviteLink = createAsyncThunk(
	"postInviteLink",
	async (data, { rejectWithValue }) => {
		try {
			return await post(BASE_URL + "/Invite/create", data)
		} catch (error) {
			return rejectWithValue(error.responseBody);
		};
	}
);

export const getInvate = createAsyncThunk(
	"getInvate",
	async (_, { getState }) => {
		const state = getState();
		return await get(BASE_URL + `/Invite/Invite?emailAddress=${state.providerGenerationInvites.data.email}`)
	}
);

export const postSendInvite = createAsyncThunk(
	"postSendInvite",
	async (_, { getState }) => {
		const state = getState();
		return await post(BASE_URL + `/Invite/SendInviteToEmail?inviteLink=${state.providerGenerationInvites.inviteKey}`)
	}
);