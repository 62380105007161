import { styled } from '@mui/material/styles';

export const Title = styled('h2')(() => ({
	color: 'var(--dark-blue-color)',
	fontFamily: 'Montserrat',
	lineHeight: '22px',
	fontSize: '18px',
	fontStyle: 'normal',
	fontWeight: '600',
	margin: '0px',
	'@media (max-width: 425px)': {
		fontSize: '14px',
		lineHeight: '18px',
	},
}));

export const IconKayak = styled('div')(() => ({
	position: 'absolute',
	top: '-16px',
	backgroundColor: '#002875',
	width: '40px',
	height: '40px',
	lineHeight: '48px',
	borderRadius: '50px',
	textAlign: 'center',
}));

export const ProgressBarContsainer = styled('div')(() => ({
	display: 'flex',
	gap: '8px',
}));

export const Step = styled('div')(({ color, steps }) => ({
	position: 'relative',
	backgroundColor: color,
	width: `${100 / steps.length}%`,
	height: '7px',
	borderRadius: '5px',
}));

export const Caption = styled('div')(() => ({
	marginTop: '20px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	alignItems: 'flex-end',
}));