import { setEducation, setLivingArrangement, setLivingCondition, toggleGradeSchoolEducation, setLastGradeEducation, setOtherLivingArrangement, setOtherEducation } from '../../../../../../store/users/patientProfile/basicInformation/education/slice';
import { getEducationById, saveEducationById } from '../../../../../../store/users/patientProfile/basicInformation/education/middlewares';

import { getLivingArrangements } from '../../../../../../store/lookups/livingArrangement/middlewares';
import { getLivingConditions } from '../../../../../../store/lookups/livingCondition/middlewares';
import { getEducations } from '../../../../../../store/lookups/education/middlewares';

import { selectLivingArrangements } from '../../../../../../store/lookups/livingArrangement/selectors';
import { selectLivingConditions } from '../../../../../../store/lookups/livingCondition/selectors';
import { selectEducations } from '../../../../../../store/lookups/education/selectors';

import { validateGroup } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { Education } from './Education';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const EducationContainer = () => {
    const [showButton, setShowButton] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();

    const educations = useSelector(selectEducations);
    const livingArrangements = useSelector(selectLivingArrangements);
    const livingConditions = useSelector(selectLivingConditions);

    const statePatientProfileEducation     = useSelector(state => state.patientProfileEducation);
	const getEntityPatientProfileEducation = (name) => statePatientProfileEducation[name];

    useEffect(() => {
        dispatch(getEducationById(id));
        dispatch(getEducations());
        dispatch(getLivingConditions());
        dispatch(getLivingArrangements());
    }, [dispatch, id]);

    const FN_TO_CALL = {
		Education: setEducation,
        OtherEducation: setOtherEducation,
        LastGradeEducation: setLastGradeEducation,
        GradeSchoolEducation: toggleGradeSchoolEducation,
        LivingArrangement: setLivingArrangement,
        OtherLivingArrangement: setOtherLivingArrangement,
        LivingCondition: setLivingCondition,
	};

    const onChangeFunc = (name, event = null) => {
        if(event) dispatch(FN_TO_CALL[name](event.value));
        else dispatch(FN_TO_CALL[name]());
        setShowButton(true);
    };

    const onCancelEducation = () => {
        dispatch(getEducationById(id));
        setShowButton(false);
    }

    const onSaveEducation = (event) => {
        const isValid = validateGroup(event);

        if (isValid) {
            dispatch(saveEducationById(id));
            setShowButton(false);
        }
    }

    return (
        <Education
            showButton={showButton}

            educations={educations}
            livingArrangements={livingArrangements}
            livingConditions={livingConditions}

            getEntityPatientProfileEducation={getEntityPatientProfileEducation}
            onChangeFunc={onChangeFunc}

            onCancelEducation={onCancelEducation}
            onSaveEducation={onSaveEducation}
        />
    );
};