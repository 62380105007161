import { Wrapper } from '../../../../../components/Main/Main.style';
import { EMPLOYMENT, GRID_SM, GRID_XS, MAX_WIDTH, ZIP_MASK } from '../../../../../utils/constants';
import { onKeyDownHandle } from '../../../../../utils/helper';
import { Grid } from '@mui/material';
import LoadPanel from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import Tooltip from '@mui/material/Tooltip';

import {
	Validator,
	RequiredRule,
} from 'devextreme-react/validator';

export const Employment = ({
	loading,

	states,
	employments,
	primaryIncomeSources,

	employment,
	employer,
	address,
	city,
	state,
	zip,
	primaryIncomeSource,
	otherPrimaryIncomeSource,

	onChangeEmployment,
	onChangeEmployer,
	onChangeAddress,
	onChangeCity,
	onChangeState,
	onChangeZip,
	onChangePrimaryIncomeSource,
	onChangeOtherPrimaryIncomeSource,

	onSaveEmployment,
	onSaveAndContinueEmployment,
}) => {
	return (
		<Wrapper id={EMPLOYMENT} maxWidth={MAX_WIDTH}>
			<LoadPanel
				visible={loading}
				position={{ of: `#${EMPLOYMENT}` }} />
			{
				!loading &&
				<Grid container spacing={2}>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Employment Status *">
							<>
								<SelectBox
									label="Employment Status *"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={employment}
									dataSource={employments}
									onValueChanged={onChangeEmployment}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-employment' }}
								>
									<Validator validationGroup={EMPLOYMENT}>
										<RequiredRule
											message="Employment is required"
										/>
									</Validator>
								</SelectBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Employer">
							<>
								<TextBox
									label="Employer"
									value={employer}
									onValueChanged={onChangeEmployer}
									maxLength={140}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-employer' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Address">
							<>
								<TextBox
									label="Address"
									value={address}
									onValueChanged={onChangeAddress}
									maxLength={150}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-address' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM} title="City">
						<Tooltip title="City">
							<>
								<TextBox
									label="City"
									value={city}
									onValueChanged={onChangeCity}
									maxLength={20}
									labelMode="floating"
									stylingMode="outlined"
									inputAttr={{ autocomplete: 'new-city' }}>
								</TextBox>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="State">
							<>
								<SelectBox
									label="State"
									defaultValue={state}
									valueExpr="Oid"
									displayExpr="Name"
									dataSource={states}
									onValueChanged={onChangeState}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-state' }}
								/>
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS} sm={GRID_SM}>
						<Tooltip title="Zip">
							<>
								<TextBox
									label="Zip"
									value={zip}
									onValueChanged={onChangeZip}
									labelMode="floating"
									stylingMode="outlined"
									onKeyDown={onKeyDownHandle}
									maskChar="."
									mask={ZIP_MASK}
									showMaskMode="onFocus"
									maskRules={{ X: /[0-9]/ }}
									maskInvalidMessage="Zip must have at least 5 symbols"
									validationMessagePosition="bottom"
									inputAttr={{ autocomplete: 'new-zip' }} />
							</>
						</Tooltip>
					</Grid>
					<Grid item xs={GRID_XS}>
						<Tooltip title="Primary Income Source">
							<>
								<SelectBox
									label="Primary Income Source"
									valueExpr="Oid"
									displayExpr="Name"
									defaultValue={primaryIncomeSource}
									dataSource={primaryIncomeSources}
									onValueChanged={onChangePrimaryIncomeSource}
									validationMessagePosition="bottom"
									labelMode="floating"
									stylingMode="outlined"
									searchEnabled={true}
									inputAttr={{ autocomplete: 'new-primaryIncomeSource' }}
								/>
							</>
						</Tooltip>
					</Grid>
					{
						primaryIncomeSource === (primaryIncomeSources.find((e) => e?.Name === "Other")?.Oid) &&
						<Grid item xs={GRID_XS}>
							<Tooltip title="Specify Primary Income Source">
								<>
									<TextBox
										label="Specify Primary Income Source"
										value={otherPrimaryIncomeSource}
										onValueChanged={onChangeOtherPrimaryIncomeSource}
										maxLength={64}
										labelMode="floating"
										stylingMode="outlined"
										inputAttr={{ autocomplete: 'new-otherPrimaryIncomeSource' }} />
								</>
							</Tooltip>
						</Grid>
					}
					<Grid item xs={GRID_XS} style={{ marginBottom: '30px', textAlign: 'right', gap: '10px', display: 'flex', justifyContent: 'flex-end' }} >
						<Button
							text="Save"
							type="default"
							stylingMode="outlined"
							validationGroup={EMPLOYMENT}
							onClick={onSaveEmployment} />
						<Button
							text="Save and continue"
							type="default"
							stylingMode="contained"
							validationGroup={EMPLOYMENT}
							onClick={onSaveAndContinueEmployment} />
					</Grid>
				</Grid>
			}
		</Wrapper>
	);
};