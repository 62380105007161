import { BASE_URL, EDUCATION } from "../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../core/rest";

export const getEducation = createAsyncThunk(
    "getEducation",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${EDUCATION}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getEducationByID = createAsyncThunk(
    "getEducationByID",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${EDUCATION}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postEducation = createAsyncThunk(
    "postEducation",
    async (data, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${EDUCATION}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postEducationByID = createAsyncThunk(
    "postEducationByID",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${EDUCATION}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveEducation = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationEducation;

    const data = {
        "education": store.education,
        "otherEducation": store.otherEducation,
        "lastGradeEducation": store.lastGradeEducation,
        "gradeSchoolEducation": store.gradeSchoolEducation,
        "arrangement": store.livingArrangement,
        "otherArrangement": store.otherLivingArrangement,
        "conditions": store.livingCondition
    }

    dispatch(postEducation(data));
}

export const saveEducationByID = (id) => (dispatch, getState) => {
    const store = getState().newPatientRegistrationEducation;

    const data = {
        "education": store.education,
        "otherEducation": store.otherEducation,
        "lastGradeEducation": store.lastGradeEducation,
        "gradeSchoolEducation": store.gradeSchoolEducation,
        "arrangement": store.livingArrangement,
        "otherArrangement": store.otherLivingArrangement,
        "conditions": store.livingCondition
    }

    dispatch(postEducationByID({ id, data }));
}

