import { Wrapper, Main, Header, Img, Title, SubTitle, Text, Link } from "./Welcome.style";
import { UnorderedList, Item } from "../../../components/Main/Main.style";
import Button from '../../../components/Button/Button';
import { useResizeDetector } from "react-resize-detector";
import { useNavigate } from 'react-router-dom';
import { FooterBlock } from '../FooterBlock/FooterBlock';

export const Welcome = () => {
	let navigate = useNavigate();
	const { width, ref } = useResizeDetector();

	const handleClick = () => {
		navigate("/main/forms/new-patient-registration-packet");
	}

	return (
		<Wrapper maxWidth="xl" ref={ref}>
			<Header>
				<Title>Welcome to <br /> APIA Behavioral Health</Title>
				<Img />
			</Header>
			<Main>
				<SubTitle>Introduction</SubTitle>
				<Text className="pb20">
					Dear New Patient,
				</Text>
				<Text>
					Thank you for choosing Aleutian Pribilof Islands Association, Inc. (APIA) for behavioral health services. We provide individual counseling, a wide variety of group therapy, substance use assessment, same day counseling for acute crisis, Community Reinforcement and Family Training (CRAFT) services and Awakuxtxin Intensive Outpatient Program (IOP) services in Anchorage, Atka, Nikolski, Saint George, and Unalaska/Dutch Harbor. We hope our services will provide a rewarding and valuable experience for you.
				</Text>
				<SubTitle>Requirements</SubTitle>
				<Text className="pb20">
					We require a registration packet for each patient. If you need assistance with the registration packet, or have questions, please contact the front desk at 1-907-222-9764.
				</Text>
				<Text>
					Below is the button which links to forms that can be filled in on your smart phone, iPad or computer. Please fill in each form and submit ID, Insurance Card and Proof of Tribal Enrollment (if applicable). The forms and all the documents you attach will be sent to registration.
				</Text>
				<Button
					label="go to forms"
					variant="contained"
					startIcon="ic:baseline-output"
					sx={{ marginTop: '32px' }}
					onClick={handleClick} />
				<SubTitle>Additional Information</SubTitle>
				<Text className="pb20">
					<b>Telehealth Services</b>
				</Text>
				<Text className="pb20">
					Telehealth services are available. Please review the APIA Consent for Electronic Communication form if you are scheduled or would like to receive telehealth services.
				</Text>
				<Text className="pb20">
					<b>Insurance</b>
				</Text>
				<Text className="pb20">
					We recommend contacting your insurance directly for pre-authorization and cost. APIA accepts IHS Benefits, Self-Pay, Medicaid, Medicare and Premera Blue Cross Blue Shield (BCBS). We are not considered “in-network” or contracted with any other insurance provider at this time.
				</Text>
				<UnorderedList style={{ paddingBottom: '20px' }}>
					<Item>
						<Text>To see if you qualify for Medicaid and/or Denali Kid Care, go to <Link href="https://www.healthcare.gov" target="_blank">www.healthcare.gov</Link></Text>
					</Item>
					<Item>
						<Text>For billing or insurance questions, go to <Link href="https://www.hrgpros.com" target="_blank">www.hrgpros.com</Link></Text>
					</Item>
				</UnorderedList>
				<Text className="pb20">
					<b>Our Website</b>
				</Text>
				<Text className="pb20">
					Please visit our <Link href="https://www.apiai.org/services/health-care/behavioral-health" target="_blank">website</Link> to learn more about our providers and behavioral health services.
				</Text>
				<FooterBlock width={width} />
			</Main>
		</Wrapper >
	);
};