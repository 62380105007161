import { getECommunicationConsent, setECommunicationConsent, getECommunicationConsentByID, setECommunicationConsentByID } from './middlewares';
import { CURRENT_DATE, FETCH_STATUSES } from '../../../utils/constants';
import { showToast } from '../../../screens/Main/MainContainer';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	telehealth: {
		decline: false,
		email: "",
		name: "",
		date: CURRENT_DATE,
		checkbox: false,
	},
	email: {
		decline: false,
		email: "",
		name: "",
		date: CURRENT_DATE,
		checkbox: false,
		signPicture: "",
	},
	getStatus: FETCH_STATUSES.IDLE,
	setStatus: FETCH_STATUSES.IDLE,
	getError: null,
	setError: null,
	loading: true,
};

export const eCommunicationConsent = createSlice({
	name: 'eCommunicationConsent',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		toggleDeclineTelehealth: (state) => {
			state.telehealth.decline = !state.telehealth.decline;
		},
		setEmailTelehealth: (state, action) => {
			state.telehealth.email = action.payload;
		},
		setNameTelehealth: (state, action) => {
			state.telehealth.name = action.payload;
		},
		setDateTelehealth: (state, action) => {
			state.telehealth.date = action.payload;
		},
		toggleCheckboxTelehealth: (state) => {
			state.telehealth.checkbox = !state.telehealth.checkbox;
		},
		toggleDeclineEmail: (state) => {
			state.email.decline = !state.email.decline;
		},
		setEmailEmail: (state, action) => {
			state.email.email = action.payload;
		},
		setNameEmail: (state, action) => {
			state.email.name = action.payload;
		},
		setDateEmail: (state, action) => {
			state.email.date = action.payload;
		},
		toggleCheckboxEmail: (state) => {
			state.email.checkbox = !state.email.checkbox;
		},
		setSignPicture: (state, action) => {
			state.email.signPicture = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getECommunicationConsent.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError = null;
			})
			.addCase(setECommunicationConsent.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError = null;
			})
			.addCase(getECommunicationConsent.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError = null;

				state.telehealth.decline = action.payload?.teleDto.decline;
				state.telehealth.email = action.payload?.teleDto.email;
				state.telehealth.name = action.payload?.teleDto.name;
				state.telehealth.date = action.payload?.teleDto.date || CURRENT_DATE;
				state.telehealth.checkbox = action.payload?.teleDto.checkbox;

				state.email.decline = action.payload?.emailDto.decline;
				state.email.email = action.payload?.emailDto.email;
				state.email.name = action.payload?.emailDto.name;
				state.email.date = action.payload?.emailDto.date || CURRENT_DATE;
				state.email.checkbox = action.payload?.emailDto.checkbox;
				state.email.signPicture = action.payload?.emailDto.signPicture;

				state.loading = false;
			})
			.addCase(setECommunicationConsent.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError = null;

				showToast("success", "Consent for Electronic Communication saved successfully");
			})
			.addCase(getECommunicationConsent.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError = action.error;
				state.loading = false;

				showToast('error', `Get Consent for Electronic Communication: ${action.error.message}`);
			})
			.addCase(setECommunicationConsent.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError = action.error;

				showToast('error', `Set Consent for Electronic Communication: ${action.error.message}`);
			})
			.addCase(getECommunicationConsentByID.pending, (state) => {
				state.getStatus = FETCH_STATUSES.REQUEST;
				state.getError  = null;
			})
			.addCase(getECommunicationConsentByID.fulfilled, (state, action) => {
				state.getStatus = FETCH_STATUSES.SUCCESS;
				state.getError  = null;

				state.telehealth.decline  = action.payload?.teleDto.decline;
				state.telehealth.email    = action.payload?.teleDto.email;
				state.telehealth.name     = action.payload?.teleDto.name;
				state.telehealth.date     = action.payload?.teleDto.date || CURRENT_DATE;
				state.telehealth.checkbox = action.payload?.teleDto.checkbox;

				state.email.decline     = action.payload?.emailDto.decline;
				state.email.email       = action.payload?.emailDto.email;
				state.email.name        = action.payload?.emailDto.name;
				state.email.date        = action.payload?.emailDto.date || CURRENT_DATE;
				state.email.checkbox    = action.payload?.emailDto.checkbox;
				state.email.signPicture = action.payload?.emailDto.signPicture;

				state.loading = false;
			})
			.addCase(getECommunicationConsentByID.rejected, (state, action) => {
				state.getStatus = FETCH_STATUSES.FAILURE;
				state.getError  = action.error;
				state.loading   = false;

				showToast('error', `Get Consent for Electronic Communication: ${action.error.message}`);
			})
			.addCase(setECommunicationConsentByID.pending, (state) => {
				state.setStatus = FETCH_STATUSES.REQUEST;
				state.setError  = null;
			})
			.addCase(setECommunicationConsentByID.fulfilled, (state) => {
				state.setStatus = FETCH_STATUSES.SUCCESS;
				state.setError  = null;

				showToast("success", "Consent for Electronic Communication saved successfully");
			})
			.addCase(setECommunicationConsentByID.rejected, (state, action) => {
				state.setStatus = FETCH_STATUSES.FAILURE;
				state.setError  = action.error;

				showToast('error', `Set Consent for Electronic Communication: ${action.error.message}`);
			})
	},
});

export const {
	setLoading,

	toggleDeclineTelehealth,
	setEmailTelehealth,
	setNameTelehealth,
	setDateTelehealth,
	toggleCheckboxTelehealth,
	setSignPicture,

	toggleDeclineEmail,
	setEmailEmail,
	setNameEmail,
	setDateEmail,
	toggleCheckboxEmail,
} = eCommunicationConsent.actions;

export default eCommunicationConsent.reducer;