import { BASE_URL, REFERRAL_INFO } from "../../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getReferralInfoById = createAsyncThunk(
    "getReferralInfoById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${REFERRAL_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postReferralInfoById = createAsyncThunk(
    "postReferralInfoById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${REFERRAL_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveReferralInfoById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileReferralInfo;

    const data = {
        "reason": store.reason,
        "referralInfo": store.referralInfo,
        "otherReferralInfo": store.otherReferralInfo,
        "refferalForMedicalRN": store.refferalForMedicalRN,
        "contactedByPhysicalExam": store.contactedByPhysicalExam
    }

    dispatch(postReferralInfoById({ id, data }));
}