import { BASE_URL, EDUCATION } from "../../../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getEducationById = createAsyncThunk(
    "getEducationById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${EDUCATION}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postEducationById = createAsyncThunk(
    "postEducationById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${EDUCATION}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveEducationById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileEducation;

    const data = {
        "education": store.education,
        "otherEducation": store.otherEducation,
        "lastGradeEducation": store.lastGradeEducation,
        "gradeSchoolEducation": store.gradeSchoolEducation,
        "arrangement": store.livingArrangement,
        "otherArrangement": store.otherLivingArrangement,
        "conditions": store.livingCondition
    }

    dispatch(postEducationById({ id, data }));
}

