import MuiStepButton from '@mui/material/StepButton';
import MuiContainer from '@mui/material/Container';
import MuiStepper from '@mui/material/Stepper';
import MuiStep from '@mui/material/Step';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

const height = window.innerHeight - 100;

export const Wrapper = styled(Box)(() => ({
  height: height,
  width: '100%',
  display: 'flex',
  bgcolor: 'var(--background-color)',
  '& .MuiStepConnector-root': {
    flex: 'inherit',
  },
}));

export const Container = styled(MuiContainer)(() => ({
  '@media (max-width: 768px)': {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
}));

export const ScrollableWrapper = styled('div')(() => ({
  margin: "30px 0 0 30px",
  minWidth: "300px",
  height: "100vh",
  '@media (max-width: 1224px)': {
    minWidth: '200px',
  },
}));

export const FixedWrapper = styled('div')(() => ({
  background: '#fff',
  position: 'fixed',
  bottom: '0px',
  zIndex: '1',
  height: '56px',
  display: 'flex',
  justifyContent: 'space-between',
  userSelect: 'none',
}));

export const Stepper = styled(MuiStepper)(({ height }) => ({
  height: height,
  position: "fixed",
  overflowY: 'auto',
  overflowX: 'hidden',
  '& .MuiStepConnector-root': {
    marginLeft: '20px',
  },
  '@media (max-width: 768px)': {
    justifyContent: 'space-between',
    width: 'calc(100vw - 214px)',
    minWidth: '220px',
    position: "unset",
    '& .MuiStepConnector-root': {
      marginLeft: 'unset',
    },
  },
  '@media (max-width: 425px)': {
    width: 'calc(100vw - 150px)',
  },
}));

export const Step = styled(MuiStep)(() => ({
  '@media (max-width: 768px)': {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
}));

export const StepButton = styled(MuiStepButton, {
  shouldForwardProp: (prop) => prop !== 'complete'
})(({ complete }) => ({
  padding: 'unset',
  margin: 'unset',
  width: '300px',
  '& .MuiStepLabel-iconContainer': {
    backgroundColor: complete ? 'var(--green-text-color)' : 'var(--grey-icon-color)',
    color: 'var(--white-color)',
    borderRadius: '10px',
    padding: '0px',
    margin: '0px',
    width: '38px',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiStepLabel-labelContainer': {
    paddingLeft: '14px',
  },
  '& .MuiStepLabel-label.Mui-completed:not(.Mui-active)': {
    color: 'inherit',
  },
  '& .MuiStepLabel-iconContainer.Mui-active': {
    backgroundColor: complete ? 'var(--dark-green-color)' : 'var(--light-blue-color)',
  },
  '@media (max-width: 1224px)': {
    width: '200px',
  },
  '@media (max-width: 768px)': {
    width: '28px',
    justifyContent: 'flex-start',
    '& .MuiStepLabel-iconContainer': {
      width: '28px',
      height: '28px',
    },
  },
}));