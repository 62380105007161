import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { getAdminsList } from '../../../store/users/adminsList/middlewares';
import { setLoading, setActiveAdminId } from '../../../store/users/adminsList/slice';
import { selectLoading, selectActiveValue } from "../../../store/users/adminsList/selectors";
import { SELECT_ADMINS } from '../../../utils/constants';

import { AdminsProfile } from './AdminsProfile';

export const AdminsProfileContainer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector(selectLoading);
	const dataSource = useSelector(selectActiveValue);
	const { id } = useParams();
	
	useEffect(() => {
		dispatch(setLoading(true));
		dispatch(getAdminsList());
		dispatch(setActiveAdminId(id));
	}, [dispatch, id]);

	const onClickBack = () => {
		navigate("../admins", { state: { value: SELECT_ADMINS } });
		dispatch(setActiveAdminId(id));
	}

	return (
		<AdminsProfile
			loading={loading}
			onClickBack={onClickBack}
			dataSource={dataSource}
		/>
	);
}