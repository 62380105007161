import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '100px 80px 0px',
    '@media (max-width: 1400px)': {
        margin: '50px 20px 0px',
    },
}));

export const Title = styled('div')(() => ({
    color: 'var(--dark-blue-color)',
    fontFamily: 'Montserrat',
    userSelect: 'none',
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '44px',
}));

export const Text = styled('div')(() => ({
    color: 'var(--black-blue-color)',
    fontFamily: 'Montserrat',
    userSelect: 'none',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '26px',
    marginTop: '40px',
}));