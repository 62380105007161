import { BASE_URL } from "../../../utils/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setActiveStep } from "./slice";
import { get } from "../../../core/rest";

export const getNewPatientRegistrationPacket = createAsyncThunk(
    "getNewPatientRegistrationPacket",
    async (_, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + "/FormsInfo/completeness");
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const getNewPatientRegistrationPacketById = createAsyncThunk(
    "getNewPatientRegistrationPacketById",
    async (patientID, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/FormsInfo/completeness?userId=${patientID}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const setNewActiveStep = () => (dispatch, getState) => {
    const store = getState().newPatientRegistrationPacket;

    (store.isActiveStep === store.isStepsLength - 1)
        ? dispatch(setActiveStep(0))
        : dispatch(setActiveStep(store.isActiveStep + 1));
};
