import { BASE_URL, HEALTH_INFO } from "../../../../../utils/constants";
import { dateFormatForApi } from "../../../../../utils/formats";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../../../../core/rest";

export const getHealthAndTreatmentById = createAsyncThunk(
    "getHealthAndTreatmentById",
    async (id, { rejectWithValue }) => {
        try {
            return await get(BASE_URL + `/NewPatientRegistration/${id}/${HEALTH_INFO}`);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const postHealthAndTreatmentById = createAsyncThunk(
    "postHealthAndTreatmentById",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            return await post(BASE_URL + `/NewPatientRegistration/${id}/${HEALTH_INFO}`, data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        };
    },
);

export const saveHealthAndTreatmentById = (id) => (dispatch, getState) => {
    const store = getState().patientProfileHealthAndTreatment;

    const data = {
        "pregnant": store.pregnantStatus,
        "physicalExam": dateFormatForApi(store.physicalExam),
        "drugUser": store.drugUser,
        "haveEverBeenInHospital": store.haveEverBeenInHospital,
        "haveBeenInHospitalLast6Months": store.haveBeenInHospitalLast6Months,
        "howManyMentalHealthHospitals": store.howManyMentalHealthHospitals,
        "numberOfArrests": store.numberOfArrests,
    }

    dispatch(postHealthAndTreatmentById({ id, data }));
}